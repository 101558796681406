import React, { useEffect, useState, useRef } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { Icon, Divider } from 'components';
import { Button } from '@estudio-nk/the-crane-club-client-lib';
import { useTranslation } from 'react-i18next';
import { gsap } from 'gsap';
import { useFilterQueryParams } from 'hooks';
import { useKeyPress } from 'hooks/useKeyPress';

export const Modal = ({
  visible,
  toggle,
  title,
  buttons,
  children,
  className,
  variant = 'primary'
}) => {
  const { t } = useTranslation();
  const escPress = useKeyPress('Escape');
  const [active, setActive] = useState(false);
  const { params, setParams, removeParam } = useFilterQueryParams();
  const modalRef = useRef();

  const handleButtonClick = (onClick) => {
    close();
    onClick && onClick();
  };

  const close = () => {
    params.modal && removeParam('modal');
    toggle && toggle();
  };

  useEffect(() => {
    if (visible && !active) {
      // setParams({ modal: true, 'no-scroll': true });
      //setActive(true);
      gsap.fromTo(
        modalRef.current,
        {
          opacity: 0,
          scale: 0.9
        },
        {
          opacity: 1,
          scale: 1,
          duration: 0.5,
          ease: 'Power3.easeOut'
        }
      );
    }
  }, [visible, active]);

  // useEffect(() => {
  //   if (active && visible && !params.modal && toggle) {
  //     setActive(false);
  //     toggle();
  //   }
  // }, [params, toggle, visible, active]);

  useEffect(() => {
    if (escPress && visible && toggle) {
      close();
    }
  }, [escPress]);

  return visible
    ? ReactDOM.createPortal(
        <div className="fixed top-0 left-0 w-full h-screen z-50">
          <div
            className={cn(
              'modal-content absolute top-2/4 left-0 right-0 m-auto rounded-lg max-w-full w-11/12 md:w-min z-10 overflow-auto -mt-8 md:-mt-0 bg-black-light ',
              className,
              { 'pt-7': variant === 'primary' }
            )}
            style={{ transform: 'translateY(-50%)', maxHeight: '95vh' }}
            role="dialog"
            aria-modal="true"
            ref={modalRef}
          >
            {variant === 'success' ? (
              <div className="h-48 bg-green flex justify-center items-center">
                <svg
                  width="124"
                  height="124"
                  viewBox="0 0 124 124"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle
                    cx="62"
                    cy="62"
                    r="46.5"
                    stroke="white"
                    stroke-width="4"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M41.3327 62L56.8327 77.5L82.666 46.5"
                    stroke="white"
                    stroke-width="4"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
            ) : (
              <div className="flex items-center justify-between px-5 md:px-11 pb-4 md:pb-7">
                <span className="text-2xl text-gray font-bold tracking-tight">
                  {title}
                </span>
                <Icon
                  name="Close"
                  width={24}
                  height={24}
                  onClick={close}
                  className="transition-all duration-300 transform hover:rotate-90 cursor-pointer"
                />
              </div>
            )}

            {/* <Divider className="mb-10" /> */}

            <div className="px-5 md:px-11 h-full w-full">{children}</div>

            {buttons && (
              <>
                <Divider className="mt-5 md:mt-10" />

                <div className="flex items-center justify-center py-5 px-11">
                  {buttons?.map((button, index) => (
                    <Button
                      key={index}
                      onClick={() => handleButtonClick(button.onClick)}
                      className="px-5 md:px-11 mx-1 w-max"
                      colorVariant="yellow"
                      {...button}
                    >
                      {t(button.text)}
                    </Button>
                  ))}
                </div>
              </>
            )}
          </div>

          <div
            className="modal-overlay absolute top-0 left-0 w-full h-full backdrop-filter backdrop-blur-sm bg-black bg-opacity-20"
            onClick={close}
          ></div>
        </div>,

        document.body
      )
    : null;
};

Modal.PropTypes = {
  visible: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
  title: PropTypes.string,
  buttons: PropTypes.array,
  children: PropTypes.object,
  className: PropTypes.string,
  variant: PropTypes.oneOf(['primary', 'success'])
};
