import React from 'react';
import { SideImage } from 'components';

import './index.scss';

export const AuthLayout = ({ children }) => {
  return (
    <>
      <SideImage />
      <main className="auth-main">
        <div className="bg-black-light">{children}</div>
      </main>
    </>
  );
};
