import React from 'react';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Icon, Divider } from 'components';
import { Link } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import cn from 'classnames';

const SidebarCompany = ({ closeSideBarOnClick }) => {
  const location = useLocation();
  const path = location.pathname.replace('/dashboard/', '');
  const { t } = useTranslation();
  const isDesktop = useMediaQuery({
    query: '(min-width: 1024px)'
  });

  return (
    <>
      <li
        className={cn('dashboard-sidebar-item', {
          'dashboard-sidebar-item-selected': path === 'machines'
        })}
      >
        <Link to="/dashboard/machines" onClick={closeSideBarOnClick}>
          <Icon name="Machine" width={21} height={21} canvasWidth={22} center />
          <span>{t('myMachineInventory')}</span>
        </Link>
      </li>
      <li
        className={cn('dashboard-sidebar-item', {
          'dashboard-sidebar-item-selected': path === 'quotations'
        })}
      >
        <Link to="/dashboard/quotations" onClick={closeSideBarOnClick}>
          <Icon name="Pencil" width={17} height={17} canvasWidth={22} center />
          <span>{t('quotationsReceived')}</span>
        </Link>
      </li>

      {!isDesktop && (
        <>
          <Divider className="my-5 md:my-6" />
          <li
            className={cn('dashboard-sidebar-item', {
              'dashboard-sidebar-item-selected': path === 'social'
            })}
          >
            <Link to="/dashboard/social" onClick={closeSideBarOnClick}>
              <Icon
                name="Users"
                width={20}
                height={20}
                canvasWidth={22}
                center
              />
              <span>{t('socialClub')}</span>
            </Link>
          </li>
          <li
            className={cn('dashboard-sidebar-item', {
              'dashboard-sidebar-item-selected': path === 'notifications'
            })}
          >
            <Link to="/dashboard/notifications" onClick={closeSideBarOnClick}>
              <Icon
                name="Bells"
                width={19}
                height={19}
                canvasWidth={22}
                center
              />
              <span>{t('notificationsSidebar')}</span>
            </Link>
          </li>
          <li
            className={cn('dashboard-sidebar-item', {
              'dashboard-sidebar-item-selected': path.includes('clicks')
            })}
          >
            <Link to="/dashboard/clicks" onClick={closeSideBarOnClick}>
              <Icon
                name="World"
                width={17}
                height={17}
                canvasWidth={22}
                center
              />
              <span>Clicks</span>
            </Link>
          </li>
          <li
            className={cn('dashboard-sidebar-item', {
              'dashboard-sidebar-item-selected': path.includes('last-seens')
            })}
          >
            <Link to="/dashboard/last-seens" onClick={closeSideBarOnClick}>
              <Icon name="Eye" width={17} height={17} canvasWidth={22} center />
              <span>{t('lastSeens')}</span>
            </Link>
          </li>
          <li
            className={cn('dashboard-sidebar-item', {
              'dashboard-sidebar-item-selected': path.includes('recieved-likes')
            })}
          >
            <Link to="/dashboard/recieved-likes" onClick={closeSideBarOnClick}>
              <Icon
                name="Heart"
                width={17}
                height={17}
                canvasWidth={22}
                center
              />
              <span>{t('machinesLikes')}</span>
            </Link>
          </li>
        </>
      )}
      <Divider className="my-5 md:my-6" />

      <li
        className={cn('dashboard-sidebar-item', {
          'dashboard-sidebar-item-selected': path === 'likes'
        })}
      >
        <Link to="/dashboard/likes" onClick={closeSideBarOnClick}>
          <Icon name="Heart" width={17} height={17} canvasWidth={22} center />
          <span>{t('myMachinesLikes')}</span>
        </Link>
      </li>
      <li
        className={cn('dashboard-sidebar-item', {
          'dashboard-sidebar-item-selected': path === 'favourites'
        })}
      >
        <Link to="/dashboard/favourites" onClick={closeSideBarOnClick}>
          <Icon
            name="Bookmark"
            width={14}
            height={17}
            canvasWidth={22}
            center
          />
          <span>{t('myFavourites')}</span>
        </Link>
      </li>
      <li
        className={cn('dashboard-sidebar-item', {
          'dashboard-sidebar-item-selected': path === 'quotations-sent'
        })}
      >
        <Link to="/dashboard/quotations-sent" onClick={closeSideBarOnClick}>
          <Icon name="Pen" width={17} height={17} canvasWidth={22} center />
          <span>{t('quotationsSent')}</span>
        </Link>
      </li>

      <Divider className="my-5 md:my-6" />

      <li
        className={cn('dashboard-sidebar-item', {
          'dashboard-sidebar-item-selected': path === 'chat'
        })}
      >
        <Link to="/dashboard/chat" onClick={closeSideBarOnClick}>
          <Icon name="Chat" width={19} height={19} canvasWidth={22} center />
          <span>{t('chat')}</span>
        </Link>
      </li>

      <Divider className="my-5 md:my-6" />
    </>
  );
};

export default SidebarCompany;
