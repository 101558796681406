import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import path from 'path';

import { isValidAsset } from 'utils/validators';
import { Icon } from 'components';

export const FileUploader = ({ setFiles, files }) => {
  const { t } = useTranslation();
  const imageRef = useRef();

  const handlerClickAddImages = () => {
    imageRef.current.click();
  };

  const handleChangeImage = () => {
    const currentFiles = [];
    Array.from(imageRef.current.files).forEach((file) => {
      if (!isValidAsset(file)) {
        toast.error(`${file.name} ${t('notValidImage')}`);
      } else {
        currentFiles.push({
          file,
          extension: path.extname(file.name),
          url: URL.createObjectURL(file)
        });
      }
    });
    setFiles([...files, ...currentFiles]);
  };

  return (
    <div
      onClick={handlerClickAddImages}
      className="flex items-center w-full justify-start cursor-pointer"
    >
      <input
        onChange={handleChangeImage}
        type="file"
        hidden
        multiple
        ref={imageRef}
      />
      <Icon name="GrayImage" width={22} height={22} />
      <span className="text-sm ml-2">{t('PhotoOrVideo')}</span>
    </div>
  );
};
