import React from 'react';
import { Helmet } from 'react-helmet';

const defaultMeta = {
  siteName: 'The Machinery Club',
  type: 'website',
  title: 'The Machinery Club - Rent, Sell, Buy.',
  description:
    "Let's get your machinery on the club. Rent, sell, buy and connect your business with the world.",
  image: 'https://themachineryclub.com/og-wapp.jpg'
};

export const MetaTags = () => {
  return (
    <Helmet
      titleTemplate="The Machinery Club - %s"
      defaultTitle="The Machinery Club"
    >
      <meta property="og:site_name" content={defaultMeta.siteName} />
      <meta property="og:type" content={defaultMeta.type} />
      <meta property="og:description" content={defaultMeta.description} />
      <meta property="og:image" content={defaultMeta.image} />
      <meta property="og:url" content={window.location.href} />
      <meta name="twitter:card" content="summary" />
      <meta name="twitter:description" content={defaultMeta.description} />
      <meta name="twitter:title" content={defaultMeta.title} />
      <meta name="twitter:site" content={defaultMeta.siteName} />
      <meta name="twitter:image" content={defaultMeta.image} />
    </Helmet>
  );
};
