import React from 'react';
import { useTranslation } from 'react-i18next';
import { Map, Location } from 'components';

export const PublicationMap = ({ publication }) => {
  const { t } = useTranslation();

  return (
    <div>
      <div className="md:flex items-center mb-5">
        <h4 className="text-xl font-weight-300 mr-6 mb-5 md:mb-0">
          {t('location')}
        </h4>
        <div className="w-max justify-center rounded-full border-1/2 border-solid border-yellow py-2.5 px-3 max-w-full">
          <Location
            location={publication?.location}
            textClasses={'whitespace-nowrap'}
          />
        </div>
      </div>

      <div className="rounded-lg p-3 md:p-6 h-60 md:h-110 bg-black-light">
        <Map
          markers={publication?.location ? [publication?.location] : null}
          className="h-full"
        />
      </div>
    </div>
  );
};
