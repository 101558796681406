import React, { useContext } from 'react';
import { UserContext } from 'contexts/UserContext';
import { Button } from '@estudio-nk/the-crane-club-client-lib';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';

export const Welcome = ({ className }) => {
  const { t } = useTranslation();
  const { user } = useContext(UserContext);

  return (
    <div className={cn('cb-welcome', className)}>
      <h3 className={cn(user ? 'text-xl' : 'text-center text-2xl')}>
        {t('welcome')}
        {user && ','}
        {user ? (
          <strong className="block text-xxl">{user.name}</strong>
        ) : (
          <div className="mt-5">
            <Button linkTo="/auth/register" colorVariant="yellow">
              {t('createAccount')}
            </Button>
            <Button
              className="mt-2"
              variant="outline"
              linkTo="/auth/login"
              colorVariant="yellow"
            >
              {t('login')}
            </Button>
          </div>
        )}
      </h3>
    </div>
  );
};
