import React, { useContext } from 'react';
import cn from 'classnames';
import { Link } from 'react-router-dom';
import { AvatarUser } from 'components';
import { UserContext } from 'contexts/UserContext';
import { putSeenNotification } from 'api/notifications';
import { useTranslation } from 'react-i18next';
import { profileToURL, distanceToNow } from 'utils/formatter';

export const Notification = ({
  notification,
  className,
  textClasses,
  textContainerClasses,
  userNameClasses,
  badgeSize
}) => {
  const { t } = useTranslation();
  const {
    quotationsNotifications,
    unseenQuotations,
    notifications,
    unseenNotifications,
    setUnseenNotifications,
    setNotifications,
    setQuotationsNotifications,
    setUnseenQuotations
  } = useContext(UserContext);
  const isQuotation = notification.type === 'new-publication-quotation';

  const handleOnClick = async () => {
    if (isQuotation) {
      if (!notification.seen && unseenQuotations > 0) {
        try {
          await putSeenNotification(notification.id);
          setUnseenQuotations(unseenQuotations - 1);
          const _notifications = quotationsNotifications.map((n) =>
            notification.id === n.id ? { ...n, seen: true } : n
          );
          setQuotationsNotifications(_notifications);
        } catch (error) {}
      }
    } else {
      if (!notification.seen && unseenNotifications > 0) {
        try {
          await putSeenNotification(notification.id);
          setUnseenNotifications(unseenNotifications - 1);
          const _notifications = notifications.map((n) =>
            notification.id === n.id ? { ...n, seen: true } : n
          );
          setNotifications(_notifications);
        } catch (error) {}
      }
    }
  };

  return notification ? (
    <div onClick={handleOnClick}>
      <AvatarUser
        className={className}
        user={notification?.user}
        userNameClasses={cn(
          'text-white-secondary font-semibold',
          userNameClasses
        )}
        badgeSize={badgeSize}
        text={
          <Link
            className={cn('hover:underline decoration-1', textClasses, {
              'md:ml-1':
                !notification?.user?.isInfluencer &&
                !notification?.user?.isVIP &&
                !notification?.user?.company?.isVerified
            })}
            to={notification?.url || profileToURL(notification?.user)}
          >
            {isQuotation ? (
              <>
                {t('requestAQuoteFor')}{' '}
                <span className="semibold hover:underline">
                  {t(notification.description)}
                </span>
              </>
            ) : (
              t(notification.type)
            )}
          </Link>
        }
        textClasses={textContainerClasses}
      >
        <span className="text-xs text-gray flex items-center">
          {!notification.seen ? (
            <div className="mr-2 w-2 h-2 bg-yellow rounded-full relative"></div>
          ) : null}

          {notification?.createdAt
            ? distanceToNow(notification?.createdAt)
            : distanceToNow(new Date())}
        </span>
      </AvatarUser>
    </div>
  ) : null;
};
