import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

export const Box = ({
  title,
  buttonText,
  onClick,
  children,
  className,
  loading,
  variant = 'primary'
}) => (
  <div
    className={cn(
      'bg-black-light  rounded-lg overflow-hidden',
      {
        'opacity-40': loading,
        'py-5 md:py-6 px-6 md:px-9 shadow-m': variant === 'primary',
        'py-4 px-5': variant === 'flat'
      },
      className
    )}
  >
    {title && (
      <div className={cn('flex items-center justify-between mb-6')}>
        <h4
          className={cn({
            'bg-gray-dark w-2/4 h-4 rounded-xs text-transparent': loading,
            'text-white': !loading,
            'uppercase font-semibold text-sm': variant === 'primary',
            'text-base': variant === 'flat'
          })}
        >
          {title}
        </h4>
        {buttonText && onClick && !loading && (
          <button
            onClick={onClick}
            className="text-xs text-yellow hover:underline"
          >
            {buttonText}
          </button>
        )}
      </div>
    )}
    <div className={cn('h-full')}>{!loading && children}</div>
  </div>
);

Box.propTypes = {
  title: PropTypes.string,
  buttonText: PropTypes.string,
  onClick: PropTypes.func,
  children: PropTypes.node,
  className: PropTypes.string,
  loading: PropTypes.bool,
  variant: PropTypes.oneOf(['primary', 'flat'])
};
