import React, { useEffect, useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { PublicationFiltersContext } from 'contexts/PublicationFiltersContext';
import cn from 'classnames';
import { Spinner, Divider } from 'components';
import { CategoryCard } from 'components';
import { getCategoriesByTypeWithCount } from 'api/categories';
import { useFilterQueryParams } from 'hooks';

export const ExploreCategories = () => {
  const { t } = useTranslation();
  const [filteredTypes, setTypes] = useState([]);
  const [loading, setLoading] = useState(true);
  const { setParams } = useFilterQueryParams();
  const { setLoadingFilters, setOperationType, setFilters, setType } =
    useContext(PublicationFiltersContext);

  useEffect(() => {
    setLoadingFilters(true);
    fetchTypes();
  }, []);

  const fetchTypes = async () => {
    try {
      setLoading(true);
      const res = await getCategoriesByTypeWithCount();
      setTypes(res);
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  const handleClick = (type, category) => {
    setParams(
      { type: type.id, categories: [category.id], operationType: 'both' },
      '/machines'
    );
    setType(type.id);
    setOperationType('both');
    setFilters({ categories: [category.id] });
    setLoadingFilters(false);
  };

  const renderType = (type, categories) => (
    <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-3 lg:grid-cols-4 2xl:grid-cols-5 gap-4 lg:gap-8 ">
      {categories?.map((category, index) => (
        <CategoryCard
          key={index}
          category={category}
          onClick={() =>
            category.publicationCount !== '0' && handleClick(type, category)
          }
          buttonText={'viewMachines'}
          disabled={category.publicationCount === '0'}
          className={cn(
            'pb-5 rounded-lg flex flex-col justify-between bg-black-light group overflow-hidden transition duration-300',
            {
              'border border-solid border-gray-dark hover:border-yellow cursor-pointer':
                category.publicationCount !== '0'
            }
          )}
        />
      ))}
    </div>
  );

  return loading ? (
    <Spinner />
  ) : (
    <div className="wrapper-lg upload-category bg-background">
      <div className="px-0 md:px-10 lg:px-20">
        {filteredTypes.length ? (
          filteredTypes.map(
            (type, i) =>
              type.categories?.length > 0 && (
                <>
                  <div className="my-10">
                    <p className="text-xl font-medium uppercase mb-8">
                      {type.name}
                    </p>
                    {renderType(
                      { id: type.id, name: type.name },
                      type.categories
                    )}
                  </div>
                  <Divider />
                </>
              )
          )
        ) : (
          <div className="grid grid-cols-2 sm:grid-cols-2 md:grid-cols-4 lg:grid-cols-4 2xl:grid-cols-5 gap-8 lg:gap-8">
            {new Array(10).fill(0).map((_, index) => (
              <div
                key={index}
                className="animate-pulse p-5 rounded-lg flex flex-col justify-between cursor-pointer group overflow-hidden"
              >
                <span className="h-40 rounded-xl opacity-20 mb-4 w-full bg-background" />
                <span className="h-6 w-full bg-background opacity-50 mb-2" />
                <span className="h-5 w-14 bg-yellow opacity-20" />
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};
