import { useEffect, useState } from 'react';
import { getPostsFiles } from 'api/social/posts';
import { postToURL } from 'utils/formatter';
import { Link } from 'react-router-dom';
import { InfiniteScroll, PostFile } from 'components';
import { PullDownToRefresh } from '@estudio-nk/the-crane-club-client-lib';
import { useTranslation } from 'react-i18next';

const PER_PAGE = 18;

export const ExploreSocial = () => {
  const [loading, setLoading] = useState(true);
  const [hasMore, setHasMore] = useState(true);
  const [selectedPage, setSelectedPage] = useState([]);
  const [resultsTotal, setResultsTotal] = useState({});
  const [media, setMedia] = useState([]);
  const { t } = useTranslation();

  useEffect(() => {
    fetch(true);
  }, []);

  const fetch = async (useCache) => {
    try {
      const { count, totalPages, rows } = await getPostsFiles(
        0,
        PER_PAGE,
        undefined,
        useCache
      );
      setMedia(rows);
      setSelectedPage(0);
      if (totalPages === 1) {
        setHasMore(false);
      }
      setResultsTotal({ count: count, totalPages: totalPages });
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const loadNextPages = async () => {
    if (!loading) {
      try {
        const nextPage = selectedPage + 1;
        setSelectedPage(nextPage);
        const { rows } = await getPostsFiles(nextPage, PER_PAGE);
        setMedia([...media, ...rows]);
        const restPosts = resultsTotal.count - rows.length;
        setResultsTotal({ ...resultsTotal, count: restPosts });
        if (restPosts - PER_PAGE < PER_PAGE) {
          setHasMore(false);
          const { rows } = await getPostsFiles(nextPage, restPosts);
          setMedia([...media, ...rows]);
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  return (
    <div>
      <PullDownToRefresh onRefresh={fetch}>
        <InfiniteScroll
          next={loadNextPages}
          dataLength={media.length}
          hasMore={hasMore}
        >
          <div className="grid grid-cols-3 gap-0">
            {media.map(({ postFiles, id }, i) => (
              <Link
                to={postToURL(id, postFiles[0].order)}
                key={i}
                className="h-32 rounded-md"
              >
                <PostFile
                  file={postFiles[0]}
                  size={400}
                  videoOptions={{
                    playing: true,
                    loop: true,
                    muted: true,
                    playsinline: true
                  }}
                  videoPreview
                  videoClasses="flex justify-center rounded-lg w-full h-full"
                  imageClasses="object-cover w-full h-full"
                />
              </Link>
            ))}
          </div>
        </InfiniteScroll>
      </PullDownToRefresh>
    </div>
  );
};
