import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { getPublicationStatus } from 'utils/formatter';
import './index.scss';

export const PublicationBadge = ({ publication }) => {
  const { t } = useTranslation();
  const { isActive, isVerified, verifiedAt } = publication;
  const colors = {
    active: 'green',
    rejected: 'red',
    review: 'yellow',
    inactive: 'gray'
  };

  const label = useMemo(
    () => getPublicationStatus(isActive, isVerified, verifiedAt),
    [isActive, isVerified, verifiedAt]
  );

  return (
    <div className={`cb-badge ${colors[label]}`}>{t(`badges.${label}`)}</div>
  );
};
