import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { getPostLikes } from 'api/social/posts';
import { LikesModal } from 'components';

import './index.scss';

export const PostLikesModal = ({ postId, visible, toggle }) => {
  const [likes, setLikes] = useState([]);

  useEffect(() => {
    if (visible) {
      loadLikes();
    }
    return setLikes([]);
  }, [visible]);

  const loadLikes = async () => {
    try {
      const likes = await getPostLikes(postId);
      setLikes(likes);
    } catch (err) {}
  };

  return (
    <LikesModal likes={likes} visible={visible} toggle={toggle}/>
  );
};

PostLikesModal.propTypes = {
  postId: PropTypes.number,
  visible: PropTypes.bool
};
