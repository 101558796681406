import { useContext } from 'react';
import { ChannelList as SChannelList } from 'stream-chat-react';
import { UserContext } from 'contexts/UserContext';

export const ChannelList = ({ showSearch, variant, onClick, ...rest }) => {
  const { user } = useContext(UserContext);
  const params = new URLSearchParams(window.location.search);

  return (
    user && (
      <div onClick={onClick}>
        <SChannelList
          darkMode={true}
          filters={{
            type: 'messaging',
            members: { $in: [user.uid.toString()] }
          }}
          sort={
            params.has('new') ? { created_at: -1 } : { last_message_at: -1 }
          }
          showChannelSearch={showSearch}
          {...rest}
        />
      </div>
    )
  );
};
