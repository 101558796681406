import { useState } from 'react';
import { Icon } from 'components';

export const CollapsableCard = ({ title, subtitle, onEdit, onDelete }) => {
  const [deleteLoading, setDeleteLoading] = useState(false);

  const handleDelete = async () => {
    try {
      setDeleteLoading(true);
      await onDelete();
    } catch (error) {
      throw error;
    } finally {
      setDeleteLoading(false);
    }
  };

  return (
    <div className="h-18 bg-black-light rounded-lg flex justify-between items-center px-5">
      <div>
        <div className="font-bold text-xs text-white">{title}</div>
        <div className="text-gray text-xs capitalize">{subtitle}</div>
      </div>
      <div className="flex">
        <Icon
          onClick={handleDelete}
          className="mr-3"
          name="Trash"
          rounded
          loading={deleteLoading}
        />
        <Icon onClick={onEdit} name="Pencil" rounded />
      </div>
    </div>
  );
};
