import React from 'react';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import { Box, Icon } from 'components';

export const ProfileSalary = ({ className, data, getRoleDescription }) => {
  const { t } = useTranslation();

  const machinesWorker = data.user?.roleId
    ? data.user.roleId === 9 ||
      data.user.roleId === 10 ||
      data.user.roleId === 16 ||
      data.user.roleId === 20 ||
      data.user.roleId === 23 ||
      data.user.roleId === 24 ||
      data.user.roleId === 28 ||
      data.user.roleId === 29
    : false;

  return (
    <Box className={className} variant="flat">
      <h4
        className={cn(
          'text-yellow text-lg pb-2 flex justify-start items-center',
          {
            'border-b': data.salary || data.operatorMaxTons || data.user.roleId
          }
        )}
      >
        {t('openToWork')}
        <Icon
          className="ml-2"
          width={30}
          height={30}
          name={machinesWorker ? 'OpenToWorkOperator' : 'OpenToWork'}
        />
      </h4>
      <div className={cn('py-4')}>
        {data?.user?.roleId && (
          <p className="font-bold text-base mb-2">
            {getRoleDescription(data.user.roleId)}
          </p>
        )}
        {data?.salary && (
          <p className="text-sm">
            <span className="font-semibold">Salary: </span> {data.salary}{' '}
            {data.currency === 'dollar' ? 'USD' : 'EUR'} {data.salaryPeriod}
          </p>
        )}
        {data?.operatorMaxTons && (
          <p className="text-sm">
            <span className="font-semibold">Max Tons: </span>
            {data.operatorMaxTons}
          </p>
        )}
        {data?.description && (
          <p className="text-sm whitespace-pre-line mt-2">{data.description}</p>
        )}
      </div>
    </Box>
  );
};
