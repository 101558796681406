import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Icon } from 'components';
import cn from 'classnames';
import './index.scss';

export const BackButton = ({
  className,
  onClick,
  children,
  light,
  linkTo,
  disabled
}) => {
  const classes = cn('cb-back-button', className, {
    light: light,
    'pointer-events-none opacity-70': disabled
  });

  return linkTo ? (
    <Link className={classes} to={linkTo}>
      <Icon name="ChevronLeft" />
      <p>{children}</p>
    </Link>
  ) : (
    <button className={classes} onClick={onClick} type="button">
      <Icon name="ChevronLeft" />
      <p>{children}</p>
    </button>
  );
};

BackButton.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  onClick: PropTypes.func,
  linkTo: PropTypes.string,
  type: PropTypes.string
};
