import React from 'react';
import { useTranslation } from 'react-i18next';
import { Avatar } from 'components';
import cn from 'classnames';
import { useMediaQuery } from 'react-responsive';

export const PostLikes = ({ images, likes = 0 }) => {
  const { t } = useTranslation();

  const isMobile = useMediaQuery({
    query: '(max-width: 768px)'
  });

  return (
    <>
      <button
        className={cn('flex items-center', {
          group: likes > 0,
          'cursor-auto': likes === 0
        })}
      >
        <div
          className={cn('flex', {
            'mr-4': images.length > 0 && !isMobile
          })}
        >
          {!isMobile &&
            images.map(
              (img, i) =>
                i < 4 && (
                  <Avatar
                    src={img}
                    notLoggedUser
                    onlyPhoto
                    className="-mr-3"
                    size={28}
                    key={i}
                    borderColor="#f7a709"
                  />
                )
            )}
        </div>
        <span className="text-xs text-gray transition duration-300 group-hover:text-yellow">
          {likes} {t(likes === 1 ? 'like' : 'likes')}
        </span>
      </button>
    </>
  );
};
