import axios from 'utils/axios';

export const getProfile = async (id) => {
  try {
    if (id) {
      const {
        data: { user }
      } = await axios.get(`api/auth/users/${id}`);
      return user;
    }
  } catch (error) {
    if (process.env.REACT_APP_DEV_MODE !== 'true') {
      console.log('ERROR: ', error.message);
    }
    throw error;
  }
};

export const getFollowersOrFollowing = async (id, type) => {
  try {
    const res = await axios.get(`api/social/${type}/${id}`);
    return res.data;
  } catch (error) {
    console.log(error.message);
  }
};

export const getUsers = async (searchText) => {
  try {
    const res = await axios.get(`api/auth/users?searchText=${searchText}`);
    return res.data;
  } catch (error) {
    console.log(error.message);
  }
};
