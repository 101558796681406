import axios from 'utils/axios';

export const getCategories = async () => {
  try {
    const { data } = await axios.get('api/categories');
    return data;
  } catch (error) {
    if (process.env.REACT_APP_DEV_MODE !== 'true') {
      console.log('ERROR: ', error.message);
    }
    throw error;
  }
};

export const getCategoriesByType = async () => {
  try {
    const { data } = await axios.get('api/categories/by-type');
    return data;
  } catch (error) {
    if (process.env.REACT_APP_DEV_MODE !== 'true') {
      console.log('ERROR: ', error.message);
    }
    throw error;
  }
};

export const getCategoriesByTypeWithCount = async () => {
  try {
    const { data } = await axios.get('api/categories/by-type-with-count');
    return data;
  } catch (error) {
    if (process.env.REACT_APP_DEV_MODE !== 'true') {
      console.log('ERROR: ', error.message);
    }
    throw error;
  }
};
