import React, { useState, useEffect } from 'react';
import { CheckboxGroup, Icon, Divider } from 'components';
import { getOpenToWorkFilters } from 'api/user';
import { useTranslation } from 'react-i18next';
import { useFilterQueryParams } from 'hooks';
import cn from 'classnames';

export const JobFilters = () => {
  const [rolesOpen, setRolesOpen] = useState(false);
  const [roles, setRoles] = useState([]);
  const [selectedRoles, setSelectedRoles] = useState([]);
  const [countriesOpen, setCountriesOpen] = useState(false);
  const [countries, setCountries] = useState([]);
  const [selectedCountries, setSelectedCountries] = useState([]);
  const { setParams, params } = useFilterQueryParams();

  const { t } = useTranslation();

  const fetchFilters = async () => {
    try {
      const { countries, roles } = await getOpenToWorkFilters();
      const _countries = countries.map((country) => ({
        id: country,
        name: country
      }));
      setCountries(_countries);
      const _roles = roles.map(({ id, description }) => ({
        id,
        name: t(`${description}`)
      }));
      setRoles(_roles);
      setSelectedCountries(params?.countries || []);
      setSelectedRoles(params?.roles?.map((role) => parseInt(role)) || []);
      setCountriesOpen(params?.countries?.length || false);
      setRolesOpen(params?.roles?.length || false);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchFilters();
  }, []);

  const handleRoles = (value, checked) => {
    if (checked) {
      const _roles = [...selectedRoles, value];
      setSelectedRoles(_roles);
      setParams({ roles: _roles });
    } else {
      const _selectedRoles = (selectedRoles || []).filter(
        (item) => item !== value
      );
      _selectedRoles.length
        ? setSelectedRoles(_selectedRoles)
        : setSelectedRoles([]);
      setParams({
        roles: _selectedRoles?.length ? _selectedRoles : undefined
      });
    }
  };

  const handleCountries = (value, checked) => {
    if (checked) {
      const _countries = [...selectedCountries, value];
      setSelectedCountries(_countries);
      setParams({ countries: _countries });
    } else {
      const _selectedCountries = (selectedCountries || []).filter(
        (item) => item !== value
      );
      _selectedCountries.length
        ? setSelectedCountries(_selectedCountries)
        : setSelectedCountries([]);
      setParams({
        countries: _selectedCountries?.length ? _selectedCountries : undefined
      });
    }
  };

  return (
    <>
      {!!roles.length && (
        <>
          <div
            onClick={() => setRolesOpen(!rolesOpen)}
            className="flex justify-between items-center cursor-pointer"
          >
            <h2 className="text-white-secondary text-base md:text-xl">{t('role')}</h2>
            <Icon
              name="ChevronDown"
              className={cn(
                'transform transition-all',
                rolesOpen && 'rotate-180'
              )}
            />
          </div>
          {rolesOpen && (
            <CheckboxGroup
              options={roles}
              selected={selectedRoles || null}
              setSelected={handleRoles}
            />
          )}
          {<Divider color="silver" className="my-3 md:my-6" />}
        </>
      )}
      {!!countries.length && (
        <>
          <div
            onClick={() => setCountriesOpen(!countriesOpen)}
            className="flex justify-between items-center cursor-pointer"
          >
            <h2 className="text-gray-intermediate text-base md:text-xl">{t('country')}</h2>
            <Icon
              name="ChevronDown"
              className={cn(
                'transform transition-all',
                countriesOpen && 'rotate-180'
              )}
            />
          </div>
          {countriesOpen && (
            <CheckboxGroup
              options={countries}
              selected={selectedCountries || null}
              setSelected={handleCountries}
            />
          )}
          {<Divider color="silver" className="my-3 md:my-6" />}
        </>
      )}
    </>
  );
};
