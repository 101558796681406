import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { getLikesByCompany } from 'api/likes';
import cn from 'classnames';
import { LikesModal } from 'components';
import { useModal } from 'hooks/useModal';
import like from 'assets/img/icons/heart.svg';

import './index.scss';

export const Likes = ({ publicationId, className, hideLogo, count }) => {
  const [likes, setLikes] = useState([]);
  const { t } = useTranslation();
  const { visible, toggleModal } = useModal();

  useEffect(() => {
    if (visible) {
      loadLikes();
    }
  }, [publicationId, visible]);

  const loadLikes = async () => {
    try {
      const { rows } = await getLikesByCompany(publicationId);
      const likes = rows.map((like) => {
        return {
          isFollowed: like?.user?.isFollowed,
          ...like
        };
      });
      setLikes(likes);
    } catch (err) {}
  };

  const handleToggleModal = (e) => {
    e && e.stopPropagation();
    toggleModal();
  };

  return (
    <>
      <div className={cn('cb-likes', className)} onClick={handleToggleModal}>
        {!hideLogo && (
          <div
            className="w-7 h-7 rounded-full border border-solid border-silver-light bg-center mr-2 bg-no-repeat"
            style={{
              backgroundImage: `url(${like})`,
              backgroundSize: '50%'
            }}
          ></div>
        )}
        <div className="cursor-pointer">
          <span>{`${count} ${t(count === 1 ? 'like' : 'likes')}`}</span>
        </div>
      </div>
      <LikesModal visible={visible} toggle={handleToggleModal} likes={likes} />
    </>
  );
};

Likes.propTypes = {
  publicationId: PropTypes.number,
  className: PropTypes.string,
  hideLogo: PropTypes.bool,
  count: PropTypes.number
};
