import React, { useState, useEffect, useRef } from 'react';
import ReactPlayer from 'react-player/lazy';

import { scale } from '@cloudinary/url-gen/actions/resize';
import { useCloudinary, useIsInViewport } from 'hooks';

import { getCloudinaryFileName } from 'utils/formatter';
import { Spinner } from 'components';

export const PostFile = ({
  file,
  size = 1200,
  className,
  videoClasses,
  imageClasses,
  videoOptions,
  videoPreview = false,
  onClick,
  actualSlide
}) => {
  const { cld } = useCloudinary();
  const [urlGen, setUrlGen] = useState('');
  const [loaded, setLoaded] = useState(false);
  const [thumbnail, setThumbnail] = useState('');
  const ref = useRef(null);
  const [isPlaying, setIsPlaying] = useState(true);
  const isInView = useIsInViewport(ref);

  useEffect(() => {
    file && fileURL();
  }, [file]);

  useEffect(() => {
    (!isInView || actualSlide > 0) && setIsPlaying(false);
  }, [isInView, actualSlide]);

  useEffect(() => {
    thumbnail !== '' && setLoaded(false);
  }, [thumbnail]);

  const fileURL = () => {
    if (!file?.url?.includes('cloudinary')) {
      setUrlGen(file.url);
    } else {
      const publicId = file?.cloudinaryId || getCloudinaryFileName(file?.url);

      const myFile = file?.url?.includes('video')
        ? cld.video(publicId)
        : cld.image(publicId);

      const url =
        file?.transformations && file?.transformations[size]
          ? file?.transformations[size]
          : myFile?.resize(scale().width(size)).toURL();

      const thumb =
        file?.transformations?.thumbnail ||
        cld.image(`${publicId}.jpg`).setAssetType('video').toURL();
      setThumbnail(thumb);
      setUrlGen(url);
    }
  };

  //TODO if videoPreview generar URL
  return (
    <div ref={ref} className={className}>
      {file?.url?.includes('video') ? (
        <ReactPlayer
          light={thumbnail}
          url={urlGen}
          {...videoOptions}
          className={videoClasses}
          fallback={<Spinner />}
          onPlay={() => {
            setIsPlaying(true);
          }}
          playing={isPlaying}
        />
      ) : (
        <>
          {!loaded && (
            <div className="events-none w-full absolute top-0 left-0 h-full flex items-center justify-center">
              <Spinner />
            </div>
          )}
          <img
            src={urlGen}
            onLoad={() => setLoaded(true)}
            className={imageClasses}
            onClick={onClick}
            alt={''}
            preload="none"
          />
        </>
      )}
    </div>
  );
};
