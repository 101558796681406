import axios from 'utils/axios';

export const getLocations = async (text = '') => {
  try {
    const { data } = await axios.post(`api/auth/utils/locations`, {
      place: text
    });
    return data?.locations || [];
  } catch (error) {
    if (process.env.REACT_APP_DEV_MODE !== 'true') {
      console.log('ERROR: ', error.message);
    }
    throw error;
  }
};

export const getLocationInfo = async (placeId = null) => {
  try {
    const { data } = await axios.get(
      `api/auth/utils/location-info?place=${placeId}`
    );
    return data || [];
  } catch (error) {
    if (process.env.REACT_APP_DEV_MODE !== 'true') {
      console.log('ERROR: ', error.message);
    }
    throw error;
  }
};
