import React from 'react';
import { Spinner } from 'components';
import { Button } from '@estudio-nk/the-crane-club-client-lib';
import CInfiniteScroll from 'react-infinite-scroll-component';
import { useTranslation } from 'react-i18next';

export const InfiniteScroll = ({
  children,
  dataLength,
  next,
  hasMore,
  rest
}) => {
  const { t } = useTranslation();
  return (
    <CInfiniteScroll
      dataLength={dataLength} //This is important field to render the next data
      next={next}
      hasMore={hasMore}
      loader={
        <div className="flex justify-center">
          <Spinner />
        </div>
      }
      endMessage={
        <div className="mt-4 pb-20">
          <p className="flex justify-center w-full text-gray text-lg">
            {t('allSeenMessage')}
          </p>
          <div className="flex justify-center items-center">
            <Button
              variant="text"
              colorVariant="yellow"
              className="underline text-xs -mt-3"
              onClick={() => {
                window.scrollTo({ top: 0, behavior: 'smooth' });
              }}
            >
              {t('scrollToTop')}
            </Button>
          </div>
        </div>
      }
      // below props only if you need pull down functionality
      refreshFunction={next}
      pullDownToRefresh
      pullDownToRefreshThreshold={500}
      pullDownToRefreshContent={
        <h3 style={{ textAlign: 'center' }}>&#8595; Pull down to refresh</h3>
      }
      releaseToRefreshContent={
        <h3 style={{ textAlign: 'center' }}>&#8593; Release to refresh</h3>
      }
      {...rest}
    >
      {children}
    </CInfiniteScroll>
  );
};
