import React, { useState, useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';
import { toast } from 'react-toastify';

import { UserContext } from 'contexts/UserContext';
import { ReloadContext } from 'contexts/ReloadContext';
import { uploadFileToCloudinary, uploadFile } from 'api/files';
import { Avatar } from 'components';
import { Button } from '@estudio-nk/the-crane-club-client-lib';
import { postPost } from 'api/social/posts';
import { isTypeVideo } from 'utils/validators';
import { FileUploader } from '../FileUploader';
import { FilesPreview } from '../FilesPreview';
import { PostCardShare, PostInput } from 'components';

import { useLocation } from 'react-router-dom';
import cn from 'classnames';
import './index.scss';

export const NewPost = ({ className }) => {
  const { t } = useTranslation();
  const location = useLocation();
  const { user } = useContext(UserContext);
  const { reload, setReload } = useContext(ReloadContext);
  const [files, setFiles] = useState([]);
  const [description, setDescription] = useState('');
  const [sharedPost, setSharedPost] = useState(null);
  const [creatingPost, setCreatingPost] = useState(false);

  useEffect(() => {
    if (location.state?.post) {
      setSharedPost(location.state?.post);
      window.scrollTo(0, 0);
    }
  }, [location]);

  const handleSubmit = async () => {
    try {
      if (description.length > 0 || files.length > 0 || sharedPost) {
        const uuid = uuidv4();
        setCreatingPost(true);
        const data = await Promise.all(
          files.map(async ({ file }, index) => {
            if (isTypeVideo(file.type)) {
              const res = await uploadFileToCloudinary([file]);
              const r = res[0];
              if (Array.isArray(r)) {
                const lastChunk = r.find(({ data: { done } }) => done);
                let transformations;
                lastChunk.data.eager?.forEach((e) => {
                  if(e.format === 'jpg'){
                    transformations = {
                      ...transformations,
                      thumbnail: e.secure_url
                    };
                  } else {
                    transformations = {
                      ...transformations,
                      [e.width]: e.secure_url
                    };
                  }
                });
                return {
                  url: lastChunk.data?.url,
                  cloudinaryId: lastChunk.data?.public_id,
                  type: 'video',
                  order: index,
                  transformations
                };
              } else {
                let transformations;
                r.data.eager?.forEach((e) => {
                  if(e.format === 'jpg'){
                    transformations = {
                      ...transformations,
                      thumbnail: e.secure_url
                    };
                  } else {
                    transformations = {
                      ...transformations,
                      [e.width]: e.secure_url
                    };
                  }
                });
                return {
                  url: r.data.url,
                  type: 'video',
                  cloudinaryId: r.data.public_id,
                  order: index,
                  transformations
                };
              }
            } else {
              const { urls } = await uploadFile(file, {
                destination: `post/${uuid}/images/`,
                resize: [
                  { width: '1200', height: '1200' },
                  { width: '800', height: '800' },
                  { width: '400', height: '400' }
                ]
              });
              return [
                {
                  type: 'original',
                  url: urls[0]
                },
                {
                  type: 'thumbnail',
                  url: urls[1]
                },
                {
                  type: 'tiny',
                  url: urls[2]
                }
              ].map((item) => ({ ...item, order: index }));
            }
          })
        );
        await postPost({
          description,
          uuid,
          files: data.flat(),
          sharedPostId: sharedPost && sharedPost.id
        });
        toast.success(t('PostCreatedSuccessfully'));
        resetForm();
        setReload(!reload);
      }
    } catch (error) {
      console.log(error);
      toast.error(t('ErrorCreatingPost'));
    }
    setCreatingPost(false);
  };

  const resetForm = () => {
    setDescription('');
    setFiles([]);
    setSharedPost(null);
  };

  const removeFile = (index) => {
    let _files = files.filter((f, i) => i !== index);
    setFiles(_files);
  };

  return (
    <div className={cn('bg-black-light rounded-lg p-4 w-full', className)}>
      <div className="flex justify-center items-start pb-4">
        <Avatar
          className="grow-0 mr-4"
          onlyPhoto={true}
          linkTo={user?.profileURL}
          borderColor="#f7a709"
        />
        <PostInput
          value={description}
          onChange={setDescription}
          inputClasses="py-3"
          placeholder={t('WhatsHappening')}
          height="46"
        />
      </div>

      {sharedPost && (
        <PostCardShare
          className="mb-5"
          post={sharedPost}
          onClose={() => setSharedPost(null)}
        />
      )}

      {files.length > 0 && (
        <FilesPreview files={files} removeFile={removeFile} />
      )}
      <div className="flex justify-between items-center">
        {!sharedPost && <FileUploader files={files} setFiles={setFiles} />}
        <div className="w-40">
          <Button
            onClick={handleSubmit}
            loading={creatingPost}
            colorVariant="yellow"
          >
            Post
          </Button>
        </div>
      </div>
    </div>
  );
};
