import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import {
  AvatarUser,
  Icon,
  FloatingQuotationsNotifications,
  FloatingNotifications,
  FloatingChat
} from 'components';
import { UserContext } from 'contexts/UserContext';
import { useLocation } from 'react-router-dom';
import { Capacitor } from '@capacitor/core';

import './index.scss';

export const Navbar = ({
  variant = 'sidebar',
  setToggleSidebar,
  toggleSidebar,
  showHam
}) => {
  const { user } = useContext(UserContext);
  const location = useLocation();
  const isIOS = Capacitor.getPlatform() === 'ios';

  return (
    <header
      className={cn(
        'h-20 md:h-23 w-full fixed bg-black z-10 flex items-center justify-center top-0 shadow-md md:shadow-none',
        {
          'md:pl-12 xl:pl-64': variant === 'sidebar',
          'pt-safe pb-5 h-auto': isIOS
        }
      )}
    >
      {showHam && (
        <Icon
          name="Hamburguer"
          onClick={() => setToggleSidebar(!toggleSidebar)}
        />
      )}
      <div className="wrapper-lg">
        <div className="h-full flex justify-between items-center">
          <AvatarUser user={user} />
          <div className="flex">
            <FloatingNotifications />
            {user?.isCompany && <FloatingQuotationsNotifications />}
            {!location.pathname.includes('chat') && <FloatingChat />}
          </div>
        </div>
      </div>
    </header>
  );
};

Navbar.propTypes = {
  variant: PropTypes.oneOf(['noSidebar', 'sidebar'])
};
