import React, { Fragment } from 'react';
import cn from 'classnames';
import { Divider, Checkbox } from 'components';

export const SelectBox = ({ letter = [], handleOnSelect, selected, items }) => {
  return (
    <div className="relative mt-8">
      <span className="text-yellow font-semibold text-3xl md:text-4xxl uppercase mb-8 block">
        {letter}
      </span>
      <div className="grid md:grid-cols-3 w-full gap-y-5 md:gap-y-7">
        {items.map(({ id, name, isCustom }) => (
          <Fragment key={id}>
            <Checkbox
              name={name}
              className={cn('capitalize', isCustom && 'required')}
              checked={selected?.has(id)}
              setChecked={(checked) => handleOnSelect(id, checked)}
            />
          </Fragment>
        ))}
      </div>
      <Divider className="mt-8" />
    </div>
  );
};
