import axios from 'utils/axios';

export const addUserNotificationToken = async (token) => {
  try {
    localStorage.setItem('FCM_TOKEN', token);
    return axios.post(`api/auth/users/notification-token`, {
      token
    });
  } catch (error) {
    if (process.env.REACT_APP_DEV_MODE !== 'true') {
      console.log('ERROR: ', error.message);
    }
    throw error;
  }
};

export const getUserNotificationToken = async () => {
  try {
    const { data } = await axios.get('api/auth/users/notification-token');
    return data;
  } catch (error) {
    if (process.env.REACT_APP_DEV_MODE !== 'true') {
      console.log('ERROR: ', error.message);
    }
    throw error;
  }
};

export const deleteTokenFromUser = async (token) => {
  try {
    return axios.put('api/auth/users/notification-token', { token });
  } catch (error) {
    if (process.env.REACT_APP_DEV_MODE !== 'true') {
      console.log('ERROR: ', error.message);
    }
    throw error;
  }
};
