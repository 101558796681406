import axios from 'utils/axios';

export const getBrands = async (category) => {
  try {
    const url = category ? `api/brands/by-category/${category}` : 'api/brands';
    const { data } = await axios.get(url);
    return data || [];
  } catch (error) {
    if (process.env.REACT_APP_DEV_MODE !== 'true') {
      console.log('ERROR: ', error.message);
    }
    throw error;
  }
};

export const getBrandsByUser = async (userId) => {
  try {
    const { data } = await axios.get(`api/brands/by-user/${userId}`);
    return data || [];
  } catch (error) {
    if (process.env.REACT_APP_DEV_MODE !== 'true') {
      console.log('ERROR: ', error.message);
    }
    throw error;
  }
};

export const getFeaturedBrands = async (category = null) => {
  try {
    const { data } = await axios.get('api/brands/featured');
    return data?.brands || [];
  } catch (error) {
    if (process.env.REACT_APP_DEV_MODE !== 'true') {
      console.log('ERROR: ', error.message);
    }
    throw error;
  }
};

export const updateBrands = async (brands, customBrands) => {
  try {
    await axios.post('api/brands', {
      brands,
      customBrands
    });
  } catch (error) {
    if (process.env.REACT_APP_DEV_MODE !== 'true') {
      console.log('ERROR: ', error.message);
    }
    throw error;
  }
};

export const createBrands = async (brands) => {
  try {
    const { data } = await axios.post('api/brands/new', brands);
    return data;
  } catch (error) {
    if (process.env.REACT_APP_DEV_MODE !== 'true') {
      console.log('ERROR: ', error.message);
    }
    throw error;
  }
};
