import axios from 'axios';
const { REACT_APP_API_FILE_URL } = process.env;

export const uploadFile = async (file, { destination, resize }) => {
  try {
    const token = localStorage.getItem('tmc-token');
    const formData = new FormData();
    formData.append('upload', file);
    if (destination) {
      formData.append('destination', destination);
    }
    if (resize) {
      formData.append('resize', JSON.stringify(resize));
    }
    const res = await axios.post(REACT_APP_API_FILE_URL, formData, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-type': 'multipart/form-data'
      }
    });
    return res.data;
  } catch (error) {
    throw error;
  }
};

export const deleteFile = async (url) => {
  try {
    const token = localStorage.getItem('tmc-token');
    await axios.delete(`${REACT_APP_API_FILE_URL}/${encodeURIComponent(url)}`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
  } catch (error) {}
};

export const uploadFileToCloudinary = async (files) => {
  try {
    return Promise.all(
      files.map((file) => {
        const fileSize = file.size;
        if (fileSize > 20000000) {
          return processFile(file);
        } else {
          const formData = new FormData();
          formData.append('file', file);
          formData.append(
            'upload_preset',
            file.type.includes('video') ? 'video-preset' : 'image-preset'
          );
          // to modify cloudinary presets: https://cloudinary.com/console/c-acd0d34590c7a121c023f1eba8419a/settings/upload#upload_presets
          return axios.post(
            'https://api.cloudinary.com/v1_1/the-crane-club/upload',
            formData
          );
        }
      })
    );
  } catch (error) {
    throw error;
  }
};

const processFile = (file) => {
  const XUniqueUploadId = +new Date();
  const size = file.size;
  const sliceSize = 20000000;
  let start = 0;
  let end = 0;

  const calls = [];

  while (end < size) {
    end = start + sliceSize;
    if (end > size) {
      end = size;
    }
    const s = slice(file, start, end);
    calls.push({
      unique: XUniqueUploadId,
      piece: s,
      start,
      end: end - 1,
      size
    });
    if (end < size) {
      start += sliceSize;
    }
  }
  return Promise.all(
    calls.map((c) => send(c.unique, c.piece, c.start, c.end, c.size))
  );
};

const send = (unique, piece, start, end, size) => {
  const formdata = new FormData();

  formdata.append('file', piece);
  formdata.append('cloud_name', 'the-crane-club');
  formdata.append('upload_preset', 'llot5afc');

  return axios.post(
    'https://api.cloudinary.com/v1_1/the-crane-club/upload',
    formdata,
    {
      headers: {
        'X-Unique-Upload-Id': unique,
        'Content-Range': 'bytes ' + start + '-' + end + '/' + size
      }
    }
  );
};

const slice = (file, start, end) => {
  var slice = file.mozSlice
    ? file.mozSlice
    : file.webkitSlice
    ? file.webkitSlice
    : file.slice
    ? file.slice
    : () => {};

  return slice.bind(file)(start, end);
};
