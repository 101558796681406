import i18n from 'i18next';
import Backend from 'i18next-xhr-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import en from 'locales/en/translation.json';
import es from 'locales/es/translation.json';

const resources = {
  en: {
    translation: en
  },
  es: {
    translation: es
  }
};

i18n.on('languageChanged', (lng) => {
  if (i18n.options.whitelist.includes(lng)) {
    const currentLenguage = window.location.pathname.substring(1, 3);
    if (i18n.options.whitelist.includes(currentLenguage)) {
      if (lng !== currentLenguage)
        window.location.replace(
          window.location.pathname.replace(/\/en\/|\/es\//g, `/${lng}/`)
        );
    } else {
      window.location.replace(
        `/${lng}${window.location.pathname}${window.location.search}`
      );
    }
  }
});

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: 'en',
    whitelist: ['en', 'es'],
    debug: false,
    resources,
    react: {
      useSuspense: false
    },
    detection: {
      order: ['path'],
      lookupFromPathIndex: 0,
      checkWhitelist: true
    },
    interpolation: {
      escapeValue: false,
      formatSeparator: '.'
    }
  });

export default i18n;
