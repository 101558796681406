import React from 'react';
import { Line } from 'react-chartjs-2';

const options = {
  tension: 0.4,
  borderWidth: 1,
  pointBorderWidth: 0,
  leyend: {
    display: false,
    labels: {
      display: false
    }
  },
  scales: {
    x: {
      grid: {
        display: false
      }
    }
  }
};

export const Chart = ({ data }) => {
  return <Line data={data} options={options} />;
};
