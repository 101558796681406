import React, { useState, useContext } from 'react';
import { followUser } from 'api/social/follows';
import { useModal } from 'hooks';
import { UserContext } from 'contexts/UserContext';
import { useTranslation } from 'react-i18next';
import { LoginModal } from 'components';
import { Button } from '@estudio-nk/the-crane-club-client-lib';

export const FollowButton = ({
  className,
  onClick,
  user,
  variant,
  ...rest
}) => {
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const { user: loggedUser } = useContext(UserContext);
  const { visible, toggleModal } = useModal();
  const [followed, setFollowed] = useState(user?.isFollowed === '1');

  const handleOnClick = async (e) => {
    e.stopPropagation();
    e.preventDefault();
    try {
      if (!loggedUser) {
        toggleModal(true);
      } else {
        setLoading(true);
        await followUser(user.uid);
        setFollowed(!followed);
        onClick && (await onClick(followed ? -1 : 1, e));
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Button
        onClick={handleOnClick}
        className={className}
        loading={loading}
        variant={
          variant === 'text' ? variant : followed ? 'outline' : 'primary'
        }
        colorVariant="yellow"
        {...rest}
      >
        {t(followed ? 'followed' : 'follow')}
      </Button>
      <LoginModal visible={visible} toggleModal={toggleModal} />
    </>
  );
};
