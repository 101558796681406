import { useState, useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import {
  Input,
  Icon,
  PhoneInput,
  Dropdown,
  LocationAutocomplete,
  AvatarEditorModal,
  FileInput,
  AvatarUpload
} from 'components';
import { Button } from '@estudio-nk/the-crane-club-client-lib';
import { UserContext } from 'contexts/UserContext';
import { useForm, Controller, useModal } from 'hooks';
import { useTranslation } from 'react-i18next';
import { uploadFile } from 'api/files';
import { registerUser } from 'api/auth/register';
import { getRoles } from 'api/roles';
import { useMediaQuery } from 'react-responsive';
import { login } from 'api/auth';
import { isValidImage } from 'utils/validators';
import { locationToString } from 'utils/formatter';
import { v4 as uuidv4 } from 'uuid';
import cn from 'classnames';

import { toast } from 'react-toastify';
import './index.scss';

import defaultBanner from 'assets/img/cover-default.jpg';

export const IndividualRegisterForm = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordConfirm, setShowPasswordConfirm] = useState(false);
  const [roles, setRoles] = useState([]);
  const [banner, setBanner] = useState(null);
  const [bannerURL, setBannerURL] = useState('');
  const [newBannerImage, setNewBannerImage] = useState(null);
  const [loading, setLoading] = useState(false);
  const { loadUser } = useContext(UserContext);
  const { visible: bannerEditorVisible, toggleModal: bannerEditorToggle } =
    useModal();

  const isMobile = useMediaQuery({
    query: '(max-width: 480px)'
  });

  const fetchRoles = async () => {
    try {
      const _roles = await getRoles();
      setRoles(
        _roles.map(({ id, description }) => ({ value: id, text: description }))
      );
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchRoles();
  }, []);

  const submit = async (data) => {
    if (data.password !== data.confirmPassword) {
      toast.warn(t('passwordsShouldMatch'));
    } else {
      try {
        setLoading(true);
        const uid = uuidv4();
        const _photoURL = data.photoURL
          ? await uploadFile(data.photoURL.file, {
              destination: `users/${uid}/photo`,
              resize: [{ width: '400', height: '400' }]
            })
          : null;
        const _banner = banner
          ? await uploadFile(banner, {
              destination: `users/${uid}/banner`,
              resize: [{ width: '1680', height: '1680' }]
            })
          : null;
        await registerUser({
          email: data.email,
          name: data.name,
          uuid: uid,
          roleId: data.role,
          companyName: data.companyName,
          phoneNumber: data.phoneNumber,
          password: data.password,
          location: data.location,
          isCompany: false,
          photoURL: _photoURL?.urls[0] || null,
          bannerURL: _banner?.urls[0] || null
        });
        const user = await login({
          email: data.email,
          password: data.password
        });
        loadUser(user);
        history.push('/dashboard/my-cv', { state: { register: true } });
      } catch (error) {
        console.log(error);
        if (error?.response?.status === 404) {
          toast.error(t('userExist'));
        } else {
          toast.error(t(error.message));
        }
      } finally {
        setLoading(false);
      }
    }
  };

  const handleBannerChange = async (files) => {
    try {
      if (!isValidImage(files[0].file)) {
        toast.error(t('imageInvalidFormat'));
        return;
      }
      setNewBannerImage(files[0].file);
      bannerEditorToggle(true);
    } catch (error) {
      toast.error(t('dataUpdatedError'));
    }
  };

  const onConfirmBannerEditor = async (banner) => {
    try {
      const img = window.URL.createObjectURL(banner);
      setBanner(banner);
      setBannerURL(img);
    } catch (error) {
      toast.error(t('dataUpdatedError'));
    }
  };

  const {
    register,
    handleSubmit,
    control,
    formState: { errors }
  } = useForm({
    mode: 'onBlur',
    defaultValue: {
      role: 1
    }
  });

  return (
    <form onSubmit={handleSubmit(submit)} className="personal-register">
      <div
        className={cn(
          'relative flex items-center justify-center bg-cover bg-no-repeat  rounded-xl',
          { 'bg-center bg-opacity-10': bannerURL },
          { 'bg-background': !bannerURL }
        )}
        style={{
          paddingTop: '42%',
          paddingBottom: '10%',
          backgroundImage: `url(${bannerURL || defaultBanner})`,
          ...(!banner && {
            backgroundPosition: 'center -50px'
          })
        }}
      >
        <FileInput
          accept="image/*"
          trigger={
            <Button size="sm" className="w-40" colorVariant="yellow">
              {t('uploadBanner')}
            </Button>
          }
          image={newBannerImage}
          onChange={handleBannerChange}
          showList={false}
        />
        <AvatarEditorModal
          visible={bannerEditorVisible}
          toggleModal={bannerEditorToggle}
          image={newBannerImage}
          onConfirm={onConfirmBannerEditor}
          editorWidth={isMobile ? 320 : 600}
          editorHeight={isMobile ? 180 : 300}
          borderRadius={0}
        />
      </div>
      <Controller
        control={control}
        name="photoURL"
        render={({ field: { onChange } }) => (
          <AvatarUpload
            label={t('uploadYourPhoto')}
            onChange={onChange}
            error={errors.photoURL}
            icon="User"
          />
        )}
      />
      <Input
        placeholder={t('fullname')}
        className="mb-5"
        error={errors.name}
        register={register('name', { required: true })}
      />
      <Input
        placeholder={t('email')}
        className="mb-5"
        type="email"
        error={errors.email}
        register={register('email', { required: true })}
      />
      <Controller
        control={control}
        name="phoneNumber"
        rules={{ required: true }}
        render={({ field: { value, onChange } }) => (
          <PhoneInput
            value={value}
            placeholder={t('phoneNumber')}
            className="mb-5"
            error={errors.phoneNumber}
            onChange={onChange}
          />
        )}
      />
      <Controller
        control={control}
        name="role"
        render={({ field }) => (
          <Dropdown
            className="mb-5 cb-role"
            variant="standard"
            label={t('role')}
            items={roles}
            {...field}
          />
        )}
      />
      <Input
        placeholder={t('companyName')}
        className="mb-5"
        register={register('companyName')}
      />
      <Controller
        control={control}
        name="location"
        defaultValue={null}
        render={({ field: { value, onChange } }) => (
          <LocationAutocomplete
            value={{
              description: value ? locationToString(value) : '',
              placeId: value?.placeId
            }}
            setValue={onChange}
            className="mb-4"
            error={errors.location}
            placeholder={t('address')}
          />
        )}
      />
      <div className="input-with-icon mb-5">
        <Input
          placeholder={t('password')}
          type={showPassword ? 'text' : 'password'}
          error={errors.password}
          errorMessage={
            errors.password &&
            t(`form.${errors.password.type}`, {
              value: 8
            })
          }
          register={register('password', {
            required: true,
            minLength: 8
          })}
        />
        {showPassword ? (
          <Icon
            name="ShowPassword"
            width={19}
            height={13}
            onClick={() => setShowPassword(false)}
          />
        ) : (
          <Icon
            name="HidePassword"
            width={20}
            height={17}
            onClick={() => setShowPassword(true)}
          />
        )}
      </div>

      <div className="input-with-icon mb-8">
        <Input
          placeholder={t('confirmPassword')}
          type={showPasswordConfirm ? 'text' : 'password'}
          error={errors.confirmPassword}
          errorMessage={
            errors.confirmPassword &&
            t(`form.${errors.confirmPassword.type}`, {
              value: 8
            })
          }
          register={register('confirmPassword', {
            required: true,
            minLength: 8
          })}
        />
        {showPasswordConfirm ? (
          <Icon
            name="ShowPassword"
            width={19}
            height={13}
            onClick={() => setShowPasswordConfirm(false)}
          />
        ) : (
          <Icon
            name="HidePassword"
            width={20}
            height={17}
            onClick={() => setShowPasswordConfirm(true)}
          />
        )}
      </div>

      <Button type="submit" loading={loading} colorVariant="yellow">
        {t('CreateAccount')}
      </Button>
    </form>
  );
};
