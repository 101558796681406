import React, { useState } from 'react';
import { Input, Icon } from 'components';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';

export const PasswordInput = ({ onChange, className, ...rest }) => {
  const { t } = useTranslation();
  const [showPassword, setShowPassword] = useState(false);
  return (
    <div className={cn('flex items-center', className)}>
      <Input
        placeholder={t('password')}
        className="w-full"
        type={showPassword ? 'text' : 'password'}
        onChange={onChange}
        {...rest}
      />
      {showPassword ? (
        <Icon
          name="ShowPassword"
          width={19}
          height={13}
          onClick={() => setShowPassword(false)}
        />
      ) : (
        <Icon
          name="HidePassword"
          width={20}
          height={17}
          onClick={() => setShowPassword(true)}
        />
      )}
    </div>
  );
};
