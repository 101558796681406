import { useState, useEffect } from 'react';
import { getLocations, getLocationInfo } from 'api/locations';
import { useTranslation } from 'react-i18next';
import { useDebounce } from 'hooks/useDebounce';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import cn from 'classnames';

import './index.scss';

export const LocationAutocomplete = ({
  value,
  setValue,
  className,
  placeholder,
  error,
  restProps
}) => {
  const [text, setText] = useState('');
  const [places, setPlaces] = useState([]);
  const debouncedSearchTerm = useDebounce(text, 400);
  const [opened, setOpened] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    if (debouncedSearchTerm) loadLocation();
  }, [debouncedSearchTerm]);

  const loadLocation = async () => {
    try {
      const res = await getLocations(text);
      if (res.length === 1) {
        const placeInfo = await getLocationInfo(res[0].placeId);
        setValue(placeInfo.location);
        setPlaces(res);
      } else {
        setPlaces(res);
      }
      // setOpened(res.length > 1);
    } catch (error) {
      setPlaces([]);
    }
  };

  const handleSelectedPlace = async (place) => {
    try {
      const res = await getLocationInfo(place.placeId);
      setValue(res.location);
    } catch (error) {
      setValue(null);
    }
  };

  return (
    <div className={cn('cb-location-autocomplete', className, { error })}>
      <Autocomplete
        id="location-autocomplete"
        options={places}
        disablePortal
        clearOnEscape
        getOptionLabel={(option) => option.description}
        onOpen={() => setOpened(true)}
        onClose={() => setOpened(false)}
        open={opened}
        renderInput={(params) => (
          <TextField
            className={cn({ error })}
            InputProps={{
              classes: cn({ error }),
              autoComplete: false
            }}
            placeholder={placeholder}
            variant="standard"
            {...params}
          />
        )}
        value={value}
        onInputChange={(_, newValue) => setText(newValue)}
        onChange={(_, newValue) => handleSelectedPlace(newValue)}
        autoComplete={false}
        {...restProps}
      />
      {error && (
        <span className="text-2xs text-yellow-dark flex justify-end mt-1">
          {t(`form.${error.type}`)}
        </span>
      )}
    </div>
  );
};
