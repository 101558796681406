export const useDataLayer = () => {
  const track = (body) => {
    window.dataLayer = window.dataLayer || []
    window.dataLayer.push(body);
  }

  return {
    track
  };
};
