import React, { useContext, useState, useEffect } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { Icon, Button } from '@estudio-nk/the-crane-club-client-lib';
import {
  Logo,
  Avatar,
  LanguagePicker,
  PublishNowButton,
  Menu,
  FloatingNotifications,
  FloatingQuotationsNotifications,
  FloatingChat,
  PublishNowModal
} from 'components';
import { Menu as DMenu } from './Menu';
import { ArrowButton } from 'components/ArrowButton';
import { SearchBar } from 'components/search/SearchBar';
import { UserContext } from 'contexts/UserContext';
import { useModal } from 'hooks/useModal';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';
import cn from 'classnames';

import './index.scss';

export const Header = () => {
  const { user } = useContext(UserContext);
  const history = useHistory();
  const location = useLocation();
  const { t } = useTranslation();
  const [activeMenu, setActiveMenu] = useState(null);
  const { visible, toggleModal } = useModal();
  const isDesktop = useMediaQuery({
    query: '(min-width: 1024px)'
  });

  useEffect(() => {
    document.body.style.overflow = activeMenu ? 'hidden' : 'unset';
  }, [activeMenu]);

  return (
    <header
      className={cn(
        'top-0 w-screen md:w-unset md:bg-black z-30 relative bg-black-primary',
        !isDesktop ? '' : 'pt-5 md:pt-0'
      )}
    >
      {isDesktop ? (
        <div
          className={cn('wrapper-lg flex items-center justify-between lg:h-40')}
        >
          <Logo
            size="small"
            className="w-40 cursor-pointer mr-10"
            onClick={() => history.push('/')}
          />

          <div className="flex-1 h-full py-3 md:pb-0 md:pt-7">
            <div
              className={cn(
                'flex justify-between md:pb-6 md:border-solid md:border-b md:border-gray-divider'
              )}
            >
              <SearchBar />

              {user ? (
                <div className="flex items-center">
                  <FloatingNotifications />
                  {user?.isCompany && <FloatingQuotationsNotifications />}
                  <FloatingChat className="mr-2" />

                  <Menu
                    trigger={<Avatar reverse onlyPhoto borderColor="#f7a709" />}
                    buttonClasses="flex items-center"
                    items={[
                      {
                        label: t('dashboard'),
                        onClick: () => history.push(`/dashboard`)
                      },
                      {
                        label: t('viewMyProfile'),
                        onClick: () => history.push(user?.profileURL)
                      }
                    ]}
                  />

                  {/* <LanguagePicker /> */}
                </div>
              ) : (
                <div className="flex justify-between items-center">
                  <Button
                    className="whitespace-nowrap px-5"
                    linkTo="/auth/register"
                    colorVariant="yellow"
                  >
                    {t('CreateAccount')}
                  </Button>
                  <ArrowButton linkTo="/auth/login" className="ml-7">
                    {t('Login')}
                  </ArrowButton>
                  <LanguagePicker className="ml-7" />
                </div>
              )}
            </div>

            <div className="flex items-center justify-between pt-3">
              <div className="flex items-center">
                <Link
                  to="/social-club"
                  className={`item bg-yellow text-black px-3 pb-1 pt-0.5 block rounded-sm mr-2, ${
                    location.pathname.includes('social-club') && 'social-active'
                  }`}
                >
                  <span id="social-button">{t('socialClub')}</span>
                </Link>

                <Link
                  to="/machines"
                  className={`item capitalize ${
                    location.pathname.includes('machines') && 'active'
                  }`}
                >
                  <span>Marketplace</span>
                </Link>

                <Link
                  to="/categories"
                  className={`item capitalize ${
                    location.pathname.includes('categories') && 'active'
                  }`}
                >
                  <span>{t('categories')}</span>
                </Link>

                <Link
                  to="/jobs"
                  className={`item text-white-secondary ${
                    location.pathname === '/jobs' && 'active'
                  }`}
                >
                  <span>{t('jobs')}</span>
                </Link>

                <Link
                  to="/suppliers"
                  className={`item text-white-secondary ${
                    location.pathname === '/suppliers' && 'active'
                  }`}
                >
                  <span>{t('suppliers')}</span>
                </Link>

                {/* <Link
                  to="/parts-suppliers"
                  className={`item text-white-secondary ${
                    location.pathname.includes('parts-suppliers') && 'active'
                  }`}
                >
                  <span>{`${t('parts')} ${t('y')} ${t('accessories')}`}</span>
                </Link> */}

                <Link
                  to="/vip-members"
                  className={`item text-white-secondary ${
                    location.pathname.includes('/vip-members') && 'active'
                  }`}
                >
                  <span>👑&nbsp;VIP</span>
                </Link>

                <Link
                  to="/influencers"
                  className={cn(
                    'highlight-btn hidden text-yellow lg:flex whitespace-nowrap ml-2 bg-yellow-ultra-light bg-opacity-30',
                    location.pathname.includes('/influencers') &&
                      'active border border-yellow'
                  )}
                >
                  🔥{' ' + t('influencers')}
                </Link>
              </div>

              {user && user?.isCompany && (
                <PublishNowButton className="w-36" size="sm" />
              )}
            </div>
          </div>
        </div>
      ) : (
        <div className="fixed w-full shadow-m bg-black pt-safe top-0">
          <div className="wrapper-lg py-2 flex justify-between items-center">
            <Logo
              size="big"
              className="w-5/12 max-w-xs cursor-pointer"
              onClick={() => history.push('/')}
            />
            {user ? (
              <div className="flex items-center">
                <div
                  onClick={
                    user?.isCompany
                      ? toggleModal
                      : () => history.push('/social-club')
                  }
                  className="p-3 w-12 h-12"
                >
                  <Icon
                    canvasWidth={28}
                    canvasHeight={28}
                    width="20"
                    height="20"
                    name="PlusRounded"
                    center
                    className="text-yellow"
                  />
                </div>

                <FloatingNotifications />
                {user?.isCompany && <FloatingQuotationsNotifications />}
                <FloatingChat className="mr-2" />
              </div>
            ) : (
              <Button
                className="w-28 ml-2 h-11"
                size="sm"
                linkTo="/auth/register"
                colorVariant="yellow"
              >
                {t('createAccount')}
              </Button>
            )}
          </div>
        </div>
      )}
      <PublishNowModal visible={visible} toggleModal={toggleModal} />
      <DMenu activeMenu={activeMenu} setActiveMenu={setActiveMenu} />
    </header>
  );
};
