import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { UserContext } from 'contexts/UserContext';
import { putSeenNotification } from 'api/notifications';
import { AvatarUser, Icon } from 'components';

export const NewNotification = ({
  notification,
  title,
  user,
  titleLink,
  variant = 'notification',
  link,
  children
}) => {
  const {
    unseenNotifications,
    setUnseenNotifications,
    unseenQuotations,
    setUnseenQuotations
  } = useContext(UserContext);
  const handleOnClick = async () => {
    try {
      await putSeenNotification(notification.id);
      notification.type === 'new-publication-quotation'
        ? setUnseenQuotations(unseenQuotations - 1)
        : setUnseenNotifications(unseenNotifications - 1);

      notification.seen = true;
    } catch (error) {}
  };

  const logo = (
    <Icon
      name={variant === 'chat' ? 'ChatWhite' : 'BellsWhite'}
      width={14}
      height={14}
      rounded
      className="absolute -bottom-1 -right-1 bg-yellow p-1"
    />
  );

  return (
    <div className="p-2 max-w-xs" onClick={handleOnClick}>
      {title && (
        <Link
          to={
            titleLink
              ? titleLink
              : notification.type === 'new-publication-quotation'
              ? '/dashboard/quotations'
              : '/dashboard/notifications'
          }
          className="lock text-base text-yellow mb-3"
        >
          {title}
        </Link>
      )}
      <AvatarUser
        user={user}
        link={link}
        logo={logo}
        userNameClasses="font-semibold text-white-secondary"
        text={children}
      ></AvatarUser>
    </div>
  );
};

NewNotification.propTypes = {
  variant: PropTypes.oneOf(['chat', 'notification'])
};
