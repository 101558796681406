import React, { useState } from 'react';
import { Logo, Input, Icon, Divider } from 'components';
import { Button } from '@estudio-nk/the-crane-club-client-lib';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { postNewsletter } from 'api/newsletter';
import './index.scss';

export const Footer = () => {
  // eslint-disable-next-line no-unused-vars
  const [newsletterEmail, setNewsletterEmail] = useState('');
  const [showNewsletterMessage, setShowNewsletterMessage] = useState(false);
  const [newsletterLoading, setNewsletterLoading] = useState(false);
  const { t } = useTranslation();
  const year = new Date().getFullYear();

  const submitNewsletter = async (e) => {
    e.preventDefault();
    setNewsletterLoading(true);
    await postNewsletter(newsletterEmail);
    setShowNewsletterMessage(true);
    setNewsletterLoading(false);
    setNewsletterEmail('');
    setTimeout(() => {
      setShowNewsletterMessage(false);
    }, 2000);
  };

  return (
    <footer className="cb-footer">
      <div className="wrapper-lg px-14 pt-16 pb-7 rounded-lg bg-black-light">
        <div className="flex justify-between items-center mb-20">
          <Logo className="w-75 h-8" onClick={() => null} />
          <h2 className="text-yellow text-2xl lg:text-4xl leading relative">
            <a href="mailto:hello@themachineryclub.com">
              hello@themachineryclub.com
            </a>
          </h2>
        </div>
        <div className="flex justify-between">
          <div className="flex">
            {/* <div className="flex flex-col">
              <span className="bolder mb-9">{t('myAccount')}</span>
              <Link to="/dashboard" className="subtitle mb-4">{t('summary')}</Link>
              <Link to="/upload" className="subtitle mb-4">{t('sell')}</Link>
            </div> */}

            <div className="flex flex-col">
              <span className="bolder mb-9">{t('followUs')}</span>
              <a
                href="https://www.facebook.com/TheMachineryClub"
                target="_blank"
                className="subtitle mb-4 flex items-center"
                rel="noopener noreferrer"
              >
                <Icon name="Facebookyellow" />
                <span className="ml-2.5">/TheMachineryClub</span>
              </a>
              <a
                href="https://www.instagram.com/themachineryclub/"
                target="_blank"
                className="subtitle mb-4 flex"
                rel="noopener noreferrer"
              >
                <Icon name="Instagram" />
                <span className="ml-2.5">/themachineryclub</span>
              </a>
              <a
                href="https://www.linkedin.com/company/the-machinery-club/"
                target="_blank"
                className="subtitle mb-4 flex items-center"
                rel="noopener noreferrer"
              >
                <Icon name="Linkedin" />
                <span className="ml-2.5">/the-machinery-club</span>
              </a>
              <a
                href="https://twitter.com/machinery_club"
                target="_blank"
                className="subtitle mb-4 flex items-center"
                rel="noopener noreferrer"
              >
                <Icon name="Twitter" />
                <span className="ml-2.5">@machinery_club</span>
              </a>
              <a
                href="https://www.youtube.com/channel/UCLpiPP6RlQaBVHEzIoFEO2A"
                target="_blank"
                className="subtitle mb-4 flex items-center"
                rel="noopener noreferrer"
              >
                <Icon name="Youtube" />
                <span className="ml-2.5">The machinery Club</span>
              </a>
            </div>
          </div>
          <div className="flex flex-col w-80">
            <span className="bolder">{t('subscribeToOurNewsletter')}</span>
            <form className="flex" onSubmit={(e) => submitNewsletter(e)}>
              <Input
                placeholder={t('enterYourEmail')}
                onChange={setNewsletterEmail}
                value={newsletterEmail}
                required
                type="email"
              />
              <div className="w-24">
                <Button
                  className="ml-4"
                  type="submit"
                  loading={newsletterLoading}
                  colorVariant="yellow"
                >
                  {t('send')}
                </Button>
              </div>
            </form>
            {showNewsletterMessage && (
              <span className="text-yellow text-xs pt-3 pl-4">
                {t('thanksForSubscribing')}
              </span>
            )}
          </div>
        </div>
        <div className="flex items-center mt-16">
          <Icon name="Location" />
          <span className="text-xs leading-8 ml-2.5">
            7901 4th St N Ste 300, St. Petersburg, FL 33702, USA
          </span>
        </div>
        <Divider className="mt-7 mb-5" />
        <div className="flex justify-between items-center">
          <span className="text-2xs text-gray">
            Copyright © {year} The Machinery Club Inc. {t('allRightsReserved')}.
          </span>

          <div className="text-gray">
            <Link to="/privacy-policy" className="text-2xs px-4">
              {t('privacyPolicies')}
            </Link>
            <Link to="/terms-and-conditions" className="text-2xs px-4">
              {t('terms&conditions')}
            </Link>
            <a
              href="https://estudionk.com"
              className="text-2xs px-4 opacity-30"
              rel="noopener noreferrer"
              target="_blank"
            >
              Powered by estudio/nk
            </a>
            {/* <Link to="/help" className="text-2xs px-4">
              {t('help')}
            </Link> */}
          </div>
        </div>
      </div>
    </footer>
  );
};
