import React, { useState } from 'react';
import chatClient from 'services/chat';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import { NewNotification, Icon } from 'components';
import { useTranslation } from 'react-i18next';

export const useChatEvents = () => {
  const [userId, setUserId] = useState();
  const { t } = useTranslation();

  const listenForEvents = async (userId) => {
    const _userId = userId.toString();
    setUserId(_userId);

    chatClient.on('notification.added_to_channel', async (event) => {
      //If a new conversation starts, query the new channel and listen it because it's not on the initial channel's array.
      const [channel] = await chatClient.queryChannels({
        type: 'messaging',
        id: event.channel.id,
        members: { $in: [_userId] }
      });
      listenChannel(channel);
    });

    const channels = await chatClient.queryChannels({
      type: 'messaging',
      members: { $in: [_userId] }
    });

    channels.forEach((c) => {
      listenChannel(c, _userId);
    });
  };

  const listenChannel = (c, uid) => {
    c.on('message.new', (event) => {
      const { user, message } = event;
      if (
        user.id !== (uid || userId) &&
        !window.location.href.includes('chat')
      ) {
        const chatNotification = (
          <Link to="/dashboard/chat">
            {`: ${message.text.substring(0, 20)}${
              message.text.length > 20 ? '...' : ''
            }`}
          </Link>
        );
        toast(
          <NewNotification
            variant="chat"
            titleLink="/dashboard/chat"
            link="/dashboard/chat"
            title={t('newMessage')}
            user={user}
          >
            {chatNotification}
          </NewNotification>,
          {
            closeButton: (
              <Icon
                name="Close"
                style={{ top: '1.5rem', right: '1.5rem', position: 'absolute' }}
              />
            )
          }
        );
      }
    });
  };

  return { listenForEvents };
};
