import React, { useEffect, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { useQuery } from 'hooks';
import 'swiper/swiper.min.css';
import 'swiper/components/navigation/navigation.min.css';
import 'swiper/components/thumbs/thumbs.min.css';
import SwiperCore, {
  Navigation,
  Pagination,
  Thumbs,
  Keyboard
} from 'swiper/core';
import { useModal } from 'hooks';
import cn from 'classnames';
import { Overlay } from '@estudio-nk/the-crane-club-client-lib';
import { useMediaQuery } from 'react-responsive';
import { PostFile } from 'components';
import { Capacitor } from '@capacitor/core';
import './index.scss'

SwiperCore.use([Navigation, Thumbs, Keyboard, Pagination]);

export const PostFilesCloudinary = ({ files }) => {
  const query = useQuery();
  const { toggleModal, visible } = useModal();
  const sortedFiles = files?.sort((a, b) => (a.order > b.order ? 1 : -1));
  const [swiper, setSwiper] = useState(null);
  const [swiperOverlay, setSwiperOverlay] = useState(null);
  const [actualIndex, setActualIndex] = useState(0);
  const [actualSlide, setActualSlide] = useState(0);
  const isMobile = useMediaQuery({
    query: '(max-width: 1025px)'
  });

  useEffect(() => {
    if (swiper !== null) {
      setTimeout(() => {
        swiper.update();
        swiper?.slideTo(query.get('order') || 0, 0);
      }, 1000);
    }
  }, [swiper]);

  const handleSlideOnClick = (index) => {
    if(!Capacitor.isNativePlatform()){
      !visible && toggleModal();
      index && setActualIndex(index);
    }
  };

  useEffect(() => {
    swiperOverlay?.updateAutoHeight(0);
    setTimeout(() => {
      swiperOverlay?.updateAutoHeight(0);
    }, 1000);
  }, [swiperOverlay]);

  const slides = sortedFiles?.map((file, i) => (
    <SwiperSlide
      key={i}
      onClick={() => handleSlideOnClick(i)}
      className={cn({
        'image-swiper': !file?.url.includes('video'),
        'video-swiper': file?.url?.includes('video')
      })}
    >
      <PostFile
        file={file}
        size={800}
        videoClasses="flex justify-center video-container mx-auto w-full min-height-360"
        imageClasses="mx-auto"
        videoOptions={{
          playsinline: true,
          playing: isMobile,
          muted: true,
          loop: true,
          controls: true,
          preload: 'none'
        }}
        actualSlide={actualSlide}
      />
    </SwiperSlide>
  ));

  return (
    <div className="mt-8 cursor-pointer cb-post-files">
      {files.length > 1 ? (
        <>
          <Swiper
            slidesPerView="auto"
            autoHeight={true}
            navigation={true}
            className="on-postcard-swiper flex flex-col"
            pagination={{ type: 'bullets', bulletClass: 'swiper-dot' }}
            onSwiper={setSwiper}
            onSlideChange={() => setActualSlide(swiper.realIndex+1)}
          >
            {slides}
          </Swiper>
          {!isMobile && 
            <Overlay visible={visible} toggle={toggleModal}>
              <Swiper
                slidesPerView="auto"
                autoHeight={true}
                navigation={true}
                onSwiper={setSwiperOverlay}
                initialSlide={actualIndex}
                className="fullscreen-swiper flex flex-col"
                pagination={{ type: 'bullets', bulletClass: 'swiper-dot' }}
                keyboard={{ enabled: true }}
                onSlideChange={() => setActualSlide(swiperOverlay?.realIndex+1)}
              >
                {sortedFiles?.map((file, i) => (
                  <SwiperSlide
                    key={i}
                    onClick={() => handleSlideOnClick(i)}
                    className={cn({
                      'image-swiper': !file?.url.includes('video'),
                      'video-swiper': file?.url?.includes('video')
                    })}
                  >
                    <PostFile
                      file={file}
                      videoClasses="flex justify-center video-container mx-auto w-full min-height-360"
                      imageClasses="mx-auto"
                      videoOptions={{
                        playsinline: true,
                        playing: false,
                        muted: true,
                        loop: true,
                        controls: true,
                        preload: 'none'
                      }}
                      actualSlide={actualSlide}
                    />
                  </SwiperSlide>
                ))}
              </Swiper>
            </Overlay>
          }
        </>
      ) : (
        <>
          <div className="w-full">
            <PostFile
              file={files[0]}
              size={800}
              onClick={handleSlideOnClick}
              videoOptions={{
                playsinline: true,
                playing: true,
                muted: true,
                loop: true,
                controls: true,
                preload: 'none'
              }}
              videoClasses="flex justify-center w-auto h-auto mx-auto rounded min-height-360"
              imageClasses="mx-auto object-contain"
              onSlideChange={actualSlide}
            />
          </div>
          {!isMobile && 
            <Overlay
              visible={visible}
              toggle={toggleModal}
              containerClasses={cn({ 'w-screen': isMobile })}
            >
              <PostFile
                file={files[0]}
                videoOptions={{
                  playsinline: true,
                  playing: true,
                  muted: true,
                  loop: true,
                  controls: true,
                  preload: 'none'
                }}
                videoClasses="flex justify-center w-auto h-auto mx-auto rounded"
                imageClasses="rounded-lg mx-auto relative block one-image"
                onSlideChange={actualSlide}
              />
            </Overlay>
          }
        </>
      )}
    </div>
  );
};
