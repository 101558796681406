import axios from 'utils/axios';

export const getLikes = async (page = 0, size = 12) => {
  try {
    const { data } = await axios.get(`api/likes?page=${page}&size=${size}`);
    return data;
  } catch (error) {
    if (process.env.REACT_APP_DEV_MODE !== 'true') {
      console.log('ERROR: ', error.message);
    }
    throw error;
  }
};

export const getRecievedLikes = async (page = 0, size = 12) => {
  try {
    const { data } = await axios.get(
      `api/likes/recieved?page=${page}&size=${size}`
    );
    return data;
  } catch (error) {
    if (process.env.REACT_APP_DEV_MODE !== 'true') {
      console.log('ERROR: ', error.message);
    }
    throw error;
  }
};

export const getLikesByCompany = async (publicationId = 0) => {
  try {
    const { data } = await axios.get(`api/likes/${publicationId}`);
    return data;
  } catch (error) {
    if (process.env.REACT_APP_DEV_MODE !== 'true') {
      console.log('ERROR: ', error.message);
    }
    throw error;
  }
};

export const saveLikes = async (publicationId) => {
  try {
    const res = await axios.post('api/likes', { publicationId });
    return res;
  } catch (error) {
    if (process.env.REACT_APP_DEV_MODE !== 'true') {
      console.log('ERROR: ', error.message);
    }
    throw error;
  }
};
