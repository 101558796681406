import React, { useState, useEffect, useRef, useContext } from 'react';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import useOnClickOutside from 'hooks/useOnClickOutside';
import { SearchBarDropdown } from '../SearchBarDropdown';
import { PublicationFiltersContext } from 'contexts/PublicationFiltersContext';
import { Radio, Icon } from 'components';
import { Button } from '@estudio-nk/the-crane-club-client-lib';
import { useFilterQueryParams } from 'hooks';
import { useHistory } from 'react-router-dom';
import { useQuery } from 'hooks';
import './index.scss';

export const SearchBar = ({
  className,
  inputClasses,
  isExplore = false,
  showDropdown = true,
  showSwitch = true,
  onSubmit,
  onChange
}) => {
  const { t } = useTranslation();
  const ref = useRef();
  const query = useQuery();
  const history = useHistory();
  const type = query.get('type') || 'machines';
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [searchType, setSearchType] = useState(type);
  const { getParam, setParam, removeParam } = useFilterQueryParams();
  const { search, setSearch } = useContext(PublicationFiltersContext);

  useOnClickOutside(ref, () => setIsDropdownOpen(false));

  useEffect(() => {
    const searchText = getParam('search') || '';
    setSearchText(searchText);
    setSearch(searchText);
  }, []);

  const handleInputChange = ({ target: { value } }) => {
    setSearchText(value);
    onChange && onChange(value || '');
    showDropdown && setIsDropdownOpen(value.length > 0);
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();
  };

  const handleOnSearch = () => {
    if (searchType === 'machines') {
      if (searchText?.length > 0) {
        setParam({ name: 'search', value: searchText }, '/machines');
        setSearch(searchText);
      } else {
        removeParam('search');
        setSearch('');
      }
    } else {
      if (search?.length > 0) {
        history.push(
          `/${search.includes('#') ? 'tags' : 'search'}/${search.replace(
            '#',
            ''
          )}`
        );
        setIsDropdownOpen(false);
      }
    }
  };

  const handleDropdown = (text) => {
    if (searchType === 'machines') {
      setSearch(text);
      setSearchText(text);
      setParam({ name: 'search', value: text }, '/machines');
    }
    setIsDropdownOpen(false);
  };

  const handleOnKeyPress = (e) => {
    if (e.key === 'Enter') {
      onSubmit ? onSubmit() : handleOnSearch();
    }
  };

  const handleFocus = () => {
    search?.length > 0 && setIsDropdownOpen(true);
  };

  const classes = cn('cb-search-bar w-full md:w-auto', className, {
    'dropdown-open': isDropdownOpen
  });

  const onSearchTypeChange = (type) => {
    setSearchType(type);
    isExplore && history.push(`/explore?type=${type}`);
  };

  const onClickSearch = () => {
    if (searchType === 'social') {
      history.push(
        `/${search.includes('#') ? 'tags' : 'search'}/${search.replace(
          '#',
          ''
        )}`
      );
    } else {
      setSearch(searchText);
      setParam({ name: 'search', value: searchText }, '/machines');
    }
  };

  return (
    <form className={classes} onSubmit={handleFormSubmit} ref={ref}>
      <div className="relative flex-grow">
        <div className="relative search-input w-full md:w-auto lg:w-80 xl:w-144 border-b-2 border-gray-600 lg:border-none">
          <Icon name="Search" />
          <input
            type="text"
            value={searchText || ''}
            className={cn('w-full bg-background', inputClasses)}
            onChange={handleInputChange}
            onKeyPress={handleOnKeyPress}
            placeholder={t(
              `searchBar${searchType === 'social' ? 'Social' : ''}`
            )}
            onFocus={handleFocus}
          />

          {showSwitch && (
            <div className="absolute top-1/2 transform -translate-y-1/2 right-2">
              <Radio
                className=""
                items={[
                  { value: 'social', label: t('social') },
                  { value: 'machines', label: t('machines') }
                ]}
                value={isExplore ? type : searchType}
                setValue={onSearchTypeChange}
              />
            </div>
          )}
        </div>
        {isDropdownOpen && showDropdown && (
          <SearchBarDropdown
            search={searchText}
            onClick={handleDropdown}
            type={searchType}
            onClickSearch={onClickSearch}
          />
        )}
      </div>
      <div
        className="ml-6 w-32 hidden md:block"
        onClick={() => (onSubmit ? onSubmit() : handleOnSearch())}
      >
        <Button variant="outline" colorVariant="yellow">
          {t('Search')}
        </Button>
      </div>
    </form>
  );
};
