import axios from 'utils/axios';

export const getRoles = async () => {
  try {
    const { data } = await axios.get('api/auth/roles');
    return data || [];
  } catch (error) {
    if (process.env.REACT_APP_DEV_MODE !== 'true') {
      console.log('ERROR: ', error.message);
    }
    throw error;
  }
};
