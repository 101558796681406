import React from 'react';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Icon, Divider } from 'components';
import { Link } from 'react-router-dom';
import cn from 'classnames';

const SidebarUser = ({ closeSideBarOnClick }) => {
  const location = useLocation();
  const path = location.pathname.replace('/dashboard/', '');
  const { t } = useTranslation();

  return (
    <>
      <li
        className={cn('dashboard-sidebar-item', {
          'dashboard-sidebar-item-selected': path === 'notifications'
        })}
      >
        <Link to="/dashboard/notifications" onClick={closeSideBarOnClick}>
          <Icon name="Bells" width={19} height={19} canvasWidth={22} center />
          <span>{t('myNotificationsTitle')}</span>
        </Link>
      </li>
      <li
        className={cn('dashboard-sidebar-item', {
          'dashboard-sidebar-item-selected': path === 'favourites'
        })}
      >
        <Link to="/dashboard/favourites" onClick={closeSideBarOnClick}>
          <Icon
            name="Bookmark"
            width={14}
            height={17}
            canvasWidth={22}
            center
          />
          <span>{t('myFavourites')}</span>
        </Link>
      </li>
      <li
        className={cn('dashboard-sidebar-item', {
          'dashboard-sidebar-item-selected': path === 'quotations-sent'
        })}
      >
        <Link to="/dashboard/quotations-sent" onClick={closeSideBarOnClick}>
          <Icon name="Pen" width={17} height={17} canvasWidth={22} center />
          <span>{t('quotations')}</span>
        </Link>
      </li>
      <li
        className={cn('dashboard-sidebar-item', {
          'dashboard-sidebar-item-selected': path === 'likes'
        })}
      >
        <Link to="/dashboard/likes" onClick={closeSideBarOnClick}>
          <Icon name="Heart" width={14} height={17} canvasWidth={22} center />
          <span>{t('myMachineLikes')}</span>
        </Link>
      </li>
      <li
        className={cn('dashboard-sidebar-item', {
          'dashboard-sidebar-item-selected': path === 'chat'
        })}
      >
        <Link to="/dashboard/chat" onClick={closeSideBarOnClick}>
          <Icon name="Chat" width={19} height={19} canvasWidth={22} center />
          <span>{t('chat')}</span>
        </Link>
      </li>
      <Divider className="mt-5 mb-4" />
      <li
        className={cn('dashboard-sidebar-item', {
          'dashboard-sidebar-item-selected': path === 'settings/personal'
        })}
      >
        <Link to="/dashboard/settings/personal" onClick={closeSideBarOnClick}>
          <Icon name="User" width={19} height={19} canvasWidth={22} center />
          <span>{t('personalInfo')}</span>
        </Link>
      </li>
      <li
        className={cn('dashboard-sidebar-item', {
          'dashboard-sidebar-item-selected': path === 'my-cv'
        })}
      >
        <Link to="/dashboard/my-cv" onClick={closeSideBarOnClick}>
          <Icon name="File" width={19} height={19} canvasWidth={22} center />
          <span>{t('myCurriculumVitae')}</span>
        </Link>
      </li>
      <Divider className="mt-5 mb-4" />
    </>
  );
};

export default SidebarUser;
