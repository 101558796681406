import { Cloudinary } from '@cloudinary/url-gen';

export const useCloudinary = () => {
  const cld = new Cloudinary({
    cloud: {
      cloudName: 'the-crane-club'
    }
  });

  return {
    cld
  };
};
