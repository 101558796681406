import React, { useEffect, useState, useContext } from 'react';
import { useParams } from 'react-router-dom';
import cn from 'classnames';
import {
  PublicationCardContainer,
  FilterButton,
  Pagination,
  Icon,
  CompaniesBanners
} from 'components';
import { PublicationCard } from '@estudio-nk/the-crane-club-client-lib';
import { ReducedFilters } from '../ReducedFilters';
import { getVerified, getVerifiedByUser } from 'api/publications';
import { PublicationFiltersContext } from 'contexts/PublicationFiltersContext';
import { useTranslation } from 'react-i18next';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { PUBLICATIONS_PER_PAGE, ORDERBYLIST } from 'constants/index';
import { useFilterQueryParams, usePageQueryParams } from 'hooks';
import { useMediaQuery } from 'react-responsive';

import './index.scss';

export const PublicationsLists = ({
  className,
  toggleMobileFilters,
  filterByUser = false,
  userId = 0,
  fullSize = false,
  scrollTop = false
}) => {
  const { t } = useTranslation();
  const { id } = useParams();
  const [publications, setPublications] = useState([]);
  const [orderByOption, setOrderByOptions] = useState([]);
  const [resultsTotal, setResultsTotal] = useState({
    count: 0,
    totalPages: 0
  });
  const [isPublicationRow, setIsPublicationRow] = useState(false);
  const [loading, setLoading] = useState(false);
  const { params } = useFilterQueryParams();
  const { getPage, setPage, removePage, getOrderBy, setPageAndOrderBy } =
    usePageQueryParams();
  const { search, loadingFilters } = useContext(PublicationFiltersContext);
  const page = getPage();
  const orderBy = getOrderBy();
  const isMobile = useMediaQuery({
    query: '(max-width: 768px)'
  });

  useEffect(() => {
    fetchPublication();
    switch (params.operationType) {
      case 'rent':
        setOrderByOptions(ORDERBYLIST.filter((order) => order.type !== 'sell'));
        break;
      case 'sell':
        setOrderByOptions(ORDERBYLIST.filter((order) => order.type !== 'rent'));
        break;
      default:
        setOrderByOptions(ORDERBYLIST);
        break;
    }
    scrollTop && window.scrollTo({ top: 0, behavior: 'smooth' });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    page,
    orderBy,
    loadingFilters,
    search,
    params.type,
    params.operationType,
    params.brands,
    params.years,
    params.categories,
    params.countries,
    params.condition
  ]);

  const fetchPublication = async () => {
    try {
      if (loadingFilters || loading) return;
      setLoading(true);
      const type = params.type || 0;
      const operationType = params?.operationType?.toLowerCase() || 'both';
      if (!filterByUser) {
        const filters = {
          brands: params.brands,
          years: params.years,
          categories: params.categories,
          countries: params.countries,
          condition: params.condition
        };
        const { count, rows, totalPages } = await getVerified(
          type,
          operationType,
          page - 1,
          PUBLICATIONS_PER_PAGE,
          orderBy,
          { ...filters, search }
        );
        setResultsTotal({ count, totalPages });
        setPublications(rows);
      } else {
        const { count, rows, totalPages } = await getVerifiedByUser(
          type,
          operationType,
          userId,
          page - 1,
          PUBLICATIONS_PER_PAGE,
          orderBy
        );
        setResultsTotal({ count, totalPages });
        setPublications(rows);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setTimeout(() => {
        setLoading(false);
      }, 1000);
    }
  };

  const handleOrderBy = ({ target }) => {
    setPageAndOrderBy({
      page: undefined,
      orderBy: target.value
    });
  };

  const handlePage = (page) => {
    if (page > 1) {
      setPage(page);
    } else {
      removePage();
    }
  };

  return (
    <div
      className={cn(`w-full pt-6 xl:pt-14`, className, {
        'animate-pulse pointer-events-none': loading || loadingFilters,
        'lg:w-3/4': !fullSize
      })}
    >
      <div className="flex items-center justify-between h-8 mb-5">
        <div
          className={cn('text-silver text-xs', {
            'bg-background rounded-sm text-transparent w-24 h-4':
              loading || loadingFilters
          })}
        >
          {!loading && !loadingFilters && (
            <>
              {!!publications.length && (
                <>
                  <span className="text-gray-">{publications.length} </span>
                  <span>- {t('results')} </span>
                  {resultsTotal.count && (
                    <span>
                      {t('of')}{' '}
                      <span className="text-yellow">{resultsTotal.count}</span>
                    </span>
                  )}
                </>
              )}
            </>
          )}
        </div>

        {!id && <FilterButton onClick={toggleMobileFilters} />}

        <div className="hidden md:flex items-center">
          <div
            className={cn('text-silver text-xs flex items-center mr-14', {
              'bg-background rounded-sm text-transparent w-40 h-4': loading
            })}
          >
            {filterByUser && <ReducedFilters userId={id} />}
            {!loading && !!publications.length && (
              <div className="flex items-center">
                <span className="text-white-secondary mx-2 flex-shrink-0">
                  {t('orderBy')}:{' '}
                </span>
                <div className="select-xs">
                  <Select
                    value={orderBy}
                    IconComponent={() => (
                      <Icon name="ChevronDown" width={13} height={6} />
                    )}
                    onChange={handleOrderBy}
                  >
                    {orderByOption.map(({ label, value }, index) => (
                      <MenuItem key={index} value={value}>
                        {t(label)}
                      </MenuItem>
                    ))}
                  </Select>
                </div>
              </div>
            )}
          </div>

          <div
            className={cn('flex items-center list-grid-buttons', {
              'bg-black-background rounded-sm text-transparent w-10 h-4':
                loading
            })}
          >
            {!loading && !loadingFilters && !!publications.length && (
              <>
                <Icon
                  name="List"
                  width={16}
                  height={16}
                  className={`p-2 cursor-pointer ${
                    isPublicationRow ? 'active' : ''
                  }`}
                  onClick={() => setIsPublicationRow(true)}
                />
                <Icon
                  name="Grid"
                  width={16}
                  height={16}
                  className={`p-2 cursor-pointer ${
                    isPublicationRow ? '' : 'active'
                  }`}
                  onClick={() => setIsPublicationRow(false)}
                />
              </>
            )}
          </div>
        </div>
      </div>

      {!loading && !loadingFilters && !publications?.length && (
        <div className="flex items-center justify-center text-2xl text-yellow p-8">
          <p>{t('noPublicationsToDisplay')}</p>
        </div>
      )}

      <div
        className={`grid ${
          isMobile || isPublicationRow ? 'grid-cols-1' : 'grid-cols-3'
        } gap-6`}
      >
        {!loading &&
          !loadingFilters &&
          !!publications.length &&
          publications.map((publication) => (
            <PublicationCardContainer
              key={`${publication.id}-${Math.random()}`}
              publication={publication}
              row={isMobile ? false : isPublicationRow}
              className="dark-shadow"
            />
          ))}

        {(loading || loadingFilters) &&
          new Array(10)
            .fill(0)
            .map((_, index) => (
              <PublicationCard
                key={index}
                row={isMobile ? false : isPublicationRow}
                colorVariant="yellow"
                className="dark-shadow"
              />
            ))}
      </div>
      {!loading && !!publications.length && (
        <div>
          <Pagination
            totalPages={resultsTotal.totalPages}
            currentPage={page}
            setPages={handlePage}
          />
        </div>
      )}
      {isMobile && <CompaniesBanners />}
    </div>
  );
};
