import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Icon } from 'components';
import cn from 'classnames';
import './index.scss';

export const ForwardButton = ({ className, onClick, children, linkTo }) => {
  const classes = cn('cb-forward-button', className);

  return linkTo ? (
    <Link className={classes} to={linkTo}>
      <Icon name="ChevronRight" />
      <p>{children}</p>
    </Link>
  ) : (
    <button className={classes} onClick={onClick} type="button">
      <Icon name="ChevronRight" />
      <p>{children}</p>
    </button>
  );
};

ForwardButton.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  onClick: PropTypes.func,
  linkTo: PropTypes.string,
  type: PropTypes.string
};
