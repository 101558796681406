import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import reactStringReplace from 'react-string-replace';
import { HASHTAG_REGEX } from 'utils/constants';

const TEXT_LIMIT = 270;

export const PostDescription = ({ description }) => {
  const [seeMore, setSeeMore] = useState(true);
  const { t } = useTranslation();

  useEffect(() => {
    description.length > TEXT_LIMIT && setSeeMore(false);
    transformTextToURLS(description);
  }, [description]);

  const transformTextToURLS = (description) => {
    let t = reactStringReplace(description, HASHTAG_REGEX, (match, i) => (
      <Link
        key={match + i}
        className="text-yellow cursor-pointer hover:underline"
        to={`/tags/${match.replace('#', '')}`}
      >
        {match}
      </Link>
    ));
    let finalText = reactStringReplace(t, /(https?:\/\/\S+)/g, (match, i) => (
      <a
        key={match + i}
        rel="noreferrer"
        href={match}
        className="cursor-pointer hover:underline text-yellow"
        target="_blank"
      >
        {match}
      </a>
    ));
    return finalText;
  };

  return (
    <div>
      <div className={`text-white text-sm leading-5.5 whitespace-pre-line`}>
        <div>
          {seeMore
            ? transformTextToURLS(description)
            : transformTextToURLS(description.substr(0, TEXT_LIMIT))}
        </div>

        {description.length > TEXT_LIMIT && !seeMore && (
          <>
            ...
            <button
              onClick={() => setSeeMore(true)}
              className="text-yellow underline hover:no-underline ml-1"
            >
              {t('seeMore')}
            </button>
          </>
        )}
      </div>
    </div>
  );
};
