import React, { Fragment } from 'react';
import CRadio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import cn from 'classnames';

import './index.scss';

function StyledRadio(props) {
  return (
    <CRadio
      disableRipple
      color="default"
      checkedIcon={<span className="icon checked-icon"></span>}
      icon={<span className="icon" />}
      {...props}
    />
  );
}

export const Radio = ({
  title,
  items,
  className,
  value,
  setValue = () => {}
}) =>
  items.length > 0 ? (
    <Fragment>
      {title && <h4 className="text-xl text-yellow">{title}</h4>}
      <div className={cn(className)}>
        <FormControl className={cn(className, 'cb-radio')} component="fieldset">
          <RadioGroup value={value} onClick={(e) => setValue(e.target.value)}>
            {items.map(({ value, label, disabled }, index) => (
              <div key={'radio-' + index} className="block">
                <FormControlLabel
                  key={index}
                  value={value}
                  control={<StyledRadio />}
                  label={label}
                  disabled={disabled}
                />
              </div>
            ))}
          </RadioGroup>
        </FormControl>
      </div>
    </Fragment>
  ) : (
    <></>
  );
