import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Checkbox } from 'components/forms';
import '@material-tailwind/react/tailwind.css';
import './index.scss';

export const CheckboxGroup = ({
  title,
  selected,
  options,
  setSelected,
  translateOptions = false,
  className
}) => {
  const { t } = useTranslation();

  return (
    <div className={className}>
      <h4 className="text-xl text-yellow">{title}</h4>
      <div className="mt-5">
        {options?.map((item) => (
          <div className="my-2" key={item?.id ? item.id : item}>
            <Checkbox
              id={item?.id ? item.id : item}
              name={
                translateOptions
                  ? item?.name
                    ? t(item.name)
                    : t(item)
                  : item?.name
                  ? item.name
                  : item
              }
              checked={selected?.includes(item?.id ? item.id : item) || false}
              setChecked={(checked) =>
                setSelected && setSelected(item?.id ? item.id : item, checked)
              }
            />
          </div>
        ))}
      </div>
    </div>
  );
};

CheckboxGroup.propTypes = {
  title: PropTypes.string,
  sleected: PropTypes.string,
  options: PropTypes.array,
  settings: PropTypes.func,
  translateOptions: PropTypes.bool
};
