import React from 'react';
import cn from 'classnames';
import CPhoneInput from 'react-phone-input-2';
import { useTranslation } from 'react-i18next';
import 'react-phone-input-2/lib/style.css';

import 'react-phone-input-2/lib/style.css';
import './index.scss';


export const PhoneInput = ({
  className,
  error,
  errorMessage,
  placeholder,
  ...rest
}) => {
  const { t } = useTranslation();
  return (
    <div className={cn('cb-phone-input', className, { error })}>
      <CPhoneInput
        enableLongNumbers
        alwaysDefaultMask
        placeholder={placeholder || t('phone')}
        defaultMask=".............."
        {...rest}
      />
      {error && (
        <span className="text-2xs text-yellow-dark flex justify-end mt-1">
          {errorMessage ? errorMessage : t(`form.${error.type}`)}
        </span>
      )}
    </div>
  );
};
