import React from 'react';
import cn from 'classnames';

export const Cell = ({ onClick, className, children }) => {
  return (
    <th
      scope="col"
      onClick={onClick}
      className={cn(
        'text-left text-xs font-medium text-white-secondary whitespace-nowrap',
        { 'hidden sm:table-cell': !children },
        className
      )}
    >
      {children}
    </th>
  );
};
