import { useEffect, useState, useContext } from 'react';
import { Button } from '@estudio-nk/the-crane-club-client-lib';
import { Pagination, LoginModal, UserCardContainer } from 'components';
import { UserContext } from 'contexts/UserContext';
import { usePageQueryParams, useModal } from 'hooks';
import { getOpenToWork } from 'api/user';
import machineBackground from 'assets/img/machine-background.png';
import { useFilterQueryParams } from 'hooks';
import { useTranslation } from 'react-i18next';

export const JobsList = () => {
  const [loading, setLoading] = useState(true);
  const [users, setUsers] = useState(new Array(9).fill({}));
  const [resultsTotal, setResultsTotal] = useState({
    count: 0,
    totalPages: 0
  });
  const { getPage, setPage } = usePageQueryParams();
  const { params } = useFilterQueryParams();
  const { user } = useContext(UserContext);
  const { visible: visibleLoginModal, toggleModal: toggleLoginModal } =
    useModal();
  const { t } = useTranslation();
  const page = getPage() || 1;

  const fetchUser = async () => {
    try {
      setLoading(true);
      const { rows, count, totalPages } = await getOpenToWork(
        page - 1,
        8,
        params?.countries || null,
        params?.roles?.map((r) => parseInt(r)) || null
      );
      setResultsTotal({ count, totalPages });
      setUsers(rows);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchUser();
  }, [page]);

  useEffect(() => {
    setPage(1);
    fetchUser();
  }, [params?.countries, params?.roles]);

  return (
    <div className="wrapper-lg pt-14 flex flex-col">
      <div className="flex flex-col md:grid md:grid-cols-2 lg:grid-cols-4 xl:grid-cols-4 gap-x-5 gap-y-8">
        <>
          {users.map((user, i) => (
            <UserCardContainer key={i} user={user} loading={loading} />
          ))}
          {!user?.isOpenToWork && !user?.isCompany && (
            <div
              className="max-w-60 h-full rounded-md p-10 flex flex-col justify-between"
              style={{
                background: 'rgba(0, 0, 0, 0.5)',
                backgroundImage: `url(${machineBackground})`
              }}
            >
              <div>
                <span className="text-4xl">👷🏻</span>
                <div className="text-2xl text-gray-light font-semibold uppercase mt-2 leading-6">
                  {t('areYouOpenToWork')}
                </div>
              </div>
              <Button
                linkTo={'/dashboard/my-cv'}
                size="md mt-3 capitalize"
                colorVariant="yellow"
              >
                {t('updateMyCV')}
              </Button>
            </div>
          )}
        </>
      </div>
      <Pagination
        totalPages={resultsTotal.totalPages}
        currentPage={page}
        setPages={setPage}
      />
      <LoginModal visible={visibleLoginModal} toggleModal={toggleLoginModal} />
    </div>
  );
};
