import { useState, useRef, useContext, useEffect } from 'react';
import Badge from '@mui/material/Badge';
import { Box, Icon, Notification, Divider } from 'components';
import { Button } from '@estudio-nk/the-crane-club-client-lib';
import { UserContext } from 'contexts/UserContext';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import cn from 'classnames';
import useOnClickOutside from 'hooks/useOnClickOutside';

export const FloatingNotifications = ({ className }) => {
  const [opened, setOpened] = useState(false);
  const { t } = useTranslation();
  const history = useHistory();
  const [loadingSeen, setLoadingSeen] = useState(false);
  const { notifications, unseenNotifications, readAllNotification } =
    useContext(UserContext);
  const ref = useRef();
  useOnClickOutside(ref, () => setOpened(false));

  useEffect(() => {}, [notifications]);

  const handleOnViewAll = () => {
    history.push('/dashboard/notifications');
    setOpened(false);
  };

  const handleOnClick = () => {
    if (window.innerWidth > 768) {
      setOpened(!opened);
    } else {
      history.push('/dashboard/notifications');
    }
  };

  const handleMarkAsSeen = async () => {
    try {
      setLoadingSeen(true);
      await readAllNotification();
    } catch (error) {
    } finally {
      setLoadingSeen(false);
    }
  };

  return (
    <div className={cn('relative', className)} ref={ref}>
      <div
        className="p-3 w-12 h-12 cursor-pointer rounded-full transition duration-300 hover:bg-black-light "
        onClick={() => handleOnClick()}
      >
        <Badge badgeContent={unseenNotifications || 0}>
          <Icon
            name="Bell"
            canvasWidth={22}
            canvasHeight={22}
            width={20}
            height={20}
            center
          />
        </Badge>
      </div>

      {opened && (
        <Box
          title={t('latestNotifications')}
          buttonText={t('viewAllF')}
          onClick={() => handleOnViewAll()}
          className="absolute top-full right-0 w-80 py-1 bg-background shadow-m"
        >
          <div>
            <div className="overflow-y-scroll" style={{ maxHeight: '60vh' }}>
              {notifications?.slice(0, 20)?.map((notification, i) => (
                <div key={i}>
                  <Notification
                    notification={notification}
                    textContainerClasses="text-xs"
                    userNameClasses="text-xs"
                    textClasses="text-xs ml-1"
                    badgeSize={16}
                  />
                  <Divider className="my-3" />
                </div>
              ))}
            </div>
            <div className="text-yellow text-center w-full">
              <Button
                variant="text"
                colorVariant="yellow"
                className="mt-2 mx-auto text-xs"
                onClick={handleMarkAsSeen}
                loading={loadingSeen}
                disabled={unseenNotifications === 0}
              >
                {t('seeAll')}
              </Button>
            </div>
          </div>
        </Box>
      )}
    </div>
  );
};
