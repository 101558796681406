import { useEffect, useState, useContext } from 'react';
import { Link } from 'react-router-dom';
import { getCompanyUsers } from 'api/user';
import { useTranslation } from 'react-i18next';
import { ForwardButton } from 'components';
import { slugify } from 'utils/formatter';
import { ReloadContext } from 'contexts/ReloadContext';

export const CompaniesMenu = ({ close }) => {
  const { t } = useTranslation();
  const [companies, setCompanies] = useState([]);
  const { setReload } = useContext(ReloadContext);

  useEffect(() => {
    fetchCompanies();
  }, []);

  const fetchCompanies = async () => {
    try {
      const { companies } = await getCompanyUsers();
      setCompanies(companies);
    } catch (err) {}
  };

  const handleOnClick = () => {
    setReload(true);
    close();
  };

  return (
    <div className="grid wrapper-lg py-12 gap-x-4 gap-y-1 justify-center grid-cols-6">
      {companies?.map(({ logoURL, name, id }, index) => (
        <Link
          to={`/u/${id}/${slugify(name)}`}
          className="h-52 rounded-lg flex justify-center flex-col items-center cursor-pointer"
          onClick={handleOnClick}
          key={index}
        >
          <div
            className="w-2/3 h-20 bg-center bg-no-repeat rounded-lg"
            style={{
              backgroundSize: '80%',
              backgroundImage: `url(${logoURL})`
            }}
          ></div>
          <span className="font-semibold text-base text-gray-light mt-6">
            {name}
          </span>
          <ForwardButton className="mt-2">{t('seePublications')}</ForwardButton>
        </Link>
      ))}
    </div>
  );
};
