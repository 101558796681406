import { useState, useRef } from 'react';
import Badge from '@mui/material/Badge';
import { useTranslation } from 'react-i18next';
import { Link, useHistory } from 'react-router-dom';
import cn from 'classnames';
import useOnClickOutside from 'hooks/useOnClickOutside';
import {
  ChannelList,
  ChannelListPreviewItem,
  Chat,
  Box,
  Icon
} from 'components';
import { useChatUnreadCount } from 'hooks';

export const FloatingChat = ({ className }) => {
  const [opened, setOpened] = useState(false);
  const { t } = useTranslation();
  const history = useHistory();
  const unreadCount = useChatUnreadCount();
  const ref = useRef();
  useOnClickOutside(ref, () => setOpened(false));

  const handleOnViewAll = () => {
    history.push('/dashboard/chat');
    setOpened(false);
  };

  const handleOnClick = () => {
    if (window.innerWidth > 768) {
      setOpened(!opened);
    } else {
      history.push('/dashboard/chat');
    }
  };

  return (
    <div className={cn('relative', className)} ref={ref}>
      <div
        className="p-3 w-12 h-12 cursor-pointer rounded-full transition duration-300 hover:bg-black-light "
        onClick={() => handleOnClick()}
      >
        <Badge badgeContent={unreadCount}>
          <Icon
            name="Chat"
            canvasWidth={22}
            canvasHeight={22}
            width={20}
            height={20}
            center
          />
        </Badge>
      </div>

      {opened && (
        <Box
          title={t('latestsMessages')}
          buttonText={t('viewAllM')}
          onClick={() => handleOnViewAll()}
          className="absolute top-full right-0 w-80"
        >
          <div onClick={() => setOpened(false)}>
            <Chat
              customClasses={{
                chat: 'h-min flat channel-list-home'
              }}
            >
              <ChannelList
                options={{ limit: 5 }}
                Preview={ChannelListPreviewItem}
              />
            </Chat>
            <Link
              to="/dashboard/chat"
              className="text-xs text-yellow hover:underline inline-block text-center mt-5 w-full"
            >
              {t('viewAllConversations')}
            </Link>
          </div>
        </Box>
      )}
    </div>
  );
};
