import axios from 'utils/axios';
import { uploadFile, deleteFile } from 'api/files';

export const getCompanyUsers = async (payload) => {
  try {
    const { data } = await axios.get(`api/auth/users/companies`, payload);
    return data;
  } catch (error) {
    if (process.env.REACT_APP_DEV_MODE !== 'true') {
      console.log('ERROR: ', error.message);
    }
    throw error;
  }
};

export const getSuppliers = async (payload) => {
  try {
    const { data } = await axios.get(`api/auth/users/suppliers`, payload);
    return data;
  } catch (error) {
    if (process.env.REACT_APP_DEV_MODE !== 'true') {
      console.log('ERROR: ', error.message);
    }
    throw error;
  }
};

export const getPartSuppliers = async (payload) => {
  try {
    const { data } = await axios.get(`api/auth/users/parts-suppliers`, payload);
    return data;
  } catch (error) {
    if (process.env.REACT_APP_DEV_MODE !== 'true') {
      console.log('ERROR: ', error.message);
    }
    throw error;
  }
};

export const getInfluencers = async (page, size) => {
  try {
    const { data } = await axios.get(
      `api/auth/users/influencers?page=${page}&size=${size}`
    );
    return data;
  } catch (error) {
    if (process.env.REACT_APP_DEV_MODE !== 'true') {
      console.log('ERROR: ', error.message);
    }
    throw error;
  }
};

export const getOpenToWork = async (page, size, countries, rolesId) => {
  try {
    let url = `api/auth/users/open-to-work?page=${page}&size=${size}`;
    countries && (url += `&countries=${countries}`);
    rolesId && (url += `&rolesId=${rolesId}`);
    const { data } = await axios.get(url);
    return data;
  } catch (error) {
    if (process.env.REACT_APP_DEV_MODE !== 'true') {
      console.log('ERROR: ', error.message);
    }
    throw error;
  }
};

export const getVIPs = async (page, size) => {
  try {
    let url = `api/auth/users/vips?page=${page}&size=${size}`;
    const { data } = await axios.get(url);
    return data;
  } catch (error) {
    if (process.env.REACT_APP_DEV_MODE !== 'true') {
      console.log('ERROR: ', error.message);
    }
    throw error;
  }
};

export const getOpenToWorkFilters = async () => {
  try {
    const { data } = await axios.get('api/auth/users/open-to-work-filters');
    return data;
  } catch (error) {
    if (process.env.REACT_APP_DEV_MODE !== 'true') {
      console.log('ERROR: ', error.message);
    }
    throw error;
  }
};

export const getMe = async (token) => {
  try {
    const storageToken = localStorage.getItem('tmc-token');
    if (token || storageToken) {
      const { data } = await axios.get(
        'api/auth/users/me',
        token && {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );
      return data;
    } else {
      return null;
    }
  } catch (error) {
    if (process.env.REACT_APP_DEV_MODE !== 'true') {
      console.log('ERROR: ', error.message);
    }
    throw error;
  }
};

export const updateUserLanguage = async (lng = 'en') => {
  try {
    const language = lng.toLowerCase().includes('es') ? 'es' : 'en';
    const { data } = await axios.put('api/auth/users/language', {
      lng: language
    });
    return data;
  } catch (error) {
    if (process.env.REACT_APP_DEV_MODE !== 'true') {
      console.log('ERROR: ', error.message);
    }
    throw error;
  }
};

export const updateUser = async (payload) => {
  try {
    const { data } = await axios.put(`api/auth/users`, payload);
    return data;
  } catch (error) {
    if (process.env.REACT_APP_DEV_MODE !== 'true') {
      console.log('ERROR: ', error.message);
    }
    throw error;
  }
};

export const updateCookiesAccepted = async () => {
  try {
    const { data } = await axios.put(`api/auth/users/accept-cookies`);
    return data;
  } catch (error) {
    if (process.env.REACT_APP_DEV_MODE !== 'true') {
      console.log('ERROR: ', error.message);
    }
    throw error;
  }
};

export const updateBanner = async (uid, file, oldURL) => {
  try {
    const {
      urls: [newURL]
    } = await uploadFile(file, {
      destination: `users/${uid}/banner/`,
      resize: [{ width: '1680', height: '1680' }]
    });
    await axios.put(`api/auth/users`, { bannerURL: newURL });
    !!oldURL && oldURL !== newURL && (await deleteFile(oldURL));
    return newURL;
  } catch (error) {
    if (process.env.REACT_APP_DEV_MODE !== 'true') {
      console.log('ERROR: ', error.message);
    }
    throw error;
  }
};

export const updateProfilePicture = async (uid, file, oldURL) => {
  try {
    const { urls } = await uploadFile(file, {
      destination: `users/${uid}/photo`,
      resize: [{ width: '800', height: '800' }]
    });
    const newURL = urls[0];
    await axios.put('api/auth/users', { photoURL: newURL });
    !!oldURL && oldURL !== newURL && (await deleteFile(oldURL));
    return newURL;
  } catch (error) {
    if (process.env.REACT_APP_DEV_MODE !== 'true') {
      console.log('ERROR: ', error.message);
    }
    throw error;
  }
};

export const requestVIPOrInfluencer = async (request = true) => {
  try {
    return axios.put(`api/auth/users/`, {
      requestVIPOrInfluencer: request
    });
  } catch (error) {
    if (process.env.REACT_APP_DEV_MODE !== 'true') {
      console.log('ERROR: ', error.message);
    }
    throw error;
  }
};
