import React from 'react';
import { Link } from 'react-router-dom';
import { profileToURL } from 'utils/formatter';
import { Avatar, ProfileBadge } from 'components';
import cn from 'classnames';

export const AvatarUser = ({
  className,
  userNameClasses,
  user,
  variant = 'text-below',
  children,
  textClasses,
  logo,
  text,
  link,
  badgeSize = 24,
  ...rest
}) => {
  const userLink = (
    <Link
      to={link || profileToURL(user)}
      className={cn('inline-flex items-center', userNameClasses)}
    >
      <div>{user?.name}</div>{' '}
      <ProfileBadge className="ml-2" size={badgeSize} profile={user} />
    </Link>
  );

  return (
    <div className={cn('flex justify-start items-center', className)}>
      <Link to={profileToURL(user)} className="relative flex-shrink-0">
        <Avatar
          src={user?.photoURL}
          onlyPhoto
          isCustom
          showAdminLogo={!user}
          borderColor="#f7a709"
          {...rest}
        />
        {logo}
      </Link>
      <div className="ml-3">
        {variant === 'no-text' ? (
          <div className="font-semibold">
            {' '}
            {userLink} {text}
          </div>
        ) : variant === 'text-aside' ? (
          <div className="flex items-center">
            <div className="text-sm font-semibold">
              {userLink} {text}
            </div>
            {children}
          </div>
        ) : (
          <div>
            <div className={cn(textClasses)}>
              {userLink} {text}
            </div>
            {children}
          </div>
        )}
      </div>
    </div>
  );
};
