import { useState, useEffect } from 'react';

export const useModal = () => {
  const [visible, setVisible] = useState(false);
  const [modalTitle, setModalTitle] = useState('');
  const [modalButtons, setModalButtons] = useState(null);
  const [modalChildren, setModalChildren] = useState(null);

  useEffect(() => {
    return () => {
      document.body.style.overflow = 'visible';
    };
  }, []);

  const toggleModal = (value) => {
    if(value !== undefined){
      setVisible(value);
    }else{
      setVisible(!visible);
    }
    toggleBodyOverflow();
  };

  const toggleBodyOverflow = () => {
    document.body.style.overflow = visible ? 'visible' : 'hidden';
  };

  return {
    toggleModal,
    visible,
    modalTitle,
    setModalTitle,
    modalButtons,
    setModalButtons,
    modalChildren,
    setModalChildren
  };
};
