import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import cn from 'classnames';
import './index.scss';

import { Icon } from 'components';

export const ArrowButton = ({
  className,
  onClick,
  children,
  linkTo,
  yellow,
  white
}) => {
  const classes = cn('cb-arrow-button', className, { yellow, white });

  const inner = (
    <>
      <span>{children}</span>
      <Icon name="ChevronRightCircle" width={26} height={26} />
    </>
  );

  return linkTo ? (
    <Link className={classes} to={linkTo}>
      {inner}
    </Link>
  ) : (
    <button className={classes} onClick={onClick}>
      {inner}
    </button>
  );
};

ArrowButton.propTypes = {
  variant: PropTypes.oneOf(['primary, secondary']),
  className: PropTypes.string,
  children: PropTypes.node,
  onClick: PropTypes.func,
  linkTo: PropTypes.string,
  yellow: PropTypes.bool
};
