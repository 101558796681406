import React, { useState, useEffect, useContext } from 'react';
import { useHistory, useLocation, Link } from 'react-router-dom';
import { Avatar, Icon, Logo } from 'components';
import { Button } from '@estudio-nk/the-crane-club-client-lib';
import { UserContext } from 'contexts/UserContext';
import cn from 'classnames';
import { Capacitor } from '@capacitor/core';
import { useTranslation } from 'react-i18next';

import './index.scss';

export const MobileNavbar = ({ className }) => {
  const history = useHistory();
  const { user } = useContext(UserContext);
  const location = useLocation();
  const [showMenu, setShowMenu] = useState(false);
  const { t } = useTranslation();
  const isIOS = Capacitor.getPlatform() === 'ios';

  useEffect(() => {
    document.body.style.overflow = showMenu ? 'hidden' : 'unset';
  }, [showMenu]);

  const handleClick = (path) => {
    setShowMenu(false);
    history.push(path);
  };

  return (
    <>
      {!user && (
        <div
          className="fixed bottom-16 max-w-md mx-auto left-0 right-0 z-10 mb-safe"
          style={{ width: '90%' }}
        >
          <Button linkTo="/auth/register" colorVariant="yellow">
            {t('sellYourMachine')}
          </Button>
        </div>
      )}

      <div
        className={cn(
          'cb-navbar fixed bottom-0 w-screen grid items-center justify-items-center shadow-m grid-cols-5 px-2 bg-black-light',
          className,
          {
            'pt-3 pb-safe': isIOS,
            'h-13': !isIOS
          }
        )}
      >
        <Icon
          onClick={() => handleClick('/social-club')}
          className={cn(location.pathname === '/social-club' && 'active')}
          name="Club"
          canvasWidth={'100%'}
          canvasHeight={'100%'}
          center
          height={36}
          width={36}
        />
        <Icon
          onClick={() => handleClick('/machines')}
          className={cn(location.pathname === '/machines' && 'active')}
          name="Machine"
          canvasWidth={'100%'}
          canvasHeight={'100%'}
          center
          height={22}
          width={22}
        />
        <Icon
          onClick={() => handleClick('/dashboard/menu')}
          className={cn(
            location.pathname.includes('/dashboard/menu') && 'active'
          )}
          name="House"
          width={22}
          height={22}
          canvasWidth={'100%'}
          canvasHeight={'100%'}
          center
        />
        <Icon
          onClick={() => handleClick('/explore')}
          width={18}
          height={18}
          canvasWidth={'100%'}
          canvasHeight={'100%'}
          center
          className={cn(location.pathname === '/explore' && 'active')}
          name="Search"
        />
        <Link
          to={user?.profileURL || '/auth/login'}
          className="w-full h-full flex items-center justify-center"
        >
          <Avatar size={28} onlyPhoto borderColor="#f7a709" />
        </Link>

        {/* <Icon
          className="text-yellow"
          height={showMenu && 20}
          onClick={() => setShowMenu(!showMenu)}
          name={showMenu ? 'Close' : 'Hamburger'}
        /> */}
      </div>
      {/* {showMenu && <MobileMenu setShowMenu={setShowMenu} />} */}
    </>
  );
};
