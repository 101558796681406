import React, { useState, useEffect, useContext } from 'react';
import { Icon } from 'components';
import { PublicationFiltersContext } from 'contexts/PublicationFiltersContext';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';
import { useFilterQueryParams, usePageQueryParams } from 'hooks';
import { getTypesByUser } from 'api/types';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

export const ReducedFilters = ({ userId }) => {
  const { t } = useTranslation();
  const [type, setType] = useState(0);
  const { setLoadingFilters } = useContext(PublicationFiltersContext);
  const { setParams, params, cleanAllParams } = useFilterQueryParams();
  const { removePage } = usePageQueryParams();

  const isDesktop = useMediaQuery({
    query: '(min-width: 768px)'
  });

  const [types, setTypes] = useState([]);

  const [loadingTypes, setLoadingTypes] = useState(true);

  useEffect(() => {
    loadTypes();
  }, []);

  const loadTypes = async () => {
    try {
      setLoadingFilters(true);
      setLoadingTypes(true);
      const _types = await getTypesByUser(userId);
      const formattedTypes = [...[{ id: 0, name: t('all') }], ..._types];
      setTypes(formattedTypes);
      setType(params.type ? params?.type : 0);
    } catch (err) {
      console.log(err);
    } finally {
      setLoadingTypes(false);
      setLoadingFilters(false);
    }
  };

  const handleType = ({ target }) => {
    cleanAllParams();
    setType(target.value);
    setParams({ type: target.value, operationType: 'both' });
    removePage();
  };

  if (!loadingTypes) {
    <div>
      <div className="text-base md:text-xl text-yellow w-full bg-yellow bg-opacity-20 h-6 rounded-xs"></div>
    </div>;
  }

  return (
    <div className="flex w-full flex-end">
      <span className="text-white-secondary mr-2">{t('category')}: </span>
      <div className="select-xs">
        <Select
          value={type}
          IconComponent={() => (
            <Icon name="ChevronDown" width={13} height={6} />
          )}
          onChange={handleType}
        >
          {types.map(({ id, name }, index) => (
            <MenuItem key={index} value={id}>
              {name}
            </MenuItem>
          ))}
        </Select>
      </div>
    </div>
  );
};
