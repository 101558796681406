import React from 'react';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import { Box } from 'components';

export const ProfileLanguages = ({ className, data }) => {
  const { t } = useTranslation();

  return (
    <Box className={className} variant="flat">
      <h4 className="text-yellow text-lg border-b pb-2">{t('languages')}</h4>
      {data?.map((e, i) => (
        <div className={cn('py-4', { 'border-b': i !== data.length - 1 })}>
          <p className="font-bold text-base">{e.language}</p>
          <p className="font-medium text-sm text-gray capitalize">{e.level}</p>
        </div>
      ))}
    </Box>
  );
};
