import React, { useContext } from 'react';
import { Avatar } from 'components';
import { Button } from '@estudio-nk/the-crane-club-client-lib';
import { useTranslation } from 'react-i18next';
import { ChatButton, FollowButton, ProfileNav } from 'components';
import { UserContext } from 'contexts/UserContext';
import cn from 'classnames';
import { useMediaQuery } from 'react-responsive';

export const ProfileHeader = ({
  profile,
  className,
  handleOnFollow = () => {}
}) => {
  const { t } = useTranslation();
  const { user } = useContext(UserContext);
  const isDesktop = useMediaQuery({
    query: '(min-width: 1025px)'
  });

  return (
    <div
      className={cn(className, {
        'dark border-b bg-black-light': isDesktop
      })}
      style={{ borderColor: '#5e5d5d' }}
    >
      <div className="flex items-center justify-between wrapper-lg -mt-6 md:-mt-0">
        <div className="xl:w-72 sm:w-60">
          <Avatar
            src={profile.logoURL || profile.photoURL}
            isCustom
            onlyPhoto
            size={isDesktop ? 200 : 150}
            borderSize={isDesktop ? 5 : 3}
            borderColor="#f7a709"
            className={cn({
              '-my-16': isDesktop,
              '-my-12': !isDesktop
            })}
          />
        </div>
        {isDesktop && <ProfileNav profile={profile} />}
        {isDesktop ? (
          user?.uid !== profile?.uid ? (
            <div className="flex items-center sm:w-60 xl:w-72">
              <ChatButton
                userId={profile.uid}
                variant={profile.followed ? 'primary' : 'outline'}
                className="flex-1 mr-3"
              />
              <FollowButton
                user={profile}
                className="flex-1"
                onClick={handleOnFollow}
              />
            </div>
          ) : (
            <div className="flex items-center sm:w-60 xl:w-72">
              <Button linkTo="/dashboard/settings" colorVariant="yellow">
                {t('editMyProfile')}
              </Button>
            </div>
          )
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};
