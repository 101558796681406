import React from 'react';
import CCurrencyFormat from 'react-currency-format';

export const CurrencyFormat = ({ value, prefix, className }) => (
  <CCurrencyFormat
    value={value}
    displayType={'text'}
    thousandSeparator={'.'}
    prefix={prefix}
    className={className}
    decimalSeparator={','}
  />
);
