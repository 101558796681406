import React, { useEffect, useState, forwardRef } from 'react';

export const DynamicTextarea = forwardRef(
  (
    {
      className,
      inputClasses,
      minHeight = 34,
      onChange = () => {},
      value,
      ...rest
    },
    ref
  ) => {
    const [height, setHeight] = useState(`${minHeight}px`);

    useEffect(() => {
      if (value.length === 0) {
        setHeight(`${minHeight}px`);
      }
    }, [value, minHeight]);

    const handleOnChange = ({ target }) => {
      if (target.value.length > 0) {
        setHeight(`${target.scrollHeight}px`);
      } else {
        setHeight(`${minHeight}px`);
      }
      onChange(target.value);
    };

    return (
      <div className={className}>
        <textarea
          onChange={handleOnChange}
          className={inputClasses}
          value={value}
          style={{ height: height }}
          ref={ref}
          {...rest}
        />
      </div>
    );
  }
);
