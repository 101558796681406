import React from 'react';
import { useTranslation } from 'react-i18next';
import { PublicationCard } from '@estudio-nk/the-crane-club-client-lib';
import { PublicationCardContainer, ArrowButton } from 'components';

export const HomePublicationsList = ({
  data,
  title,
  buttonLink,
  className
}) => {
  const { t } = useTranslation();

  return (
    <div className={className}>
      <div className="flex items-center justify-between w-full mb-9">
        <h3 className="md:text-2xl text-lg pr-5 uppercase text-gray-light tracking-tight font-semibold">
          {title}
        </h3>

        {buttonLink && (
          <div>
            <ArrowButton linkTo={buttonLink} yellow>
              {t('viewMore')}
            </ArrowButton>
          </div>
        )}
      </div>

      <div className="grid grid-cols-1 md:grid-cols-4 gap-4 md:gap-6">
        {!data?.loading
          ? data?.data?.map((publication) => (
              <PublicationCardContainer
                key={`${publication.id}-${Math.random()}`}
                containerClasses="no-hover"
                publication={publication}
                row={false}
                className="dark-shadow"
              />
            ))
          : new Array(8)
              .fill(0)
              .map((_, index) => (
                <PublicationCard
                  key={index}
                  row={false}
                  colorVariant="yellow"
                  className="dark-shadow"
                />
              ))}
      </div>
    </div>
  );
};
