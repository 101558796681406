import { useQueryParams, NumberParam, StringParam } from 'use-query-params';

export const usePageQueryParams = () => {
  const [query, setQuery] = useQueryParams({
    page: NumberParam,
    orderBy: StringParam,
    accessories: StringParam
  });

  const setPage = (value) => {
    setQuery({ ...query, page: value });
  };

  const removePage = () => {
    setQuery({ ...query, page: undefined });
  };

  const getPage = () => query?.page || 1;

  const setAccessories = (value) => {
    setQuery({ ...query, accessories: value });
  };

  const removeAccessories = () => {
    setQuery({ ...query, accessories: undefined });
  };

  const getAccessories = () => query?.accessories || 'false';

  const setOrderBy = (value) => {
    setQuery({ ...query, orderBy: value });
  };

  const removeOrderBy = () => {
    setQuery({ ...query, orderBy: undefined });
  };

  const getOrderBy = () => query?.orderBy || 'verifiedAt-DESC';

  const setPageAndOrderBy = ({ page, orderBy }) =>
    setQuery({
      page,
      orderBy
    });

  return {
    getPage,
    setPage,
    removePage,
    setOrderBy,
    removeOrderBy,
    getOrderBy,
    setPageAndOrderBy,
    setAccessories,
    removeAccessories,
    getAccessories
  };
};

export default usePageQueryParams;
