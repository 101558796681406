import axios from 'utils/axios';

export const postNewsletter = async (email) => {
  try {
    const { data } = await axios.post(`api/utils/newsletter`, { email });
    return data;
  } catch (error) {
    if (process.env.REACT_APP_DEV_MODE !== 'true') {
      console.log('ERROR: ', error.message);
    }
    throw error;
  }
};
