import React from 'react';
import { Pagination } from 'components';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import { Cell } from './Cell';
import { Icon } from '../Icon/index';

import './index.scss';

export const Table = ({
  headerLabels,
  rows = [],
  totalPages,
  page,
  setPage,
  currentSorted,
  withAction,
  setCurrentSorted,
  emptyMessage
}) => {
  const { t } = useTranslation();
  return (
    <div
      className={cn('cb-table flex flex-col overflow-x-auto', {
        'with-action': withAction
      })}
    >
      <div
        className="pt-2 align-middle inline-block min-w-full"
        style={{ paddingBottom: '200px' }}
      >
        <div className="sm:rounded-lg">
          <table className="min-w-full divide-y divide-gray-divider table-auto">
            {headerLabels && (
              <thead className="bg-white bg-opacity-5">
                <tr>
                  {headerLabels.map(({ label, sorted, key }, index) => (
                    <th
                      key={index}
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-semibold text-white tracking-tight whitespace-nowrap"
                    >
                      <div className="flex justify-between">
                        {label}
                        {sorted && (
                          <Icon
                            className={cn(
                              'transform transition-transform ml-2',
                              {
                                'rotate-180':
                                  currentSorted &&
                                  currentSorted.key === key &&
                                  currentSorted.asc
                              }
                            )}
                            onClick={() =>
                              setCurrentSorted &&
                              setCurrentSorted({
                                key,
                                asc: !(
                                  currentSorted.key === key && currentSorted.asc
                                )
                              })
                            }
                            name="ChevronDown"
                          />
                        )}
                      </div>
                    </th>
                  ))}
                  {withAction && <th />}
                </tr>
              </thead>
            )}
            <tbody className="bg-black-light  divide-y divide-gray-divider">
              {rows.length ? (
                rows
              ) : (
                <tr className="dark">
                  <td colSpan="9999999 flex justify-end">
                    <p className="text-center py-10">
                      {emptyMessage || t('no-content')}
                    </p>
                  </td>
                </tr>
              )}
            </tbody>
            {totalPages > 1 && (
              <tfoot>
                <tr className="dark">
                  <td colSpan="9999999 flex justify-end">
                    <Pagination
                      totalPages={totalPages}
                      currentPage={page}
                      setPages={setPage}
                    />
                  </td>
                </tr>
              </tfoot>
            )}
          </table>
        </div>
      </div>
    </div>
  );
};

Table.Cell = Cell;
