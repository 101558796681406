import { useContext, useState } from 'react';
import { Input, Switch, Divider, SharedInputs } from 'components';
import { toast } from 'react-toastify';
import { UploadContext } from 'contexts/UploadsContext';
import { useForm, Controller } from 'hooks';
import { UnitSwitch } from './UnitSwitch';
import { ActionFooter } from './ActionFooter';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { UserContext } from 'contexts/UserContext';

export const K = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { setUpload, upload, isEdit, triggerEdit } = useContext(UploadContext);
  const { user } = useContext(UserContext);
  const [unit, setUnit] = useState(
    upload?.unit || user.location.countryCode === 'US' ? 'imperial' : 'metric'
  );

  const {
    register,
    handleSubmit,
    control,
    watch,
    trigger,
    formState: { errors }
  } = useForm({
    mode: 'onBlur',
    defaultValues: !!upload.configuration && {
      ...upload.configuration,
      description: upload.description,
      relatedFileName1:
        upload.configurationFiles &&
        upload.configurationFiles[0] &&
        upload.configurationFiles[0]?.name,
      relatedFile1:
        upload.configurationFiles &&
        upload.configurationFiles[0] &&
        upload.configurationFiles[0]?.file,
      relatedFileName2:
        upload.configurationFiles &&
        upload.configurationFiles[1] &&
        upload.configurationFiles[1]?.name,
      relatedFile2:
        upload.configurationFiles &&
        upload.configurationFiles[1] &&
        upload.configurationFiles[1]?.file,
      relatedFileName3:
        upload.configurationFiles &&
        upload.configurationFiles[2] &&
        upload.configurationFiles[2]?.name,
      relatedFile3:
        upload.configurationFiles &&
        upload.configurationFiles[2] &&
        upload.configurationFiles[2]?.file,
      relatedFileName4:
        upload.configurationFiles &&
        upload.configurationFiles[3] &&
        upload.configurationFiles[3]?.name,
      relatedFile4:
        upload.configurationFiles &&
        upload.configurationFiles[3] &&
        upload.configurationFiles[3]?.file,
      relatedFileName5:
        upload.configurationFiles &&
        upload.configurationFiles[4] &&
        upload.configurationFiles[4]?.name,
      relatedFile5:
        upload.configurationFiles &&
        upload.configurationFiles[4] &&
        upload.configurationFiles[4]?.file
    }
  });

  const handleValidate = async (e, edit) => {
    e.preventDefault();
    const validForm = await trigger();
    validForm
      ? handleSubmit((values) => submit(values, edit))()
      : toast.warning(t('errorFormMessage'));
  };

  const submit = (
    {
      maximumWorkingHeight,
      horometer,
      technicalDataLink,
      description,
      relatedFileName1,
      relatedFile1,
      relatedFileName2,
      relatedFile2,
      relatedFileName3,
      relatedFile3,
      relatedFileName4,
      relatedFile4,
      relatedFileName5,
      relatedFile5
    },
    edit
  ) => {
    setUpload({
      ...upload,
      description,
      unit,
      configuration: {
        maximumWorkingHeight,
        horometer,
        technicalDataLink
      },
      configurationFiles: [
        { name: relatedFileName1, file: relatedFile1 },
        { name: relatedFileName2, file: relatedFile2 },
        { name: relatedFileName3, file: relatedFile3 },
        { name: relatedFileName4, file: relatedFile4 },
        { name: relatedFileName5, file: relatedFile5 }
      ]
    });

    edit
      ? triggerEdit()
      : history.push(`/${isEdit ? 'edit' : 'upload'}/conditions`);
  };

  return (
    <form onSubmit={handleValidate}>
      <div className="flex flex-col md:flex-row xs:flex-row items-center">
        <label className="xs:mr-4">{t('unit')}</label>
        <UnitSwitch
          className="mt-4 xs:mt-0 mb-6 xs:mb-0"
          unit={unit}
          onChange={setUnit}
        />
      </div>
      <Divider className="mb-8 mt-2 md:mt-10 md:mb-10" />
      <div className="flex flex-col md:flex-row">
        <div className="w-full md:w-1/2 md:mr-10 ">
          <label className="required">{t('horometer')}</label>
          <Controller
            control={control}
            name="horometer"
            rules={{ required: true }}
            render={({ field }) => (
              <Input
                className="mb-9 mt-1"
                placeholder={t('horometerPlaceholder')}
                type="number"
                error={errors.horometer}
                {...field}
              />
            )}
          />
        </div>
        <div className="w-full md:w-1/2">
          <label>{`${t('maximumWorkingHeight')} (${t(
            `unitAbbreviations.length.${unit}`
          )})`}</label>
          <Controller
            control={control}
            name="maximumWorkingHeight"
            render={({ field }) => (
              <Input
                className="mb-9 mt-1"
                placeholder={t('capacityPlaceholder')}
                type="number"
                error={errors.maximumWorkingHeight}
                {...field}
              />
            )}
          />
        </div>
      </div>
      <Divider className="hidden md:block mt-10 mb-6" />
      <SharedInputs
        errors={errors}
        register={register}
        watch={watch}
        Controller={Controller}
        control={control}
      />
      <Divider className="mt-10 mb-6" />
      <ActionFooter handleValidate={handleValidate} />
    </form>
  );
};
