import React, { useContext } from 'react';
import { FeaturedData, Box, Chart, Icon, DashboardItem } from 'components';
import { formatDate } from 'utils/formatter';
import { useTranslation } from 'react-i18next';
import { useHistory, Link } from 'react-router-dom';
import { UserContext } from 'contexts/UserContext';

export const DashboardCompany = ({ data, loading }) => {
  const history = useHistory();
  const { t } = useTranslation();
  const { user } = useContext(UserContext);

  return (
    <>
      <div className="flex items-baseline mb-2">
        <p className="font-semibold text-base mr-3">Social CLUB</p>
        <Link
          to="/dashboard/social"
          className="text-xs text-yellow hover:underline"
        >
          {t('viewAllM')}
        </Link>
      </div>
      <div className="grid grid-cols-2 gap-4 mb-4 xl:mb-8 xl:grid-cols-4 xl:gap-8">
        <FeaturedData
          icon="Posts"
          iconWidth={25}
          to={user?.profileURL}
          iconHeight={18}
          number={data?.social?.activePosts}
          text={t('activePosts')}
          className="p-5"
          loading={loading}
        />

        <FeaturedData
          icon="User"
          iconWidth={27}
          to="/dashboard/social"
          iconHeight={19}
          number={data?.social?.followers}
          text={t('followers')}
          className="p-5"
          loading={loading}
        />

        <FeaturedData
          icon="User"
          iconWidth={25}
          iconHeight={19}
          to="/dashboard/social/following"
          number={data?.social?.following}
          text={t('following')}
          className="p-5"
          loading={loading}
        />

        <FeaturedData
          icon="Heart"
          iconWidth={26}
          iconHeight={18}
          to="/dashboard/social/likes"
          number={data?.social?.likesReceived}
          text={t('receivedPostsLikes')}
          className="p-5"
          loading={loading}
        />
      </div>

      <div className="flex items-baseline mb-2">
        <p className="font-semibold text-base mr-3">Clicks</p>
        <Link
          to="/dashboard/clicks"
          className="text-xs text-yellow hover:underline"
        >
          {t('viewAllM')}
        </Link>
      </div>
      <div className="grid grid-cols-2 gap-4 mb-4 xl:mb-8 xl:grid-cols-4 xl:gap-8">
        <FeaturedData
          icon="World"
          iconWidth={25}
          iconHeight={19}
          number={data?.webVisits}
          to="/dashboard/clicks/web"
          text={t('webVisits')}
          className="p-5"
          loading={loading}
        />

        <FeaturedData
          icon="Facebookyellow"
          iconWidth={27}
          iconHeight={19}
          to="/dashboard/clicks/facebook"
          number={data?.facebookClicks}
          text={t('facebookClicks')}
          className="p-5"
          loading={loading}
        />

        <FeaturedData
          icon="Instagram"
          iconWidth={25}
          iconHeight={19}
          to="/dashboard/clicks/instagram"
          number={data?.instagramClicks}
          text={t('instagramClicks')}
          className="p-5"
          loading={loading}
        />

        <FeaturedData
          icon="Linkedin"
          iconWidth={25}
          iconHeight={18}
          to="/dashboard/clicks/linkedIn"
          number={data?.linkedinClicks}
          text={t('linkedinClicks')}
          className="p-5"
          loading={loading}
        />
      </div>

      <p className="font-semibold text-base mb-2">{t('machines')}</p>
      <div className="grid grid-cols-2 gap-4 mb-4 xl:mb-8 xl:grid-cols-4 xl:gap-8">
        <FeaturedData
          icon="Machine"
          iconWidth={25}
          iconHeight={19}
          to="/dashboard/machines/active"
          number={data?.activePublications}
          text={t('activePublications')}
          className="p-5"
          loading={loading}
        />

        <FeaturedData
          icon="Pen"
          iconWidth={27}
          iconHeight={19}
          to="/dashboard/quotations"
          number={data?.quotations}
          text={t('receivedQuotations')}
          className="p-5"
          loading={loading}
        />

        <FeaturedData
          icon="Heart"
          iconWidth={25}
          iconHeight={18}
          to="/dashboard/recieved-likes"
          number={data?.likes}
          text={t('receivedMachinesLikes')}
          className="p-5"
          loading={loading}
        />

        <FeaturedData
          icon="Eye"
          iconWidth={26}
          iconHeight={18}
          to="/dashboard/last-seens"
          number={data?.last30DayVisit}
          text={t('visitasLast30Days')}
          className="p-5"
          loading={loading}
        />
      </div>

      <div className="grid grid-cols-1 gap-4 mb-4 xl:grid-cols-5 xl:gap-8 xl:mb-8 min-h-112">
        <Box
          title={t('visitsAndQuotations')}
          className="xl:col-span-3"
          loading={loading}
        >
          <div className="flex items-center justify-center">
            <Chart data={data?.chart} />
          </div>
        </Box>

        <Box
          title={t('lastQuotations')}
          buttonText={t('viewAllF')}
          onClick={() => history.push('/dashboard/quotations')}
          className="xl:col-span-2"
          loading={loading}
        >
          {data?.lastQuotations?.length ? (
            data?.lastQuotations?.map(({ user, createdAt, publication }, i) => {
              return (
                <DashboardItem
                  key={i}
                  user={user}
                  subtitle={`${publication?.category?.name} / ${publication?.model}`}
                  timestamp={formatDate(createdAt)}
                />
              );
            })
          ) : (
            <div className="flex items-center justify-center h-20">
              <Icon className="mr-1" name="User" />
              <span className="text-gray-400 text-sm">
                {t('quotationsEmptyState')}
              </span>
            </div>
          )}
        </Box>
      </div>

      <div className="grid grid-cols-1 gap-4 xl:grid-cols-2 xl:gap-8 mb-8 min-h-112">
        <Box
          title={t('lastFollowers')}
          buttonText={t('viewAllM')}
          onClick={() => history.push('/dashboard/social')}
          className="xl:col-span-1 h-full"
          loading={loading}
        >
          {data?.social.lastFollowers.length ? (
            data?.social?.lastFollowers.map(
              ({ userFollowing, createdAt }, i) => (
                <DashboardItem
                  key={i}
                  user={userFollowing}
                  timestamp={formatDate(new Date(createdAt))}
                />
              )
            )
          ) : (
            <div className="flex items-center justify-center h-20">
              <Icon className="mr-1" name="World" />
              <span className="text-gray-400 text-sm">
                {t('followersEmptyState')}
              </span>
            </div>
          )}
        </Box>
        <Box
          title={t('lastMachineLikes')}
          buttonText={t('viewAllM')}
          onClick={() => history.push('/dashboard/recieved-likes')}
          className="xl:col-span-1 h-full"
          loading={loading}
        >
          {data?.lastLikes.length ? (
            data?.lastLikes?.map(({ user, createdAt, publication }, i) => (
              <DashboardItem
                key={i}
                user={user}
                subtitle={`${publication.category.name} / ${publication.model}`}
                timestamp={formatDate(createdAt)}
              />
            ))
          ) : (
            <div className="flex items-center justify-center h-20">
              <Icon className="mr-1" name="Heart" />
              <span className="text-gray-400 text-sm">
                {t('likesEmptyState')}
              </span>
            </div>
          )}
        </Box>
      </div>

      <div className="grid grid-cols-1 gap-4 xl:grid-cols-2 xl:gap-8 mb-16 min-h-112">
        <Box
          title={t('lastClicks')}
          buttonText={t('viewAllM')}
          onClick={() => history.push('/dashboard/clicks')}
          className="xl:col-span-1 h-full"
          loading={loading}
        >
          {data?.lastWebVisits.length ? (
            data?.lastWebVisits?.map(({ createdAt, user, type }, i) => (
              <DashboardItem
                key={i}
                user={user}
                subtitle={type}
                timestamp={formatDate(new Date(createdAt))}
              />
            ))
          ) : (
            <div className="flex items-center justify-center h-20">
              <Icon className="mr-1" name="World" />
              <span className="text-gray-400 text-sm">
                {t('webVisitsEmptyState')}
              </span>
            </div>
          )}
        </Box>
        <Box
          title={t('lastPubicationsSeens')}
          buttonText={t('viewAllF')}
          onClick={() => history.push('/dashboard/last-seens')}
          className="xl:col-span-1"
          loading={loading}
        >
          {data?.lastPublicationsSeens.length ? (
            data?.lastPublicationsSeens?.map(
              ({ user, publication, createdAt }, i) => (
                <DashboardItem
                  key={i}
                  user={user}
                  subtitle={`${publication.category.name} / ${publication.model}`}
                  timestamp={formatDate(createdAt)}
                />
              )
            )
          ) : (
            <div className="flex items-center justify-center h-20">
              <Icon className="mr-1" name="Machine" />
              <span className="text-gray-400 text-sm">
                {t('publicationsEmptyState')}
              </span>
            </div>
          )}
        </Box>
      </div>
    </>
  );
};
