import { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Spinner, SelectBox, SearchInput, Divider } from 'components';
import { Button } from '@estudio-nk/the-crane-club-client-lib';
import cn from 'classnames';

export const LetterContainer = ({
  brands,
  loading,
  handleOnApply,
  createBrand
}) => {
  const ref = useRef();
  const [search, setSearch] = useState();
  const [formattedBrands, setFormattedBrands] = useState();
  const [letters, setLetters] = useState([]);
  const [createLoading, setCreateLoading] = useState(false);
  const [selected, setSelected] = useState(new Set());
  const [submitLoading, setSubmitLoading] = useState(false);
  const { t } = useTranslation();

  const formatBrands = (brands) => {
    let formatted = {};
    const letters = [];
    const selectedIds = new Set();
    brands.forEach((brand) => {
      const principalLetter = brand.name.toLowerCase().charAt(0);
      if (principalLetter in formatted) {
        formatted[principalLetter].push({
          ...brand,
          name: brand.name.toLowerCase()
        });
      } else {
        letters.push(principalLetter);
        formatted = {
          ...formatted,
          [principalLetter]: [{ ...brand, name: brand.name.toLowerCase() }]
        };
      }
      if (brand.selected === '1') selectedIds.add(brand.id);
    });
    setLetters(letters);
    setFormattedBrands(formatted);
    setSelected(selectedIds);
  };

  useEffect(() => {
    formatBrands(brands);
  }, [brands]);

  useEffect(() => {
    if (!!search) {
      formatBrands(
        brands.filter(({ name }) =>
          name.toLowerCase().includes(search.toLowerCase())
        )
      );
    } else {
      formatBrands(brands);
    }
  }, [search, brands]);

  const handleOnSelect = (id, checked) => {
    const brands = new Set(selected);
    if (checked) {
      brands.add(id);
    } else {
      brands.delete(id);
    }
    setSelected(brands);
  };

  const handleClickLetter = (_, index) => {
    const element = ref.current.children[index];
    const headerOffset = 150;
    const elementPosition = element.getBoundingClientRect().top;
    const offsetPosition = elementPosition + window.pageYOffset - headerOffset;
    window.scrollTo({
      top: offsetPosition
    });
  };

  const handleCreateBrand = async () => {
    setCreateLoading(true);
    await createBrand([search]);
    setSearch('');
    setCreateLoading(false);
  };

  const handleSave = async () => {
    try {
      setSubmitLoading(true);
      await handleOnApply(selected);
    } catch (error) {
    } finally {
      setSubmitLoading(true);
    }
  };

  return loading ? (
    <Spinner />
  ) : (
    <div>
      <div className="mt-4 lg:mt-8 rounded-sm dark">
        <div className="flex-col items-center justify-center ">
          <div
            className="hidden md:flex items-center h-12 justify-center bg-silver-100 border border-top z-10 rounded-sm"
            style={{ top: '92px' }}
          >
            <div className="flex">
              {letters.map((l, i) => (
                <span
                  key={i}
                  onClick={() => handleClickLetter(l, i)}
                  className={cn(
                    'uppercase text-xs w-6 transition duration-300 hover:text-yellow text-gray outline-none text-center cursor-pointer'
                  )}
                >
                  {l}
                </span>
              ))}
            </div>
          </div>
          <div className="flex justify-center p-5">
            <SearchInput
              placeholder={t('upload.searchBrand')}
              value={search}
              setSearch={setSearch}
              className="w-full md:w-1/3"
            />
          </div>
          <Divider />
          <div ref={ref} className="px-4 lg:px-20 py-6 lg:py-12">
            {Object.keys(formattedBrands).length ? (
              Object.entries(formattedBrands).map(([key, value], i) => (
                <SelectBox
                  letter={key}
                  key={i}
                  items={value}
                  selected={selected}
                  index={i}
                  handleOnSelect={handleOnSelect}
                />
              ))
            ) : (
              <div>
                <h2 className="text-center mb-4">
                  <strong className="capitalize">"{search}" </strong>
                  {t('doesNotExists')}
                </h2>
                <div className="flex justify-center">
                  <Button
                    className="w-40"
                    onClick={handleCreateBrand}
                    loading={createLoading}
                    colorVariant="yellow"
                  >
                    {t('createBrand')}
                  </Button>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="sticky bottom-0 dark">
        <Divider />
        <div className="flex justify-between items-center pb-16 pt-5 md:py-5 px-7">
          <div className="text-silver text-xs">
            <span className="text-yellow">(*)</span>{' '}
            {t('createdByTheCommunity')}
          </div>
          <Button
            size="md"
            className="w-40"
            onClick={handleSave}
            loading={submitLoading}
            colorVariant="yellow"
          >
            {`${t('save')} (${selected.size})`}
          </Button>
        </div>
      </div>
    </div>
  );
};
