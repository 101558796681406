import React from 'react';
import { useTranslation } from 'react-i18next';
import { Modal } from 'components';
import { useHistory } from 'react-router-dom';

export const LoginModal = ({ visible, toggleModal, title, text }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const returnURL = encodeURIComponent(
    history.location.pathname + history.location.search
  );

  return (
    <Modal
      visible={visible}
      toggle={toggleModal}
      title={title || t('almostThere')}
      buttons={[
        {
          variant: 'outline',
          text: t('createAccount'),
          onClick: () => history.push(`/auth/register?r=${returnURL}`)
        },
        {
          text: t('login'),
          onClick: () => history.push(`/auth/login?r=${returnURL}`)
        }
      ]}
    >
      <div className="w-max-full md:w-105">
        <p className="text-lg leading-8">{text || t('loginRequiredMessage')}</p>
      </div>
    </Modal>
  );
};
