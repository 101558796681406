import { convertToRaw, convertFromHTML } from 'draft-js';
import { ContentState, EditorState } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import { format } from 'date-fns';
import i18n from 'i18next';
import { es } from 'date-fns/locale';
import { formatDistanceToNow } from 'date-fns';

export const formatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD'
});

export const getPublicationStatus = (isActive, isVerified, verifiedAt) => {
  if (isActive && isVerified) return 'active';
  if (!isActive && isVerified) return 'inactive';
  if (!!verifiedAt && !isVerified) return 'rejected';
  if (!verifiedAt && !isVerified) return 'review';
};

export const getLabelFromLocation = (publication) => {
  return `${publication?.location?.streetName || ''} ${
    publication?.location?.streetNumber || ''
  } ${publication?.location?.city} ${publication?.location?.state} ${
    publication?.location?.country
  }`;
};

export const cloudinaryVideoPreview = (url) => {
  let splitted = url.split('/');
  let videoName = splitted.pop();
  videoName = 'e_preview:duration_5/' + videoName;
  splitted.push(videoName);
  return splitted.join('/');
};

export const slugify = (str) => {
  if (!str) return null;

  return str
    .replace(/[:,.]/g, '')
    .replace(/[àáâãäå]/g, 'a')
    .replace(/[èéêë]/g, 'e')
    .replace(/[ìíîï]/g, 'i')
    .replace(/ñ/g, 'n')
    .replace(/[òóôõö]/g, 'o')
    .replace(/[ùúûü]/g, 'u')
    .replace(/[ç]/g, 'c')
    .replace(/\s/g, '-')
    .replace(/[^a-zA-Z0-9-_]/g, '')
    .toLowerCase();
};

export const formatWebsite = (url) => {
  if (!url) return null;

  return url.replace('http://', '').replace('https://', '').replace('www.', '');
};

export const getCloudinaryFileName = (url) => {
  return url?.split('/')[url.split('/').length - 1].split('.')[0];
};

export const httpToHttps = (url) => {
  if (!url) return null;
  return url.replace('http://', 'https://');
};

export const publicationToURL = (publication) =>
  `/p/${slugify(publication?.type?.name)}/${slugify(
    publication?.category?.name
  )}/${slugify(publication?.customBrand || publication?.brand?.name)}/${
    publication?.id
  }/${slugify(publication?.model)}`;

export const profileToURL = (user) => {
  return `/u/${user?.uid}/${slugify(user?.name)}`;
};

export const postToURL = (id, order) =>
  `/post/${id}${order ? `?order=${order}` : ''}`;

export const locationToString = (location) =>
  `${location?.streetName ? location?.streetName + ', ' : ''}${
    location?.streetName && location?.streetNumber
      ? location?.streetNumber + ', '
      : ''
  }${location?.city ? location?.city + ', ' : ''} ${
    location?.state ? location?.state + ', ' : ''
  } ${location?.country ? location?.country + ', ' : ''}`;

export const formatDate = (date, options = {}) => {
  let formatString = 'd MMM yy';
  if (options.withTime) formatString += ' HH:mm';
  return format(new Date(date), formatString);
};

export const distanceToNow = (date) => {
  return `${i18n.language === 'es' ? 'hace ' : ''} ${formatDistanceToNow(
    new Date(date),
    i18n.language === 'es' && { locale: es }
  )} ${i18n.language === 'en' ? ' ago' : ''} `;
};

export const getRaw = (state) => {
  return state ? draftToHtml(convertToRaw(state.getCurrentContent())) : '';
};

export const convertFromRaw = (raw = '') => {
  const blockMap = convertFromHTML(raw);
  return EditorState.createWithContent(
    ContentState.createFromBlockArray(blockMap)
  );
};

export const getFileNameFromSpacesURL = (url, separator) => {
  return url ? url.split(separator)[1] : '';
};

export const fromTonsToUSTons = (value) => {
  return (value * 1.1023).toFixed(0);
};

export const fromUSTonsToTons = (value) => {
  return (value / 1.1023).toFixed(0);
};

export const fromMeterToFeet = (value) => {
  return (value * 3.28084).toFixed(0);
};

export const fromFeetToMeter = (value) => {
  return (value / 3.28084).toFixed(0);
};

export const firstLetterUppercase = (str) => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};

export const thousandSeparator = (number) => {
  return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
};

export const removeHTMLfromString = (str = '') => {
  return str.replace(/<\/?[^>]+(>|$)/g, '');
};
