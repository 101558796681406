import React, { useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import { UserContext } from 'contexts/UserContext';
// import { Capacitor } from '@capacitor/core';
import './index.scss';

import { Icon } from 'components';

export const SignInMethods = ({ showTerms = false }) => {
  const location = useLocation();
  const { loadUser } = useContext(UserContext);
  const { t } = useTranslation();

  const loadUserInfo = async (token, newUser) => {
    localStorage.setItem('tmc-token', token);
    await loadUser(null, newUser);
  };

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    // Social token
    const [token, isNew, source] = [
      params.get('token'),
      params.get('new'),
      params.get('source')
    ];
    // if (source === 'linkedin') {
    //   window.lintrk('track', { conversion_id: 8022657 });
    // }
    if (token) {
      const newUser = isNew && isNew === 'true';
      loadUserInfo(token, newUser);
    }
  }, [location.search]);

  const handleClickGoogle = async () => {
    window.open(
      `${process.env.REACT_APP_API_URL}api/auth/social/google`,
      '_self'
    );
  };

  const handleClickFacebook = async () => {
    window.open(
      `${process.env.REACT_APP_API_URL}api/auth/social/facebook`,
      '_self'
    );
  };

  const handleClickLinkedin = async () => {
    window.open(
      `${process.env.REACT_APP_API_URL}api/auth/social/linkedin`,
      '_self'
    );
  };

  const handleClickApple = async () => {
    window.open(
      `${process.env.REACT_APP_API_URL}api/auth/social/apple`,
      '_self'
    );
  };

  return (
    <div className="cb-signin-methods text-center mt-11">
      <span className="text-white-secondary text-sm">{t('orSignInWith')}</span>
      <div className="sign-methods flex items-center justify-center space-x-4 py-5">
        <Icon
          name="Facebook"
          width={8}
          height={15}
          canvasWidth={44}
          canvasHeight={44}
          onClick={handleClickFacebook}
        />
        <Icon
          name="Google"
          width={12}
          height={12}
          canvasWidth={44}
          canvasHeight={44}
          onClick={handleClickGoogle}
        />
        <Icon
          name="Apple"
          width={12}
          height={14}
          canvasWidth={44}
          canvasHeight={44}
          onClick={handleClickApple}
        />
        <Icon
          name="LinkedIn"
          width={15}
          height={14}
          canvasWidth={44}
          canvasHeight={44}
          onClick={handleClickLinkedin}
        />
      </div>

      {showTerms && (
        <p className="text-3xs text-silver mb-5">
          {t('youAgreeTo')}
          <br />
          <Link to="/terms-and-conditions" target="_blank" className="mr-1">
            {t('terms&conditions')}
          </Link>
          {t('and')}
          <Link to="/privacy-policy" target="_blank" className="ml-1">
            {t('privacyPolicy')}
          </Link>
          .
        </p>
      )}
    </div>
  );
};
