import { useContext, useEffect, useState } from 'react';
import {
  Avatar,
  Divider,
  Input,
  Spinner,
  Textarea,
  FileInput,
  LocationAutocomplete,
  AvatarEditorModal
} from 'components';
import { Button } from '@estudio-nk/the-crane-club-client-lib';
import { locationToString } from 'utils/formatter';
import { UserContext } from 'contexts/UserContext';
import { useHistory } from 'react-router';
import { useForm, Controller } from 'hooks';
import { useModal } from 'hooks/useModal';
import { toast } from 'react-toastify';
import { updateBanner, updateProfilePicture, updateUser } from 'api/user';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';
import { isValidImage } from 'utils/validators';
import { URL_REGEX } from 'utils/constants';
import defaultBanner from 'assets/img/cover-default.jpg';

import './index.scss';

export const CompanyInfoCard = () => {
  const history = useHistory();
  const { user, setUser } = useContext(UserContext);
  const [logoURL, setLogoURL] = useState(user.photoURL);
  const [bannerURL, setBannerURL] = useState(user.bannerURL);
  const [newAvatarImage, setNewAvatarImage] = useState(null);
  const [newBannerImage, setNewBannerImage] = useState(null);
  const [loadingLogo, setLoadingLogo] = useState(false);
  const [loadingBanner, setLoadingBanner] = useState(false);
  const [loading, setLoading] = useState(false);
  const { visible: avatarEditorVisible, toggleModal: avatarEditorToggle } =
    useModal();
  const { visible: bannerEditorVisible, toggleModal: bannerEditorToggle } =
    useModal();
  const { t } = useTranslation();
  const isMobile = useMediaQuery({
    query: '(max-width: 480px)'
  });

  const {
    handleSubmit,
    control,
    register,
    formState: { errors }
  } = useForm({
    mode: 'onBlur',
    defaultValues: {
      address: user.location,
      phoneNumber: user.phoneNumber,
      facebookURL: user.facebookURL,
      linkedinURL: user.linkedinURL,
      twitterURL: user.twitterURL,
      instagramURL: user.instagramURL,
      websiteURL: user.websiteURL,
      description: user?.description,
      companyResponsableName: user?.companyResponsableName,
      companyResponsableRole: user?.companyResponsableRole
    }
  });

  useEffect(() => {
    !user.isCompany && history.replace('/dashboard/settings');
  }, []);

  const submit = async (values) => {
    try {
      setLoading(true);
      const newUser = {
        phoneNumber: values.phoneNumber,
        location: typeof address === 'string' ? user.location : values.address,
        facebookURL: values.facebookURL,
        twitterURL: values.twitterURL,
        linkedinURL: values.linkedinURL,
        websiteURL: values.websiteURL,
        instagramURL: values.instagramURL,
        companyResponsableName: values.companyResponsableName,
        companyResponsableRole: values.companyResponsableRole,
        description: values.description
      };
      await updateUser(newUser);
      setUser({ ...user, ...newUser });
      toast.success(t('dataHasBeenUpdated'));
    } catch (error) {
      toast.error(t('dataUpdatedError'));
    } finally {
      setLoading(false);
    }
  };

  const handlePhotoChange = async (files) => {
    try {
      setLoadingLogo(true);
      if (!isValidImage(files[0].file)) {
        toast.error(t('imageInvalidFormat'));
        return;
      }
      setNewAvatarImage(files[0].file);
      avatarEditorToggle(true);
    } catch (error) {
      toast.error(t('dataUpdatedError'));
    } finally {
      setLoadingLogo(false);
    }
  };

  const handleBannerChange = async (files) => {
    try {
      setLoadingBanner(true);
      if (!isValidImage(files[0].file)) {
        toast.error(t('imageInvalidFormat'));
        return;
      }
      setNewBannerImage(files[0].file);
      bannerEditorToggle(true);
    } catch (error) {
      toast.error(t('dataUpdatedError'));
    } finally {
      setLoadingBanner(false);
    }
  };

  const onConfirmBannerEditor = async (banner) => {
    try {
      setLoadingBanner(true);
      const newURL = await updateBanner(user.uid, banner, user?.bannerURL);
      setBannerURL(newURL);
      setUser({ ...user, bannerURL: newURL });
      toast.success(t('dataHasBeenUpdated'));
    } catch (error) {
      toast.error(t('dataUpdatedError'));
    } finally {
      setLoadingBanner(false);
    }
  };

  const onConfirmAvatarEditor = async (avatar) => {
    try {
      setLoadingLogo(true);
      const newURL = await updateProfilePicture(user.uid, avatar, logoURL);
      setLogoURL(newURL);
      setUser({ ...user, photoURL: newURL });
      toast.success(t('dataHasBeenUpdated'));
    } catch (error) {
      toast.error(t('dataUpdatedError'));
    } finally {
      setLoadingLogo(false);
    }
  };

  return !user ? (
    <Spinner />
  ) : (
    <div className="personal-info-card bg-black-light">
      <h2 className="ml-9 pt-5 mb-5 tracking-tight text-lg">
        {t('myAccount')}
      </h2>
      <Divider />
      <div
        className="relative flex items-center justify-center bg-cover bg-center bg-no-repeat bg-yellow bg-opacity-10 rounded-xl"
        style={{
          paddingTop: '42%',
          backgroundImage: `url('${bannerURL || defaultBanner}')`,
          ...(!bannerURL && { backgroundPosition: 'center' })
        }}
      >
        <FileInput
          className="absolute bottom-4 right-4"
          accept="image/*"
          trigger={
            <Button
              size="sm"
              className="w-40"
              loading={loadingBanner}
              colorVariant="yellow"
            >
              {t('changeBanner')}
            </Button>
          }
          onChange={handleBannerChange}
          showList={false}
        />

        <AvatarEditorModal
          visible={bannerEditorVisible}
          toggleModal={bannerEditorToggle}
          image={newBannerImage}
          onConfirm={onConfirmBannerEditor}
          editorWidth={isMobile ? 320 : 450}
          editorHeight={isMobile ? 180 : 225}
          borderRadius={0}
        />
      </div>

      <div className="px-5 md:px-9 py-7">
        <div className="flex items-center -mb-5 md:mb-0">
          <Avatar
            size={isMobile ? 80 : 128}
            onlyPhoto
            className="mr-5 md:mr-10 mb-5 md:mb-0"
            linkTo={'/dashboard'}
            src={logoURL}
            borderColor="#f7a709"
          />
          <FileInput
            trigger={
              <Button
                size="sm"
                className="w-32 md:w-40"
                loading={loadingLogo}
                colorVariant="yellow"
              >
                {t('changeImage')}
              </Button>
            }
            accept="image/*"
            onChange={handlePhotoChange}
            showList={false}
          />

          <AvatarEditorModal
            visible={avatarEditorVisible}
            toggleModal={avatarEditorToggle}
            image={newAvatarImage}
            onConfirm={onConfirmAvatarEditor}
          />
        </div>
        <form onSubmit={handleSubmit(submit)}>
          <div className="w-full my-8">
            <Divider />
            <div className="grid-data">
              <label>{t('email')}</label>
              <Input
                className="border-none"
                placeholder={t('email')}
                value={user.email}
                disabled
              />
              <Divider />
              <label>{t('company')}</label>
              <Input
                className="border-none"
                placeholder={t('company')}
                value={user.name}
                disabled
              />
              <Divider />
              <label>{t('responsable')}</label>
              <Input
                className="border-none"
                placeholder={t('responsable')}
                error={errors.companyResponsableName}
                register={register('companyResponsableName', {
                  required: true
                })}
              />
              <Divider />
              <label>{t('role')}</label>
              <Input
                className="border-none"
                placeholder={t('role')}
                error={errors.companyResponsableRole}
                register={register('companyResponsableRole', {
                  required: true
                })}
              />
              <Divider />
              <label>{t('address')}</label>
              <Controller
                control={control}
                name="address"
                rules={{ required: true }}
                render={({ field: { onChange } }) => (
                  <LocationAutocomplete
                    className="border-none"
                    placeholder={locationToString(user.location)}
                    setValue={onChange}
                    error={errors.address}
                  />
                )}
              />
              <Divider />
              <label>{t('phone')}</label>
              <Input
                className="border-none"
                placeholder={t('phone')}
                register={register('phoneNumber', { required: true })}
                error={errors.phoneNumber}
              />
              <Divider />
              <label>Facebook</label>
              <Input
                className="border-none input-lowercase"
                placeholder="e.g: https://www.facebook.com/themachineclub.ok"
                register={register('facebookURL')}
              />
              <Divider />
              <label>Instagram</label>
              <Input
                className="border-none input-lowercase"
                placeholder="e.g: https://www.instagram.com/themachineclub.ok"
                register={register('instagramURL')}
              />
              <Divider />
              <label>Linkedin</label>
              <Input
                className="border-none input-lowercase"
                placeholder="e.g: https://www.linkedin.com/company/the-machine-club"
                register={register('linkedinURL')}
              />
              <Divider />
              <label>Twitter</label>
              <Input
                className="border-none input-lowercase"
                placeholder="e.g: https://twitter.com/themachineclub"
                register={register('twitterURL')}
              />
              <Divider />
              <label>{t('website')}</label>
              <Input
                className="border-none input-lowercase"
                placeholder="e.g: https://themachineclub.com"
                error={errors.websiteURL}
                errorMessage={t('form.invalidURL')}
                register={register('websiteURL', {
                  pattern: {
                    value: URL_REGEX
                  }
                })}
              />
              <Divider />
              <label className="my-4">{t('description')}</label>
            </div>
            <Textarea register={register('description')} isAccount />
          </div>
          <Button type="submit" loading={loading} colorVariant="yellow">
            {t('save')}
          </Button>
        </form>
      </div>
    </div>
  );
};
