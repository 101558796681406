import React, { useRef, useEffect } from 'react';
import cn from 'classnames';
import { Icon, Filters } from 'components';
import { useHistory, useLocation } from 'react-router-dom';


import './index.scss';

export const MobileFiltersWrapper = ({ 
  show,
  className,
  children,
  clickOutsideRoute
 }) => {
  const ref = useRef();
  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    document.body.style.overflow = 'hidden';
    return () => (document.body.style.overflow = 'unset');
  }, []);

  return (
    <div
      ref={ref}
      className={cn(
        'cb-mobile-filters pb-60 min-h-screen bg-black-light ',
        className,
        {
          active: show
        }
      )}
    >
      <div className="w-full sticky top-0 z-10 py-2 flex justify-center pt-4 bg-black-light ">
        <Icon
          onClick={() =>
            history.push(clickOutsideRoute + (location.search || ''))
          }
          className="mx-auto"
          width={40}
          name="ChevronDown"
        />
      </div>
      <div className="wrapper-lg min-h-full block">{children}</div>
    </div>
  );
};
