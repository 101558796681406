import { useState } from 'react';
import { Sidebar, Navbar } from 'components/dashboard';
import { MobileNavbar } from 'components';
import { useMediaQuery } from 'react-responsive';
import { useLocation } from 'react-router-dom';
import cn from 'classnames';
import './index.scss';

export const DashboardLayout = ({ children }) => {
  const location = useLocation();
  const isMobile = useMediaQuery({
    query: '(max-width: 768px)'
  });
  const isMediumScreen = useMediaQuery({
    query: '(min-width: 780px) and (max-width: 1280px)'
  });

  const isDesktop = useMediaQuery({
    query: '(min-width: 1024px)'
  });
  const isChatMobile = location?.pathname.includes('chat') && isMobile;
  const [toggleSidebar, setToggleSidebar] = useState(false);

  return (
    <>
      <Navbar
        showHam={isMediumScreen}
        toggleSidebar={toggleSidebar}
        setToggleSidebar={setToggleSidebar}
      />
      {(toggleSidebar || !isMediumScreen) && (
        <Sidebar
          showClose={isMediumScreen}
          setToggleSidebar={setToggleSidebar}
        />
      )}
      <main
        className={cn('dashboard-main pt-20 md:pt-23 pb-20 xl:pl-64', {
          'is-chat': isChatMobile,
          'min-h-screen': !isChatMobile
        })}
      >
        <div className="wrapper-lg md:mt-9">{children}</div>
      </main>
      {!isDesktop && <MobileNavbar />}
    </>
  );
};
