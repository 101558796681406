import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { Button } from '@estudio-nk/the-crane-club-client-lib';

export const Banner = ({
  title,
  detail,
  className,
  buttonText = null,
  loading = false,
  onClick = () => null
}) => {
  return (
    <div
      className={cn(
        'bg-yellow-100 radius border-l-4 border-yellow-500 text-yellow-700 p-4 rounded-t',
        className
      )}
      role="alert"
    >
      <div className="flex flex-row justify-between items-center">
        <div>
          <p className="font-bold">{title}</p>
          <p className="">{detail}</p>
        </div>
        <div className="">
          {!!buttonText && (
            <Button
              variant="outline"
              size="md"
              colorVariant="yellow"
              loading={loading}
              onClick={onClick}
              className="px-5"
            >
              {buttonText}
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};

Banner.propTypes = {
  title: PropTypes.string,
  detail: PropTypes.string,
  className: PropTypes.string,
  buttonText: PropTypes.string,
  loading: PropTypes.bool,
  onClick: PropTypes.func
};
