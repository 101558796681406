import axios from 'utils/axios';

export const getVerified = async (
  type = 0,
  operation = 'both',
  page = 0,
  size = 10,
  orderBy = 'verifiedAt-DESC',
  search = {}
) => {
  try {
    let url = `api/publications/verified?type=${type}&operation=${operation}&page=${page}&size=${size}&order=${orderBy}`;
    Object.keys(search).length &&
      (url = url.concat(`&search=${JSON.stringify(search)}`));
    const { data } = await axios.get(url);
    return data;
  } catch (error) {
    if (process.env.REACT_APP_DEV_MODE !== 'true') {
      console.log('ERROR: ', error.message);
    }
    throw error;
  }
};

export const getVerifiedByUser = async (
  type = 0,
  operation = 'both',
  userId = 0,
  page = 0,
  size = 10,
  orderBy = 'verifiedAt-DESC'
) => {
  try {
    let url = `api/publications/verified-by-user/${userId}?type=${type}&operation=${operation}&page=${page}&size=${size}&order=${orderBy}`;
    const { data } = await axios.get(url);
    return data;
  } catch (error) {
    if (process.env.REACT_APP_DEV_MODE !== 'true') {
      console.log('ERROR: ', error.message);
    }
    throw error;
  }
};

export const getPublicationsByUser = async (
  page = 0,
  size = 10,
  orderBy = 'verifiedAt-DESC',
  state = 'all'
) => {
  try {
    const { data } = await axios.get(
      `api/publications/by-user?page=${page}&size=${size}&state=${state}&order=${orderBy}`
    );
    return data;
  } catch (error) {
    if (process.env.REACT_APP_DEV_MODE !== 'true') {
      console.log('ERROR: ', error.message);
    }
    throw error;
  }
};

export const getPublicationsFilters = async (
  type = 0,
  operation = 'both',
  useCache
) => {
  try {
    const { data } = await axios.get(
      `api/publications/verified-filters?type=${type}&operation=${operation}`,
      { useCache }
    );
    return data;
  } catch (error) {
    if (process.env.REACT_APP_DEV_MODE !== 'true') {
      console.log('ERROR: ', error.message);
    }
    throw error;
  }
};

export const getSearch = async (search) => {
  try {
    const { data } = await axios.get(
      `api/publications/search?search=${search}`
    );
    return data;
  } catch (error) {
    if (process.env.REACT_APP_DEV_MODE !== 'true') {
      console.log('ERROR: ', error.message);
    }
    throw error;
  }
};

export const getPublication = async (id = 0, showAll = false) => {
  try {
    const url = !showAll
      ? `api/publications/${id}`
      : `api/publications/${id}?showAll=true`;
    const { data, status } = await axios.get(url);
    return { data, status };
  } catch (error) {
    if (process.env.REACT_APP_DEV_MODE !== 'true') {
      console.log('ERROR: ', error.message);
    }
    throw error;
  }
};

export const getPublicationsLastAdded = async (size = 8, userId = null) => {
  try {
    console.log(!userId);
    const url = !userId
      ? `api/publications/last-added?page=0&size=${size}`
      : `api/publications/last-added?page=0&size=${size}&id=${userId}`;
    const { data } = await axios.get(url);
    return data;
  } catch (error) {
    if (process.env.REACT_APP_DEV_MODE !== 'true') {
      console.log('ERROR: ', error.message);
    }
    throw error;
  }
};

export const postPublication = async (publication) => {
  try {
    const res = await axios.post('api/publications', publication);
    return res;
  } catch (error) {
    if (process.env.REACT_APP_DEV_MODE !== 'true') {
      console.log('ERROR: ', error.message);
    }
    throw error;
  }
};

export const updatePublication = async (publication, id) => {
  try {
    const res = await axios.put(`api/publications/${id}`, publication);
    return res;
  } catch (error) {
    if (process.env.REACT_APP_DEV_MODE !== 'true') {
      console.log('ERROR: ', error.message);
    }
    throw error;
  }
};

export const deletePublication = async (id) => {
  try {
    await axios.delete(`api/publications/${id}`);
  } catch (err) {
    throw err;
  }
};

export const getRejectedReason = async (id) => {
  try {
    await axios.delete(`api/publications/${id}`);
  } catch (error) {
    if (process.env.REACT_APP_DEV_MODE !== 'true') {
      console.log('ERROR: ', error.message);
    }
    throw error;
  }
};

export const pausePublication = async (id, isActive) => {
  try {
    await axios.put(`api/publications/trigger-status/${id}`, isActive);
  } catch (error) {
    if (process.env.REACT_APP_DEV_MODE !== 'true') {
      console.log('ERROR: ', error.message);
    }
    throw error;
  }
};
