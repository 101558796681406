import React, { useEffect, useState, useCallback } from 'react';
import { useHistory, useParams, useLocation } from 'react-router-dom';
import ReloadContext from 'contexts/ReloadContext';
import cn from 'classnames';
import { ProfileHeader } from 'components';
import { getProfile } from 'api/profiles';
import { CompanyProfileSideBar, UserProfileSideBar } from 'components/profile';
import { Header, Footer, MobileNavbar, Spinner } from 'components';
import { useMediaQuery } from 'react-responsive';
import { Helmet } from 'react-helmet';
import defaultBanner from 'assets/img/cover-default.jpg';

export const ProfileLayout = ({ children }) => {
  const [profile, setProfile] = useState({});
  const { id } = useParams();
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const isDesktop = useMediaQuery({
    query: '(min-width: 1024px)'
  });

  const page =
    location?.pathname.split('/')[location?.pathname.split('/').length - 1];
  const currentPage = page.charAt(0).toUpperCase() + page.slice(1);

  const fetchProfile = useCallback(
    async (id) => {
      try {
        setLoading(true);
        const _profile = await getProfile(id);
        if (!_profile) {
          history.push('/');
        }
        setProfile(_profile);
      } catch (error) {
      } finally {
        setLoading(false);
      }
    },
    [history]
  );

  const handleOnFollow = (isFollow) => {
    const _profile = {
      ...profile,
      countFollowers: profile.countFollowers + isFollow
    };
    setProfile(_profile);
  };

  useEffect(() => {
    fetchProfile(id);
  }, [fetchProfile, id]);

  return (
    <ReloadContext>
      <Helmet>
        <title>{`${profile?.name} - ${currentPage}`}</title>
        <meta property="og:image" content={profile?.photoURL} />
        <meta
          property="og:description"
          content={profile?.isCompany && profile?.description}
          image={profile?.photoURL}
        />
      </Helmet>
      <Header />

      <main className="pt-16 sm:pt-0">
        {loading ? (
          <Spinner />
        ) : (
          <>
            <div
              className={cn('w-full bg-silver-400', {
                'bg-center bg-cover': profile.bannerURL,
                'bg-full': !profile.bannerURL
              })}
              style={{
                paddingTop: '42%',
                backgroundImage: `url('${profile.bannerURL || defaultBanner}')`,
                ...(!profile.bannerURL && {
                  backgroundPosition: 'center'
                })
              }}
            ></div>

            <ProfileHeader profile={profile} handleOnFollow={handleOnFollow} />
            <div className="wrapper-lg company-profile flex flex-col lg:flex-row pb-20 md:pb-0">
              {profile.isCompany ? (
                <CompanyProfileSideBar
                  profile={profile}
                  className="lg:w-72 mt-10 lg:mt-16"
                />
              ) : (
                <UserProfileSideBar
                  profile={profile}
                  className="lg:w-72 mt-10 lg:mt-16"
                />
              )}
              <div className="flex-1">{children}</div>
            </div>
          </>
        )}
      </main>
      {isDesktop ? <Footer /> : <MobileNavbar />}
    </ReloadContext>
  );
};
