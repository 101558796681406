import { useContext } from 'react';
import { BackButton } from 'components';
import { Button } from '@estudio-nk/the-crane-club-client-lib';
import { useTranslation } from 'react-i18next';
import { UploadContext } from 'contexts/UploadsContext';

export const ActionFooter = ({ handleValidate }) => {
  const { t } = useTranslation();
  const { isEdit } = useContext(UploadContext);

  return (
    <div className="flex items-center justify-center md:justify-end flex-col-reverse md:flex-row">
      <BackButton linkTo={`/${isEdit ? 'edit' : 'upload'}/assets`}>
        {t('previousStep')}
      </BackButton>
      <Button
        type="submit"
        variant={isEdit ? 'outline' : undefined}
        className="w-full md:w-48 md:ml-9 mb-5 md:mb-0"
        colorVariant="yellow"
      >
        {t(isEdit ? 'next' : 'confirm')}
      </Button>
      {isEdit && (
        <Button
          onClick={(e) => handleValidate(e, true)}
          className="w-full md:w-48 mb-5 md:mb-0 md:ml-9 justify-self-start"
          colorVariant="yellow"
        >
          {t('edit')}
        </Button>
      )}
    </div>
  );
};
