import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { Link } from 'react-router-dom';
import cn from 'classnames';
import { useMediaQuery } from 'react-responsive';

const baseSections = [
  {
    text: 'activity',
    route: 'activity'
  },
  {
    text: 'media',
    route: 'media'
  }
];

export const ProfileNav = ({ profile, className }) => {
  const { t } = useTranslation();
  const location = useLocation();
  const path = location.pathname;
  const [sections, setSections] = useState(baseSections);
  const isCompany = profile?.isCompany || false;
  const isDesktop = useMediaQuery({
    query: '(min-width: 768px)'
  });

  useEffect(() => {
    if (isCompany && sections.length === baseSections.length)
      setSections([
        ...sections,
        {
          text: 'machines',
          route: 'machines'
        }
      ]);
    else {
      profile?.userResume?.id &&
        setSections([
          ...baseSections,
          {
            text: 'curriculum',
            route: 'curriculum'
          }
        ]);
    }
  }, [profile]);

  return (
    <nav
      className={cn(
        'flex items-center flex-1 md:px-16 overflow-auto border-b border-silver md:border-none mt-8 md:mt-0 md:pb-0 justify-center',
        className
      )}
      style={{ height: isDesktop ? '72px' : '' }}
    >
      {sections.map(({ route, text }, i) => (
        <Link
          key={i}
          to={`${route}?no-scroll=true`}
          className={cn(
            'h-full flex items-center justify-center transition duration-300 hover:text-yellow relative md:px-1 md:py-1 py-3 px-1 text-xs sm:text-sm xl:text-base w-36',
            {
              'text-yellow': path.includes(`/${route}`)
            }
          )}
        >
          {t(text)}
          {path.includes(`/${route}`) && (
            <div className="absolute bottom-0 left-0 w-full h-0.5 bg-yellow"></div>
          )}
        </Link>
      ))}
    </nav>
  );
};
