import React from 'react';
import { useTranslation } from 'react-i18next';
import { Icon } from 'components';
import cn from 'classnames';

export const FilterButton = ({ className, onClick = () => {} }) => {
  const { t } = useTranslation();

  return (
    <div
      onClick={onClick}
      className={cn('md:hidden flex items-center', className)}
    >
      <span className="text-base text-yellow mr-2">{t('filters')}</span>
      <Icon name="Filter" width={16} height={16} className={'cursor-pointer'} />
    </div>
  );
};
