import { StreamChat } from 'stream-chat';

const client = StreamChat.getInstance(process.env.REACT_APP_CHAT_KEY);

export const getChannelByUsers = async (user1, user2) => {
  try {
    const [channel] = await client.queryChannels({
      type: 'messaging',
      members: { $eq: [user1, user2] }
    });

    return channel;
  } catch (error) {}
};

export default client;
