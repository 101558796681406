import React from 'react';
import { useTranslation } from 'react-i18next';
import { MONTHS } from 'utils/constants';
import cn from 'classnames';
import { Box } from 'components';

export const ProfileEducation = ({ className, data }) => {
  const { t } = useTranslation();

  return (
    <Box className={className} variant="flat">
      <h4 className="text-yellow text-lg border-b pb-2">{t('education')}</h4>
      {data?.map((e, i) => (
        <div className={cn('py-4', { 'border-b': i !== data.length - 1 })}>
          <p className="font-medium text-sm">{e.career}</p>
          {e.description && (
            <div className="font-normal text-xs whitespace-pre-line mt-3">
              {e.description}
            </div>
          )}
          <p className="font-medium text-sm text-gray mt-3">
            {t(
              `months.${
                MONTHS[
                  typeof e.sinceMonth === 'object'
                    ? e.sinceMonth.value
                    : e.sinceMonth
                ]
              }`
            )}{' '}
            {e.sinceYear} -{' '}
            {e.toMonth && e.toYear
              ? `${t(
                  `months.${
                    MONTHS[
                      typeof e.toMonth === 'object'
                        ? e.toMonth.value
                        : e.toMonth
                    ]
                  }`
                )} ${e.toYear}`
              : t('currently')}
          </p>
        </div>
      ))}
    </Box>
  );
};
