import React, { Fragment, useCallback, useMemo } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { Icon } from 'components';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';

export const Stepper = () => {
  const location = useLocation();
  const history = useHistory();
  const { t } = useTranslation();

  const steps = useMemo(
    () => [
      {
        label: t('upload.stepper.category'),
        route: 'category'
      },
      {
        label: t('upload.stepper.brand'),
        route: 'brand'
      },
      {
        label: t('upload.stepper.model'),
        route: 'model'
      },
      {
        label: t('upload.stepper.year'),
        route: 'year'
      },
      {
        label: t('upload.stepper.photosAndVideos'),
        route: 'assets'
      },
      {
        label: t('upload.stepper.configuration'),
        route: 'configuration'
      },
      {
        label: t('upload.stepper.conditions'),
        route: 'conditions'
      },
      {
        label: t('upload.stepper.locationAndContact'),
        route: 'location'
      },
      {
        label: t('upload.stepper.publication'),
        route: 'publish'
      }
    ],
    [t]
  );

  const isPreviousStep = useCallback(
    (route) => {
      const routes = steps.map((e) => e.route);
      return (
        routes.indexOf(route) < routes.indexOf(location.pathname.split('/')[2])
      );
    },
    [location.pathname, steps]
  );

  const renderIcon = (route) =>
    location.pathname.includes('edit') ? (
      <Icon name="TickSolid" height={22} width={22} />
    ) : location.pathname.includes(route) &&
      location.pathname !== '/upload/publish' ? (
      <span className="w-5 h-5 rounded-full border border-solid border-yellow" />
    ) : isPreviousStep(route) || location.pathname === '/upload/publish' ? (
      <Icon name="TickSolid" height={22} width={22} />
    ) : (
      <span className="w-5 h-5 rounded-full border border-solid border-gray" />
    );

  const handleClickStep = (route) => {
    if (
      (isPreviousStep(route) || location.pathname.includes('edit')) &&
      !location.pathname.includes('publish')
    ) {
      if (!route.includes('publish')) history.push(route);
      else history.push('location');
    }
  };

  return (
    <div className="cb-stepper">
      {steps.map(({ label, route }, index) => (
        <Fragment key={index}>
          <div
            onClick={() => handleClickStep(route)}
            className={cn(
              'flex items-center',
              (isPreviousStep(route) || location.pathname.includes('edit')) &&
                'cursor-pointer'
            )}
          >
            {renderIcon(route)}
            <h3
              className={cn(
                'text-xs tracking-wider text-gray ml-4 uppercase',
                location.pathname.includes(route) &&
                  location.pathname !== '/upload/publish' &&
                  'text-yellow'
              )}
            >
              {label}
            </h3>
          </div>
          {index + 1 < steps.length && (
            <div className="w-5 h-4 flex justify-center my-2">
              <span
                className={cn(
                  'h-4 border-l border-solid border-gray ',
                  isPreviousStep(route) && 'border-green'
                )}
              />
            </div>
          )}
        </Fragment>
      ))}
    </div>
  );
};
