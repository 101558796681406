import { useContext, useEffect, useState } from 'react';
import { PasswordInput, Spinner } from 'components';
import { Button } from '@estudio-nk/the-crane-club-client-lib';
import { UserContext } from 'contexts/UserContext';
import { useHistory } from 'react-router';
import { useForm } from 'hooks';
import { changePassword } from 'api/auth';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import './index.scss';

export const PasswordUpdateForm = () => {
  const history = useHistory();
  const { t } = useTranslation();
  const { user } = useContext(UserContext);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    user.isSocial && history.replace('/dashboard/settings');
  }, [history, user.isSocial]);

  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm({ mode: 'onBlur' });

  const handleSave = async ({
    currentPassword,
    newPassword,
    repeatNewPassword
  }) => {
    try {
      setLoading(true);
      if (newPassword !== repeatNewPassword)
        toast.warning(t(`passwordsShouldMatch`));
      else {
        await changePassword(currentPassword, newPassword);
        toast.success(t(`passwordHasBeenChanged`));
        history.push('/dashboard/settings');
      }
    } catch (error) {
      if (error === 'Wrong password') toast.error(t(`invalidPassword`));
      else {
        toast.error(t(`genericError`));
      }
    } finally {
      setLoading(false);
    }
  };

  return !user ? (
    <Spinner />
  ) : (
    <div className="update-password">
      <h2 className="ml-9 pt-5 tracking-tight text-lg">{t('login')}</h2>
      <form onSubmit={handleSubmit(handleSave)}>
        <div className="px-9 pb-7">
          <div className={cn("w-full my-8", {'opacity-20': user.isSocial})}>
            <label>{t('password')}</label>
            <div className="grid-data mt-2">
              <label>{t('currentPassword')}</label>
              <PasswordInput
                className="border-none w-full"
                disabled={user.isSocial}
                placeholder={t('currentPassword')}
                error={errors.currentPassword}
                register={register('currentPassword', { required: true })}
              />

              <label>{t('newPassword')}</label>
              <PasswordInput
                className="border-none"
                disabled={user.isSocial}
                placeholder={t('newPassword')}
                error={errors.newPassword}
                register={register('newPassword', {
                  required: true,
                  minLength: 8
                })}
              />

              <label>{t('repeatNewPassword')}</label>
              <PasswordInput
                className="border-none"
                disabled={user.isSocial}
                placeholder={t('repeatNewPassword')}
                error={errors.repeatNewPassword}
                register={register('repeatNewPassword', {
                  required: true,
                  minLength: 8
                })}
              />
            </div>
          </div>
          <Button
            size="md"
            className="w-40"
            loading={loading}
            type="submit"
            colorVariant="yellow"
            disabled={user.isSocial}
          >
            {t('changePassword')}
          </Button>
          {user.isSocial && 
            <p className="text-sm text-gray mt-4">{t('youCanNotChangeYourPassword')}</p>
          }
        </div>
      </form>
    </div>
  );
};
