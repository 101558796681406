import { useEffect } from 'react';
import queryString from 'query-string';
import { useHistory } from 'react-router-dom';
import { Capacitor } from '@capacitor/core';
import { App as CapacitorApp } from '@capacitor/app';

export const RouteChangeListener = () => {
  const history = useHistory();
  useEffect(() => {
    const unlisten = history.listen(({ search }) => {
      const params = queryString.parse(search);
      !params['no-scroll'] && window.scrollTo(0, 0);
    });

    if (Capacitor.isNativePlatform()) {
      CapacitorApp.addListener('backButton', ({ canGoBack }) => {
        if (canGoBack) {
          history.goBack();
        } else {
          CapacitorApp.exitApp();
        }
      });

      CapacitorApp.addListener('appUrlOpen', (event) => {
        const slug = event.url.split('.com').pop();

        if (slug) {
          history.push(slug);
        }
      });
    }

    return () => {
      unlisten();
      Capacitor.isNativePlatform && CapacitorApp.removeAllListeners();
    };
  }, []);

  return null;
};
