export const currentYear = new Date().getFullYear();

const getYears = (withAddition) => {
  let auxYear = currentYear;
  let years = [];
  if (withAddition && currentYear % 10 !== 0) {
    while (auxYear % 10 !== 0) {
      years.push(auxYear);
      auxYear++;
    }
  }
  auxYear = withAddition ? currentYear - 1 : currentYear;
  while (auxYear !== 1969) {
    years.unshift(auxYear);
    auxYear--;
  }
  return years.reverse();
};

export const years = getYears();
export const yearsWithAddition = getYears(true);
