import React from 'react';
import cn from 'classnames';

import { useTranslation } from 'react-i18next';

export const Textarea = ({
  placeholder,
  onChange,
  className,
  inputClasses,
  error,
  errorMessage,
  register,
  isAccount,
  ...rest
}) => {
  const { t } = useTranslation();
  return (
    <div className={cn(className, error)}>
      <textarea
        placeholder={placeholder}
        className={cn(
          error
            ? 'border-yellow-dark'
            : isAccount
            ? 'border-gray-light'
            : 'border-gray-light',
          {
            'border h-full p-5 border-solid focus:border-yellow focus:ring-0 rounded-lg w-full':
              !isAccount,
            'border h-32 py-5 text-white-secondary border-solid focus:border-yellow focus:ring-0 w-full  border-l-0 border-r-0 border-t-0 resize-none text-sm':
              isAccount
          },
          'bg-black-light ',
          inputClasses
        )}
        onChange={(e) => onChange && onChange(e.target.value)}
        {...register}
        {...rest}
      />
      {error && (
        <span className="text-2xs text-yellow-dark flex justify-end mt-1">
          {errorMessage || t(`form.${error.type}`)}
        </span>
      )}
    </div>
  );
};
