import React, { useMemo, useState, useEffect } from 'react';
import { Sidebar } from 'components/dashboard';
import { MobileNavbar } from 'components';
import { Navbar } from 'components/upload';
import { useLocation, useHistory } from 'react-router-dom';
import cn from 'classnames';
import { useMediaQuery } from 'react-responsive';
import UploadProvider, { UploadContext } from 'contexts/UploadsContext';

export const UploadLayout = ({ children }) => {
  const location = useLocation();
  const history = useHistory();
  const [isFirstLoad, setIsFirstLoad] = useState(true);

  const isDesktop = useMediaQuery({
    query: '(min-width: 1024px)'
  });

  useEffect(() => {
    if (!process.env.REACT_APP_SNICK_UPLOAD) {
      if (
        location.pathname !== '/upload/category' &&
        !location.pathname.includes('edit') &&
        isFirstLoad
      ) {
        history.push('/upload/category');
      } else if (
        (location.pathname !== '/edit/category' &&
          location.pathname.includes('edit') &&
          isFirstLoad) ||
        (location.pathname === '/edit/category' &&
          !location.state &&
          isFirstLoad)
      ) {
        history.push('/dashboard/machines');
      } else {
        setIsFirstLoad(false);
      }
    }
  }, [location.pathname, isFirstLoad, history, location.state]);

  const noSidebar = useMemo(
    () => location.pathname.includes('/category'),
    [location.pathname]
  );

  return (
    <UploadProvider>
      <Navbar variant={noSidebar ? 'noSidebar' : 'sidebar'} />
      {!noSidebar && <Sidebar upload className="hidden lg:block" />}
      <main
        className={cn('pt-20 md:pt-23 min-h-screen', !noSidebar && 'lg:pl-64')}
      >
        <>
          <UploadContext.Consumer>
            {({ currentStep }) => {
              return (
                <div
                  className="h-1 bg-yellow transition-all fixed z-10 rounded-br-3xl"
                  style={{ width: `${currentStep * 10}%` }}
                />
              );
            }}
          </UploadContext.Consumer>
          <div className="pt-11 overflow-hidden pb-20 md:pb-48">{children}</div>
        </>
      </main>
      {!isDesktop && <MobileNavbar />}
    </UploadProvider>
  );
};
