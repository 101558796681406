export const UPLOAD_STEPS = [
  'category',
  'brand',
  'model',
  'year',
  'certification',
  'assets',
  'configuration',
  'condition',
  'location',
  'publication'
];

export const MONTHS = [
  'january',
  'february',
  'march',
  'april',
  'may',
  'june',
  'july',
  'august',
  'september',
  'october',
  'november',
  'december'
];

export const URL_REGEX =
  /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/g;

export const HASHTAG_REGEX = /\B(\#[a-zA-Z]+\b)(?!;)/;

export const NOTIFICATIONS_PER_PAGE = 20;
