import React from 'react';
import { Icon } from 'components';
import cn from 'classnames';
import './index.scss';

export const Spinner = ({ className, height = 40, width = 40 }) => {
  return (
    <Icon
      name="Spinner"
      height={height}
      width={width}
      className={cn(
        'flex justify-center align-center w-full mt-3 mb-3',
        className
      )}
      loading
    />
  );
};
