export const TermsEng = () => (
  <div className="wrapper-md legal">
    <h2>Website and application General Terms & Conditions</h2>
    <h2>THE CRANE CLUB INC.</h2>
    <h2>(7901 4th St N Ste 300, St. Petersburg, FL 33702, USA)</h2>
    <p>
      The general terms and conditions (the "GTC") applicable to the use of the
      services offered by THE CRANE CLUB INC are described below. ("THE CRANE
      CLUB"), a company incorporated in Florida, United States of North America,
      (hereinafter "the Services") either within THE CRANE CLUB APPLICATION
      (APP), or within the website www.thecraneclub .com, or through social
      networks Instagram user: @thecraneclub.ok , or the social networks or
      mobile applications indicated by THE CRANE CLUB in the future. Anyone who
      wishes to access and / or use the site or services may do so subject to
      the GTC, along with all other policies and principles indicated by THE
      CRANE CLUB.
    </p>
    <p>
      THE CRANE CLUB through the APP or the Website will have different phases,
      one of them being a virtual magazine where Concessionaires can register,
      and Companies that rent or sell cranes and equipment, and Construction
      Companies and other third parties that sell or rent cranes and equipment,
      and that THE CRANE CLUB indicates or authorizes.
    </p>
    <p>
      THE CRANE CLUB will also expand in a second phase the possibility for
      Users to market, apart from cranes and equipment, all kinds of spare
      parts, being only the means where they are advertised and not assuming any
      responsibility in the marketing of equipment or products between different
      Users.
    </p>
    <p>
      Anyone who does not accept these general terms and conditions, which are
      mandatory and binding, must refrain from using the site and / or services.
    </p>
    <p>
      The User must read, understand and accept all the conditions established
      in the GTC and in the Privacy Policies, as well as in the other documents
      incorporated therein by reference, prior to registering as a User of THE
      CRANE CLUB.
    </p>
    <h3>1: Ability to represent legal entities</h3>
    <p>
      The Services are only available to people who have the legal capacity to
      contract in their country of origin or place of incorporation. People who
      do not have that capacity, minors or Users who have been temporarily
      suspended or permanently disabled may not use the services. In the case of
      registration of a legal person as User, the human person who represents
      him must have sufficient capacity to contract on behalf of such society
      and with powers to bind it in accordance with these GTC.
    </p>
    <h3>2: Registration process and veracity of information</h3>
    <p>
      It is mandatory to complete the registration form that THE CRANE CLUB may
      request in all its fields, with valid data to be able to use the services
      provided by THE CRANE CLUB. The User must complete it with his personal
      information (or the company he represents) in an exact, precise and true
      way ("Personal Data") and assumes the commitment to update the Personal
      Data as necessary. THE CRABE CLUB may require additional information at
      any time and may use various means to identify its Users, but THE CRANE
      CLUB will not be responsible for the accuracy of the Personal Data
      provided by its Users. Users guarantee and respond to THE CRANE CLUB and
      third parties, in any case, of the veracity, accuracy, validity and
      authenticity of the Personal Data entered.
    </p>
    <p>
      THE CRANE CLUB reserves the right to request additional documentation and
      / or information in order to corroborate the Personal Data, as well as to
      temporarily or permanently suspend those Users whose data could not be
      confirmed.
    </p>
    <p>
      The User will access their personal THE CRANE CLUB account ("Account") by
      entering the selected user and the chosen personal security key ("Security
      Key"). The User undertakes to maintain the confidentiality of his Security
      Code.
    </p>
    <p>
      The Account is personal, unique and non-transferable, and the same User is
      prohibited from registering or owning more than one Account. In the event
      that THE CRANE CLUB detects different Accounts that contain matching or
      related data, it may cancel, suspend or disable them.
    </p>
    <p>
      The User will be responsible for all the operations carried out in his
      Account, since access to it is restricted to the entry and use of his
      Security Code, of exclusive knowledge of the User. The User agrees to
      notify THE CRANE CLUB immediately and by suitable and reliable means, of
      any unauthorized use of their Account, as well as the entry by
      unauthorized third parties to it. It is clarified that the sale,
      assignment or transfer of the Account (including reputation and ratings)
      is prohibited under any title.
    </p>
    <p>
      THE CRANE CLUB reserves the right to reject any application for
      registration or to cancel a previously accepted registration, without
      being obliged to communicate or state the reasons for its decision and
      without generating any right to compensation or reparations.
    </p>
    <p>
      THE CRANE CLUB may reject, at its sole discretion, the registration as a
      User of any person who comes from jurisdictions that do not comply with
      international regulations on Prevention and control of money laundering
      and financing of terrorism; have criminal penalties; are in bankruptcy or
      bankruptcy proceedings, or by any other antecedent considered by THE CRANE
      CLUB.
    </p>
    <p>
      By adhering to these GTC, the selling Users undertake to comply with the
      current tax regulations, as well as to avoid any maneuver that could
      import the laundering of assets from the crimes of tax evasion, smuggling
      or any other crime provided for in the legislation that implies a tax
      fraud.
    </p>
    <h3>3: Modifications to the GTC</h3>
    <p>
      THE CRANE CLUB may modify the GTC at any time, publishing the modified
      terms in the APP or on the Website, and being mandatory from the fifth day
      of said publication unless THE CRANE CLUB indicates that they will be in
      force as of publication. Said modifications will be communicated by THE
      CRANE CLUB to the users who in the Configuration of their THE CRANE CLUB
      Account have indicated that they wish to receive notifications of the
      changes in these Terms and Conditions. Any user who does not agree with
      the modifications made by THE CRANE CLUB must request the cancellation of
      their account and must not use the services.
    </p>
    <p>
      The use of the APP or the site and / or its services implies the
      acceptance of these GTC of use of THE CRANE CLUB.
    </p>
    <h3>4: Goods and Services</h3>
    <p>
      4.1 By publishing an advertisement on the APP or on the Site, the seller
      user expressly consents to the use of the payment system provider as one
      of the available ways to pay for the good or service offered and
      undertakes not to modify the price of sale of the good or service if the
      buyer decides to use the payment system or platform. Likewise, the user
      declares that he has read, understood and accepted the Terms and
      Conditions of use of said service.
    </p>
    <p>
      4.2 Payment of THE CRANE CLUB fees. By publishing an ad on the APP or on
      the site, the user accepts that the payments of said fees will be
      processed through the processor or means of payment indicated by THE CRANE
      CLUB.
    </p>
    <p>
      4.3 Publication of goods and / or services. The User must offer the goods
      and / or services for sale in the appropriate categories and
      subcategories. The publications may include descriptive texts, graphics,
      photographs and other relevant content and conditions for the sale of the
      good or the contracting of the service, provided that they do not violate
      any provision of this agreement or other policies of THE CRANE CLUB. The
      product offered by the Seller User must be exactly described in terms of
      its relevant conditions and characteristics. It is understood and presumed
      that by including the good or service in THE CRANE CLUB, the User accepts
      that he has the intention and the right to sell the good offered by him,
      or is empowered to do so by its owner and has it available for delivery
      immediately or for the term specified in the publication. It is
      established that the prices of the published products must be expressed
      with the taxes included in the country of origin when applicable, and in
      US Dollars or the legal currency or cryptocurrency indicated by THE CRANE
      CLUB. THE CRANE CLUB may remove any publication whose price is not
      expressed in this way to avoid confusion or misunderstandings regarding
      the final price of the product. It is expressly established that no
      description may contain personal or contact data, such as, and without
      being limited to, telephone numbers, e-mail address, postal address,
      Internet page addresses that contain data such as those mentioned above,
      unless authorized from THE CRANE CLUB. No other means of payment may be
      advertised, other than those set forth by THE CRANE CLUB on the article
      publication page. In the event that any of the provisions established in
      this clause is violated, THE CRANE CLUB may edit the space, request the
      User to edit it, or cancel the publication where the violation is found.
    </p>
    <p>
      4.4 License on images, photographs, brands and Product Information. The
      user authorizes THE CRANE CLUB to use, publish, reproduce and / or adapt
      the images and photographs included in its publications, its commercial
      name, brands, advertising phrases, logos, designs, drawings, images and
      any other distinctive sign that identifies the User and their products or
      services (the "Brand") and information about their products or services
      ("Product Information"). It will be the obligation of the User to include
      in the publications the images, photographs and Brand, as well as the
      Product Information, in an updated manner, including those warnings that
      are required by the applicable legislation for the sale or advertising of
      products and services. In accordance with the foregoing, THE CRANE CLUB
      may obtain the images, photographs, Brand and Product Information directly
      from the User, third parties authorized by the latter, or through the
      User's APP or website.
    </p>
    <p>
      In particular, the User grants THE CRANE CLUB a free, irrevocable,
      non-exclusive, international authorization with no time limit to use,
      publish, reproduce and / or adapt the images, photographs, the Brand and
      the Product Information in order to be used in all the applications and
      sites of THE CRANE CLUB, social networks and / or in any massive and
      non-massive means of communication, including without limitation,
      platforms and any other digital or physical means that THE CRANE CLUB
      deems appropriate or with those other platforms or Internet sites with
      which THE CRANE CLUB has made an alliance, to identify offers, classify
      products, create catalogs, carry out advertising and marketing activities
      linked to THE CRANE CLUB services, including the possibility of
      association with brands and / or trade names of third parties, as well as
      sublicense their use to third parties, including, without limitation, but
      not limited to, powers of use, publication, reproduction and adaptation of
      images, photographs, Brand and Product Information within the framework of
      Catalog Publications.
    </p>
    <p>
      The User declares and guarantees that he is the owner or licensee of the
      necessary rights over the images, photographs contained in his
      publications, on the Trademarks, as well as on the Product Information,
      and that he has the rights and powers necessary to grant the detailed
      authorization in this clause, being solely responsible for any
      infringement of third party rights or for inconsistencies or inaccuracies
      in the Product Information.
    </p>
    <p>
      THE CRANE CLUB may eliminate the publication of the images and
      photographs, and even the good or service, if it interprets, in its sole
      discretion, that the image does not comply with these Terms and
      Conditions. The images and photographs of goods or services published
      under the Premium mode must meet some additional requirements as a
      condition to be displayed on the Main Page of the Website or on the APP.
      Know the requirements.
    </p>
    <p>
      4.5 Prohibited Items. They can only be entered in the lists of goods and /
      or services offered, those whose sale is not tacitly or expressly
      prohibited in the GTC and other policies of THE CRANE CLUB or by current
      law.
    </p>
    <h3>5: Information Protection</h3>
    <p>
      To use the Services offered by THE CRANE CLUB, Users must provide certain
      personal data. Your personal information is processed and stored on
      servers or magnetic media that maintain high standards of security and
      protection, both physical and technological. For more information on the
      privacy of Personal Data and cases in which personal information will be
      disclosed, you can consult our Privacy Policies.
    </p>
    <h3>6: Users obligations</h3>
    <p>
      6.1 Buyer’s Obligations. During the period set by the Seller User,
      interested Users will make purchase offers for the goods and contract
      offers for the services. The sale offer ends once the product publication
      term expires or the amounts stipulated by the Seller expire, and the
      service contract offer ends with the expiration of the publication term.
    </p>
    <p>
      The Buyer is obliged to try to communicate with the seller and complete
      the operation if he has made an offer for a published article, regardless
      of the category or section, unless the operation is prohibited by law or
      the General Terms and Conditions and other policies of THE CRANE CLUB, in
      which case it will not be obliged to carry out the operation.
    </p>
    <p>
      By bidding for an item, the User agrees to be bound by the conditions of
      sale included in the item's description to the extent that they do not
      violate the laws or the General Terms and Conditions and other policies of
      THE CRANE CLUB. The purchase offer is irrevocable except in exceptional
      circumstances, such as that the seller substantially changes the
      description of the item after an offer is made, that there is a clear
      typographical error, or that the seller cannot verify the identity of the
      seller. Purchases made in the Supermarket category, it is understood that
      the General Terms and Conditions and other policies of THE CRANE CLUB
      apply to them, however, as indicated by the consumer protection rules,
      there will be no right of withdrawal in the acquisition of Perishable
      goods, goods for personal use or those goods cannot be returned or may
      deteriorate or expire quickly.
    </p>
    <p>
      The buyer can revoke the purchase within a period of 10 days from
      receiving the product or may request the exchange or return of the same in
      the terms of the Protected Purchase, Points Market and other Annexes
      programs, up to 30 days after receipt of the product. If the User complies
      with the previously indicated requirements and effectively revokes the
      purchase and / or proceeds a refund in relation to it, the User authorizes
      THE CRANE CLUB to reimburse the amount in relation to said purchase in the
      Mercado Pago account of the User, which will be available immediately and
      from which you can transfer the money for free to the bank account of your
      choice, send it to another person's bank account, withdraw it and / or use
      it for purchases within the platform itself .
    </p>
    <p>
      Purchase offers will only be considered valid once they have been
      processed by THE CRANE CLUB's computer system.
    </p>
    <p>
      Taxes. As established by current tax regulations, the buyer must demand an
      invoice or ticket from the seller as proof of the operation. The seller
      will not be obliged to issue an invoice or ticket only in the case of a
      natural person who occasionally makes sales.
    </p>
    <p>
      6.2. Seller's Obligations. The Selling User must have the legal capacity
      to sell the good that is the object of his offer. Likewise, it must comply
      with all relevant regulatory obligations and have the records,
      authorizations, permits and / or authorizations required by the applicable
      regulations for the sale of the goods and services offered. The seller
      user must comply with current regulations on consumer protection, such as,
      provide truthful, clear and sufficient information, indicate the price per
      unit of measure in the products that is applicable, avoid misleading
      advertising in promotions, offers or discounts granted to the buyer user
      and any other obligation derived from its publication. Failure to comply
      with this obligation will empower THE CRANE CLUB to suspend and / or
      disable the seller user to continue operating on the APP or on the site.
      If the Seller User has received an offer on the price that he established
      in the publication, he is obliged to try to communicate with the buyer and
      complete the operation with the User who offered. The cancellation of a
      sale by the selling User will impact its reputation.
    </p>
    <p>
      Since THE CRANE CLUB is a virtual meeting place between buyer and seller
      and does not participate in the operations that are carried out between
      them, the Seller will be responsible for all the obligations and tax
      charges that correspond to the sale of their items, without being able to
      imputing to THE CRANE CLUB some type of responsibility for breaches in
      this regard.
    </p>
    <p>
      Taxes. As mentioned above, THE CRANE CLUB only makes available to Users a
      virtual space that allows them to communicate via the Internet to find a
      way to sell or buy items and / or services. THE CRANE CLUB does not have
      any participation in the process of negotiation and improvement of the
      final contract between the parties. For this reason, THE CRANE CLUB is not
      responsible for the effective fulfillment of the fiscal or tax obligations
      established by the current law.
    </p>
    <p>
      The seller agrees that his buyer can revoke the purchase within a period
      of 10 days after receiving the product.
    </p>
    <p>
      In any of the aforementioned cases, the seller must accept the return of
      the sold product and may be responsible for the expenses that this
      entails, including the shipping costs that may be necessary.
    </p>
    <p>
      If the Mercado Envíos service had been used in the operation, THE CRANE
      CLUB may provide shipping labels for the buyer to make the return and
      debit the cost of the same from the seller's accou
    </p>
    <h3>7. Prohibited behavior</h3>
    <p>
      Users may not: (a) manipulate the prices of the articles; (b) interfere
      with the bidding between different Users; (c) maintain any type of
      communication by e-mail, or by any other means (including social networks)
      during the offer of the good with any of the Users who participate in it,
      except in the Questions and Answers section; (d) disclose your personal
      data or that of other users through the Questions and Answers section and
      / or by any other means (including but not limited to Twitter, Facebook
      and / or any other social network), except as stipulated specifically for
      the category Cars, motorcycles and others, Services and Properties; (e)
      accept personal data provided by other users through the Questions and
      Answers section and / or some other means (including but not limited to
      Twitter, Facebook and / or any other social network); (f) insult,
      discriminate or attack other Users, collaborators of THE CRANE CLUB or any
      person who is related to the services that THE CRANE CLUB provides on its
      platform; (g) insult or attack other Users; (h) use your reputation,
      ratings or comments received on the APP or on THE CRANE CLUB site in any
      area outside of THE CRANE CLUB, (i) publish identical articles in more
      than one publication.
    </p>
    <p>
      This type of activity will be investigated by THE CRANE CLUB and the
      offender may be sanctioned with the suspension or cancellation of the
      offer and even registration as a User of THE CRANE CLUB and / or in any
      other way it deems appropriate, without prejudice to the legal actions to
      which it may give rise due to the configuration of crimes or
      contraventions or civil damages that it may cause to the offering User
    </p>
    <h3>8: System or Database Violations</h3>
    <p>
      No action or use of any device, software, or other means to interfere with
      THE CRANE CLUB's activities and operations or THE CRANE CLUB's offers,
      descriptions, accounts or databases is permitted. Any intromission,
      attempt or activity in violation or contrary to the laws on intellectual
      property rights and/or the prohibitions stipulated in this contract will
      make the responsible party liable to the pertinent legal actions, and to
      the sanctions foreseen by this agreement, as well as to compensate the
      damages caused.
    </p>
    <h3>9: Sanctions. Suspension of operations</h3>
    <p>
      Without prejudice to other measures, THE CRANE CLUB may warn, temporarily
      suspend or permanently disable the Account of a User or a publication,
      apply a sanction that negatively impacts the reputation of a User,
      initiate actions it deems appropriate and / or suspend the provision of
      its Services if (a) any law is broken, or any of the provisions of the
      General Terms and Conditions and other policies of THE CRANE CLUB; (b) if
      it breaches its commitments as a User; (c) if it incurs in THE CRANE CLUB
      criteria in fraudulent or fraudulent conduct or acts; (d) the identity of
      the User could not be verified or any information provided by the same is
      erroneous; (e) THE CRANE CLUB understands that the publications or other
      actions may be cause for liability for the User who published them, for
      THE CRANE CLUB or for the Users. In the case of suspension or
      disqualification of a User, all the articles he/she has published will be
      removed from the system. Also will be removed from the system the bids for
      the purchase of goods offered at auction.
    </p>
    <h3>10: Responsibility</h3>
    <p>
      THE CRANE CLUB only makes available to Users a virtual space that allows
      them to communicate via the Internet to find a way to sell or buy services
      or goods.
    </p>
    <p>
      THE CRANE CLUB is not the owner of the items offered, does not have
      possession of them or offer them for sale.
    </p>
    <p>
      THE CRANE CLUB does not intervene in the perfection of the operations
      carried out between the Users nor in the conditions stipulated by them for
      the same, therefore it will not be responsible for the existence, quality,
      quantity, state, integrity or legitimacy of the goods offered, acquired or
      sold by the Users, as well as for the capacity to contract of the Users or
      the veracity of the Personal Data entered by them. Each User knows and
      agrees to be solely responsible for the items he/she publishes for sale
      and for the offers and/or purchases he/she makes.
    </p>
    <p>
      Since THE CRANE CLUB has no participation during the entire time the item
      is published for sale, nor in the subsequent negotiation and final
      contract between the parties, it will not be responsible for the effective
      fulfillment of the obligations assumed by the Users in the completion of
      the transaction. The User knows and accepts that when carrying out
      operations with other Users or third parties, he/she does so at his/her
      own risk. In no case THE CRANE CLUB will be liable for loss of profits, or
      for any other damage and / or injury that the User may have suffered, due
      to transactions made or not made by articles published through THE CRANE
      CLUB
    </p>
    <p>
      THE CRANE CLUB recommends acting with prudence and common sense when
      carrying out transactions with other Users. The User must also be aware of
      the risks of contracting with minors or with persons using a false
      identity. THE CRANE CLUB will NOT be responsible for the realization of
      offers and/or transactions with other Users based on the trust placed in
      the system or the Services provided by THE CRANE CLUB.
    </p>
    <p>
      Since the seller user has the power to eliminate questions or prevent a
      user from asking questions or offers in their publications, it is
      clarified that in that case, the user will be solely responsible for that
      decision and the consequences that may arise.
    </p>
    <h3>11: Scope Of Terms Of Use</h3>
    <p>
      This agreement does not create any contract, partnership or employment
      relationship between THE CRANE CLUB and the User. The User acknowledges
      and agrees that THE CRANE CLUB is not a party to any transaction, nor does
      it have any control over the quality, safety or legality of the items
      advertised, the truthfulness or accuracy of the advertisements, the
      ability of Users to sell or purchase items. THE CRANE CLUB cannot ensure
      that a User will complete a transaction nor can it verify the identity or
      Personal Data entered by Users does not guarantee the truthfulness of
      third party advertising appearing on the APP or on the Site and shall not
      be responsible for any correspondence or contracts that the User enters
      into with such third parties or with other Users.
    </p>
    <h3>12: Sistem failures</h3>
    <p>
      THE CRANE CLUB is not responsible for any damage, harm or loss to the User
      caused by system, server or Internet failures. THE CRANE CLUB will not be
      responsible for any virus that may infect the User's computer as a result
      of access, use or examination of its website or as a result of any
      transfer of data, files, images, text, or audio contained therein. Users
      will NOT be able to impute any responsibility or demand payment for loss
      of profits, by virtue of damages resulting from technical difficulties or
      failures in the systems or Internet. THE CRANE CLUB does not guarantee
      continuous or uninterrupted access and use of its site. The system may
      eventually be unavailable due to technical difficulties or Internet
      failures, or any other circumstance beyond THE CRANE CLUB's control; in
      such cases, we will try to restore it as quickly as possible without any
      liability. THE CRANE CLUB shall not be liable for any error or omission
      contained in its APP or its website.
    </p>
    <h3>13: Fees</h3>
    <p>
      THE CRANE CLUB will charge fees for the registration and publication, sale
      or purchase or rental of equipment through THE CRANE CLUB in accordance
      with the RATES Annex published in the APP or on the Website, which may be
      modified upon prior notice through the APP or Website to Users 10 days in
      advance.
    </p>
    <p>
      In all cases, the charges vary according to the exposure of the
      advertisement in the APP or on the Website.
    </p>
    <p>
      The User agrees to pay THE CRANE CLUB the charges for posting, selling or
      any other service provided by THE CRANE CLUB that has a fee in effect.
    </p>
    <p>
      THE CRANE CLUB reserves the right to modify, change, add, or delete the
      fees in effect, at any time, which will be notified to Users. However, THE
      CRANE CLUB may temporarily modify the Rates Policy and the rates for its
      services due to promotions, being these modifications effective when the
      promotion is made public or the announcement is made.
    </p>
    <h3>14: Reputation system</h3>
    <p>
      Because the verification of the identity of Users on the Internet is
      difficult, THE CRANE CLUB cannot confirm the intended identity of each
      User. Therefore, the User has a User reputation system that is
      periodically updated based on data related to their activity on the site
      or APP and the comments entered by Users according to the operations they
      have carried out. Both those Users who have offered the acquisition of a
      product and / or service and the seller must enter a rating informing
      about the completion or not of the operation; They can also enter a
      comment if they wish. This reputation system will also consist of a space
      where Users can comment on the ratings received and access them. Said
      comments will be included under the sole responsibility of the Users who
      issue them.
    </p>
    <p>
      Since the ratings and comments are made by the Users, these will be
      included under the sole responsibility of the Users who issue them. THE
      CRANE CLUB has no obligation to verify the truthfulness or accuracy of the
      same and is not responsible for the statements made therein by any User,
      for the offers of purchases or sales that Users make taking them into
      account or for the trust placed in the ratings of the counterparty or for
      any other comment expressed within the APP or site or through any other
      means, including email. THE CRANE CLUB reserves the right to edit and/or
      delete any comments deemed inappropriate or offensive. THE CRANE CLUB
      maintains the right to exclude those Users who are the subject of negative
      comments from other sources. For more information on the rating system,
      please refer to our THE CRANE CLUB Reputation System.
    </p>
    <h3>15: Intellectual property</h3>
    <p>
      THE CRANE CLUB reserves all rights, including intellectual and industrial
      property rights, associated with the services of THE CRANE CLUB, its
      applications, websites, the contents of its screens, programs, databases,
      networks, codes, development, software, architecture, hardware, contents,
      information, technology, integration phases, functionalities, domains,
      files that allow the User to access and create his/her Account, sales
      tools, trademarks, patents, copyrights, industrial designs and models,
      commercial names, among others, and declares that they are protected by
      national and international laws in force.
    </p>
    <p>
      In no case will it be understood that the User shall have any kind of
      right over them except to use the service of THE CRANE CLUB in accordance
      with the provisions of these General Terms and Conditions. The improper
      use or contrary to current regulations of the intellectual and industrial
      property rights of THE CRANE CLUB, as well as its total or partial
      reproduction, is prohibited, unless expressly authorized in writing by THE
      CRANE CLUB.
    </p>
    <p>
      Users may not communicate that the products or services they offer are
      sponsored, promoted, produced, offered and / or sold by THE CRANE CLUB and
      must refrain from performing any act that could cause damage, loss of
      reputation, or decrease in the value of the intellectual and industrial
      property rights of THE CRANE CLUB.
    </p>
    <p>
      The APP or the site may contain links to third party websites. Since THE
      CRANE CLUB has no control over such sites, it shall not be responsible for
      the contents, materials, actions and/or services provided by such sites,
      nor for any damage or loss caused directly or indirectly by the use of
      such sites. The presence of links to other websites does not imply a
      partnership, relationship, approval, endorsement of THE CRANE CLUB with
      such sites and their contents.
    </p>
    <h3>16: Indemnity</h3>
    <p>
      The User shall hold harmless THE CRANE CLUB, as well as its officers and
      employees, for any claim initiated by other Users, third parties or by any
      Organization, related to their activities on the APP or on the Site,
      compliance and / or non-compliance of the General Terms and Conditions or
      other Policies, as well as regarding any violation of laws or rights of
      third parties.
    </p>
    <h3>17: Governing Law and Jurisdiction</h3>
    <p>
      These GTC shall be governed in all respects by the laws of the State of LA
      FLORIDA, United States of America.
    </p>
    <p>
      Any controversy derived from the present agreement, its existence,
      validity, interpretation, scope or fulfillment, will be submitted to the
      applicable laws and to the competent Courts of La Florida, USA.
    </p>
    <p>
      All information provided by Users shall be taken as true for tax purposes.
    </p>
    <h3>18: Address</h3>
    <p>
      THE CRANE CLUB (7901 4th St N Ste 300, St. Petersburg, FL 33702, USA), and
      the following email addresses are set as the address of THE CRANE CLUB:
      hello@thecraneclub.com
    </p>
  </div>
);
