import React, { forwardRef } from 'react';
import CInput from '@material-ui/core/Input';
import InputAdornment from '@mui/material/InputAdornment';
import cn from 'classnames';
import NumberFormat from 'react-number-format';
import { useTranslation } from 'react-i18next';

import './index.scss';

export const Input = forwardRef(
  (
    {
      type = 'text',
      onChange,
      className,
      secondary,
      prefix,
      suffix,
      register,
      errorMessage,
      error,
      isMoney,
      disabled,
      ...rest
    },
    ref
  ) => {
    const { t } = useTranslation();

    const preventMinus = (e) => {
      if (e.code === 'Minus' || e.code === 'KeyE') {
        e.preventDefault();
      }
    };

    return (
      <div className={cn({ 'pointer-events-none': disabled }, className)}>
        {secondary ? (
          <input
            className={cn('cb-input', { error })}
            type={type}
            onKeyPress={(e) => type === 'number' && preventMinus(e)}
            ref={ref}
            onChange={(e) => {
              onChange && onChange(e.target.value);
            }}
            {...register}
            {...rest}
          />
        ) : type === 'number' ? (
          <NumberFormat
            value={rest.value}
            thousandSeparator="."
            decimalSeparator=","
            displayType={'text'}
            renderText={(value, props) => (
              <CInput
                className={cn({ error })}
                ref={ref}
                startAdornment={
                  prefix && (
                    <InputAdornment position="start">{prefix}</InputAdornment>
                  )
                }
                endAdornment={
                  suffix && (
                    <InputAdornment position="end">{suffix}</InputAdornment>
                  )
                }
                onKeyPress={(e) => type === 'number' && preventMinus(e)}
                onChange={(e) => {
                  onChange &&
                    onChange(
                      e.target.value.replace(/\D/g, '').split('.').join('')
                    );
                }}
                {...register}
                {...rest}
                {...props}
                value={value}
              />
            )}
          />
        ) : (
          <CInput
            className={cn({ error })}
            type={type}
            ref={ref}
            startAdornment={
              prefix && (
                <InputAdornment position="start">{prefix}</InputAdornment>
              )
            }
            onKeyPress={(e) => type === 'number' && preventMinus(e)}
            onChange={(e) => {
              onChange && onChange(e.target.value);
            }}
            {...register}
            {...rest}
          />
        )}
        {error && (
          <span className="text-2xs text-yellow flex justify-end mt-1">
            {errorMessage ? errorMessage : t(`form.${error.type}`)}
          </span>
        )}
      </div>
    );
  }
);
