import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

export const AppUrlListener = () => {
  // const history = useHistory();
  useEffect(() => {
    // App.addListener('appUrlOpen', (event) => {
    //   // Example url: https://beerswift.app/tabs/tab2
    //   // slug = /tabs/tab2
    //   const slug = event.url.split('.app').pop();
    //   if (slug) {
    //     history.push(slug);
    //   }
    //   // If no match, do nothing - let regular routing
    //   // logic take over
    // });
  }, []);

  return null;
};
