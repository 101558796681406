import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { formatDate } from 'utils/formatter';
import { ReloadContext } from 'contexts/ReloadContext';
import { useFilterQueryParams, usePageQueryParams } from 'hooks';

export const PublicationTitle = ({ publication }) => {
  const { t } = useTranslation();

  const { setParams } = useFilterQueryParams();
  const { removePage } = usePageQueryParams();
  const { setReload } = useContext(ReloadContext);

  const handleOnClickCategory = () => {
    setParams({ categories: [publication?.category.id] }, '/machinery');
    removePage();
    setReload(true);
  };
  return (
    <h1 className="text-xl md:text-xxl uppercase pb-5">
      <span
        onClick={handleOnClickCategory}
        className="block cursor-pointer hover:text-yellow text-xs text-gray leading-5 tracking-normal"
      >
        {`${publication?.type?.name} - ${publication?.category?.name}`}
      </span>
      <strong>{publication?.customBrand || publication?.brand?.name} </strong>
      {publication?.model}
      <span className="block text-2xs text-gray leading-5 tracking-normal capitalize">
        {t('published')} {formatDate(publication?.createdAt)}
      </span>
    </h1>
  );
};
