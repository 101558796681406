import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Loading } from 'components';
import { Route, Redirect } from 'react-router-dom';
import { authRoutes } from '../allRoutes';
import { useReturnURLQueryParam } from 'hooks';

import { UserContext } from 'contexts/UserContext';

const AUTH_ROUTES = authRoutes.map((r) => r.path);

const Authmiddleware = ({
  component: Component,
  isAuthProtected = false,
  path,
  availablePreLanch = false,
  onlyCompany = false,
  exact = false,
  ...rest
}) => {
  const { user } = useContext(UserContext);
  const { getR } = useReturnURLQueryParam();
  let returnURL = decodeURIComponent(getR());

  let searchParams;
  if (returnURL.includes('?')) {
    const divide = returnURL.split('?');
    returnURL = divide[0];
    searchParams = divide[1];
  }
  return (
    <Route
      exact={exact}
      {...rest}
      render={(props) => {
        if (typeof user === 'undefined') {
          return <Loading />;
        } else if (isAuthProtected && !user) {
          return (
            <Redirect
              to={{
                pathname: '/auth/login',
                state: { from: props?.location }
              }}
            />
          );
        } else if (!isAuthProtected && user && AUTH_ROUTES.includes(path)) {
          return (
            <Redirect
              to={{
                pathname: returnURL || '/',
                search: searchParams ? `?${searchParams}` : null,
                state: { from: props?.location }
              }}
            />
          );
        } else if (onlyCompany && !user.isCompany) {
          return <Redirect to={{ pathname: '/' }} />;
        }
        return user?.isNew ? (
          <Redirect
            to={{ pathname: '/dashboard/my-cv', state: { register: true } }}
          />
        ) : (
          <Component {...props} />
        );
      }}
    />
  );
};

Authmiddleware.propTypes = {
  isAuthProtected: PropTypes.bool,
  component: PropTypes.func,
  exact: PropTypes.bool
};

export default Authmiddleware;
