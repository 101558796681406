import React from 'react';
import { Switch } from 'react-router-dom';
import AuthMiddleware from 'routes/middleware/AuthMiddleware';

import { editRoutes } from 'routes/allRoutes';

import { UploadLayout } from 'layouts';

export const EditRoutes = () => (
  <UploadLayout>
    <Switch>
      {editRoutes.map((route, idx) => (
        <AuthMiddleware
          path={route.path}
          component={route.component}
          exact={route.exact}
          key={idx}
          onlyCompany={route?.onlyCompany}
          isAuthProtected
        />
      ))}
    </Switch>
  </UploadLayout>
);
