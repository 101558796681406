import React from 'react';
import { Icon, Tooltip } from 'components';
import { useTranslation } from 'react-i18next';

export const VerificatedCompanyBadge = ({
  isVerified,
  className,
  size = 24
}) => {
  const { t } = useTranslation();

  return isVerified ? (
    <Tooltip title={t('thisCompanyIsVerifiedByTheCraneClub')} placement="right">
      <div className={className}>
        <Icon name="VerifiedBadget" width={size} height={size} />
      </div>
    </Tooltip>
  ) : (
    <></>
  );
};
