import axios from 'utils/axios';

export const getFavourites = async (page = 0, size = 12) => {
  try {
    const { data } = await axios.get(
      `api/favourites?page=${page}&size=${size}`
    );
    return data;
  } catch (error) {
    if (process.env.REACT_APP_DEV_MODE !== 'true') {
      console.log('ERROR: ', error.message);
    }
    throw error;
  }
};

export const saveFavourite = async (publicationId) => {
  try {
    const res = await axios.post('api/favourites', { publicationId });
    return res;
  } catch (error) {
    if (process.env.REACT_APP_DEV_MODE !== 'true') {
      console.log('ERROR: ', error.message);
    }
    throw error;
  }
};
