export const publicationFieldsType = {
  operatingWeight: 'weight',
  maximumCapacity: 'weight',
  payload: 'weight',
  maximumDiggingDepth: 'length',
  compactionWidth: 'compaction',
  operatingWidth: 'length',
  bladeWidth: 'length',
  verticalReach: 'length',
  deploymentHeight: 'length',
  reach: 'length',
  reachTower: 'length',
  maximumLiftHeight: 'length',
  workHeight: 'length',
  drillingDiameter: 'length',
  waterVolume: 'volume',
  drumVolume: 'volume',
  bucketCapacity: 'volume',
  enginePower: 'power',
  power: 'power',
  heaped: 'volume',
  axles: ''
};

export const changeUnit = {
  kgsToPounds: (kgs) => (kgs * 2.2).toFixed(0),
  poundsToKgs: (pounds) => (pounds / 2.2).toFixed(0),
  tnsToUSTons: (tons) => (tons * 1.1023).toFixed(0),
  usTnsToTons: (usTons) => (usTons / 1.1023).toFixed(0),
  mmToIn: (mm) => (mm * 0.039).toFixed(0),
  inToMm: (inc) => (inc / 0.039).toFixed(0),
  m3ToYd3: (m3) => (m3 * 1.308).toFixed(0),
  yd3ToM3: (yd3) => (yd3 / 1.308).toFixed(0),
  mtToft: (mt) => (mt * 3.281).toFixed(0),
  ftToMt: (ft) => (ft / 3.281).toFixed(0)
};
