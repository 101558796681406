import axios from 'utils/axios';

export const getUnfollows = async (page = 0, size = 3) => {
  try {
    const res = await axios.get(
      `api/social/follows/unfollow?page=${page}&size=${size}`
    );
    return res.data;
  } catch (error) {
    console.log(error.message);
  }
};

export const getFollowing = async (page = 0, size = 3) => {
  try {
    const res = await axios.get(
      `api/social/follows/following?page=${page}&size=${size}`
    );
    return res.data;
  } catch (error) {
    console.log(error.message);
  }
};

export const followUser = async (userId) => {
  try {
    await axios.post(`api/social/follows`, { following: userId });
  } catch (error) {
    console.log(error.message);
  }
};

export const getFollowingByUser = async (userId, page = 0, size = 100) => {
  try {
    const res = await axios.get(
      `api/social/follows/following/${userId}?page=${page}&size=${size}`
    );
    return res.data;
  } catch (error) {
    console.log(error.message);
  }
};

export const getFollowersByUser = async (userId, page = 0, size = 100) => {
  try {
    const res = await axios.get(
      `api/social/follows/followers/${userId}?page=${page}&size=${size}`
    );
    return res.data;
  } catch (error) {
    console.log(error.message);
  }
};
