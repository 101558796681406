import { URL_REGEX } from 'utils/constants';

export const emailValidator = (value) =>
  /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value);

export const isValidURL = (string) => {
  let url;
  try {
    url = new URL(string);
  } catch (_) {
    return false;
  }
  return url.protocol === 'http:' || url.protocol === 'https:';
};

export const isValidWebsite = (string = '') => string.match(URL_REGEX);

export const isValidImage = (file) => {
  return file.name?.toLowerCase()?.match(/\.(jpg|jpeg|png|webp)$/) || false;
};

export const isValidAsset = (file) => {
  return (
    file.name?.toLowerCase()?.match(/\.(jpg|jpeg|png|mp4|mov|webp)$/) || false
  );
};

export const isValidFile = (file) => {
  return file.name.toLowerCase().match(/\.(pdf|csv|txt|xlsx|odt|tsv|doc)$/);
};

export const isTypeVideo = (type) => {
  return type.includes('video');
};

export const checkHttpURL = (url, protocol) => {
  if (!url || url.length === 0) {
    return '';
  }
  if (!url.startsWith('http')) {
    return `${protocol}://${url}`.toLowerCase();
  } else {
    return url.toLowerCase();
  }
};
