import React from 'react';
import { Icon } from 'components';
import ReactPlayer from 'react-player';
import './index.scss';

export const FilesPreview = ({ files, removeFile }) => {
  return (
    <div className="flex my-2">
      {files.map(({ url, file }, index) => (
        <div
          key={url}
          className="relative w-20 h-20 rounded-md mr-2 border border-yellow"
        >
          <Icon
            name="Trash"
            className="absolute right-1 top-1 cursor-pointer z-1"
            width={15}
            height={15}
            center
            canvasWidth={28}
            canvasHeight={28}
            rounded
            onClick={() => removeFile(index)}
          />
          {file.type.includes('video') ? (
            <ReactPlayer
              url={url}
              width="100%"
              height="100%"
              controls={false}
            />
          ) : (
            <div
              className="img-preview rounded-md"
              style={{ backgroundImage: `url(${url})` }}
            />
          )}
        </div>
      ))}
    </div>
  );
};
