import React, { useState, useEffect, useContext } from 'react';
import { PublicationCard } from '@estudio-nk/the-crane-club-client-lib';
import { publicationToURL } from 'utils/formatter';
import { getLikesByCompany } from 'api/likes';
import { UserContext } from 'contexts/UserContext';
import { useModal } from 'hooks/useModal';
import { LikesModal, LoginModal } from 'components';
import { saveLikes } from 'api/likes';
import { saveFavourite } from 'api/favourites';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { Capacitor } from '@capacitor/core';

export const PublicationCardContainer = ({
  publication,
  row = false,
  className,
  containerClasses
}) => {
  const { t, i18n } = useTranslation();
  const { visible, toggleModal } = useModal();
  const [likes, setLikes] = useState([]);
  const [likesCount, setLikesCount] = useState(
    parseInt(publication?.likesCount)
  );
  const { user } = useContext(UserContext);

  useEffect(() => {
    if (visible && publication) {
      loadLikes();
    }
  }, [visible]);

  const loadLikes = async () => {
    try {
      const { rows } = await getLikesByCompany(publication?.id);
      const likes = rows.map((like) => {
        return {
          isFollowed: like?.user?.isFollowed,
          ...like
        };
      });
      setLikes(likes);
    } catch (err) {}
  };

  const handleOnLike = async () => {
    if (!user) {
      toggleModal();
      return false;
    }
    try {
      const { data } = await saveLikes(publication?.id);
      if (data.msg === 'like removed') {
        setLikesCount(likesCount - 1);
      } else {
        setLikesCount(likesCount + 1);
      }
      return true;
    } catch (error) {
      toast.error(t('notifications.genericError'));
      return false;
    }
  };

  const handleOnFavourite = async () => {
    if (!user) {
      toggleModal();
      return false;
    }
    try {
      await saveFavourite(publication?.id);
      return true;
    } catch (error) {
      toast.error(t('notifications.genericError'));
      return false;
    }
  };

  const publicationURL = publicationToURL(publication);

  return publication ? (
    <>
      <PublicationCard
        publication={publication}
        containerClasses={containerClasses}
        colorVariant="yellow"
        app={Capacitor.isNativePlatform()}
        publicationURL={publicationURL}
        row={row}
        className={className}
        likesCount={likesCount}
        url={`${window.location.origin}/${i18n.language}${publicationURL}`}
        onLikeClick={handleOnLike}
        onFavouriteClick={handleOnFavourite}
        toggleModalLikes={toggleModal}
      />
      {user ? (
        <LikesModal visible={visible} toggle={toggleModal} likes={likes} />
      ) : (
        <LoginModal
          visible={visible}
          toggleModal={toggleModal}
          title={t('almostThere')}
          text={t('loginRequiredFavAndLike')}
        />
      )}
    </>
  ) : null;
};
