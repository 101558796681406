import ReactQuill from 'react-quill';
import cn from 'classnames';
import 'react-quill/dist/quill.snow.css';
import './index.scss';

const formatsQuill = [
  'header',
  'font',
  'size',
  'bold',
  'italic',
  'underline',
  'strike',
  'blockquote',
  'list',
  'bullet',
  'indent',
  'align',
  'link',
  'image',
  'background',
  'color',
  'emoji'
];

export const SecondaryEditor = ({
  value = '',
  onChange = () => {},
  className
}) => {
  return (
    <ReactQuill
      className={cn('cb-secondary-input', className)}
      value={value}
      onChange={onChange}
      formats={formatsQuill}
    />
  );
};
