import { useState, useEffect } from 'react';
import {
  CollapsableCard,
  Divider,
  Input,
  Dropdown,
  Textarea,
  Radio
} from 'components';
import { Button } from '@estudio-nk/the-crane-club-client-lib';
import { MONTHS } from 'utils/constants';
import { years } from 'utils/date';
import { useTranslation } from 'react-i18next';
import { useForm, Controller } from 'hooks';
import { toast } from 'react-toastify';

export const JobContainer = ({ value, handleSave, handleDelete, roles }) => {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isCurrent, setIsCurrent] = useState(!value?.toMonth);

  useEffect(() => {
    if (isCurrent) {
      unregister('toMonth');
      unregister('toYear');
    }
  }, [isCurrent]);

  const {
    handleSubmit,
    register,
    unregister,
    control,
    reset,
    formState: { isValid }
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      role: value?.role,
      isCompany: value?.isCompany,
      country: value?.country,
      sinceMonth: value?.sinceMonth,
      sinceYear: value?.sinceYear,
      toMonth: value?.toMonth,
      toYear: value?.toYear,
      description: value?.description
    }
  });

  const { t } = useTranslation();

  const submit = async (values) => {
    if (
      values.sinceYear > values.toYear ||
      (values.sinceYear === values.toYear && values.sinceMonth > values.toMonth)
    )
      toast.error(t('pleaseReviewTheDates'));
    else {
      setLoading(true);
      await handleSave(values);
      setLoading(false);
      reset();
      setOpen(false);
    }
  };

  return (
    <div>
      {value && (
        <CollapsableCard
          title={roles.find((role) => role.value === value?.role)?.text}
          subtitle={`${MONTHS[value?.sinceMonth]} ${value?.sinceYear} - ${
            !value?.toMonth
              ? t('currently')
              : `${MONTHS[value?.toMonth]} ${value?.toYear}`
          }`}
          onEdit={() => setOpen(!open)}
          onDelete={handleDelete}
        />
      )}

      {(open || !value) && (
        <form onSubmit={handleSubmit(submit)}>
          <div className="flex items-center mt-2">
            <span className="text-sm font-gray mr-3">
              {t('isYourCurrentJob')}
            </span>
            <Radio
              items={[
                {
                  value: 'yes',
                  label: t('yes')
                },
                {
                  value: 'no',
                  label: t('no')
                }
              ]}
              value={isCurrent ? 'yes' : 'no'}
              setValue={(value) => setIsCurrent(value === 'yes')}
            />
          </div>
          <div className="grid-data mt-3">
            <label>{t('role')}</label>
            <Controller
              control={control}
              name="role"
              rules={{ required: true }}
              render={({ field }) => (
                <Dropdown
                  className="border-none mr-3"
                  placeholder={t('role')}
                  items={roles}
                  {...field}
                />
              )}
            />
            <Divider />
            <label>{t('company')}</label>
            <Input
              placeholder={t('company')}
              register={register('company', { required: true })}
            />
            <Divider />
            <label>{t('country')}</label>
            <Input
              placeholder={t('country')}
              register={register('country', { required: true })}
            />
            <Divider />
            <label>{t('since')}</label>
            <div className="flex py-2 items-center">
              <Controller
                control={control}
                name="sinceMonth"
                rules={{ required: true }}
                render={({ field }) => (
                  <Dropdown
                    className="border-none mr-3"
                    placeholder={t('month')}
                    inputClasses="text-sm"
                    items={MONTHS.map((m, i) => ({
                      text: t(`months.${m}`),
                      value: i
                    }))}
                    {...field}
                  />
                )}
              />
              <Controller
                control={control}
                name="sinceYear"
                rules={{ required: true }}
                render={({ field }) => (
                  <Dropdown
                    placeholder={t('year')}
                    inputClasses="text-sm p-2"
                    className="border-none"
                    items={years}
                    {...field}
                  />
                )}
              />
            </div>
            {!isCurrent && (
              <>
                <Divider />
                <label>{t('to')}</label>
                <div className="flex py-2 items-center">
                  <Controller
                    control={control}
                    name="toMonth"
                    rules={{ required: true }}
                    render={({ field }) => (
                      <Dropdown
                        className="border-none mr-3"
                        placeholder={t('month')}
                        inputClasses="text-sm"
                        items={MONTHS.map((m, i) => ({
                          text: t(`months.${m}`),
                          value: i
                        }))}
                        {...field}
                      />
                    )}
                  />
                  <Controller
                    control={control}
                    name="toYear"
                    rules={{ required: true }}
                    render={({ field }) => (
                      <Dropdown
                        className="border-none"
                        inputClasses="text-sm"
                        placeholder={t('year')}
                        items={years}
                        {...field}
                      />
                    )}
                  />
                </div>
              </>
            )}
            <Divider />
            <label className="mt-3">{t('description')}</label>
          </div>
          <Controller
            control={control}
            name="description"
            render={({ field }) => (
              <Textarea className="mt-3 text-xs h-32" {...field} />
            )}
          />

          <div className="flex justify-end mt-3">
            <Button
              className="w-full md:w-52"
              type="submit"
              disabled={!isValid}
              loading={loading}
              colorVariant="yellow"
            >
              {t('save')}
            </Button>
          </div>
        </form>
      )}
    </div>
  );
};
