import React, { useState, useEffect } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper.min.css';
import 'swiper/components/navigation/navigation.min.css';
import 'swiper/components/thumbs/thumbs.min.css';
import './index.scss';
import SwiperCore, {
  Navigation,
  Thumbs,
  Keyboard,
  Pagination
} from 'swiper/core';
import { useModal } from 'hooks';
import { Overlay } from '@estudio-nk/the-crane-club-client-lib';
import { YoutubeEmbed, Icon } from 'components';
import { getYouTubeVideoID } from 'utils/youtube';

SwiperCore.use([Navigation, Thumbs, Keyboard, Pagination]);

export const PublicationMedia = ({ files, videoURL }) => {
  const [thumbsSwiper, setThumbsSwiper] = useState(null);
  const [showSwiper, setShowSwiper] = useState(false);
  const { toggleModal, visible } = useModal();
  const [swiperOverlay, setSwiperOverlay] = useState(null);
  const [actualIndex, setActualIndex] = useState(0);
  const videoId = getYouTubeVideoID(videoURL);
  const sortedFiles = files?.sort((a, b) => (a.order > b.order ? 1 : -1));

  const handleOnSlideChange = (index) => {
    setActualIndex(index);
  };

  useEffect(() => {
    swiperOverlay?.updateAutoHeight(0);
    setTimeout(() => {
      swiperOverlay?.updateAutoHeight(0);
    }, 1000);
  }, [swiperOverlay]);

  const slides = sortedFiles?.map((file, i) => (
    <SwiperSlide key={i}>
      <img
        src={file.url}
        alt={`Machine media ${i + 1}`}
        className="object-contain mx-auto rounded-lg"
      />
    </SwiperSlide>
  ));

  const thumbnails = sortedFiles?.map((file, i) => (
    <SwiperSlide key={i}>
      <img
        src={file.url}
        alt={`Machine media ${i + 1}`}
        className="object-cover"
      />
    </SwiperSlide>
  ));

  useEffect(() => {
    setTimeout(() => {
      setShowSwiper(true);
    }, 500);
  }, []);

  const renderSlides = (reRender) => (
    <>
      <Swiper
        style={{
          '--swiper-navigation-color': 'rgb(67, 67, 67)',
          '--swiper-pagination-color': 'rgb(67, 67, 67)'
        }}
        spaceBetween={10}
        navigation={reRender}
        onActiveIndexChange={(swiper) =>
          handleOnSlideChange(swiper.activeIndex)
        }
        thumbs={{ swiper: thumbsSwiper }}
        className="main-swiper mb-6 bg-black-light rounded-lg"
      >
        {slides}
        {videoURL && (
          <SwiperSlide>
            <YoutubeEmbed embedId={videoId} />
          </SwiperSlide>
        )}
        <Icon
          onClick={toggleModal}
          name="FullScreen"
          width={18}
          height={18}
          rounded={true}
          center={true}
          canvasWidth={40}
          canvasHeight={40}
          className="absolute bottom-5 right-5 cb-icon z-20"
        />
      </Swiper>

      <Swiper
        onSwiper={setThumbsSwiper}
        spaceBetween={24}
        slidesPerView={4}
        freeMode={true}
        watchSlidesVisibility={true}
        watchSlidesProgress={true}
        className="thumbs-swiper"
      >
        {thumbnails}
        {videoURL && (
          <SwiperSlide className="flex items-center justify-center">
            <div
              className="bg"
              style={{
                backgroundImage: `url(https://img.youtube.com/vi/${videoId}/0.jpg)`
              }}
            ></div>
            <Icon
              name="Play"
              width={44}
              height={44}
              canvasWidth={44}
              canvasHeight={44}
              className="absolute top-2/4 left-2/4 z-1 transform -translate-x-1/2 -translate-y-1/2"
            />
          </SwiperSlide>
        )}
      </Swiper>
    </>
  );

  return (
    <div className="cb-media">
      {renderSlides(showSwiper)}

      <Overlay visible={visible} toggle={toggleModal}>
        <Swiper
          style={{
            '--swiper-navigation-color': 'rgb(67, 67, 67)',
            '--swiper-pagination-color': 'rgb(67, 67, 67)'
          }}
          className="publication-fullscreen-swiper rounded-lg"
          onSwiper={setSwiperOverlay}
          autoHeight={true}
          slidesPerView={1}
          initialSlide={actualIndex}
          keyboard={{ enabled: true }}
          navigation={true}
        >
          {slides}
          {videoURL && (
            <SwiperSlide>
              <YoutubeEmbed embedId={videoId} />
            </SwiperSlide>
          )}
        </Swiper>
      </Overlay>
    </div>
  );
};
