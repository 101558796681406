import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { changeUnit, publicationFieldsType } from 'utils/utils';
import cn from 'classnames';
import './index.scss';

export const PublicationConfigurationTable = ({
  configuration,
  unit = 'metric',
  weightUnitType = 'kg'
}) => {
  const { t } = useTranslation();
  const [selectedUnit, setSelectedUnit] = useState(unit);

  const getValue = (field, value) => {
    if (typeof value === 'boolean') {
      return value ? t('yes') : t('no');
    } else if (publicationFieldsType[field] && unit !== selectedUnit) {
      switch (publicationFieldsType[field]) {
        case 'weight':
          return weightUnitType === 'kg'
            ? selectedUnit === 'metric'
              ? changeUnit.poundsToKgs(value)
              : changeUnit.kgsToPounds(value)
            : selectedUnit === 'metric'
            ? changeUnit.usTnsToTons(value)
            : changeUnit.tnsToUSTons(value);
        case 'compaction':
          return selectedUnit === 'metric'
            ? changeUnit.inToMm(value)
            : changeUnit.mmToIn(value);
        case 'volume':
          return selectedUnit === 'metric'
            ? changeUnit.yd3ToM3(value)
            : changeUnit.m3ToYd3(value);
        case 'length':
          return selectedUnit === 'metric'
            ? changeUnit.ftToMt(value)
            : changeUnit.mtToft(value);
        default:
          return value;
      }
    }
    return value;
  };

  return (
    <div className="py-6">
      <div className="flex text-xs mb-3 justify-end">
        <span
          className={cn(
            selectedUnit === 'metric'
              ? 'underline text-yellow pointer-events-none'
              : 'cursor-pointer text-silver'
          )}
          onClick={() => setSelectedUnit('metric')}
        >
          {t('metric')}
        </span>
        <span className="text-silver">&nbsp;-&nbsp;</span>
        <span
          className={cn(
            selectedUnit === 'imperial'
              ? 'underline text-yellow pointer-events-none'
              : 'cursor-pointer text-silver'
          )}
          onClick={() => setSelectedUnit('imperial')}
        >
          {t('imperial')}
        </span>
      </div>
      <div className="cb-config-table  flex flex-wrap">
        {configuration &&
          Object.entries(configuration).map((k, i) => (
            <div
              key={i}
              className="w-full md:w-1/2 config-table-cell text-sm py-1 px-5 flex flex-wrap"
            >
              <strong className="whitespace-nowrap">{t(k[0])}</strong>:
              <span>&nbsp;</span>
              <div className="whitespace-nowrap">
                <span className="capitalize">{getValue(k[0], k[1])}</span>
                {publicationFieldsType[k[0]] && (
                  <>
                    <span>
                      {publicationFieldsType[k[0]] === 'weight'
                        ? ` ${t(
                            `unitAbbreviations.${weightUnitType}.${selectedUnit}`
                          )}`
                        : ` ${t(
                            `unitAbbreviations.${
                              publicationFieldsType[k[0]]
                            }.${selectedUnit}`
                          )}`}
                    </span>
                  </>
                )}
              </div>
            </div>
          ))}
      </div>
    </div>
  );
};
