import React from 'react';
import cn from 'classnames';
import CSlider from '@material-ui/core/Slider';

import './index.scss';

export const Slider = ({
  value,
  className,
  onChange,
  min,
  max,
  step = 1,
  yellowDot,
  hideLabel = false
}) => {
  const handleChange = (_, newValue) => {
    onChange(newValue);
  };

  return (
    <div className={cn('cb-slider', className, { yellowDot, hideLabel })}>
      <CSlider
        value={value}
        onChange={handleChange}
        valueLabelDisplay="auto"
        aria-labelledby="range-slider"
        step={step}
        min={min}
        max={max}
      />
    </div>
  );
};
