import { useState } from 'react';
import { CollapsableCard, Divider, Input, Dropdown } from 'components';
import { Button } from '@estudio-nk/the-crane-club-client-lib';
import { useTranslation } from 'react-i18next';
import { useForm, Controller } from 'hooks';
import { toast } from 'react-toastify';

export const LanguageContainer = ({ value, handleSave, handleDelete }) => {
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);

  const {
    handleSubmit,
    register,
    control,
    reset,
    formState: { isValid }
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      language: value?.language,
      level: value?.level
    }
  });

  const { t } = useTranslation();

  const submit = async (values) => {
    if (
      values.fromYear > values.toYear ||
      (values.fromYear === values.toYear && values.fromMonth > values.toMonth)
    )
      toast.error(t('pleaseReviewTheDates'));
    else {
      setLoading(true);
      await handleSave(values);
      reset();
      setLoading(false);
      setOpen(false);
    }
  };

  return (
    <div>
      {value && (
        <CollapsableCard
          title={value?.language}
          subtitle={value?.level}
          onEdit={() => setOpen(!open)}
          onDelete={handleDelete}
        />
      )}
      {(open || !value) && (
        <form onSubmit={handleSubmit(submit)}>
          <div className="grid-data mt-3">
            <label>{t('language')}</label>
            <Input
              placeholder={t('language')}
              register={register('language', { required: true })}
            />
            <Divider />
            <label>{t('level')}</label>
            <Controller
              control={control}
              name="level"
              rules={{ required: true }}
              render={({ field }) => (
                <Dropdown
                  className="border-none overflow-hidden"
                  placeholder={t('level')}
                  inputClasses="text-base font-light"
                  items={[
                    { value: 'basic', text: t('basic') },
                    { value: 'intermediate', text: t('intermediate') },
                    { value: 'advanced', text: t('advanced') },
                    { value: 'native', text: t('native') }
                  ]}
                  {...field}
                />
              )}
            />
          </div>

          <div className="flex justify-end mt-3">
            <Button
              className="w-full md:w-52"
              type="submit"
              disabled={!isValid}
              loading={loading}
              colorVariant="yellow"
            >
              {t('save')}
            </Button>
          </div>
        </form>
      )}
    </div>
  );
};
