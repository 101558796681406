import React, { createContext, useState } from 'react';

export const ReloadContext = createContext();

const Provider = ({ children }) => {
  const [reload, setReload] = useState(true);

  return (
    <ReloadContext.Provider value={{ reload, setReload }}>
      {children}
    </ReloadContext.Provider>
  );
};

export default Provider;
