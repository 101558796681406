import React, { useContext } from 'react';
import { Button } from '@estudio-nk/the-crane-club-client-lib';
import { useTranslation } from 'react-i18next';
import { ChatButton, FollowButton, ProfileNav } from 'components';
import { UserContext } from 'contexts/UserContext';
import cn from 'classnames';

export const ProfileActionsMobile = ({ profile, className }) => {
  const { t } = useTranslation();
  const { user } = useContext(UserContext);

  return (
    <div className={cn(className)}>
      {user?.uid !== profile?.uid ? (
        <div className="flex items-center">
          <ChatButton userId={profile.uid} size="sm" className="flex-1 mr-3" />
          <FollowButton user={profile} size="sm" className="flex-1" />
        </div>
      ) : (
        <div className="flex items-center">
          <Button linkTo="/dashboard/settings" size="sm" colorVariant="yellow">
            {t('editMyProfile')}
          </Button>
        </div>
      )}
      <ProfileNav profile={profile} />
    </div>
  );
};
