import React, { useContext } from 'react';
import { Modal, AvatarUser, FollowButton } from 'components';
import { useTranslation } from 'react-i18next';
import { UserContext } from 'contexts/UserContext';

export const LikesModal = ({ visible, likes, toggle }) => {
  const { t } = useTranslation();
  const { user } = useContext(UserContext);

  return (
    <Modal
      visible={visible}
      title={t('likes')}
      toggle={toggle}
      children={
        <div className="pb-10 w-max" style={{ minWidth: '300px' }}>
          {likes.length > 0 ? (
            likes?.map(({ user: profile, isFollowed }, index) => (
              <div
                className="flex whitespace-nowrap justify-between items-center text-xs mt-2"
                key={index}
              >
                <AvatarUser
                  className="mr-10"
                  user={profile}
                  variant="no-text"
                />
                {user?.uid !== profile?.uid && (
                  <FollowButton
                    className="w-20"
                    size="sm"
                    user={{ ...profile, isFollowed }}
                  />
                )}
              </div>
            ))
          ) : (
            <div className="animate-pulse">
              <div className="w-full h-11 bg-gray-dark opacity-40 rounded-lg"></div>
              <div className="w-full h-11 bg-gray-dark opacity-30 rounded-lg mt-2"></div>
              <div className="w-full h-11 bg-gray-dark opacity-20 rounded-lg mt-2"></div>
              <div className="w-full h-11 bg-gray-dark opacity-10 rounded-lg mt-2"></div>
            </div>
          )}
        </div>
      }
    />
  );
};
