import React, { useState } from 'react';
import AvatarEditor from 'react-avatar-editor';
import { useTranslation } from 'react-i18next';
import { Modal, Slider } from 'components';
import './index.scss';

export const AvatarEditorModal = ({
  title,
  visible,
  toggleModal,
  image,
  onConfirm,
  editorWidth = 256,
  editorHeight = 256,
  borderRadius = 999
}) => {
  const { t } = useTranslation();
  const [editor, setEditor] = useState(null);
  const [scale, setScale] = useState(1);

  const setEditorRef = (editor) => {
    setEditor(editor);
  };

  const confirm = () => {
    if (editor) {
      const canvas = editor.getImage();
      const ctx = canvas.getContext('2d');
      ctx.globalCompositeOperation = 'destination-over';
      ctx.fillStyle = 'black';
      ctx.fillRect(0, 0, canvas.width, canvas.height);
      canvas.toBlob((blob) => {
        const file = new File([blob], `avatar${new Date().getTime()}.jpg`, {
          type: 'image/jpeg'
        });
        onConfirm(file);
      }, 'image/jpeg');
    }
    toggleModal();
  };

  return (
    <Modal
      visible={visible}
      toggle={toggleModal}
      title={t(title || 'editYourAvatar')}
      buttons={[
        {
          variant: 'outline',
          text: t('cancel'),
          onClick: () => toggleModal()
        },
        {
          text: t('confirm'),
          onClick: () => confirm()
        }
      ]}
    >
      <div className="mx-auto mt-3 max-w-full" style={{ width: editorWidth }}>
        <div
          className="w-full rounded-lg overflow-hidden border border-silver-light"
          style={{ height: editorHeight }}
        >
          <AvatarEditor
            image={image}
            ref={setEditorRef}
            width={editorWidth}
            height={editorHeight}
            borderRadius={borderRadius}
            border={0}
            scale={scale}
            backgroundColor="#000000"
          />
        </div>

        <div className="flex items-center justify-between mt-5">
          <span className="font-bold mb-2 mr-5 text-sm tracking-tight">
            {t('scale')}
          </span>
          <Slider
            value={scale}
            onChange={setScale}
            min={0}
            max={5}
            step={0.1}
            className="flex-1"
            yellowDot
            hideLabel={true}
          />
        </div>
      </div>
    </Modal>
  );
};
