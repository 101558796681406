import React from 'react';
import cn from 'classnames';

export const Divider = ({ className, color = 'silver' }) => {
  const colors = {
    light: '#f2f3f5',
    dark: '#d6d6d6',
    silver: '#515151'  
  };
  return (
    <hr
      className={cn('cb-divider block w-full bg-black-light', className)}
      style={{ borderColor: colors[color], height: '0.5px' }}
    />
  );
};
