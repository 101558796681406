import React from 'react';
import { useTranslation } from 'react-i18next';
import { BackButton, Icon } from 'components';

export const UserTypePicker = ({ setUserIsCompany }) => {
  const { t } = useTranslation();
  return (
    <>
      <BackButton linkTo="/" light>
        {t('back')}
      </BackButton>
      <h2 className="text-xl font-bold mb-12 mt-6">
        {`${t('sellYourMachine')} ${t('and')}`}
        <span className="text-xl text-yellow">{` ${t('noCommission')}`}</span>
      </h2>
      <div
        onClick={() => setUserIsCompany(false)}
        className="w-full px-7 py-5 border border-solid border-gray rounded-lg flex justify-between items-center cursor-pointer hover:border-yellow"
      >
        <Icon name="User" height={21} />
        <span className="tracking-tight text-sm font-normal text-white-secondary">
          {t('iAmAnIndividual')}
        </span>
        <Icon name="ChevronRight" />
      </div>
      <div
        onClick={() => setUserIsCompany(true)}
        className="w-full px-7 py-5 border border-solid border-gray rounded-lg flex justify-between items-center cursor-pointer mt-6 mb-52 hover:border-yellow"
      >
        <Icon name="Company" height={21} />
        <span className="tracking-tight text-sm font-normal text-white-secondary">
          {t('iAmACompany')}
        </span>
        <Icon name="ChevronRight" />
      </div>
    </>
  );
};
