import React from 'react';
import cn from 'classnames';
import './index.scss';

export const Checkbox = ({
  id,
  checked,
  name,
  setChecked,
  disabled = false,
  size = 'md',
  className
}) => {
  const classes = cn('cb-checkbox', `cb-checkbox-size-${size}`, className);

  return (
    <label className={classes}>
      <input
        type="checkbox"
        checked={checked}
        onChange={(e) => !disabled && setChecked(e.target.checked)}
        disabled={disabled}
      />
      <span>{name}</span>
    </label>
  );
};
