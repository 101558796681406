import axios from 'utils/axios';

export const getWebVisits = async (page = 0, size = 10, type = '') => {
  try {
    const { data } = await axios.get(
      `api/web-visits?page=${page}&size=${size}&type=${type}`
    );
    return data;
  } catch (error) {
    if (process.env.REACT_APP_DEV_MODE !== 'true') {
      console.log('ERROR: ', error.message);
    }
    throw error;
  }
};

export const saveWebVisit = async (userId, source, type) => {
  try {
    await axios.post('api/web-visits', { visitedTo: userId, source, type });
  } catch (error) {
    if (process.env.REACT_APP_DEV_MODE !== 'true') {
      console.log('ERROR: ', error.message);
    }
    throw error;
  }
};
