import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { LetterContainer } from 'components';
import { getBrands, updateBrands, createBrands } from 'api/brands';
import { toast } from 'react-toastify';

export const MachinesDealers = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const [brands, setBrands] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const brands = await getBrands();
      setBrands([...brands]);
    } catch (error) {
      toast.error(t('genericError'));
    } finally {
      setLoading(false);
    }
  };

  const handleOnApply = async (selected) => {
    const customBrands = [...selected].filter((v) =>
      brands.find(({ id, isCustom }) => id === v && isCustom)
    );
    const brandsId = [...selected].filter((v) =>
      brands.find(({ id, isCustom }) => id === v && !isCustom)
    );
    try {
      await updateBrands(brandsId, customBrands);
      toast.success(t('brandsHaveBeenUpdated'));
      history.push('/dashboard/brands/machines');
    } catch (error) {
      toast.error(t('genericError'));
    }
  };

  const createBrand = async (data) => {
    try {
      await createBrands(data);
      toast.success(t('brandHasBeenCreated'));
      await fetchData();
    } catch (error) {
      toast.error(t('genericError'));
    }
  };

  return (
    <LetterContainer
      brands={brands}
      loading={loading}
      handleOnApply={handleOnApply}
      createBrand={createBrand}
    />
  );
};
