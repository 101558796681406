import { useRef, useState } from 'react';
import { DynamicTextarea, Icon } from 'components';
import { useOnClickOutside } from 'hooks';
import { Picker } from 'emoji-mart';
import cn from 'classnames';

import './index.scss';

export const PostInput = ({
  value,
  onChange,
  placeholder,
  height = '40',
  inputClasses
}) => {
  const [showEmojis, setShowEmojis] = useState(false);
  const textareaRef = useRef();
  const emojiPickerRef = useRef(null);
  const [cursorLocation, setCursorLocation] = useState();

  const clickOutsideEmoji = useOnClickOutside(emojiPickerRef, () =>
    setShowEmojis(false)
  );

  const addEmoji = (e) => {
    onChange(
      value.slice(0, cursorLocation) + e.native + value.slice(cursorLocation)
    );
  };
  return (
    <div
      className="cb-post-input relative flex-1"
      style={{ minHeight: `${height}px` }}
    >
      <DynamicTextarea
        className="flex-grow"
        minHeight={height}
        inputClasses={cn(
          'text-sm rounded-3xl px-5 pr-9 w-full post-description',
          inputClasses
        )}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        ref={textareaRef}
      />

      <div
        className="emoji-picker absolute top-6 right-3 cursor-pointer"
        ref={emojiPickerRef}
      >
        <Icon
          name="EmojiPicker"
          width={20}
          height={20}
          onClick={() => {
            setCursorLocation(textareaRef.current.selectionStart);
            setShowEmojis(!showEmojis);
          }}
        />
        {showEmojis && (
          <Picker
            onSelect={addEmoji}
            style={{ position: 'absolute', top: 0, right: 0, zIndex: 1 }}
          />
        )}
      </div>
    </div>
  );
};
