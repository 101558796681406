import { Suspense } from 'react';
import Routes from './Routes';
import { BrowserRouter, Route } from 'react-router-dom';
import { QueryParamProvider } from 'use-query-params';
import i18n from '../i18n';
import { RouteChangeListener, Spinner } from 'components';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export const baseUrl = '/' + i18n.language;

const loading = <div className="fixed top-0 left-0 w-full h-full bg-background flex items-center justify-center"><Spinner/></div>;

const Router = () => {
  return (
    <Suspense fallback={loading}>
      <BrowserRouter basename={baseUrl}>
        <QueryParamProvider ReactRouterRoute={Route}>
          <RouteChangeListener />
          <Routes />
        </QueryParamProvider>
        <ToastContainer
          theme="dark"
          position="bottom-right"
          autoClose={5000}
          hideProgressBar={false}
          progressStyle={
            {
              background: "#f7a70b"
            }
          }
          closeOnClick
          limit={3}
          draggable
          pauseOnHover
        />
      </BrowserRouter>
    </Suspense>
  );
};

export default Router;
