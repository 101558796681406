import React, { useMemo } from 'react';
import { CategoriesMenu } from './CategoriesMenu';
import { CompaniesMenu } from './CompaniesMenu';

export const Menu = ({ activeMenu, setActiveMenu }) => {
  const catMenu = useMemo(
    () => <CategoriesMenu close={() => setActiveMenu(false)} />,
    []
  );
  const compMenu = useMemo(
    () => <CompaniesMenu close={() => setActiveMenu(false)} />,
    []
  );

  const renderMenu = () => {
    switch (activeMenu) {
      case 'categories':
        return catMenu;
      case 'companies':
        return compMenu;
      default:
        return null;
    }
  };

  return (
    activeMenu && (
      <div className="absolute top-40 w-full h-screen">
        <div
          className="absolute top-0 left-0 w-full h-full backdrop-filter"
          style={{ background: 'rgba(14, 14, 14, 0.6)' }}
          onClick={() => setActiveMenu(null)}
        ></div>
        <div className="bg-black-light relative z-30 overflow-hidden">
          {renderMenu()}
        </div>
      </div>
    )
  );
};
