import { useRef, useState, Fragment } from 'react';
import { Icon } from 'components';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

export const FileInput = ({
  onChange,
  title,
  emptyTitle,
  className,
  error,
  errorMessage,
  value,
  validate,
  maxSize = 30000000,
  trigger,
  showList = true,
  ...rest
}) => {
  const relatedFilesRef = useRef();
  const [files, setFiles] = useState(value || []);
  const { t } = useTranslation();

  const handleChangeFile = (e) => {
    try {
      const files = Array.from(e.target.files).map((file) => {
        if ((validate && !validate(file)) || file.size > maxSize) {
          if (file.size > maxSize)
            throw new Error('tooBigFile', maxSize / 1000000);
          throw new Error('invalidType', file.name);
        }
        return {
          file,
          url: URL.createObjectURL(file)
        };
      });
      const auxFiles = !files ? [] : [...files];
      onChange && onChange([...auxFiles]);
      setFiles([...auxFiles]);
    } catch (error) {
      if (error.message === 'invalidType')
        toast.error(`${error.fileName} ${t('isNotValidFile')}`);
      else toast.error(`${t('fileIsTooBig')} ${maxSize / 1000000}Mb `);
    }
  };

  const removeFile = (index) => {
    const newArray = [...files];
    newArray.splice(index, 1);
    onChange && onChange(newArray.length ? newArray : null);
    setFiles(newArray.length ? newArray : null);
  };

  return (
    <div className={className}>
      <div
        onClick={() => relatedFilesRef.current.click()}
        className="flex items-center cursor-pointer mb-5 md:mb-0"
      >
        <input
          onChange={handleChangeFile}
          type="file"
          hidden
          ref={relatedFilesRef}
          {...rest}
        />
        {trigger || (
          <Fragment>
            <Icon name="Plus" rounded height={10} width={10} />
            <span className="text-yellow text-xs ml-3">
              {title && emptyTitle
                ? files.length
                  ? t(title)
                  : t(emptyTitle)
                : t(title)}
            </span>
          </Fragment>
        )}
      </div>
      {error && (
        <span className="text-2xs text-yellow-dark flex justify-start mt-1">
          {errorMessage ? errorMessage : t(`form.${error.type}`)}
        </span>
      )}
      {showList &&
        files &&
        files.map(({ file }, index) => (
          <div className="flex mt-1 text-xs" key={index}>
            {file ? file.name : t('fileNamePlaceholder')}
            <Icon
              className="ml-2"
              name="Trash"
              width={10}
              onClick={() => removeFile(index)}
            />
          </div>
        ))}
    </div>
  );
};
