import React, { useState, useEffect } from 'react';
import { AvatarUser, Icon, Divider } from 'components';
import { useTranslation } from 'react-i18next';
import { getCommentLikes, saveCommentLike } from 'api/social/comments';
import { formatDistanceToNow } from 'date-fns';
import { es } from 'date-fns/locale';

export const PostComment = ({ comment }) => {
  const [likes, setLikes] = useState({});
  const [likeLoading, setLikeLoading] = useState(false);
  const { t, i18n } = useTranslation();

  useEffect(() => {
    fetchLikes();
  }, []);
  const fetchLikes = async () => {
    try {
      const likes = await getCommentLikes(comment.id);
      setLikes(likes);
    } catch (error) {
      console.log('fetch Error: ', error);
    }
  };

  const handleOnCommentLike = async () => {
    try {
      setLikeLoading(true);
      await saveCommentLike(comment.id);
      fetchLikes();
    } catch (error) {
      console.log(error);
    } finally {
      setLikeLoading(false);
    }
  };

  return (
    <div className="my-2 w-full">
      <div className="flex flex-row mt-4 items-center">
        <AvatarUser
          user={comment.user}
          size={34}
          borderColor="#DBDBDB"
          variant="text-aside"
        >
          <span className="text-gray ml-2 text-xs">
            {i18n.language === 'es' && 'hace '}
            {formatDistanceToNow(
              new Date(comment.createdAt),
              i18n.language === 'es' && { locale: es }
            )}
            {i18n.language === 'en' && ' ago'}
          </span>
        </AvatarUser>
        <div className="flex flex-row items-baseline text-sm mr-3"></div>
      </div>
      <div className="px-11">
        <p className="text-white-secondary text-sm leading-5.5 mb-3 whitespace-pre-line">
          {comment.content}
        </p>

        <div className="flex flex-row text-xs items-center -ml-3">
          <Icon
            name={likes?.isLiked ? 'HeartSolid' : 'Heart'}
            className="p-3"
            loading={likeLoading}
            onClick={handleOnCommentLike}
            width={10}
            height={10}
          />
          {likes.count > 0 && (
            <span className="text-gray text-2xs">{`${likes.count} ${t(
              likes.count === 1 ? 'like' : 'likes'
            )}`}</span>
          )}
        </div>

        <Divider className="mt-4" />
      </div>
    </div>
  );
};
