import axios from 'utils/axios';

export const getTypes = async () => {
  try {
    const { data } = await axios.get('api/types');
    return data || [];
  } catch (error) {
    if (process.env.REACT_APP_DEV_MODE !== 'true') {
      console.log('ERROR: ', error.message);
    }
    throw error;
  }
};

export const getTypesByUser = async (userId) => {
  try {
    const { data } = await axios.get(`api/types/by-user?userId=${userId}`);
    return data || [];
  } catch (error) {
    if (process.env.REACT_APP_DEV_MODE !== 'true') {
      console.log('ERROR: ', error.message);
    }
    throw error;
  }
};
