import axios from 'utils/axios';

export const createChannel = async (userTargetId) => {
  try {
    const { data } = await axios.post('api/auth/chat/channel', {
      userTargetId
    });
    return data;
  } catch (error) {
    if (process.env.REACT_APP_DEV_MODE !== 'true') {
      console.log('ERROR: ', error.message);
    }
    throw error;
  }
};
