import React, { createContext, useState } from 'react';

export const PublicationFiltersContext = createContext();

const Provider = ({ children }) => {
  const [filters, setFilters] = useState({});
  const [search, setSearch] = useState('');
  const [type, setType] = useState(0);
  const [operationType, setOperationType] = useState('both');
  const [loadingFilters, setLoadingFilters] = useState(false);

  const resetFilters = () => {
    setType(0);
    setOperationType('both');
    setFilters({});
    setLoadingFilters(false);
  };

  return (
    <PublicationFiltersContext.Provider
      value={{
        filters,
        setFilters,
        type,
        search,
        setType,
        operationType,
        setOperationType,
        loadingFilters,
        setLoadingFilters,
        setSearch,
        resetFilters
      }}
    >
      {children}
    </PublicationFiltersContext.Provider>
  );
};

export default Provider;
