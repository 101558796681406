import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { I18nextProvider } from 'react-i18next';
import UserProvider from 'contexts/UserContext';
import ReloadContext from 'contexts/ReloadContext';
import PublicationFiltersProvider from 'contexts/PublicationFiltersContext';
import i18n from './i18n';

ReactDOM.render(
  <React.StrictMode>
    <UserProvider>
      <ReloadContext>
        <PublicationFiltersProvider>
          <I18nextProvider i18n={i18n}>
            <App />
          </I18nextProvider>
        </PublicationFiltersProvider>
      </ReloadContext>
    </UserProvider>
  </React.StrictMode>,
  document.getElementById('root')
);
