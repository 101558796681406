import React from 'react';
import { AvatarUser } from 'components';

export const DashboardItem = ({ user, subtitle, timestamp }) => {
  return (
    <div className="py-4 flex items-start justify-between border-solid border-b border-gray-light last:border-0">
      <div className="flex items-center">
        <AvatarUser user={user} badgeSize={18}>
          {subtitle && <div className="text-gray text-xs capitalize">{subtitle}</div>}
        </AvatarUser>
      </div>

      {timestamp && (
        <div className="text-xs text-gray uppercase font-semibold whitespace-nowrap">
          {timestamp}
        </div>
      )}
    </div>
  );
};
