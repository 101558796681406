import React from 'react';
import { Switch, Redirect } from 'react-router-dom';
import AuthMiddleware from 'routes/middleware/AuthMiddleware';

import { uploadRoutes } from 'routes/allRoutes';

import { UploadLayout } from 'layouts';

export const UploadRoutes = () => (
  <UploadLayout>
    <Switch>
      {uploadRoutes.map((route, idx) => (
        <AuthMiddleware
          path={route.path}
          component={route.component}
          exact={route.exact}
          key={idx}
          onlyCompany={route.onlyCompany}
          isAuthProtected
        />
      ))}
      <Redirect
        to={{
          pathname: '/upload/category'
        }}
      />
    </Switch>
  </UploadLayout>
);
