import { useState, useRef, useContext, useEffect } from 'react';
import Badge from '@mui/material/Badge';
import { Box, Icon, Notification, Divider } from 'components';
import { UserContext } from 'contexts/UserContext';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import useOnClickOutside from 'hooks/useOnClickOutside';
import cn from 'classnames';

export const FloatingQuotationsNotifications = ({ className }) => {
  const [opened, setOpened] = useState(false);
  const { t } = useTranslation();
  const history = useHistory();
  const { user, quotationsNotifications, unseenQuotations } =
    useContext(UserContext);
  const ref = useRef();
  useOnClickOutside(ref, () => setOpened(false));

  useEffect(() => {}, [quotationsNotifications]);

  const handleOnViewAll = () => {
    history.push('/dashboard/quotations');
    setOpened(false);
  };

  const handleOnClick = () => {
    if (window.innerWidth > 768) {
      setOpened(!opened);
    } else {
      history.push('/dashboard/quotations');
    }
  };

  return user?.isCompany ? (
    <div className={cn('relative', className)} ref={ref}>
      <div
        className="p-3 w-12 h-12 cursor-pointer rounded-full transition duration-300 hover:bg-black-light"
        onClick={() => handleOnClick()}
      >
        <Badge badgeContent={unseenQuotations || 0}>
          <Icon
            name="Pencil"
            canvasWidth={22}
            canvasHeight={22}
            width={20}
            height={20}
            center
          />
        </Badge>
      </div>

      {opened && (
        <Box
          title={t('latestQuotations')}
          buttonText={t('viewAllF')}
          onClick={() => handleOnViewAll()}
          className="absolute top-full right-0 w-80 py-1"
        >
          <div>
            <div className="overflow-y-auto" style={{ maxHeight: '60vh' }}>
              {quotationsNotifications?.slice(0, 20)?.map((notification, i) => (
                <div key={i}>
                  <Notification
                    notification={notification}
                    textContainerClasses="text-xs"
                    userNameClasses="text-xs"
                    textClasses="text-xs ml-1"
                    badgeSize={16}
                  />
                  <Divider className="my-3" />
                </div>
              ))}
            </div>
          </div>
        </Box>
      )}
    </div>
  ) : null;
};
