import React from 'react';
import { string, func } from 'prop-types';

import big from 'assets/img/logo/logo-big.svg';
import small from 'assets/img/logo/logo-small.svg';
import collapsed from 'assets/img/logo/logo-collapsed.svg';

const logos = { big, small, collapsed };

export const Logo = ({
  size = 'big',
  showClub = false,
  className,
  onClick = null
}) => (
  <div className={className} onClick={onClick}>
    <img src={logos[size]} alt="TMC" />
  </div>
);

Logo.propTypes = {
  size: string,
  className: string,
  onClick: func
};
