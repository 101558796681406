import React, { useContext } from 'react';
import {
  Box,
  Icon,
  DashboardItem,
  DashboardItemPublication,
  FeaturedData
} from 'components';
import { formatDate } from 'utils/formatter';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { UserContext } from 'contexts/UserContext';

export const DashboardCustomer = ({ data, loading }) => {
  const history = useHistory();
  const { t } = useTranslation();
  const { user } = useContext(UserContext);

  return (
    <>
      <div className="grid grid-cols-2 gap-4 mb-4 xl:mb-8 xl:grid-cols-4 xl:gap-8">
        <FeaturedData
          icon="Posts"
          iconWidth={25}
          iconHeight={18}
          number={data?.social?.activePosts}
          to={user?.profileURL}
          text={t('activePosts')}
          className="p-5"
          loading={loading}
        />

        <FeaturedData
          icon="User"
          iconWidth={27}
          iconHeight={19}
          to="/dashboard/social"
          number={data?.social?.followers}
          text={t('followers')}
          className="p-5"
          loading={loading}
        />

        <FeaturedData
          icon="User"
          iconWidth={25}
          iconHeight={19}
          to="/dashboard/social/following"
          number={data?.social?.following}
          text={t('following')}
          className="p-5"
          loading={loading}
        />

        <FeaturedData
          icon="ThumbsUp"
          iconWidth={26}
          iconHeight={18}
          to="/dashboard/social/likes"
          number={data?.social?.likesReceived}
          text={t('receivedPostsLikes')}
          className="p-5"
          loading={loading}
        />
      </div>
      <div className="grid grid-cols-1 gap-4 xl:grid-cols-5 xl:gap-8 mb-9 min-h-112">
        <Box
          title={t('lastFavourites')}
          buttonText={t('viewAllF')}
          onClick={() => history.push('/dashboard/favourites')}
          className="xl:col-span-3"
          loading={loading}
        >
          {!!data?.lastFavourites.length ? (
            data?.lastFavourites?.map(({ createdAt, publication }, i) => (
              <DashboardItemPublication key={i} publication={publication} />
            ))
          ) : (
            <div className="flex items-center justify-center h-20">
              <Icon className="mr-1" name="Heart" />
              <span className="text-gray-400 text-sm">
                {t('favouritesEmptyState')}
              </span>
            </div>
          )}
        </Box>

        <Box
          title={t('lastQuotations')}
          buttonText={t('viewAllF')}
          onClick={() => history.push('/dashboard/quotations-sent')}
          className="xl:col-span-2"
          loading={loading}
        >
          {data?.lastQuotations.length ? (
            data?.lastQuotations?.map(({ publication, createdAt }, i) => (
              <DashboardItemPublication key={i} publication={publication} />
            ))
          ) : (
            <div className="flex items-center justify-center h-20">
              <Icon className="mr-1" name="User" />
              <span className="text-gray-400 text-sm">
                {t('lastContactsEmptyState')}
              </span>
            </div>
          )}
        </Box>
      </div>

      <div className="grid grid-cols-1 gap-4 xl:grid-cols-2 xl:gap-8 mb-16 min-h-112">
        <Box
          title={t('lastFollowers')}
          buttonText={t('viewAllM')}
          onClick={() => history.push('/dashboard/social')}
          className="xl:col-span-1 h-full"
          loading={loading}
        >
          {data?.social?.lastFollowers?.length ? (
            data?.social?.lastFollowers.map(
              ({ userFollowing, createdAt }, i) => (
                <DashboardItem
                  key={i}
                  user={userFollowing}
                  timestamp={formatDate(new Date(createdAt))}
                />
              )
            )
          ) : (
            <div className="flex items-center justify-center h-20">
              <Icon className="mr-1" name="World" />
              <span className="text-gray-400 text-sm">
                {t('followersEmptyState')}
              </span>
            </div>
          )}
        </Box>
        <Box
          title={t('lastMachineLikes')}
          buttonText={t('viewAllM')}
          onClick={() => history.push('/dashboard/likes')}
          className="xl:col-span-1 h-full"
          loading={loading}
        >
          {data?.lastLikes?.length ? (
            data?.lastLikes?.map(({ createdAt, publication, user }, i) => (
              <DashboardItemPublication key={i} publication={publication} />
            ))
          ) : (
            <div className="flex items-center justify-center h-20">
              <Icon className="mr-1" name="Heart" />
              <span className="text-gray-400 text-sm">
                {t('likesEmptyState')}
              </span>
            </div>
          )}
        </Box>
      </div>
    </>
  );
};
