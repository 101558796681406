import { Fragment } from 'react';
import { Menu as CMenu, Transition } from '@headlessui/react';
import { Icon, Divider } from 'components';
import cn from 'classnames';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

export const Menu = ({ items = [], trigger, buttonClasses }) => {
  return (
    <CMenu as="div" className="relative inline-block text-left">
      <div>
        <CMenu.Button
          className={cn(
            buttonClasses
              ? buttonClasses
              : 'rounded-full flex items-center text-gray-400 hover:text-yellow'
          )}
        >
          {trigger}
        </CMenu.Button>
      </div>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <CMenu.Items className="origin-top-right absolute right-0 mt-2 w-max rounded-md shadow-lg bg-background z-10">
          <div className="py-1">
            {items.map(({ icon, label, onClick = () => {} }, index) => (
              <Fragment key={index}>
                <CMenu.Item>
                  {({ active }) => (
                    <div
                      href="#"
                      className={classNames(
                        active && 'text-yellow cursor-pointer',
                        'flex items-center px-6 pr-7 py-3 text-xs text-gray transtion duration-300'
                      )}
                      onClick={onClick}
                    >
                      {icon && (
                        <Icon className="mr-3" name={icon} disabled={!active} />
                      )}
                      <span>{label}</span>
                    </div>
                  )}
                </CMenu.Item>
                {index + 1 < items.length && <Divider />}
              </Fragment>
            ))}
          </div>
        </CMenu.Items>
      </Transition>
    </CMenu>
  );
};
