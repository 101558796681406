import React from 'react';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import './index.scss';

export const PublicationDescription = ({ description, className }) => {
  const { t } = useTranslation();
  return (
    <div className={cn('py-8', className)}>
        <h4 className="text-xl mb-5 font-weight-300">{t('publicationDescription')}</h4>
        <div className="text-xs leading-5 md:text-base md:leading-7 text-description break-words" dangerouslySetInnerHTML={{__html: description}}></div>
    </div>
  );
};
