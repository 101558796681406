import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Navigation } from 'swiper/core';
import 'swiper/swiper.min.css';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import './index.scss';
import { PublicationCard } from '@estudio-nk/the-crane-club-client-lib';
import { PublicationCardContainer } from 'components';
import { useMediaQuery } from 'react-responsive';
SwiperCore.use([Navigation]);

export const SlideLastPublications = ({ className, publications }) => {
  const { t } = useTranslation();
  const isMobile = useMediaQuery({
    query: '(max-width: 768px)'
  });

  return (
    <div className="cb-slide-lastpublications">
      <div className="flex items-center justify-between w-full mb-9">
        <h3 className="md:text-2xl text-lg pr-5 uppercase text-gray-light tracking-tight font-semibold">
          {t('lastAddedToTMC')}
        </h3>
      </div>

      <Swiper
        spaceBetween={24}
        slidesPerView={1}
        freeMode={true}
        navigation={!isMobile}
        breakpoints={{
          1200: {
            slidesPerView: 4
          },
          800: {
            slidesPerView: 3
          },
          600: {
            slidesPerView: 2
          }
        }}
      >
        {publications?.data.length > 0
          ? publications?.data?.map((publication) => (
              <>
                <SwiperSlide
                  className="flex items-center justify-center"
                  key={publication.id}
                >
                  <PublicationCardContainer
                    key={`${publication.id}-${Math.random()}`}
                    publication={publication}
                    row={false}
                    className="dark-shadow"
                  />
                </SwiperSlide>
              </>
            ))
          : new Array(8).fill(0).map((_, index) => (
              <>
                <SwiperSlide
                  className="flex items-center justify-center"
                  key={index}
                >
                  <PublicationCard
                    key={index}
                    row={false}
                    colorVariant="yellow"
                  />
                </SwiperSlide>
              </>
            ))}
      </Swiper>
    </div>
  );
};
