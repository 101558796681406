import { useState, useEffect } from 'react';
import chatClient from 'services/chat';

const LS_UNREAD_KEY = 'tcc-chat-unread-count';

export const useChatUnreadCount = () => {
  const [unreadCount, setUnreadCount] = useState(localStorage.getItem(LS_UNREAD_KEY || 0));
  
  const saveUnreadCount = (total) => {
    setUnreadCount(total);
    localStorage.setItem(LS_UNREAD_KEY, total);
  }
  
  useEffect(() => {
    chatClient.on((event) => { 
      if (event.total_unread_count !== undefined) { 
        saveUnreadCount(event.total_unread_count)
      } 
    });
  }, [])

  return unreadCount;
};
