import React, { useState, useEffect, useContext, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { getCommentsByPost, createComment } from 'api/social/comments';
import { toast } from 'react-toastify';
import { getReducePostLikes, savePostLike } from 'api/social/posts';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { useDoubleTap } from 'use-double-tap';
import {
  DynamicTextarea,
  PostComment,
  PostLikes,
  PostLikesModal,
  Divider,
  Menu,
  Avatar,
  Icon,
  PostCardContent,
  PostCardShare,
  LoginModal
} from 'components';
import { Button } from '@estudio-nk/the-crane-club-client-lib';
import { Picker } from 'emoji-mart';
import { UserContext } from 'contexts/UserContext';
import { useModal } from 'hooks/useModal';
import { postToURL } from 'utils/formatter';
import { useOnClickOutside } from 'hooks';
import 'emoji-mart/css/emoji-mart.css';
import { useMediaQuery } from 'react-responsive';

export const PostCard = ({ post, className, openedComments }) => {
  const textareaRef = useRef();
  const emojiPickerRef = useRef(null);
  const [showComments, setShowComments] = useState(openedComments);
  const [showEmojis, setShowEmojis] = useState(false);
  const [comments, setComments] = useState([]);
  const [commentsLength, setCommentsLength] = useState(0);
  const [loadingComments, setLoadingComments] = useState(false);
  const [likeCount, setLikeCount] = useState(0);
  const [likesURL, setLikesURL] = useState([]);
  const [isLiked, setIsLiked] = useState(false);
  const [doubleTapping, setDoubleTapLoading] = useState(false);
  const [comment, setComment] = useState('');
  const [cursorLocation, setCursorLocation] = useState();
  const [commentLoading, setCommentLoading] = useState(false);
  const { visible, toggleModal } = useModal();
  const postURL = postToURL(post?.id);
  const { t } = useTranslation();
  const history = useHistory();
  const isMobile = useMediaQuery({
    query: '(max-width: 768px)'
  });
  const clickOutsideEmoji = useOnClickOutside(emojiPickerRef, () =>
    setShowEmojis(false)
  );
  const { visible: visibleLoginModal, toggleModal: toggleModalLogin } =
    useModal();

  const { user } = useContext(UserContext);

  useEffect(() => {
    const lUrls = post.postLikes.map((postLike) => postLike.user?.photoURL);
    setLikesURL(lUrls);
    setLikeCount(parseInt(post.countLikes));
    setIsLiked(!!post.liked?.length);
    setCommentsLength(parseInt(post.countComments));
  }, []);

  useEffect(() => {
    if (showComments) {
      fetchComments();
    }
  }, [showComments]);

  const onDoubleTap = useDoubleTap(async (event) => {
    if (isMobile) {
      setDoubleTapLoading(true);
      !isLiked && (await handleOnPostLike());
      setTimeout(() => {
        setDoubleTapLoading(false);
      }, 500);
    }
  });

  const fetchComments = async () => {
    try {
      setLoadingComments(true);
      const comments = await getCommentsByPost(post.id);
      setComments(comments);
      setCommentsLength(comments.length);
      setLoadingComments(false);
    } catch (error) {}
  };

  const fetchLikes = async () => {
    try {
      const likes = await getReducePostLikes(post.id);
      setLikeCount(likes.count);
      setIsLiked(likes.isLiked);
      setLikesURL(likes.rows.map(({ user }) => user.photoURL));
    } catch (error) {}
  };

  const handleOnCommentClick = async () => {
    try {
      if (!user) {
        toggleModalLogin(true);
        return;
      }
      if (!comment.trim().length) return;
      setCommentLoading(true);
      const commentBody = {
        postId: post.id,
        content: comment
      };
      await createComment(commentBody);
      fetchComments();
      setComment('');
      setShowComments(true);
    } catch (error) {
      console.log(error);
    } finally {
      setCommentLoading(false);
    }
  };

  const handleOnPostLike = async () => {
    const currentIsLiked = isLiked;
    try {
      if (!user) {
        toggleModalLogin(true);
        return;
      }
      setIsLiked(!currentIsLiked);
      await savePostLike(post.id);
      await fetchLikes();
    } catch (error) {
      setIsLiked(currentIsLiked);
      console.log(error);
    }
  };

  const addEmoji = (e) => {
    setComment(
      comment.slice(0, cursorLocation) +
        e.native +
        comment.slice(cursorLocation)
    );
  };

  const shareLink = async () => {
    try {
      const canShare = navigator.share;
      if (canShare) {
        const shareData = {
          title: `${post?.user?.name}`,
          text: `${post?.description}`,
          url: `${window.location.origin}${postURL}?utm_source=tmc_web_copy_link`
        };
        await navigator.share(shareData);
      } else {
        await navigator.clipboard.writeText(
          `${window.location.origin}${postURL}?utm_source=tmc_web_copy_link`
        );
        toast.success(t('linkCopied'));
      }
    } catch (error) {}
  };

  const handleOnShowComment = () => {
    if (!user) {
      toggleModalLogin(true);
      return;
    }
    setShowComments(!showComments);
  };

  const handleOnLikesClick = () => {
    if (!user) {
      toggleModalLogin(true);
      return;
    }
    likeCount > 0 && toggleModal();
  };

  return (
    <div
      {...onDoubleTap}
      className={cn(
        'flex flex-col w-full py-4 px-4 rounded-lg relative bg-black-light',
        className
      )}
    >
      {post && <PostCardContent post={post} likeLoading={doubleTapping} />}

      {post.sharedPostId && (
        <PostCardShare className="mt-5" post={post.sharedPost} />
      )}

      <div className="my-6 flex items-center justify-between">
        <div className="w-1/3">
          <div onClick={handleOnLikesClick}>
            <PostLikes likes={likeCount} images={likesURL} />
          </div>
          <PostLikesModal
            postId={post.id}
            visible={visible}
            toggle={toggleModal}
          />
        </div>

        {commentsLength > 0 && (
          <button
            className={cn(
              'flex items-center cursor-pointer hover:text-yellow transition duration-300 text-xs text-gray',
              {}
            )}
            colorVariant="yellow"
            onClick={handleOnShowComment}
          >
            <Icon
              name="Comments"
              width={14}
              height={13}
              className="mr-2"
              loading={loadingComments}
            />
            <span>
              {`${commentsLength} 
              ${
                !isMobile
                  ? t(commentsLength === 1 ? 'comment' : 'comments')
                  : ''
              }`}
            </span>
          </button>
        )}

        <div className="w-1/3 flex justify-end">
          <Icon
            name={isLiked ? 'HeartSolid' : 'Heart'}
            rounded
            onClick={handleOnPostLike}
            className="mr-3 bg-white"
          />
          <Menu
            trigger={
              <Icon name="Share" rounded loading={false} className="bg-white" />
            }
            items={
              user
                ? [
                    {
                      label: t('repost'),
                      onClick: () => history.push(`/social-club`, { post })
                    },
                    {
                      label: t('share'),
                      onClick: () => shareLink()
                    }
                  ]
                : [
                    {
                      label: t('share'),
                      onClick: () => shareLink()
                    }
                  ]
            }
          />
        </div>
      </div>

      <Divider className="mb-6" />

      {user && (
        <div className="flex items-center">
          <Avatar size={34} onlyPhoto borderColor="#f7a709" />

          <div className="flex-1 mx-2 relative" ref={emojiPickerRef}>
            <DynamicTextarea
              value={comment}
              onChange={setComment}
              inputClasses="block box-border text-sm rounded-lg py-1.5 pl-5 pr-10 post-description w-full text-xs text-white"
              placeholder={t('writeComment')}
              ref={textareaRef}
            />
            <div className="absolute top-2.5 right-3 cursor-pointer">
              <Icon
                name="EmojiPicker"
                width={15}
                height={15}
                onClick={() => {
                  setCursorLocation(textareaRef.current.selectionStart);
                  setShowEmojis(!showEmojis);
                }}
              />
            </div>
            {showEmojis && (
              <Picker
                onSelect={(e) => addEmoji(e)}
                style={{
                  position: 'absolute',
                  bottom: 0,
                  right: 0,
                  zIndex: 1
                }}
              />
            )}
          </div>

          <Button
            className="w-34 h-34 pt-0.5 pr-0.5"
            onClick={handleOnCommentClick}
            loading={commentLoading}
            colorVariant="yellow"
            rounded
          >
            <Icon name="Send" width={14} height={14} />
          </Button>
        </div>
      )}

      <div className="mt-3">
        {showComments &&
          comments.map((comment) => (
            <PostComment key={comment.id} comment={comment} />
          ))}
      </div>
      <LoginModal
        visible={visibleLoginModal}
        toggleModal={() => toggleModalLogin(!visibleLoginModal)}
      />
    </div>
  );
};

PostCard.propTypes = {
  post: PropTypes.object.isRequired,
  className: PropTypes.string
};
