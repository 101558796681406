import {
  useQueryParams,
  ArrayParam,
  StringParam,
  NumberParam,
  DelimitedNumericArrayParam
} from 'use-query-params';
import { useHistory } from 'react-router-dom';

const paramsObject = {
  type: NumberParam,
  operationType: StringParam,
  categories: DelimitedNumericArrayParam,
  brands: DelimitedNumericArrayParam,
  countries: ArrayParam,
  condition: StringParam,
  roles: ArrayParam,
  years: DelimitedNumericArrayParam,
  search: StringParam,
  modal: StringParam,
  view: StringParam,
  'no-scroll': StringParam,
  tutorial: StringParam
};

export const useFilterQueryParams = () => {
  const [query, setQuery] = useQueryParams(paramsObject);
  const history = useHistory();

  const setTypeAndOperationTypeParams = (type, operationType) => {
    setQuery({
      type: type,
      operationType: operationType,
      categories: undefined,
      brands: undefined,
      countries: undefined,
      years: undefined,
      search: undefined,
      condition: undefined
    });
  };

  const setParam = (newParam, redirect, state = {}) => {
    redirect && history.push(redirect, state);
    setQuery({ ...query, [newParam.name]: newParam.value });
  };

  const setParams = (newParams = {}, redirect, state = {}) => {
    redirect && history.push(redirect, state);
    setQuery(newParams);
  };

  const removeParam = (removedParam) => {
    setQuery({ ...query, [removedParam]: undefined });
  };

  const getParam = (param) => {
    return query[param];
  };

  const getValidParam = () => {
    let params = {};
    Object.entries(query).forEach((q) => {
      const [key, value] = q;
      if (value) {
        params = {
          ...params,
          [key]: value
        };
      }
    });
    return params;
  };

  const cleanAllParams = () => {
    setQuery({
      categories: undefined,
      brands: undefined,
      countries: undefined,
      roles: undefined,
      years: undefined,
      search: undefined,
      condition: undefined,
      modal: undefined,
      view: undefined,
      'no-scroll': undefined,
      tutorial: undefined
    });
  };

  return {
    params: getValidParam(),
    getParams: getValidParam,
    getParam,
    setParam,
    setParams,
    setTypeAndOperationTypeParams,
    removeParam,
    cleanAllParams
  };
};

export default useFilterQueryParams;
