import React from 'react';
import cn from 'classnames';
import { Header, Footer, MobileNavbar } from 'components';
import { useLocation } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import './index.scss';

export const OverviewLayout = ({ children }) => {
  const location = useLocation();
  const isDesktop = useMediaQuery({
    query: '(min-width: 1024px)'
  });

  return (
    <>
      {location.pathname !== '/explore' && <Header />}
      <main
        className={cn('min-h-screen  pb-20 xl:py-0', {
          'pt-16 xl:pt-0': location.pathname !== '/explore'
        })}
      >
        {children}
      </main>
      {isDesktop ? <Footer /> : <MobileNavbar />}
    </>
  );
};
