import React, { useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { FollowersFollowingModal, LoginModal } from 'components';
import { UserContext } from 'contexts/UserContext';
import { useModal } from 'hooks/useModal';
import cn from 'classnames';

export const ProfileFollowers = ({ id, followers, following }) => {
  const { t } = useTranslation();
  const { visible, toggleModal } = useModal();
  const { visible: visibleLoginModal, toggleModal: toggleModalLogin } =
    useModal();
  const [type, setType] = useState('followers');
  const { user } = useContext(UserContext);

  const handleClick = (type) => {
    if (!user) {
      toggleModalLogin(true);
      return;
    }
    openModal(type);
  };
  const openModal = (type) => {
    setType(type);
    toggleModal();
  };

  return (
    <>
      <div className="flex text-base">
        <div
          className={cn('mr-3 cursor-pointer', {
            'pointer-events-none': !followers
          })}
          onClick={() => followers > 0 && handleClick('followers')}
        >
          <strong className="text-yellow">{followers || 0} </strong>
          {t('followers')}
        </div>
        <div
          className={cn('cursor-pointer', {
            'pointer-events-none': !following
          })}
          onClick={() => following > 0 && handleClick('following')}
        >
          <strong className="text-yellow">{following || 0} </strong>
          {t('following')}
        </div>
      </div>
      <LoginModal
        visible={visibleLoginModal}
        toggleModal={() => toggleModalLogin(!visibleLoginModal)}
      />

      <FollowersFollowingModal
        id={id}
        visible={visible}
        toggle={() => toggleModal(false)}
        type={type}
      />
    </>
  );
};
