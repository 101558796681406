import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Tooltip, Icon } from 'components';
import { VerificatedCompanyBadge } from '.';

export const ProfileBadge = ({
  className,
  profile,
  placement = 'right',
  size = 24
}) => {
  const { t } = useTranslation();
  const [machineWorker, setMachineWorker] = useState(false);
  useEffect(() => {
    [9, 10, 16, 20, 23, 24, 28, 29].includes(profile?.roleId) &&
      setMachineWorker(true);
  }, [profile]);

  return profile &&
    (profile.isInfluencer || profile.isVIP || profile.isOpenToWork) ? (
    <>
      {profile.isOpenToWork && (
        <Tooltip
          className={className}
          title={`${profile.role ? `${t(profile.role)} ` : ''}${t(
            'openToWork'
          )}`}
          placement={placement}
        >
          <div>
            <Icon
              name={machineWorker ? 'OpenToWorkOperator' : 'OpenToWork'}
              width={size}
              height={size}
            />
          </div>
        </Tooltip>
      )}
      {(profile.isInfluencer || profile.isVIP) && (
        <Tooltip
          className={className}
          title={t(profile.isInfluencer ? 'influencerUser' : 'VIPMember')}
          placement={placement}
        >
          <div>
            <Icon
              name={profile.isInfluencer ? 'Influencer' : 'Vip'}
              width={size}
              height={size}
            />
          </div>
        </Tooltip>
      )}
    </>
  ) : profile?.isVerified ? (
    <VerificatedCompanyBadge
      isVerified={profile.isVerified}
      size={16}
      className="mx-2"
    />
  ) : null;
};
