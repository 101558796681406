import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { Avatar, ForwardButton } from 'components';

import './index.scss';
import { useMediaQuery } from 'react-responsive';

export const Navbar = ({ variant = 'sidebar' }) => {
  const { t } = useTranslation();
  const isMobile = useMediaQuery({
    query: '(max-width: 768px)'
  });

  return (
    <header
      className={cn(
        'h-20 md:h-23 w-full fixed bg-black z-10 flex items-center justify-center',
        {
          'lg:pl-64': variant === 'sidebar'
        }
      )}
    >
      <div className="wrapper-lg">
        <div className="h-full flex justify-between items-center">
          <Avatar linkTo="/dashboard" onlyPhoto={isMobile} borderColor="#f7a709" />
          <ForwardButton linkTo="/dashboard">
            {t('backToDashboard')}
          </ForwardButton>
        </div>
      </div>
    </header>
  );
};

Navbar.propTypes = {
  variant: PropTypes.oneOf(['noSidebar', 'sidebar'])
};
