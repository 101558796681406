import { useState } from 'react';
import cn from 'classnames';
import { Link } from 'react-router-dom';

export const CategoryCard = ({ to, onClick = () => {}, category }) => {
  const [loaded, setLoaded] = useState(false);

  return (
    <Link
      to={to}
      onClick={onClick}
      className="pb-5 w-full rounded-lg flex flex-col justify-between cursor-pointer group overflow-hidden"
    >
      <img
        src={category.logoURL}
        alt={category.name}
        onLoad={() => setLoaded(true)}
        className={cn(
          'mb-4 transition transform duration-300 group-hover:scale-110 block',
          {
            hidden: !loaded
          }
        )}
      />
      {!loaded && (
        <div className="h-52 mt-4 w-2/3 animate-pulse bg-yellow mx-auto opacity-30 mb-4 rounded-md" />
      )}
      <div className="px-3 md:px-6 relative">
        <span className="tracking-tight capitalize block xl:h-12">
          {category.name}
        </span>
      </div>
    </Link>
  );
};
