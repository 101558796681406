import React from 'react';
import { locationToString } from 'utils/formatter';
import ReactCountryFlag from 'react-country-flag';
import cn from 'classnames';

export const Location = ({
  location,
  className,
  textClasses,
  oneLine = false,
  customWidth = false
}) => {
  return (
    <div
      className={cn('flex items-center', className, {
        'whitespace-nowrap': oneLine,
        'xl:w-max xl:max-w-full': !customWidth
      })}
    >
      <ReactCountryFlag
        className="mr-2.5 flex rounded-full object-cover"
        svg
        countryCode={location?.countryCode || 'US'}
      />
      <span
        className={cn(
          'text-xs overflow-hidden overflow-ellipsis w-full',
          textClasses
        )}
      >
        {locationToString(location)}
      </span>
    </div>
  );
};
