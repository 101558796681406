import { useEffect, useState, useContext } from 'react';
import propTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Modal, AvatarUser, FollowButton, Spinner } from 'components';
import { getFollowersByUser, getFollowingByUser } from 'api/social/follows';
import { UserContext } from 'contexts/UserContext';
import InfiniteScroll from 'react-infinite-scroll-component';

import './index.scss';

const USERS_PER_PAGE = 20;

export const FollowersFollowingModal = ({ id, visible, toggle, type }) => {
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [height, setHeight] = useState(window.innerHeight - 80);
  const { user } = useContext(UserContext);
  const [hasMore, setHasMore] = useState(true);
  const [selectedPage, setSelectedPage] = useState(0);
  const [followContainerRef, setFollowContainerRef] = useState(null);
  const [resultsTotal, setResultsTotal] = useState({});
  const { t } = useTranslation();

  useEffect(() => {
    if (visible) {
      fetch();
    }
  }, [visible]);

  const fetch = async () => {
    try {
      setLoading(true);
      const { rows, count, totalPages } =
        type === 'followers'
          ? await getFollowersByUser(id, 0, USERS_PER_PAGE)
          : await getFollowingByUser(id, 0, USERS_PER_PAGE);

      setUsers(rows);
      setResultsTotal({ count, totalPages });
      count <= USERS_PER_PAGE && setHeight(followContainerRef?.innerHeight);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const loadNextPages = async (e) => {
    if (!loading) {
      try {
        const nextPage = selectedPage + 1;
        setSelectedPage(nextPage);
        const res =
          type === 'followers'
            ? await getFollowersByUser(id, nextPage, USERS_PER_PAGE)
            : await getFollowingByUser(id, nextPage, USERS_PER_PAGE);
        const _users = [...users, ...res.rows];
        setUsers(_users);
        const restUsers = resultsTotal.count - res.rows.length;
        setResultsTotal({ ...resultsTotal, count: restUsers });
        if (restUsers - USERS_PER_PAGE < USERS_PER_PAGE) {
          setHasMore(false);
          const lastsPost =
            type === 'followers'
              ? await getFollowersByUser(id, nextPage, restUsers)
              : await getFollowingByUser(id, nextPage, restUsers);
          setUsers([...users, ...lastsPost.rows]);
        }
      } catch (error) {
      } finally {
      }
    }
  };

  return (
    <Modal
      visible={visible}
      title={t(type)}
      toggle={toggle}
      children={
        !loading ? (
          <InfiniteScroll
            className="pb-10 md:w-max"
            style={{ minWidth: '300px' }}
            ref={setFollowContainerRef}
            dataLength={users.length} //This is important field to render the next data
            next={loadNextPages}
            height={height}
            hasMore={hasMore}
            pullDownToRefreshThreshold={500}
            // scrollableTarget={followsContainer}
            loader={
              <div className="flex justify-center">
                <Spinner />
              </div>
            }
            // below props only if you need pull down functionality
          >
            {users?.map((profile, index) => (
              <div
                className="flex whitespace-nowrap justify-between items-center text-xs mt-2"
                key={index}
              >
                <AvatarUser user={profile} className="mr-10" />
                {user.uid !== profile.uid && (
                  <FollowButton className="w-20" size="sm" user={profile} />
                )}
              </div>
            ))}
          </InfiniteScroll>
        ) : (
          <div
            className="animate-pulse pb-10 md:w-max"
            style={{ minWidth: '300px' }}
          >
            <div className="w-full h-11 bg-gray-dark opacity-40 rounded-lg"></div>
            <div className="w-full h-11 bg-gray-dark opacity-30 rounded-lg mt-2"></div>
            <div className="w-full h-11 bg-gray-dark opacity-20 rounded-lg mt-2"></div>
            <div className="w-full h-11 bg-gray-dark opacity-10 rounded-lg mt-2"></div>
          </div>
        )
      }
    />
  );
};

FollowersFollowingModal.propTypes = {
  id: propTypes.number,
  visible: propTypes.bool
};
