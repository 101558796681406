import React from 'react';
import { Switch } from 'components';
import { useTranslation } from 'react-i18next';

export const CurrencySwitch = ({ onChange = () => {}, unit, className }) => {
  const { t } = useTranslation();
  return (
    <Switch
      className={className}
      items={[
        { value: 'dollar', text: t('dollar') },
        { value: 'euro', text: t('euro') }
      ]}
      value={unit}
      setValue={onChange}
    />
  );
};
