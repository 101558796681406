import React from 'react';
import { Switch } from 'react-router-dom';
import AuthMiddleware from 'routes/middleware/AuthMiddleware';

import { authRoutes } from 'routes/allRoutes';

import { AuthLayout } from 'layouts';

export const AuthRoutes = () => (
  <AuthLayout>
    <Switch>
      {authRoutes.map((route, idx) => (
        <AuthMiddleware
          path={route.path}
          component={route.component}
          exact={route.exact}
          key={idx}
        />
      ))}
    </Switch>
  </AuthLayout>
);
