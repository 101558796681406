import axios from 'utils/axios';

export const login = async (user) => {
  try {
    const res = await axios.post('api/auth/login', user);
    localStorage.setItem('tmc-token', res.data.token);
    return res.data.user;
  } catch (error) {
    throw error.response.data;
  }
};

export const logout = async () => {
  try {
    await axios.get('api/auth/logout');
    localStorage.removeItem('tmc-token');
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const forgetPassword = async (email) => {
  try {
    await axios.post(`api/auth/forget`, { email });
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const recoverPassword = async (token, password) => {
  try {
    await axios.post(`api/auth/recover`, { token, password });
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const changePassword = async (oldPassword, newPassword) => {
  try {
    await axios.put('api/auth/users/change-password', {
      oldPassword,
      newPassword
    });
  } catch (error) {
    if (process.env.REACT_APP_DEV_MODE !== 'true') {
      console.log('ERROR: ', error.message);
    }
    throw error?.response?.data || error;
  }
};

export const createPassword = async (email, password) => {
  try {
    await axios.put('api/auth/users/create-password', {
      email,
      password
    });
  } catch (error) {
    if (process.env.REACT_APP_DEV_MODE !== 'true') {
      console.log('ERROR: ', error.message);
    }
    throw error?.response?.data || error;
  }
};

export const deleteUser = async () => {
  try {
    await axios.delete('api/auth/users/by-user');
  } catch (error) {
    throw error?.response?.data || error;
  }
}