import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { UserContext } from 'contexts/UserContext';
import defaultUser from 'assets/img/default-user.jpeg';
import adminLogo from 'assets/img/admin-logo.png';
import cn from 'classnames';
import './index.scss';

export const Avatar = ({
  className,
  linkTo,
  reverse,
  onlyPhoto,
  username,
  size,
  notLoggedUser,
  src,
  showAdminLogo,
  borderSize,
  borderColor,
  isCustom
}) => {
  const { user } = useContext(UserContext);
  const history = useHistory();
  const classes = cn('cb-avatar flex-shrink-0', className, {
    'flex-row-reverse': reverse,
    'only-photo': onlyPhoto,
    'cursor-pointer': linkTo
  });

  const handleClick = () => history.push(linkTo);

  return (
    <div onClick={linkTo && handleClick} className={classes}>
      <img
        src={
          showAdminLogo
            ? adminLogo
            : isCustom && !src
            ? defaultUser
            : notLoggedUser || src
            ? src
              ? src
              : defaultUser
            : user?.photoURL || defaultUser
        }
        alt={isCustom ? 'Avatar' : user?.name || 'Avatar'}
        onError={(e) => {
          e.currentTarget.src = defaultUser;
        }}
        style={{
          ...(size && { height: `${size}px`, width: `${size}px` }),
          ...(borderSize && { borderWidth: `${borderSize}px` }),
          ...(borderColor && { borderColor })
        }}
        className={cn({
          'object-contain': user?.isCompany,
          'object-cover': !user?.isCompany
        })}
      />
      {!onlyPhoto && <p className="text-gray">{username || user?.name}</p>}
    </div>
  );
};

Avatar.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func,
  reverse: PropTypes.bool,
  onlyPhoto: PropTypes.bool,
  isCustom: PropTypes.bool
};
