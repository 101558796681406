import talkBanner from 'assets/img/banners/talk.jpg';
import cn from 'classnames';

export const CompaniesBanners = ({ className }) => {
  return (
    <></>
    // <div className={cn(className)}>
    //   <a
    //     href="https://theanythingloankompany.com/machinery-maintenance-financing"
    //     target="_blank"
    //     className="block mt-5"
    //   >
    //     <img src={talkBanner} alt="T.A.L.K" className="rounded-xl" />
    //   </a>
    // </div>
  );
};
