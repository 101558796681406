import React, { useEffect, useState, useContext } from 'react';
import { getCategories } from 'api/categories';
import { ReloadContext } from 'contexts/ReloadContext';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Navigation } from 'swiper/core';
import 'swiper/swiper.min.css';
import { CategoryCard } from 'components/home';
import { useMediaQuery } from 'react-responsive';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import './index.scss';
SwiperCore.use([Navigation]);

export const CategoriesSlide = ({
  className,
  close,
  sendToPublish,
  isPreLaunch
}) => {
  const [categories, setCatagories] = useState([]);
  const { setReload } = useContext(ReloadContext);
  const { t } = useTranslation();
  const isMobile = useMediaQuery({
    query: '(max-width: 768px)'
  });

  useEffect(() => {
    fetchCategories();
  }, []);

  const fetchCategories = async () => {
    const categories = await getCategories();
    setCatagories(categories);
  };

  const handleClick = () => {
    setReload(true);
    close && close();
  };

  return (
    <div className={cn('cb-categories-slide', className)}>
      <Swiper
        spaceBetween={24}
        slidesPerView={isMobile ? 2 : 5}
        freeMode={true}
        navigation={!isMobile}
        autoplay={{
          delay: 2000,
          pauseOnMouseEnter: true,
          disableOnInteraction: false
        }}
      >
        {categories?.map((image) => (
          <SwiperSlide
            className="flex items-center justify-center"
            key={image.id}
          >
            <CategoryCard
              to={
                !sendToPublish
                  ? isPreLaunch
                    ? '#'
                    : `/machinery?categories=${image.id}`
                  : `/upload/category?c=${image.id}`
              }
              onClick={() => handleClick(image.id)}
              image={image}
            />
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};
