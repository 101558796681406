import { Chat as SChat, Streami18n } from 'stream-chat-react';
import { useTranslation } from 'react-i18next';
import chatClient from 'services/chat';

import './index.scss';

export const Chat = ({ children, ...rest }) => {
  const { i18n } = useTranslation();
  const i18nInstance = new Streami18n({ language: i18n.language });

  return (
    <SChat
      client={chatClient}
      theme="messaging dark"
      i18nInstance={i18nInstance}
      {...rest}
    >
      {children}
    </SChat>
  );
};
