import React from 'react';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import { Icon } from 'components';

export const Switch = ({
  value,
  setValue,
  className,
  items,
  error,
  size = 'md'
}) => {
  const { t } = useTranslation();

  return (
    <div
      className={cn(
        'w-max my-4 overflow-auto whitespace-nowrap pr-10',
        className,
        {
          'h-9': size === 'md',
          'h-7': size === 'sm'
        }
        
      )}
      style={{
        maxWidth: '95vw'
      }}
    >
      <div className=" flex h-full row bg-transparent text-sm font-medium tracking-tight w-max">
        {items.map((item, index) => (
          <div
            className={cn(
              'relative flex items-center justify-center border-solid border-gray-600',
              !index
                ? `border-t border-l border-b border-r-1/2`
                : index === items.length - 1
                ? `border-t border-r border-b`
                : 'border-t border-b border-l-0 border-r-1/2',
              !item?.disabled
                ? value === (item.text ? item.value : item)
                  ? 'text-black bg-yellow'
                  : 'text-white-secondary'
                : 'text-gray',
              {
                'px-5': size === 'md',
                'px-3 text-xs': size === 'sm',
                'rounded-l-md': size === 'md' && !index,
                'rounded-l-sm': size === 'sm' && !index,
                'rounded-r-md':
                  size === 'md' && index && index === items.length - 1,
                'rounded-r-sm':
                  size === 'sm' && index && index === items.length - 1
              },
              {
                'cursor-pointer': !item?.disabled
              }
            )}
            key={index}
            onClick={() =>
              !item?.disabled && setValue(item.text ? item.value : item)
            }
          >
            {item.icon && (
              <Icon
                name={item.icon}
                fill="white"
                className={cn(
                  value === (item.text ? item.value : item)
                    ? 'active'
                    : 'text-gray-light',
                  'mr-2 hidden lg:block'
                )}
              />
            )}
            {item.text || item}
          </div>
        ))}
      </div>
      {error && (
        <span className="text-2xs text-yellow-dark flex justify-end mt-1">
          {t(`form.${error.type}`)}
        </span>
      )}
    </div>
  );
};
