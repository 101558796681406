import React from 'react';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import { PublishNowModal } from './PublishNowModal';
import { useModal } from 'hooks/useModal';
import { Button } from '@estudio-nk/the-crane-club-client-lib';

export const PublishNowButton = ({
  className,
  size = 'md',
  variant = 'primary'
}) => {
  const { t } = useTranslation();
  const { visible, toggleModal } = useModal();

  return (
    <>
      <Button
        className={cn(className)}
        size={size}
        onClick={() => toggleModal(true)}
        variant={variant}
        colorVariant="yellow"
      >
        {t('publishNow')}
      </Button>
      <PublishNowModal visible={visible} toggleModal={toggleModal} />
    </>
  );
};
