import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { Icon, PostCardContent } from 'components';
import { useTranslation } from 'react-i18next';
import 'emoji-mart/css/emoji-mart.css';

export const PostCardShare = ({ post, className, onClose }) => {
  const { t } = useTranslation();

  return (
    <div
      className={cn(
        'flex flex-col w-full py-4 px-4 rounded-sm relative border border-gray-200 bg-black-light',
        className
      )}
    >
      {onClose && (
        <Icon
          name="Close"
          className="absolute right-3 top-3"
          onClick={onClose}
        />
      )}
      {post ? (
        <PostCardContent post={post} sharing />
      ) : (
        <p className="text-xs text-silver">{t('thisPostHasBeenDeleted')}</p>
      )}
    </div>
  );
};

PostCardShare.propTypes = {
  post: PropTypes.object.isRequired,
  className: PropTypes.string
};
