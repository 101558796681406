import { Switch } from 'react-router-dom';
import AuthMiddleware from 'routes/middleware/AuthMiddleware';

import { publicRoutes, overviewRoutes } from 'routes/allRoutes';

import { OverviewLayout } from 'layouts';

export const OverviewRoutes = () => (
  <OverviewLayout>
    <Switch>
      {publicRoutes.map((route, idx) => (
        <AuthMiddleware
          path={route.path}
          component={route.component}
          exact={route.exact}
          key={idx}
        />
      ))}
      {overviewRoutes.map((route, idx) => (
        <AuthMiddleware
          path={route.path}
          component={route.component}
          exact={route.exact}
          key={idx}
        />
      ))}
    </Switch>
  </OverviewLayout>
);
