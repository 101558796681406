import React, { useContext, useState, useMemo, useEffect } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
  PostDescription,
  PostFiles,
  PostFilesCloudinary,
  Menu,
  AvatarUser,
  Icon,
  Modal,
  PostInput
} from 'components';
import { distanceToNow } from 'utils/formatter';
import { postToURL } from 'utils/formatter';
import { PublicationCardContainer } from 'components';
import { deletePost, editPost } from 'api/social/posts';
import 'emoji-mart/css/emoji-mart.css';
import cn from 'classnames';
import { useModal } from 'hooks/useModal';
import { toast } from 'react-toastify';
import { UserContext } from 'contexts/UserContext';
import { ReloadContext } from 'contexts/ReloadContext';
import { useMediaQuery } from 'react-responsive';

export const PostCardContent = ({ post, sharing, likeLoading }) => {
  const { user } = useContext(UserContext);
  const { reload, setReload } = useContext(ReloadContext);
  const [liking, setLiking] = useState(false);
  const { t } = useTranslation();
  const postURL = postToURL(post?.id);
  const [loadingDots, setLoadingDots] = useState(false);
  const [description, setDescription] = useState(post.description);
  const { visible: deleteModalVisible, toggleModal: toggleDeleteModal } =
    useModal();
  const { visible: editModalVisible, toggleModal: toggleEditModal } =
    useModal();

  useEffect(() => {
    if (likeLoading) {
      setLiking(true);
      setTimeout(() => {
        setLiking(false);
      }, 1000);
    }
  }, [likeLoading]);

  const handleDeletePost = async () => {
    try {
      setLoadingDots(true);
      await deletePost(post.id);
      setReload(!reload);
      toast.success(t('postHasBeenDeleted'));
    } catch (error) {
      console.log(error);
    } finally {
      setLoadingDots(false);
    }
  };

  const handleEditPost = async () => {
    try {
      setLoadingDots(true);
      await editPost(post.id, { description });
      post.description = description;
      toast.success(t('postHasBeenEdited'));
      toggleEditModal();
    } catch (error) {
      toast.error(t('genericError'));
    } finally {
      setLoadingDots(false);
    }
  };

  const isUserPost = useMemo(
    () => user?.uid === post?.user?.uid,
    [post?.user?.uid, user?.uid]
  );

  return (
    <>
      <div>
        <div className="flex items-center justify-between mb-6">
          <div className="flex items-center">
            <AvatarUser user={post.user}>
              <Link to={postURL} className="text-xs text-gray hover:underline">
                {distanceToNow(post.createdAt)}
              </Link>
            </AvatarUser>
          </div>

          {!sharing && isUserPost && (
            <>
              <Menu
                trigger={
                  <Icon
                    name="Dots"
                    loading={loadingDots}
                    className="py-2 px-5 dark"
                    width={loadingDots ? 15 : 22}
                    height={loadingDots ? 15 : 6}
                  />
                }
                items={[
                  {
                    label: t('editPost'),
                    onClick: toggleEditModal
                  },
                  {
                    label: t('deletePost'),
                    onClick: toggleDeleteModal
                  }
                ]}
              />
              <Modal
                title={t('deletePost')}
                visible={deleteModalVisible}
                toggle={toggleDeleteModal}
                buttons={[
                  {
                    text: 'cancel',
                    variant: 'outline'
                  },
                  {
                    text: 'delete',
                    onClick: handleDeletePost
                  }
                ]}
              >
                <p>{t('areYouSureYouWantToDeleteThisPost?')}</p>
              </Modal>
              <Modal
                className="overflow-visible"
                title={t('editPost')}
                visible={editModalVisible}
                toggle={toggleEditModal}
                buttons={[
                  {
                    text: 'cancel',
                    variant: 'outline'
                  },
                  {
                    text: 'save',
                    onClick: handleEditPost
                  }
                ]}
              >
                <div className="w-96 max-w-full">
                  <PostInput
                    value={description}
                    inputClasses="py-2"
                    onChange={setDescription}
                    height="80"
                  />
                </div>
              </Modal>
            </>
          )}
        </div>

        <PostDescription description={post.description} />

        {!!post.publicationId ? (
          <div className="h-auto w-full flex justify-center align-center mt-8">
            <div className="w-full px-0 xs:px-4 xs:w-3/4 max-w-md">
              <PublicationCardContainer
                publication={{ ...post.publication, user: post.user }}
                className="dark-shadow"
              />
            </div>
          </div>
        ) : (
          post?.postFiles?.length > 0 && (
            <div className="relative block w-full">
              <div
                className={cn(
                  'absolute flex z-10 pointer-events-none w-full h-full justify-center items-center duration-300',
                  {
                    'scale-0 opacity-0': !liking,
                    'scale-100 opacity-100 ease-in': liking
                  }
                )}
              >
                <Icon
                  // className={cn({'animate-ping-slow': liking})}
                  name="HeartSolid"
                  width={60}
                  height={60}
                />
              </div>
              {post?.postFiles.find(({ type }) => type === 'tiny') ? (
                <PostFiles files={post.postFiles} />
              ) : (
                <PostFilesCloudinary files={post.postFiles} />
              )}
            </div>
          )
        )}
      </div>
    </>
  );
};

PostCardContent.propTypes = {
  post: PropTypes.object.isRequired,
  className: PropTypes.string
};
