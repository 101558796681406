import { useContext, useEffect, useState } from 'react';
import {
  Avatar,
  Divider,
  Input,
  Spinner,
  FileInput,
  Dropdown,
  AvatarEditorModal,
  LocationAutocomplete
} from 'components';
import { Button } from '@estudio-nk/the-crane-club-client-lib';
import { UserContext } from 'contexts/UserContext';
import { updateUser, updateBanner, updateProfilePicture } from 'api/user';
import { useModal } from 'hooks/useModal';
import { useHistory } from 'react-router';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';
import { isValidImage, checkHttpURL } from 'utils/validators';
import { getRoles } from 'api/roles';
import { locationToString } from 'utils/formatter';
import defaultBanner from 'assets/img/cover-default.jpg';
import './index.scss';

export const PersonalInfoCard = () => {
  const history = useHistory();
  const { t } = useTranslation();
  const { user, setUser } = useContext(UserContext);
  const [roles, setRoles] = useState([]);
  const [loadingPicture, setLoadingPicture] = useState(false);
  const [loadingBanner, setLoadingBanner] = useState(false);
  const [loading, setLoading] = useState(false);
  const [logoURL, setLogoURL] = useState('');
  const [location, setLocation] = useState(null);
  const [companyName, setCompanyName] = useState('');
  const [facebookURL, setFacebookURL] = useState('');
  const [linkedinURL, setLinkedinURL] = useState('');
  const [instagramURL, setInstagramURL] = useState('');
  const [twitterURL, setTwitterURL] = useState('');
  const [newAvatarImage, setNewAvatarImage] = useState(null);
  const [newBannerImage, setNewBannerImage] = useState(null);
  const [bannerURL, setBannerURL] = useState('');
  const [name, setName] = useState('');
  const [roleId, setRoleId] = useState(null);
  const [phoneNumber, setPhoneNumber] = useState('');
  const [email, setEmail] = useState('');
  const { visible: avatarEditorVisible, toggleModal: avatarEditorToggle } =
    useModal();
  const { visible: bannerEditorVisible, toggleModal: bannerEditorToggle } =
    useModal();
  const isMobile = useMediaQuery({
    query: '(max-width: 480px)'
  });

  useEffect(() => {
    user.isCompany && history.replace('/dashboard/settings');
    setLoading(false);
  }, [history, user.isCompany]);

  useEffect(() => {
    fetchUser();
    fetchRoles();
  }, []);

  const fetchUser = async () => {
    try {
      setEmail(user.email);
      setPhoneNumber(user.phoneNumber);
      setName(user.name);
      setRoleId(user.roleId);
      setCompanyName(user.companyName);
      setLogoURL(user.photoURL);
      setBannerURL(user.bannerURL);
      setFacebookURL(user.facebookURL);
      setLocation(user.location);
      setLinkedinURL(user.linkedinURL);
      setTwitterURL(user.twitterURL);
      setInstagramURL(user.instagramURL);
    } catch (error) {
      toast.error(t('genericError'));
    }
  };

  const fetchRoles = async () => {
    try {
      const _roles = await getRoles();
      setRoles(
        _roles.map(({ id, description }) => ({ value: id, text: description }))
      );
    } catch (error) {
      console.log(error);
    }
  };

  const handleSave = async () => {
    try {
      setLoading(true);
      const newUser = {
        name,
        phoneNumber,
        roleId,
        companyName,
        location,
        facebookURL: checkHttpURL(facebookURL, 'https'),
        twitterURL: checkHttpURL(twitterURL, 'https'),
        linkedinURL: checkHttpURL(linkedinURL, 'https'),
        instagramURL: checkHttpURL(instagramURL, 'https')
      };
      await updateUser(newUser);
      const selectedRole = roles.find(({ value }) => value === roleId);
      setUser({
        ...user,
        ...newUser,
        role: selectedRole.text
      });
      toast.success(t('dataHasBeenUpdated'));
    } catch (error) {
      toast.error(t('dataUpdatedError'));
    } finally {
      setLoading(false);
    }
  };

  const handlePhotoChange = async (files) => {
    try {
      setLoadingPicture(true);
      if (!isValidImage(files[0].file)) {
        toast.error(t('imageInvalidFormat'));
        return;
      }
      setNewAvatarImage(files[0].file);
      avatarEditorToggle(true);
    } catch (error) {
      toast.error(t('dataUpdatedError'));
    } finally {
      setLoadingPicture(false);
    }
  };

  const handleBannerChange = async (files) => {
    try {
      setLoadingBanner(true);
      if (!isValidImage(files[0].file)) {
        toast.error(t('imageInvalidFormat'));
        return;
      }
      setNewBannerImage(files[0].file);
      bannerEditorToggle(true);
    } catch (error) {
      toast.error(t('dataUpdatedError'));
    } finally {
      setLoadingBanner(false);
    }
  };

  const onConfirmBannerEditor = async (banner) => {
    try {
      setLoadingBanner(true);
      const newURL = await updateBanner(user.uid, banner, user.bannerURL);
      setBannerURL(newURL);
      setUser({ ...user, bannerURL: newURL });
      toast.success(t('dataHasBeenUpdated'));
    } catch (error) {
      toast.error(t('dataUpdatedError'));
    } finally {
      setLoadingBanner(false);
    }
  };

  const onConfirmAvatarEditor = async (avatar) => {
    try {
      setLoadingPicture(true);
      const newURL = await updateProfilePicture(
        user.uid,
        avatar,
        user.photoURL
      );
      setLogoURL(newURL);
      setUser({ ...user, photoURL: newURL });
      toast.success(t('dataHasBeenUpdated'));
    } catch (error) {
      toast.error(t('dataUpdatedError'));
    } finally {
      setLoadingPicture(false);
    }
  };

  return !user && loading ? (
    <Spinner />
  ) : (
    <div className="personal-info-card bg-black-light">
      <h2 className="ml-9 pt-5 mb-5 tracking-tight text-lg">
        {t('myAccount')}
      </h2>
      <Divider />
      <div
        className="relative flex items-center justify-center bg-cover bg-center bg-no-repeat bg-yellow bg-opacity-10 rounded-xl"
        style={{
          paddingTop: '42%',
          backgroundImage: `url(${bannerURL || defaultBanner})`,
          ...(!bannerURL && { backgroundPosition: 'center' })
        }}
      >
        <FileInput
          className="absolute bottom-4 right-4"
          accept="image/*"
          trigger={
            <Button
              size="sm"
              className="w-40"
              loading={loadingBanner}
              colorVariant="yellow"
            >
              {t('changeBanner')}
            </Button>
          }
          onChange={handleBannerChange}
          showList={false}
        />

        <AvatarEditorModal
          visible={bannerEditorVisible}
          toggleModal={bannerEditorToggle}
          image={newBannerImage}
          onConfirm={onConfirmBannerEditor}
          editorWidth={isMobile ? 320 : 600}
          editorHeight={isMobile ? 180 : 300}
          borderRadius={0}
        />
      </div>
      <div className="px-5 md:px-9 py-7">
        <div className="flex items-center -mb-5 md:mb-0">
          <Avatar
            size={isMobile ? 80 : 128}
            onlyPhoto
            className="mr-5 md:mr-10 mb-5 md:mb-0"
            linkTo="/dashboard"
            src={logoURL}
            borderColor="#f7a709"
          />
          <FileInput
            trigger={
              <Button
                size="sm"
                className="w-32 md:w-40"
                loading={loadingPicture}
                colorVariant="yellow"
              >
                {user?.isCompany ? t('changeLogo') : t('changeImage')}
              </Button>
            }
            onChange={handlePhotoChange}
            showList={false}
          />

          <AvatarEditorModal
            visible={avatarEditorVisible}
            toggleModal={avatarEditorToggle}
            image={newAvatarImage}
            onConfirm={onConfirmAvatarEditor}
          />
        </div>
        <div className="w-full my-8">
          <Divider />
          <div className="grid-data">
            <label>{t('email')}</label>
            <Input
              className="border-none pointer-events-none"
              placeholder={t('email')}
              value={email}
            />
            <Divider />
            <label>{t('name')}</label>
            <Input
              className="border-none"
              placeholder={t('name')}
              value={name}
              onChange={setName}
            />
            <Divider />
            <label>{t('address')}</label>
            <LocationAutocomplete
              value={{
                description: location ? locationToString(location) : '',
                placeId: location?.placeId
              }}
              setValue={setLocation}
              className="border-none w-full"
              placeholder={t('address')}
            />
            <Divider />
            <label>{t('phone')}</label>
            <Input
              className="border-none"
              placeholder={t('phone')}
              value={phoneNumber}
              onChange={setPhoneNumber}
            />
            <Divider />
            <label>{t('role')}</label>
            <Dropdown
              className="border-none"
              label={t('role')}
              variant="standard"
              value={roleId}
              onChange={setRoleId}
              items={roles}
            />
            <Divider />
            <label>{t('company')}</label>
            <Input
              className="border-none"
              value={companyName}
              onChange={setCompanyName}
            />
            <Divider />
            <label>Facebook</label>
            <Input
              className="border-none"
              placeholder="e.g: https://www.facebook.com/user"
              value={facebookURL}
              onChange={setFacebookURL}
            />
            <Divider />
            <label>Instagram</label>
            <Input
              className="border-none"
              placeholder="e.g: https://www.instagram.com/user"
              value={instagramURL}
              onChange={setInstagramURL}
            />
            <Divider />
            <label>Linkedin</label>
            <Input
              className="border-none"
              placeholder="e.g: https://www.linkedin.com/user"
              value={linkedinURL}
              onChange={setLinkedinURL}
            />
            <Divider />
            <label>Twitter</label>
            <Input
              className="border-none"
              placeholder="e.g: https://twitter.com/user"
              value={twitterURL}
              onChange={setTwitterURL}
            />
            <Divider />
          </div>
        </div>
        <Button
          loading={loading}
          onClick={handleSave}
          colorVariant="yellow"
          disabled={name.split().length === 0}
        >
          {t('save')}
        </Button>
      </div>
    </div>
  );
};
