import background from 'assets/img/auth/background.png';
import tmc from 'assets/img/auth/tmc.png';

import './index.scss';

export const SideImage = () => {
  return (
    <div
      style={{ backgroundImage: `url(${background})` }}
      className="auth-side-image items-center justify-center"
    >
      <div className="absolute top-0 right-14">
        <img src={tmc} alt="The Machine Club" className="h-screen" />
      </div>
    </div>
  );
};
