import React, { useState, useEffect } from 'react';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { Box, PublicationCardContainer } from 'components';
import { getProfile } from 'api/profiles';
import { getPublicationsLastAdded } from 'api/publications';
import { getLastMedia } from 'api/social/posts';
import { useHistory } from 'react-router-dom';
import { PostFile } from 'components';

export const ActivitySidebar = ({ userId, className }) => {
  const { t } = useTranslation();
  const [media, setMedia] = useState(['', '', '', '', '', '']);
  const [lastPublication, setLastPublication] = useState(null);
  const [loadingPublication, setLoadingPublication] = useState(true);
  const history = useHistory();

  useEffect(() => {
    fetch();
  }, []);

  const fetch = async () => {
    try {
      const _profile = await getProfile(userId);
      const { rows } = await getLastMedia(userId, 0, 6);
      if (_profile.isCompany) {
        const publications = await getPublicationsLastAdded(1, _profile.uid);
        setLastPublication(publications?.rows[0]);
      }
      setMedia(rows);
      setLoadingPublication(false);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div
      className={cn(className, {
        'animate-pulse':
          (media?.length && media[0] === '') || loadingPublication
      })}
    >
      {media?.length > 0 && (
        <Box
          title={t('lastMedia')}
          variant="flat"
          onClick={() => history.push('media')}
          buttonText={t('viewAllM')}
          className="mb-8"
        >
          <div
            className="grid grid-cols-3 gap-1.5 items-center justify-between cursor-pointer"
            onClick={() => history.push('media')}
          >
            {media.map((m, i) => (
              <div
                className="relative border border-silver rounded-md cursor-pointer overflow-hidden"
                style={{ paddingTop: '100%' }}
              >
                <div className="absolute flex flex-col justify-center top-0 left-0 w-full h-full">
                  <PostFile
                    file={m}
                    size={400}
                    videoOptions={{
                      playing: true,
                      loop: true,
                      playsinline: true,
                      muted: true
                    }}
                    key={i}
                    videoPreview
                    videoClasses="flex justify-center rounded-lg w-full h-full"
                    imageClasses="w-full"
                  />
                </div>
              </div>
            ))}
          </div>
        </Box>
      )}
      {(loadingPublication || lastPublication) && (
        <Box
          title={t('lastPublication')}
          variant="flat"
          onClick={() => history.push('machines')}
          buttonText={t('viewAllF')}
        >
          <div className="-mx-5">
            {lastPublication && (
              <PublicationCardContainer
                publication={lastPublication}
                row={false}
                className="no-shadow mb-3"
              />
            )}
          </div>
        </Box>
      )}
    </div>
  );
};
