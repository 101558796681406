import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { createChannel } from 'api/chat';
import { useTranslation } from 'react-i18next';
import { getChannelByUsers } from 'services/chat';
import { useModal } from 'hooks/useModal';
import { UserContext } from 'contexts/UserContext';

import { LoginModal } from 'components';
import { Button } from '@estudio-nk/the-crane-club-client-lib';

export const ChatButton = ({
  userId,
  className,
  variant = 'outline',
  colorVariant = 'yellow',
  ...rest
}) => {
  const { t } = useTranslation();
  const { visible: visibleLoginModal, toggleModal: toggleModalLogin } =
    useModal();

  const history = useHistory();
  const { user } = useContext(UserContext);

  const createChat = async () => {
    try {
      if (!user) {
        toggleModalLogin(true);
        return;
      }
      const channel = await getChannelByUsers(
        userId.toString(),
        user.uid.toString()
      );
      if (channel) {
        history.push(`/dashboard/chat/${channel.id}`);
      } else {
        await createChannel(userId);
        history.push('/dashboard/chat?new=true');
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <Button
        onClick={createChat}
        variant={variant}
        colorVariant="yellow"
        className={className}
        {...rest}
      >
        {t('message')}
      </Button>
      <LoginModal
        visible={visibleLoginModal}
        toggleModal={() => toggleModalLogin(!visibleLoginModal)}
      />
    </>
  );
};
