import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import {
  Divider,
  Icon,
  ProfileFollowers,
  Location,
  ProfileActionsMobile,
  ProfileBadge
} from 'components';
import ClampLines from 'react-clamp-lines';
import { saveWebVisit } from 'api/userWebVisits';
import { formatWebsite } from 'utils/formatter';
import { useMediaQuery } from 'react-responsive';

export const CompanyProfileSideBar = ({ profile, className }) => {
  const { t } = useTranslation();
  const isDesktop = useMediaQuery({
    query: '(min-width: 1025px)'
  });
  const handleOnWebSiteClick = async (type) => {
    await saveWebVisit(profile.uid, 'company', type);
  };

  return (
    <div className={className}>
      <div className="my-5 md:my-7">
        {profile.name ? (
          <h2 className="font-semibold text-2xl uppercase flex items-center">
            {profile?.name}
            <ProfileBadge profile={profile} className="ml-4" size={30} />
          </h2>
        ) : (
          <div className="h-8 bg-gray-100 rounded-sm"></div>
        )}
      </div>

      {!isNaN(parseInt(profile.countFollowers)) ? (
        <ProfileFollowers
          id={profile.uid}
          followers={parseInt(profile.countFollowers)}
          following={parseInt(profile.countFollowing)}
        />
      ) : (
        <div className="flex">
          <div className="h-6 w-28 rounded-sm bg-gray-200 mr-3"></div>
          <div className="h-6 w-28 rounded-sm bg-gray-200"></div>
        </div>
      )}

      <Divider color="silver" className="my-6 md:my-9" />

      <h3 className="text-xl">{t('aboutUs')}</h3>

      {profile?.location && (
        <Location
          location={profile.location}
          className="my-7 text-white-secondary"
        />
      )}
      {profile.description && (
        <ClampLines
          text={profile.description}
          lines={5}
          ellipsis="..."
          moreText={t('seeMore')}
          lessText={t('seeLess')}
          className="text-xs md:text-sm text-white-secondary font-manrope leading-5 md:leading-7"
          innerElement="p"
        />
      )}

      {profile.companyResponsableName && (
        <div className="mt-6 flex items-center">
          <Icon
            name="User"
            width={17}
            height={17}
            canvasWidth={19}
            center={true}
            className="mr-3"
          />
          <span className="text-yellow text-sm">
            {profile.companyResponsableName} ({profile.companyResponsableRole})
          </span>
        </div>
      )}
      {profile?.phoneNumber && (
        <a
          href={`tel:${profile.phoneNumber}`}
          onClick={() => handleOnWebSiteClick('phone')}
          className="flex items-center my-4 group"
          target="_blank"
          rel="noreferrer"
        >
          <Icon
            name="Smartphone"
            width={11}
            height={19}
            canvasWidth={19}
            center={true}
            className="mr-3"
          />
          <span className="text-sm text-yellow underline group-hover:no-underline">
            +{profile?.phoneNumber}
          </span>
        </a>
      )}
      <a
        href={`mailto:${profile?.email}`}
        onClick={() => handleOnWebSiteClick('email')}
        className="flex items-center group my-4"
        target="_blank"
        rel="noreferrer"
      >
        <Icon
          name="Email"
          width={17}
          height={17}
          canvasWidth={19}
          center={true}
          className="mr-3"
        />
        <span className="text-sm text-yellow underline group-hover:no-underline">
          {profile?.email}
        </span>
      </a>

      <div className="pt-6 md:p-7 lg:p-0">
        <Divider color="silver" className="mb-6 md:my-5 lg:my-9" />

        <div className="flex items-center company-social justify-center lg:justify-start pl-7 lg:pl-0">
          {profile.facebookURL && (
            <a
              href={`${profile.facebookURL}?utm_source=TheMachineClub`}
              target="_blank"
              rel="noopener noreferrer"
              className="mr-7"
              onClick={() => handleOnWebSiteClick('facebook')}
            >
              <Icon name="Facebookyellow" width={25} height={25} />
            </a>
          )}

          {profile.instagramURL && (
            <a
              href={`${profile.instagramURL}?utm_source=TheMachineClub`}
              target="_blank"
              rel="noopener noreferrer"
              className="mr-7"
              onClick={() => handleOnWebSiteClick('instagram')}
            >
              <Icon name="Instagram" width={26} height={21} />
            </a>
          )}

          {profile.linkedinURL && (
            <a
              href={`${profile.linkedinURL}?utm_source=TheMachineClub`}
              target="_blank"
              rel="noopener noreferrer"
              className="mr-7"
              onClick={() => handleOnWebSiteClick('linkedIn')}
            >
              <Icon name="Linkedin" width={23} height={22} />
            </a>
          )}

          {profile.twitterURL && (
            <a
              href={`${profile.twitterURL}?utm_source=TheMachineClub`}
              target="_blank"
              rel="noopener noreferrer"
              className="mr-7"
              onClick={() => handleOnWebSiteClick('twitter')}
            >
              <Icon name="Twitter" width={26} height={21} />
            </a>
          )}
        </div>
        {profile.websiteURL && (
          <a
            href={`${profile.websiteURL}?utm_source=TheMachineClub`}
            target="_blank"
            rel="noopener noreferrer"
            className={cn(
              'mr-7 flex justify-center lg:justify-start hover:underline text-yellow',
              {
                'mt-4':
                  profile.twitterURL ||
                  profile.linkedinURL ||
                  profile.instagramURL ||
                  profile.facebookURL
              }
            )}
            onClick={() => handleOnWebSiteClick('web')}
          >
            <Icon className="mr-2" name="World" width={25} height={25} />
            <span className="text-yellow">
              {formatWebsite(profile.websiteURL)}
            </span>
          </a>
        )}
      </div>

      {!isDesktop && (
        <ProfileActionsMobile profile={profile} className="mt-6" />
      )}
    </div>
  );
};
