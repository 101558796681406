import { useQueryParams, StringParam } from 'use-query-params';

export const useReturnURLQueryParam = () => {
  const [query, setQuery] = useQueryParams({
    r: StringParam
  });

  const setR = (value) => {
    setQuery({ ...query, r: value });
  };

  const removeR = () => {
    setQuery({ ...query, r: undefined });
  };

  const getR = () => query?.r || '/';

  return {
    getR,
    setR,
    removeR
  };
};

export default useReturnURLQueryParam;
