import { Tooltip, Icon } from 'components';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';

import './index.scss';

export const ConditionChip = ({
  text,
  tooltipText,
  rent,
  size = 'md',
  className
}) => {
  const { t } = useTranslation();
  return (
    <Tooltip
      title={tooltipText && t('withOperator')}
      placement="right"
      disableHoverListener={!tooltipText}
    >
      <div
        className={cn(
          'cb-chip w-min bg-black-light',
          className,
          {
            'yellow-rent': rent,
            'text-yellow border-yellow': !rent
          },
          `size-${size}`
        )}
      >
        {text}

        {tooltipText && (
          <Icon
            name="Operator"
            width={size === 'sm' ? 8 : 10}
            height={size === 'sm' ? 8 : 10}
            canvasWidth={size === 'sm' ? 17 : 22}
            canvasHeight={size === 'sm' ? 17 : 22}
            rounded
            center
          />
        )}
      </div>
    </Tooltip>
  );
};
