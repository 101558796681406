import axios from 'utils/axiosNotifications';

export const getNotifications = async (page = 0, size = 100) => {
  try {
    const { data } = await axios.get(
      `${process.env.REACT_APP_API_NOTIFICATIONS}app-notifications?page=${page}&size=${size}`
    );
    return data;
  } catch (error) {
    throw error;
  }
};

export const putSeenNotification = async (notificationId) => {
  try {
    const response = await axios.put(
      `${process.env.REACT_APP_API_NOTIFICATIONS}app-notifications/${notificationId}`
    );
    return response;
  } catch (error) {
    throw error;
  }
};

export const seenAll = async (quotations = false) => {
  try {
    const response = await axios.put(
      `${process.env.REACT_APP_API_NOTIFICATIONS}app-notifications/seen-all`,
      { quotations }
    );
    return response;
  } catch (error) {
    throw error;
  }
};
