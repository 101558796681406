import React from 'react';
import { useTranslation } from 'react-i18next';
import { Modal, Icon, ForwardButton } from 'components';
import { Link } from 'react-router-dom';

import './index.scss';

export const PublishNowModal = ({ visible, toggleModal }) => {
  const { t } = useTranslation();

  return (
    <Modal
      visible={visible}
      toggle={() => toggleModal(!visible)}
      className="cb-upload-modal"
      title={t('publishNow')}
    >
      <div className="w-full md:w-170 flex pb-10 flex-col md:flex-row">
        <Link
          to="/social-club"
          onClick={() => toggleModal(false)}
          className=" shadow-m w-full rounded-lg py-3 md:py-0 md:pb-5 cursor-pointer transition-all duration-300 hover:shadow-sm border hover:border-yellow flex items-center md:block h-28 md:h-auto bg-black-light"
        >
          <div className="flex w-1/2 md:w-full justify-center md:h-40 items-center">
            <Icon
              name="GrayImage"
              width={82}
              height={82}
              className="transform scale-75 md:scale-100 text-yellow"
            />
          </div>
          <div className="px-4 md:px-6">
            <span className="text-lg md:text-xl leading-7 tracking-tight capitalize font-semibold">
              {t('postsOnTheClub')}
            </span>
            <p class="text-xs opacity-50 h-8">
              {t('allTheCommunityWillSeeYourPost')}
            </p>
            <ForwardButton className="mt-2.5">{t('select')}</ForwardButton>
          </div>
        </Link>

        <Link
          to="/upload"
          onClick={() => toggleModal(false)}
          className=" shadow-m w-full rounded-lg py-3 md:py-0 md:pb-5 mt-5 md:mt-0 md:ml-5 cursor-pointer transition-all duration-300 hover:shadow-sm border hover:border-yellow flex items-center md:block h-28 md:h-auto bg-black-light"
        >
          <div className="flex w-1/2 md:w-full justify-center md:h-40 items-center">
            <Icon
              name="Machine"
              width={82}
              height={82}
              className="transform scale-75 md:scale-100"
            />
          </div>
          <div className="px-4 md:px-6">
            <span className="text-lg md:text-xl leading-7 tracking-tight capitalize font-semibold">
              {t('machineForSell')}
            </span>
            <ForwardButton className="mt-2.5">{t('select')}</ForwardButton>
          </div>
        </Link>

        <Link
          to="/dashboard/parts/new"
          onClick={() => toggleModal(false)}
          className="relative shadow-m w-full rounded-lg py-3 md:py-0 md:pb-5 mt-5 md:mt-0 md:mx-5 pointer-events-none transition-all duration-300 hover:shadow-sm border hover:border-yellow flex items-center md:block h-28 md:h-auto disabled bg-black-light"
        >
          <div className="flex w-1/2 md:w-full justify-center md:h-40 items-center text-gray">
            <Icon
              name="SparePart"
              width={88}
              height={53}
              className="transform scale-75 md:scale-100"
            />
          </div>
          <div className="px-4 md:px-6">
            <span className="text-md sm:text-xl leading-3 sm:leading-7 tracking-tight font-semibold">
              {`${t('parts')} ${t('and')} ${t('accesories')}`}
            </span>
            {/* <ForwardButton className="mt-2.5">{t('select')}</ForwardButton> */}
            <div className="text-gray font-medium mt-2.5">
              {t('comingSoon')}
            </div>
          </div>
        </Link>
      </div>
    </Modal>
  );
};
