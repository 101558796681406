import React from 'react';
import cn from 'classnames';
import { Icon } from 'components';
import './index.scss';

export const SearchInput = ({ setSearch, placeholder, className, inputClasses, value }) => {
  const handleInputChange = (e) => {
    const query = e.target.value;
    setSearch(query);
  };

  return (
    <div className={cn('cb-search-input', className)}>
      <div className="relative flex-grow">
        <div className="relative search-input">
          <Icon name="Search" />
          <input
            type="text"
            value={value}
            className={inputClasses}
            onChange={handleInputChange}
            placeholder={placeholder}
          />
        </div>
      </div>
    </div>
  );
};
