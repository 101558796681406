import { publicationToURL } from 'utils/formatter';
import React from 'react';
import { Link } from 'react-router-dom';
import { CurrencyFormat } from '..';
import { useTranslation } from 'react-i18next';

export const DashboardItemPublication = ({ publication }) => {
  const { t } = useTranslation();

  return (
    <Link
      to={publicationToURL(publication)}
      className="py-4 flex items-center justify-between border-solid border-b border-gray-light last:border-0"
    >
      <div className="flex items-center w-4/5">
        <div
          className="w-12 h-10 rounded-md bg-cover bg-center shadow-lg"
          style={{
            background: `url('${publication.publicationFiles[0].url}')`
          }}
        ></div>

        <div className="pl-4 text-xs text-white-secondary uppercase">
          <strong className="font-semibold">
            {publication?.customBrand || publication.brand.name}{' '}
          </strong>
          {publication.model}
          {publication.category && (
            <>
              <br />
              <div className="text-gray text-xs capitalize">
                {publication.category.name}
              </div>
            </>
          )}
        </div>
      </div>
      <div className="text-xs text-yellow">
        {publication?.EXWPrice ||
        publication?.nationalRentPrice ||
        publication?.internationalRentPrice ? (
          <CurrencyFormat
            value={
              publication?.EXWPrice ||
              publication?.nationalRentPrice ||
              publication?.internationalRentPrice
            }
            prefix={t(publication?.currency || 'dollar')}
          />
        ) : (
          t('inquire')
        )}
      </div>
    </Link>
  );
};
