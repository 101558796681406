import React, { useEffect, useState, useContext } from 'react';
import { getFeaturedBrands } from 'api/brands';
import { useFilterQueryParams } from 'hooks';
import { ReloadContext } from 'contexts/ReloadContext';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper.min.css';
import SwiperCore, { Autoplay } from 'swiper/core';
import { useMediaQuery } from 'react-responsive';
import cn from 'classnames';
SwiperCore.use([Autoplay]);

export const AutomaticSlideBrands = ({ className, notClick }) => {
  const [brands, setBrands] = useState([]);
  const { setParam } = useFilterQueryParams();
  const { reload, setReload } = useContext(ReloadContext);
  const isMobile = useMediaQuery({
    query: '(max-width: 768px)'
  });

  useEffect(() => {
    load();
  }, []);

  const load = async () => {
    try {
      const brands = await getFeaturedBrands();
      setBrands(brands);
    } catch (err) {}
  };

  const handleClick = (id) => {
    if (notClick) return;
    setParam({ name: 'brands', value: [id] }, '/machines');
    setReload(true);
  };

  return (
    <div className="my-7 md:my-12 rounded-lg py-2 md:py-10">
      <Swiper
        spaceBetween={24}
        slidesPerView={isMobile ? 2 : 5}
        freeMode={true}
        loop={true}
        autoplay={{
          delay: 2000,
          pauseOnMouseEnter: true,
          disableOnInteraction: false
        }}
        className="thumbs-swiper"
      >
        {brands?.map(({ logo, name, id }, index) => (
          <SwiperSlide className="flex items-center justify-center" key={id}>
            <div
              onClick={() => handleClick(id)}
              className={cn(
                'p-3 md:p-0 rounded-lg hover:dark flex justify-center items-center',
                {
                  'cursor-pointer': !notClick
                }
              )}
              key={index}
            >
              <img src={logo} alt={name} className="transform scale-125" />
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};
