import i18n from './i18n';
import Routes from 'routes';
import './App.scss';
import { MetaTags } from 'components';
import '@stream-io/stream-chat-css/dist/css/index.css';
import { MuiThemeProvider, createTheme } from '@material-ui/core/styles';
import { Capacitor } from '@capacitor/core';
import TagManager from 'react-gtm-module';

export const baseUrl = '/' + i18n.language;

if(!Capacitor.isNativePlatform()){
  const tagManagerArgs = {
    gtmId: 'GTM-MDRXNGR'
  };
  
  TagManager.initialize(tagManagerArgs);
}

const theme = createTheme({
  palette: {
    type: 'dark'
  }
});

const App = () => {
  return (
    <div id="App" className="App bg-background py-safe">
      <MetaTags />
      <MuiThemeProvider theme={theme}>
        <Routes />
      </MuiThemeProvider>
    </div>
  );
};

export default App;
