import axios from 'utils/axios';
import { track, trackFB } from 'utils/dataLayer';
import ReactGA from 'react-ga';

export const registerUser = async (user) => {
  try {
    const { data } = await axios.post('api/auth/users', user);
    track({
      event: 'registro'
    });

    trackFB('Registro_Individuals', user);
    ReactGA.event({
      category: 'Individual',
      action: 'Created an Account'
    });
    return data;
  } catch (error) {
    if (process.env.REACT_APP_DEV_MODE !== 'true') {
    }
    console.log('ERROR: ', error);
    throw error;
  }
};
