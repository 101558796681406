import React from 'react';
import { Redirect } from 'react-router-dom';

// Public routes
const Home = React.lazy(() => import('pages/Home'));
const Overview = React.lazy(() => import('pages/Overview'));
const Publication = React.lazy(() => import('pages/Publication'));
const MachineInfluencers = React.lazy(() => import('pages/MachineInfluencers'));
const VIPDealers = React.lazy(() => import('pages/VIPDealers'));
const Suppliers = React.lazy(() => import('pages/Suppliers'));
const PartsSuppliers = React.lazy(() => import('pages/PartsSuppliers'));
const Jobs = React.lazy(() => import('pages/Jobs'));
const Categories = React.lazy(() => import('pages/Categories'));
const Explore = React.lazy(() => import('pages/Explore'));

// Legal routes
const Terms = React.lazy(() => import('pages/legal/Terms'));
const Privacy = React.lazy(() => import('pages/legal/Privacy'));
const Help = React.lazy(() => import('pages/Help'));

// Auth routes
const Login = React.lazy(() => import('pages/auth/Login'));
const Forget = React.lazy(() => import('pages/auth/Forget'));
const Change = React.lazy(() => import('pages/auth/Change'));
const Register = React.lazy(() => import('pages/auth/Register'));

// Dashboard routes
const DashboardHome = React.lazy(() => import('pages/dashboard'));
const Favourites = React.lazy(() => import('pages/dashboard/Favourites'));
const QuotationsReceived = React.lazy(() =>
  import('pages/dashboard/QuotationsReceived')
);
const QuotationsSent = React.lazy(() =>
  import('pages/dashboard/QuotationsSent')
);
const Likes = React.lazy(() => import('pages/dashboard/Likes'));
const RecievedLikes = React.lazy(() => import('pages/dashboard/RecievedLikes'));
const Publications = React.lazy(() => import('pages/dashboard/Publications'));
const DealerBrands = React.lazy(() => import('pages/dashboard/DealerBrands'));
const Brands = React.lazy(() => import('pages/dashboard/Brands'));
const LastSeens = React.lazy(() => import('pages/dashboard/LastSeens'));
const WebVisits = React.lazy(() => import('pages/dashboard/WebVisits'));
const PersonalInfo = React.lazy(() =>
  import('pages/dashboard/settings/PersonalInfo')
);
const CompanyInfo = React.lazy(() =>
  import('pages/dashboard/settings/CompanyInfo')
);
const Account = React.lazy(() => import('pages/dashboard/settings/Account'));
const Settings = React.lazy(() => import('pages/dashboard/settings'));
const MobileMenu = React.lazy(() => import('pages/dashboard/MobileMenu'));
const ChatPage = React.lazy(() => import('pages/dashboard/Chat/index'));
const Notifications = React.lazy(() => import('pages/dashboard/Notifications'));
const DashboardSocial = React.lazy(() => import('pages/dashboard/Social'));
const MyCV = React.lazy(() => import('pages/dashboard/MyCV'));

// Upload routes
const Category = React.lazy(() => import('pages/upload/Category'));
const Brand = React.lazy(() => import('pages/upload/Brand'));
const Model = React.lazy(() => import('pages/upload/Model'));
const Year = React.lazy(() => import('pages/upload/Year'));
const Certification = React.lazy(() => import('pages/upload/Certification'));
const Assets = React.lazy(() => import('pages/upload/Assets'));
const Configuration = React.lazy(() => import('pages/upload/Configuration'));
const Conditions = React.lazy(() => import('pages/upload/Conditions'));
const Location = React.lazy(() => import('pages/upload/Location'));
const Publish = React.lazy(() => import('pages/upload/Publish'));

// Profile routes
const Activity = React.lazy(() =>
  import('pages/profile/Activity').then((module) => ({
    default: module.Activity
  }))
);
const Media = React.lazy(() =>
  import('pages/profile/Media').then((module) => ({ default: module.Media }))
);
const ProfileMachines = React.lazy(() =>
  import('pages/profile/ProfileMachines').then((module) => ({
    default: module.ProfileMachines
  }))
);
const ProfileResume = React.lazy(() =>
  import('pages/profile/ProfileResume').then((module) => ({
    default: module.ProfileResume
  }))
);

// Social routes
const Post = React.lazy(() =>
  import('pages/social/Post').then((module) => ({ default: module.Post }))
);
const PostsBySearchText = React.lazy(() =>
  import('pages/social/PostsBySearchText').then((module) => ({
    default: module.PostsBySearchText
  }))
);

export const publicRoutes = [
  { path: '/terms-and-conditions', component: Terms },
  { path: '/privacy-policy', component: Privacy }
];

export const overviewRoutes = [
  { path: '/social-club', component: Home, exact: true },
  { path: '/suppliers', component: Suppliers, exact: true },
  { path: '/parts-suppliers', component: PartsSuppliers, exact: true },
  { path: '/jobs', component: Jobs, exact: true },
  { path: '/explore', component: Explore },
  { path: '/machines', component: Overview },
  { path: '/categories', component: Categories },
  {
    path: '/p/:type/:category/:brand/:id/:model',
    component: Publication
  },
  { path: '/help', component: Help },
  { path: '/post/:id', component: Post },
  {
    path: '/tags/:searchText',
    component: PostsBySearchText
  },
  {
    path: '/search/:searchText',
    component: PostsBySearchText
  },
  {
    path: '/influencers',
    component: MachineInfluencers
  },
  {
    path: '/vip-members',
    component: VIPDealers
  },
  {
    path: '/home',
    component: (e) => <Redirect to={`/${e.location.search}`} />
  },
  {
    path: '/',
    component: () => <Redirect to="/machines" />
  }
];

export const dashboardRoutes = [
  {
    path: '/dashboard/favourites',
    component: Favourites,
    exact: true
  },
  {
    path: '/dashboard/quotations',
    component: QuotationsReceived,
    exact: true
  },
  {
    path: '/dashboard/quotations-sent',
    component: QuotationsSent,
    exact: true
  },
  {
    path: '/dashboard/likes',
    component: Likes,
    exact: true
  },
  {
    path: '/dashboard/recieved-likes',
    component: RecievedLikes,
    exact: true
  },
  {
    path: '/dashboard/machines/:type?',
    component: Publications,
    exact: true
  },
  {
    path: '/dashboard/dealer-brands',
    component: DealerBrands,
    exact: true
  },
  {
    path: '/dashboard/brands/:type?',
    component: Brands,
    exact: true
  },
  {
    path: '/dashboard/last-seens',
    component: LastSeens,
    exact: true
  },
  {
    path: '/dashboard/clicks/:filter?',
    component: WebVisits,
    exact: true
  },
  {
    path: '/dashboard',
    component: DashboardHome,
    exact: true
  },
  {
    path: '/dashboard/settings',
    component: Settings,
    exact: true
  },
  {
    path: '/dashboard/settings/personal',
    component: PersonalInfo,
    exact: true
  },
  {
    path: '/dashboard/settings/account',
    component: Account,
    exact: true
  },
  {
    path: '/dashboard/settings/company',
    component: CompanyInfo,
    exact: true
  },
  {
    path: '/dashboard/menu',
    component: MobileMenu,
    exact: true
  },
  {
    path: '/dashboard/chat/:customChannel?',
    component: ChatPage,
    exact: true
  },
  {
    path: '/dashboard/notifications',
    component: Notifications,
    exact: true
  },
  {
    path: '/dashboard/social/:filter?',
    component: DashboardSocial,
    exact: true
  },
  {
    path: '/dashboard/my-cv',
    component: MyCV,
    exact: true
  }
];

export const authRoutes = [
  {
    path: '/auth/login',
    component: Login,
    exact: true
  },
  {
    path: '/auth/change-password',
    component: Change,
    exact: true
  },
  {
    path: '/auth/forget-password',
    component: Forget,
    exact: true
  },
  {
    path: '/auth/register',
    component: Register,
    exact: true
  },
  {
    path: '/auth',
    component: (e) => <Redirect to="/auth/register" />
  }
];

export const uploadRoutes = [
  {
    path: '/upload/category',
    component: Category,
    onlyCompany: true
  },
  { path: '/upload/brand', component: Brand },
  { path: '/upload/model', component: Model },
  { path: '/upload/year', component: Year },
  {
    path: '/upload/certification',
    component: Certification
  },
  { path: '/upload/assets', component: Assets },
  {
    path: '/upload/configuration',
    component: Configuration
  },
  {
    path: '/upload/conditions',
    component: Conditions
  },
  { path: '/upload/location', component: Location },
  { path: '/upload/publish', component: Publish }
];

export const editRoutes = [
  {
    path: '/edit/category',
    component: Category,
    onlyCompany: true
  },
  {
    path: '/edit/brand',
    component: Brand,
    onlyCompany: true
  },
  {
    path: '/edit/model',
    component: Model,
    onlyCompany: true
  },
  {
    path: '/edit/year',
    component: Year,
    onlyCompany: true
  },
  {
    path: '/edit/certification',
    component: Certification,
    onlyCompany: true
  },
  {
    path: '/edit/assets',
    component: Assets,
    onlyCompany: true
  },
  {
    path: '/edit/configuration',
    component: Configuration,
    onlyCompany: true
  },
  {
    path: '/edit/conditions',
    component: Conditions,
    onlyCompany: true
  },
  {
    path: '/edit/location',
    component: Location,
    onlyCompany: true
  },
  {
    path: '/edit/publish',
    component: Publish,
    onlyCompany: true
  }
];

export const profileRoutes = [
  {
    path: '/u/:id/:name/activity',
    component: Activity
  },
  { path: '/u/:id/:name/media', component: Media },
  {
    path: '/u/:id/:name/machines',
    component: ProfileMachines
  },
  {
    path: '/u/:id/:name/curriculum',
    component: ProfileResume
  },
  {
    path: '/u/:id/:name/machines',
    component: ProfileMachines
  },
  {
    path: '/u/:id/:name',
    component: (e) => <Redirect to={`${e.match.url}/activity`} />
  }
];
