import React, { createContext, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

export const UploadContext = createContext();

export default function Provider({ children }) {
  const history = useHistory();
  const location = useLocation();
  const [upload, setUpload] = useState({});
  const [currentStep, setCurrentStep] = useState(0);

  const isEdit = location.pathname.includes('edit');
  const triggerEdit = () => {
    history.push('/edit/location', true);
  };

  return (
    <UploadContext.Provider
      value={{
        upload,
        setUpload,
        currentStep,
        setCurrentStep,
        triggerEdit,
        isEdit
      }}
    >
      {children}
    </UploadContext.Provider>
  );
}
