import axios from 'utils/axios';
import { uploadFile } from 'api/files';

export const getAll = async (searchText, page = 0, size = 10) => {
  try {
    const res = await axios.get(
      !searchText
        ? `api/social/posts?page=${page}&size=${size}`
        : `api/social/posts/by-searchtext/${searchText.replace('#', '')}${
            searchText.includes('#') ? '?hashtag=true' : ''
          }`
    );
    return res.data;
  } catch (error) {
    console.log(error.message);
  }
};

export const getById = async (id) => {
  try {
    const { data } = await axios.get(`api/social/posts/post/${id}`);
    return data;
  } catch (error) {
    console.log(error.message);
  }
};

export const getPostsByUser = async (userId, page = 0, size = 10) => {
  try {
    const res = await axios.get(
      `api/social/posts/${userId}?page=${page}&size=${size}`
    );
    return res.data;
  } catch (error) {
    console.log(error.message);
  }
};

export const getPostLikes = async (postId) => {
  try {
    const res = await axios.get(`api/social/posts-likes/${postId}`);
    return res.data;
  } catch (error) {
    console.log(error.message);
  }
};

export const getReducePostLikes = async (postId) => {
  try {
    const res = await axios.get(`api/social/posts-likes/reduce/${postId}`);
    return res.data;
  } catch (error) {
    console.log(error.message);
  }
};

export const savePostLike = async (postId) => {
  try {
    const res = await axios.post(`api/social/posts-likes`, { postId });
    return res;
  } catch (error) {
    console.log(error.message);
  }
};

export const postPost = async (post) => {
  try {
    const res = await axios.post(`api/social/posts`, post);
    return res;
  } catch (error) {
    console.log(error.message);
  }
};

export const deletePost = async (id) => {
  try {
    const res = await axios.delete(`api/social/posts/${id}`);
    return res;
  } catch (error) {
    console.log(error.message);
  }
};

export const editPost = (id, content) => {
  try {
    return axios.put(`api/social/posts/${id}`, content);
  } catch (error) {
    console.log(error.message);
  }
};

export const uploadPostFiles = async (userId, postUid, files) => {
  try {
    let newUrls = [];
    await Promise.all(
      files.map(async (f) => {
        if (f) {
          const { urls } = await uploadFile(f.file, {
            destination: `users/${userId}/posts/${postUid}`,
            resize: [{ width: '800', height: '800' }]
          });
          const newURL = urls[0];
          newUrls = [...newUrls, newURL];
          // !!oldURL && oldURL !== newURL && (await deleteFile(oldURL));
          return newURL;
        }
      })
    );
    return newUrls;
  } catch (error) {
    if (process.env.REACT_APP_DEV_MODE !== 'true') {
    }
    throw error;
  }
};

export const getLastMedia = async (id, page, size) => {
  try {
    const res = await axios.get(
      `api/social/posts-files/${id}?page=${page}&size=${size}`
    );
    return res.data;
  } catch (error) {
    throw error;
  }
};

export const getPostsFiles = async (page, size, search = '', useCache) => {
  try {
    const res = await axios.get(
      `api/social/posts-files?page=${page}&size=${size}&search=${search}`,
      { useCache }
    );
    return res.data;
  } catch (error) {
    throw error;
  }
};
