import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import { ReactComponent as Bag } from 'assets/img/icons/bag.svg';
import { ReactComponent as Instagram } from 'assets/img/icons/instagram.svg';
import { ReactComponent as Facebookyellow } from 'assets/img/icons/facebook.svg';
import { ReactComponent as Linkedin } from 'assets/img/icons/linkedin.svg';
import { ReactComponent as Settings } from 'assets/img/icons/settings.svg';
import { ReactComponent as Pencil } from 'assets/img/icons/pencil.svg';
import { ReactComponent as Stop } from 'assets/img/icons/stop.svg';
import { ReactComponent as Location } from 'assets/img/icons/location.svg';
import { ReactComponent as Hamburger } from 'assets/img/icons/hamburger.svg';
import { ReactComponent as Dashboard } from 'assets/img/icons/dashboard.svg';
import { ReactComponent as SignIn } from 'assets/img/icons/sign-in.svg';
import { ReactComponent as Twitter } from 'assets/img/icons/twitter.svg';
import { ReactComponent as Tick } from 'assets/img/icons/tick.svg';
import { ReactComponent as TickSolid } from 'assets/img/icons/tick-solid.svg';
import { ReactComponent as US } from 'assets/img/icons/US.svg';
import { ReactComponent as Spain } from 'assets/img/icons/spain.svg';
import { ReactComponent as Company } from 'assets/img/icons/company.svg';
import { ReactComponent as Trash } from 'assets/img/icons/trash.svg';
import { ReactComponent as Heart } from 'assets/img/icons/heart.svg';
import { ReactComponent as HeartSolid } from 'assets/img/icons/heart-solid.svg';
import { ReactComponent as Plus } from 'assets/img/icons/plus.svg';
import { ReactComponent as PlusRounded } from 'assets/img/icons/plus-rounded.svg';
import { ReactComponent as Warning } from 'assets/img/icons/warning.svg';
import { ReactComponent as File } from 'assets/img/icons/file.svg';
import { ReactComponent as Video } from 'assets/img/icons/video.svg';
import { ReactComponent as Image } from 'assets/img/icons/image.svg';
import { ReactComponent as Bookmark } from 'assets/img/icons/bookmark.svg';
import { ReactComponent as BookmarkSolid } from 'assets/img/icons/bookmark-solid.svg';
import { ReactComponent as House } from 'assets/img/icons/house.svg';
import { ReactComponent as Machine } from 'assets/img/icons/machine.svg';
import { ReactComponent as Spinner } from 'assets/img/icons/spinner.svg';
import { ReactComponent as User } from 'assets/img/icons/user.svg';
import { ReactComponent as UserSolid } from 'assets/img/icons/user-solid.svg';
import { ReactComponent as Bell } from 'assets/img/icons/bell.svg';
import { ReactComponent as Bells } from 'assets/img/icons/bells.svg';
import { ReactComponent as BellsWhite } from 'assets/img/icons/bells-white.svg';
import { ReactComponent as Dialog } from 'assets/img/icons/dialog.svg';
import { ReactComponent as Logout } from 'assets/img/icons/logout.svg';
import { ReactComponent as Target } from 'assets/img/icons/target.svg';
import { ReactComponent as Search } from 'assets/img/icons/search.svg';
import { ReactComponent as ChevronRight } from 'assets/img/icons/chevron-right.svg';
import { ReactComponent as ChevronLeft } from 'assets/img/icons/chevron-left.svg';
import { ReactComponent as ChevronDown } from 'assets/img/icons/chevron-down.svg';
import { ReactComponent as ChevronRightCircle } from 'assets/img/icons/chevron-right-circle.svg';
import { ReactComponent as ShowPassword } from 'assets/img/icons/show-password.svg';
import { ReactComponent as HidePassword } from 'assets/img/icons/hide-password.svg';
import { ReactComponent as Facebook } from 'assets/img/icons/social/facebook.svg';
import { ReactComponent as Google } from 'assets/img/icons/social/google.svg';
import { ReactComponent as Apple } from 'assets/img/icons/social/apple.svg';
import { ReactComponent as LinkedIn } from 'assets/img/icons/social/linkedin.svg';
import { ReactComponent as Share } from 'assets/img/icons/share.svg';
import { ReactComponent as CameraSolid } from 'assets/img/icons/camera-solid.svg';
import { ReactComponent as List } from 'assets/img/icons/list.svg';
import { ReactComponent as Grid } from 'assets/img/icons/grid.svg';
import { ReactComponent as Play } from 'assets/img/icons/play.svg';
import { ReactComponent as ArrowRightCircle } from 'assets/img/icons/arrow-right-circle.svg';
import { ReactComponent as Certificate } from 'assets/img/icons/certificate.svg';
import { ReactComponent as CertificateYellow } from 'assets/img/icons/certificate-orange.svg';
import { ReactComponent as VerifiedBadget } from 'assets/img/icons/verified-badget.svg';
import { ReactComponent as Email } from 'assets/img/icons/email.svg';
import { ReactComponent as Smartphone } from 'assets/img/icons/smartphone.svg';
import { ReactComponent as Close } from 'assets/img/icons/close.svg';
import { ReactComponent as SolidClose } from 'assets/img/icons/solid-close.svg';
import { ReactComponent as Eye } from 'assets/img/icons/eye.svg';
import { ReactComponent as World } from 'assets/img/icons/world.svg';
import { ReactComponent as VerticalDots } from 'assets/img/icons/vertical-dots.svg';
import { ReactComponent as Filter } from 'assets/img/icons/filter.svg';
import { ReactComponent as Upload } from 'assets/img/icons/upload.svg';
import { ReactComponent as Youtube } from 'assets/img/icons/youtube.svg';
import { ReactComponent as Operator } from 'assets/img/icons/operator.svg';
import { ReactComponent as SparePart } from 'assets/img/icons/spare-part.svg';
import { ReactComponent as Accessory } from 'assets/img/icons/accesory.svg';
import { ReactComponent as Hamburguer } from 'assets/img/icons/hamburguer.svg';
import { ReactComponent as Brands } from 'assets/img/icons/brands.svg';
import { ReactComponent as FullScreen } from 'assets/img/icons/fullscreen.svg';
import { ReactComponent as Comments } from 'assets/img/icons/comments.svg';
import { ReactComponent as Send } from 'assets/img/icons/send.svg';
import { ReactComponent as ThumbsUp } from 'assets/img/icons/thumbs-up.svg';
import { ReactComponent as EmojiPicker } from 'assets/img/icons/emoji-picker.svg';
import { ReactComponent as Chat } from 'assets/img/icons/chat.svg';
import { ReactComponent as ChatWhite } from 'assets/img/icons/chat-white.svg';
import { ReactComponent as GrayImage } from 'assets/img/icons/gray-image.svg';
import { ReactComponent as Dots } from 'assets/img/icons/dots.svg';
import { ReactComponent as Pen } from 'assets/img/icons/pen.svg';
import { ReactComponent as Users } from 'assets/img/icons/users.svg';
import { ReactComponent as Metrics } from 'assets/img/icons/metrics.svg';
import { ReactComponent as Posts } from 'assets/img/icons/posts.svg';
import { ReactComponent as Influencer } from 'assets/img/icons/influencer.svg';
import { ReactComponent as Vip } from 'assets/img/icons/vip.svg';
import { ReactComponent as OpenToWork } from 'assets/img/icons/open-to-work.svg';
import { ReactComponent as OpenToWorkOperator } from 'assets/img/icons/open-to-work-operator.svg';
import { ReactComponent as Club } from 'assets/img/icons/club.svg';

import './index.scss';

const SvgIcons = {
  Bag,
  Bookmark,
  House,
  Machine,
  User,
  UserSolid,
  Bell,
  Bells,
  Dialog,
  Logout,
  Target,
  Search,
  ChevronLeft,
  ChevronDown,
  ChevronRight,
  ChevronRightCircle,
  ShowPassword,
  HidePassword,
  Facebook,
  Google,
  Apple,
  LinkedIn,
  Video,
  Image,
  Trash,
  Heart,
  Plus,
  PlusRounded,
  List,
  Grid,
  Warning,
  Spinner,
  Instagram,
  Facebookyellow,
  Linkedin,
  Twitter,
  Location,
  US,
  Spain,
  Play,
  ArrowRightCircle,
  Company,
  Share,
  Certificate,
  CertificateYellow,
  VerifiedBadget,
  Email,
  Smartphone,
  Close,
  SolidClose,
  HeartSolid,
  BookmarkSolid,
  Eye,
  TickSolid,
  Tick,
  World,
  SignIn,
  VerticalDots,
  Pencil,
  Stop,
  Hamburger,
  Dashboard,
  File,
  Settings,
  Filter,
  Upload,
  Youtube,
  CameraSolid,
  Operator,
  SparePart,
  Hamburguer,
  Accessory,
  Brands,
  FullScreen,
  Comments,
  Send,
  EmojiPicker,
  Chat,
  GrayImage,
  Dots,
  ThumbsUp,
  BellsWhite,
  ChatWhite,
  Pen,
  Users,
  Metrics,
  Posts,
  Influencer,
  Vip,
  OpenToWork,
  OpenToWorkOperator,
  Club
};

export const Icon = ({
  name,
  className,
  width = 16,
  height = 16,
  canvasWidth,
  canvasHeight,
  onClick,
  loading,
  rounded,
  center,
  disabled,
  classNameSpinner,
  ...rest
}) => {
  const SvgIcon = SvgIcons[name];

  const classes = cn('cb-icon', `cb-icon-${name.toLowerCase()}`, className, {
    'cursor-pointer': onClick,
    'flex justify-center items-center': center,
    'bg-blue-light': name === 'Operator',
    disabled,
    rounded,
    loading
  });
  return (
    <div
      style={{ width: canvasWidth, height: canvasHeight }}
      className={classes}
      onClick={onClick}
    >
      {loading ? (
        <div className={cn(classNameSpinner)}>
          <Spinner
            width={width}
            height={height}
            aria-hidden="true"
            role="img"
            focusable={false}
            {...rest}
          />
        </div>
      ) : (
        <SvgIcon
          width={width}
          height={height}
          aria-hidden="true"
          role="img"
          focusable={false}
          {...rest}
        />
      )}
    </div>
  );
};

Icon.propTypes = {
  name: PropTypes.oneOf(Object.keys(SvgIcons)).isRequired,
  className: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
  canvasWidth: PropTypes.number,
  canvasHeight: PropTypes.number,
  classNameSpinner: PropTypes.string,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  center: PropTypes.bool
};
