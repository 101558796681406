import React from 'react';
import { useTranslation } from 'react-i18next';
import { locationToString } from 'utils/formatter';
import { Modal, Icon, Divider } from 'components';

export const PublicationModalContact = ({
  publication,
  visible,
  toggleModal
}) => {
  const { t } = useTranslation();

  return (
    <Modal
      visible={visible}
      toggle={toggleModal}
      title={t('theDealerHasBeenContacted')}
      buttons={[
        {
          variant: 'outline',
          text: 'close' //translation key
        }
      ]}
    >
      <div className="w-full md:w-max">
        <p className="max-w-sm">{t('modalContactText')}</p>

        <Divider className="my-4 md:my-8" />

        <p className="mb-5 font-semibold text-gray">
          {t('youCanContactTheDealerAnytimeAt')}
        </p>

        <div className="flex items-center">
          <Icon
            name="Target"
            width={21}
            height={21}
            canvasWidth={22}
            center={true}
            className="mr-3"
          />
          <span className="text-sm text-white">
            {locationToString(publication?.location)}
          </span>
        </div>

        <a
          href={`tel:+${publication?.contactPhone}`}
          className="flex items-center my-4 group"
          target="_blank"
        >
          <Icon
            name="Smartphone"
            width={14}
            height={22}
            canvasWidth={22}
            center={true}
            className="mr-3"
          />
          <span className="text-sm text-yellow underline group-hover:no-underline">
            +{publication?.contactPhone}
          </span>
        </a>

        <a
          href={`mailto:${
            publication?.contactEmail || publication?.user?.email
          }`}
          className="flex items-center group"
          target="_blank"
        >
          <Icon
            name="Email"
            width={20}
            height={20}
            canvasWidth={22}
            center={true}
            className="mr-3"
          />
          <span className="text-sm text-yellow underline group-hover:no-underline">
            {publication?.contactEmail || publication?.user?.email}
          </span>
        </a>
      </div>
    </Modal>
  );
};
