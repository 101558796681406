import React, { useContext } from 'react';
import { UserCard } from '@estudio-nk/the-crane-club-client-lib';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { profileToURL } from 'utils/formatter';

import { useModal } from 'hooks';
import { LoginModal } from 'components';
import { followUser } from 'api/social/follows';
import { UserContext } from 'contexts/UserContext';

export const UserCardContainer = ({ user, loading, newTab = true }) => {
  const { user: currentUser } = useContext(UserContext);
  const { t } = useTranslation();
  const { visible, toggleModal } = useModal();

  const handleOnFollow = async () => {
    try {
      if (currentUser) {
        await followUser(user.uid);
      } else {
        toggleModal();
      }
    } catch (error) {
      toast(t('error.following'));
      throw error;
    }
  };

  return (
    <>
      <UserCard
        user={user}
        colorVariant="yellow"
        onFollowClick={handleOnFollow}
        loading={loading}
        newTab={newTab}
        linkTo={profileToURL(user)}
      />
      <LoginModal visible={visible} toggleModal={toggleModal} />
    </>
  );
};
