export const TermsEsp = () => (
  <div className="wrapper-md legal">
    <h2>Términos y condiciones</h2>
    <p>
      A continuación se describen los términos y condiciones generales (los
      "TCG") aplicables al uso de los servicios ofrecidos por THE CRANE CLUB
      INC. (“THE CRANE CLUB”), una sociedad constituida en La Florida, Estados
      Unidos de Norteamérica, (en adelante "los Servicios") ya sea dentro de la
      APLICACIÓN DE THE CRANE CLUB (APP), o dentro del sitio web{' '}
      <a href="www.thecraneclub.com">www.thecraneclub.com</a>, o a través de las
      redes sociales Instagram usuario: @thecraneclub.ok, o las redes sociales o
      aplicaciones móviles que en el futuro indique THE CRANE CLUB. Cualquier
      persona que desee acceder y/o usar el sitio o los servicios podrá hacerlo
      sujetándose a los TCG, junto con todas las demás políticas y principios
      que indique THE CRANE CLUB.
    </p>
    <p>
      THE CRANE CLUB a través de la APP o del Sitio Web tendrá diferentes fases,
      siendo una de ellas una revista virtual donde se podrán inscribir
      Concesionarios, y Empresas que alquilan o vendan grúas y equipos, y
      Empresas Constructoras y otros terceros que vendan o alquilen grúas y
      equipos, y que THE CRANE CLUB indique o autorice.{' '}
    </p>
    <p>
      THE CRANE CLUB también ampliará en una segunda fase la posibilidad de que
      los Usuarios comercialicen aparte de grúas y equipos todo tipo de
      repuestos siendo solo el medio donde se publicitan y no asumiendo
      responsabilidad alguna en la comercialización de los equipos o productos
      entre diferentes Usuarios.{' '}
    </p>
    <p>
      Cualquier persona que no acepte estos términos y condiciones generales,
      los cuales tienen un carácter obligatorio y vinculante, deberá abstenerse
      de utilizar el sitio y/o los servicios.
    </p>
    <p>
      El Usuario debe leer, entender y aceptar todas las condiciones
      establecidas en los TCG y en las Políticas de Privacidad así como en los
      demás documentos incorporados a los mismos por referencia, previo a su
      inscripción como Usuario de THE CRANE CLUB.
    </p>
    <h3>PRIMERO: Capacidad para representar personas jurídicas</h3>
    <p>
      Los Servicios sólo están disponibles para personas que tengan capacidad
      legal para contratar en su país de origen o lugar de constitución. No
      podrán utilizar los servicios las personas que no tengan esa capacidad,
      los menores de edad o Usuarios que hayan sido suspendidos temporalmente o
      inhabilitados definitivamente. En el caso de inscripción de una persona
      jurídica como Usuario, deberá tener la persona humana que lo representa
      capacidad suficiente para contratar en nombre de tal sociedad y con
      facultades para obligar a la misma conforme estos TCG.
    </p>
    <h3>SEGUNDA: Proceso de inscripción y veracidad de la información </h3>
    <p>
      Es obligatorio completar el formulario de inscripción que eventualmente
      THE CRANE CLUB solicite en todos sus campos, con datos válidos para poder
      utilizar los servicios que brinda THE CRANE CLUB. El Usuario deberá
      completarlo con su información personal (o de la sociedad que representa)
      de manera exacta, precisa y verdadera ("Datos Personales") y asume el
      compromiso de actualizar los Datos Personales conforme resulte necesario.
      THE CRABE CLUB podrá requerir en todo momento información adicional y
      podrá utilizar diversos medios para identificar a sus Usuarios, pero THE
      CRANE CLUB no será responsable por la certeza de los Datos Personales
      provistos por sus Usuarios. Los Usuarios garantizan y responden frente a
      THE CRANE CLUB y a terceros, en cualquier caso, de la veracidad,
      exactitud, vigencia y autenticidad de los Datos Personales ingresados.
    </p>
    <p>
      THE CRANE CLUB se reserva el derecho de solicitar documentación y/o
      información adicional a efectos de corroborar los Datos Personales, así
      como de suspender temporal o definitivamente a aquellos Usuarios cuyos
      datos no hayan podido ser confirmados.{' '}
    </p>
    <p>
      El Usuario accederá a su cuenta personal de THE CRANE CLUB ("Cuenta")
      mediante el ingreso del usuario seleccionado y de la clave de seguridad
      personal elegida ("Clave de Seguridad"). El Usuario se obliga a mantener
      la confidencialidad de su Clave de Seguridad.
    </p>
    <p>
      La Cuenta es personal, única e intransferible, y está prohibido que un
      mismo Usuario inscriba o posea más de una Cuenta. En caso de que THE CRANE
      CLUB detecte distintas Cuentas que contengan datos coincidentes o
      relacionados, podrá cancelar, suspender o inhabilitarlas.
    </p>
    <p>
      El Usuario será responsable por todas las operaciones efectuadas en su
      Cuenta, pues el acceso a la misma está restringido al ingreso y uso de su
      Clave de Seguridad, de conocimiento exclusivo del Usuario. El Usuario se
      compromete a notificar a THE CRANE CLUB en forma inmediata y por medio
      idóneo y fehaciente, cualquier uso no autorizado de su Cuenta, así como el
      ingreso por terceros no autorizados a la misma. Se aclara que está
      prohibida la venta, cesión o transferencia de la Cuenta (incluyendo la
      reputación y calificaciones) bajo ningún título.
    </p>
    <p>
      THE CRANE CLUB se reserva el derecho de rechazar cualquier solicitud de
      inscripción o de cancelar una inscripción previamente aceptada, sin que
      esté obligado a comunicar o exponer las razones de su decisión y sin que
      ello genere algún derecho a indemnización o resarcimiento.
    </p>
    <p>
      THE CRANE CLUB podrá rechazar a su exclusivo criterio la inscripción como
      Usuario de cualquier persona que provenga de jurisdicciones que no cumplan
      con las normativas internacionales sobre Prevención y control de lavado de
      activos y financiamiento del terrorismo; tengan sanciones penales; se
      encuentren en proceso concursal o quiebra, o por cualquier otro
      antecedente que considere THE CRANE CLUB.
    </p>
    <p>
      Al adherir a estos TCG, los Usuarios vendedores se comprometen a cumplir
      con la normatividad fiscal vigente, así como evitar cualquier maniobra que
      pudiera importar el lavado de activos proveniente de los delitos de
      evasión tributaria, contrabando o cualquier otro delito previsto en la
      legislación que implique una defraudación fiscal.{' '}
    </p>
    <h3>TERCERA: Modificaciones de los TCG</h3>
    <p>
      THE CRANE CLUB podrá modificar los TCG en cualquier momento, publicando en
      la APP o en el Sitio Web los términos modificados, y siendo obligatorios a
      partir del quinto día de dicha publicación salvo que THE CRANE CLUB
      indique que estarán vigentes a partir de la publicación. Dichas
      modificaciones serán comunicadas por THE CRANE CLUB a los usuarios que en
      la Configuración de su Cuenta de THE CRANE CLUB hayan indicado que desean
      recibir notificaciones de los cambios en estos Términos y Condiciones.
      Todo usuario que no esté de acuerdo con las modificaciones efectuadas por
      THE CRANE CLUB deberá solicitar la baja de su cuenta y no deberá utilizar
      los servici
    </p>
    <p>
      El uso de la APP o del sitio y/o sus servicios implica la aceptación de
      estos TCG de uso de THE CRANE CLUB.
    </p>
    <h3>CUARTA - Listado de Bienes</h3>
    <p>
      4.1 Al publicar un anuncio en la APP o en el Sitio, el usuario vendedor
      consiente expresamente la utilización del proveedor de sistema de pago
      como una de las formas disponibles para el pago del bien o servicio
      ofrecido y se obliga a no modificar en nada el precio de venta del bien o
      servicio si el comprador decidiera utilizar el sistema o plataforma de
      pago. Asimismo, el usuario declara que ha leído, comprendido y aceptado
      los Términos y Condiciones de utilización de dicho servicio.
    </p>
    <p>
      4.2 Pago de tarifas de THE CRANE CLUB. Al publicar un anuncio en la APP o
      en el sitio, el usuario acepta que los pagos de dichas tarifas se
      procesarán a través del procesador o medio de pago que indique THE CRANE
      CLUB.
    </p>
    <p>
      4.3 Publicación de bienes y/o servicios. El Usuario deberá ofrecer a la
      venta, los bienes y/o servicios en las categorías y subcategorías
      apropiadas. Las publicaciones podrán incluir textos descriptivos,
      gráficos, fotografías y otros contenidos y condiciones pertinentes para la
      venta del bien o la contratación del servicio, siempre que no violen
      ninguna disposición de este acuerdo o demás políticas de THE CRANE CLUB.
      El producto ofrecido por el Usuario Vendedor debe ser exactamente
      descripto en cuanto a sus condiciones y características relevantes. Se
      entiende y presume que mediante la inclusión del bien o servicio en THE
      CRANE CLUB, el Usuario acepta que tiene la intención y el derecho de
      vender el bien por él ofrecido, o está facultado para ello por su titular
      y lo tiene disponible para su entrega inmediata o para el plazo
      especificado en la publicación. Se establece que los precios de los
      productos publicados deberán ser expresados con los impuestos incluidos en
      el país de origen cuando corresponda la aplicación del mismo, y en Dólares
      Estadounidenses o la moneda o criptomoneda del curso legal que indique THE
      CRANE CLUB. THE CRANE CLUB podrá remover cualquier publicación cuyo precio
      no sea expresado de esta forma para evitar confusiones o malos entendidos
      en cuanto al precio final del producto. Se deja expresamente establecido
      que ninguna descripción podrá contener datos personales o de contacto,
      tales como, y sin limitarse a, números telefónicos, dirección de e-mail,
      dirección postal, direcciones de páginas de Internet que contengan datos
      como los mencionados anteriormente, salvo autorización de THE CRANE CLUB.
      No podrá publicitarse otros medios de pagos, distintos de los enunciados
      por THE CRANE CLUB en la página de publicación de artículos. En caso de
      que se infrinja cualquiera de las disposiciones establecidas en esta
      cláusula, THE CRANE CLUB podrá editar el espacio, solicitar al Usuario que
      lo edite, o dar de baja la publicación donde se encuentre la infracción.
    </p>
    <p>
      4.4 Licencia sobre imágenes, fotografías, marcas e Información de
      Producto. El usuario autoriza a THE CRANE CLUB a utilizar, publicar,
      reproducir y/o adaptar las imágenes y fotografías incluidas en sus
      publicaciones, su nombre comercial, marcas, frases publicitarias, logos,
      diseños, dibujos, imágenes y todo otro signo distintivo que identifique al
      Usuario y sus productos o servicios (la "Marca") e información de sus
      productos o servicios ("Información de Producto"). Será obligación del
      Usuario incluir en las publicaciones las imágenes, fotografías y Marca,
      así como la Información de Producto, de manera actualizada, incluyendo
      aquellas advertencias que sean requeridas por la legislación aplicable
      para la venta o publicidad de los productos y servicios. Conforme a lo
      anterior, THE CRANE CLUB podrá obtener las imágenes, fotografías, Marca e
      Información de Producto directamente del Usuario, terceros autorizados por
      éste, o a través de la APP o del sitio web del Usuario.
    </p>
    <p>
      En particular, el Usuario otorga a THE CRANE CLUB una autorización
      gratuita, irrevocable, no exclusiva, internacional y sin límite temporal
      para usar, publicar, reproducir y/o adaptar las imágenes, fotografías, la
      Marca y la Información de Producto con el fin de ser usadas en todas las
      aplicaciones y los sitios de THE CRANE CLUB, redes sociales y/o en
      cualquier medio masivo y no masivo de comunicación, incluyendo sin
      limitación, plataformas y cualquier otro medio digital o físico que THE
      CRANE CLUB considere oportuno o con aquellas otras plataformas o sitios de
      Internet con los cuales THE CRANE CLUB haya realizado una alianza, para
      identificar ofertas, clasificar productos, crear catálogos, realizar
      acciones publicitarias y de marketing vinculadas a los servicios de THE
      CRANE CLUB, incluyendo la posibilidad de asociación con marcas y/o nombres
      comerciales de terceros, así como sublicenciar su uso a terceros,
      incluyendo, de manera enunciativa, más no limitativa, facultades de uso,
      publicación, reproducción y adaptación de las imágenes, fotografías, Marca
      e Información de Producto en el marco de las Publicaciones de Catálogo.
    </p>
    <p>
      El Usuario declara y garantiza que es titular o licenciatario de los
      derechos necesarios sobre las imágenes, fotografías contenidas en sus
      publicaciones, sobre las Marcas, así como sobre la Información de
      Producto, y que cuenta con los derechos y facultades necesarias para
      conceder la autorización detallada en esta cláusula, siendo responsable
      exclusivo por cualquier infracción a derechos de terceros o por las
      inconsistencias o inexactitud en la Información de Producto.
    </p>
    <p>
      THE CRANE CLUB podrá eliminar la publicación de las imágenes y
      fotografías, e incluso del bien o servicio, si interpretara, a su
      exclusivo criterio, que la imagen no cumple con los presentes Términos y
      Condiciones. Las imágenes y fotografías de bienes o servicios publicados
      bajo la modalidad Premium deberán cumplir con algunos requisitos
      adicionales como condición para ser expuestas en la Página Principal del
      Sitio Web o en la APP. Conoce los requisitos.
    </p>
    <p>
      4.5 Artículos Prohibidos. Sólo podrán ser ingresados en las listas de
      bienes y/o servicios ofrecidos, aquellos cuya venta no se encuentre tácita
      o expresamente prohibida en los TCG y demás políticas de THE CRANE CLUB o
      por la ley vigente.{' '}
    </p>
    <h3>QUINTA: Protección de la Información</h3>
    <p>
      Para utilizar los Servicios ofrecidos por THE CRANE CLUB, los Usuarios
      deberán facilitar determinados datos de carácter personal. Su información
      personal se procesa y almacena en servidores o medios magnéticos que
      mantienen altos estándares de seguridad y protección tanto física como
      tecnológica. Para mayor información sobre la privacidad de los Datos
      Personales y casos en los que será revelada la información personal, se
      pueden consultar nuestras Políticas de Privacidad.
    </p>
    <h3>SEXTA: Obligaciones de los Usuarios</h3>
    <p>
      6.1 Obligaciones del Comprador. Durante el plazo fijado por el Usuario
      Vendedor, los Usuarios interesados realizarán ofertas de compra para los
      bienes y ofertas de contratación para los servicios. La oferta de venta
      concluye una vez que vence el plazo de la publicación del producto o se
      acaban las cantidades estipuladas por el Vendedor, y la oferta de
      contratación del servicio culmina con el vencimiento del plazo de la
      publicación.
    </p>
    <p>
      El Comprador está obligado a intentar comunicarse con el vendedor y
      completar la operación si ha realizado una oferta por un artículo
      publicado, independientemente de la categoría o sección, salvo que la
      operación esté prohibida por la ley o los Términos y Condiciones Generales
      y demás políticas de THE CRANE CLUB, en cuyo caso no estará obligado a
      concretar la operación.
    </p>
    <p>
      Al ofertar por un artículo el Usuario acepta quedar obligado por las
      condiciones de venta incluidas en la descripción del artículo en la medida
      en que las mismas no infrinjan las leyes o los Términos y Condiciones
      Generales y demás políticas de THE CRANE CLUB. La oferta de compra es
      irrevocable salvo en circunstancias excepcionales, tales como que el
      vendedor cambie sustancialmente la descripción del artículo después de
      realizada alguna oferta, que exista un claro error tipográfico, o que no
      pueda verificar la identidad del vendedor. Las compras realizadas en la
      categoría Supermercado, se entiende que les aplican los mismos los
      Términos y Condiciones Generales y demás políticas de THE CRANE CLUB, sin
      embargo, tal como lo indican las reglas de protección al consumidor no
      habrá derecho de retracto en la adquisición de bienes perecederos, bienes
      de uso personal o aquellos bienes no puedan ser devueltos o puedan
      deteriorarse o caducar con rapidez.
    </p>
    <p>
      El comprador puede revocar la compra dentro de un plazo de 10 días desde
      que recibe el producto o podrá pedir el cambio o devolución del mismo en
      los términos de los programas de Compra Protegida, Mercado Puntos y demás
      Anexos, hasta 30 días después de la recepción del producto. Si el Usuario
      cumple con los requisitos previamente señalados y efectivamente revoca la
      compra y/o proceda una devolución en relación con la misma, el Usuario
      autoriza para que THE CRANE CLUB haga el reembolso del importe en relación
      con dicha compra en la cuenta de Mercado Pago del Usuario, el cuál estará
      disponible de forma inmediata y desde la cual podrá transferir el dinero
      de manera gratuita a la cuenta bancaria de su preferencia, enviarlo a la
      cuenta bancaria de otra persona, retirarlo y/o utilizarlo para compras
      dentro de la propia plataforma.
    </p>
    <p>
      Las ofertas de compra sólo serán consideradas válidas, una vez que hayan
      sido procesadas por el sistema informático de THE CRANE CLUB.
    </p>
    <p>
      Impuestos. Tal como lo establece la normativa fiscal vigente, el comprador
      debe exigir factura o ticket al vendedor como comprobante de la operación.
      El vendedor no estará obligado a emitir factura o ticket sólo en el caso
      de tratarse de una persona física que efectúa ventas ocasionalmente.
    </p>
    <p>
      6.2. Obligaciones del Vendedor. El Usuario Vendedor debe tener capacidad
      legal para vender el bien objeto de su oferta. Asimismo, debe cumplir con
      todas las obligaciones regulatorias pertinentes y contar con los
      registros, habilitaciones, permisos y/o autorizaciones exigidas por la
      normativa aplicable para la venta de los bienes y servicios ofrecidos. El
      usuario vendedor deberá cumplir con la normatividad vigente en materia de
      protección al consumidor, tales como, entregar información veraz, clara y
      suficiente, indicar el precio por unidad de medida en los productos que le
      sea aplicable, evitar publicidad engañosa en promociones, ofertas o
      descuentos que le otorgue al usuario comprador y cualquier otra obligación
      derivada de su publicación. El no cumplimiento de dicha obligación,
      facultará a THE CRANE CLUB a suspender y/o inhabilitar al usuario vendedor
      para continuar operando en la APP o en el sitio. Si el Usuario Vendedor ha
      recibido una oferta sobre el precio que estableció en la publicación,
      queda obligado a intentar comunicarse con el comprador y completar la
      operación con el Usuario que ofertó. La cancelación de una venta por parte
      del Usuario vendedor impactará en su reputación.{' '}
    </p>
    <p>
      Dado que THE CRANE CLUB es un lugar virtual de encuentro entre comprador y
      vendedor y no participa de las operaciones que se realizan entre ellos, el
      Vendedor será responsable por todas las obligaciones y cargas impositivas
      que correspondan por la venta de sus artículos, sin que pudiera
      imputársele a THE CRANE CLUB algún tipo de responsabilidad por
      incumplimientos en tal sentido.
    </p>
    <p>
      Impuestos. Como se menciona anteriormente, THE CRANE CLUB sólo pone a
      disposición de los Usuarios un espacio virtual que les permite comunicarse
      mediante Internet para encontrar una forma de vender o comprar artículos
      y/o servicios. THE CRANE CLUB no tiene participación alguna en el proceso
      de negociación y perfeccionamiento del contrato definitivo entre las
      partes. Por eso, THE CRANE CLUB no es responsable por el efectivo
      cumplimiento de las obligaciones fiscales o impositivas establecidas por
      la ley vigente.
    </p>
    <p>
      El vendedor acepta que su comprador puede revocar la compra dentro de un
      plazo de 10 días desde que recibe el producto.{' '}
    </p>
    <p>
      En cualquiera de los mencionados casos, el vendedor deberá aceptar la
      devolución del producto vendido y podrá hacerse cargo de los gastos que
      esto conlleve, incluyendo los costos de envío que fueren necesarios.{' '}
    </p>
    <p>
      Si en la operación en cuestión hubiese sido usado el servicio de Mercado
      Envíos, THE CRANE CLUB podrá disponibilizar etiquetas de envíos para que
      el comprador realice la devolución y debitar el costo de las mismas de la
      cuenta del vendedor.
    </p>
    <h3>SEPTIMA. Prohibiciones</h3>
    <p>
      Los Usuarios no podrán: (a) manipular los precios de los artículos; (b)
      interferir en la puja entre distintos Usuarios; (c) mantener ningún tipo
      de comunicación por e-mail, o por cualquier otro medio (incluso redes
      sociales) durante la oferta del bien con ninguno de los Usuarios que
      participan en la misma, salvo en la sección de Preguntas y Respuestas; (d)
      dar a conocer sus datos personales o de otros usuarios a través de la
      sección de Preguntas y Respuestas y/o por algún otro medio (incluyendo
      pero sin limitar a Twitter, Facebook y/ o cualquier otra red social),
      salvo lo estipulado específicamente para la categoría Autos, motos y
      otros, Servicios e Inmuebles; (e) aceptar datos personales proporcionados
      por otros usuarios a través de la sección de Preguntas y Respuestas y/o
      algún otro medio (incluyendo pero sin limitar Twitter, Facebook y/o
      cualquier otra red social); (f) insultar, discriminar o agredir a otros
      Usuarios, colaboradores de THE CRANE CLUB o cualquier persona que esté
      relacionada a los servicios que THE CRANE CLUB presta en su plataforma;
      (g) insultar o agredir a otros Usuarios; (h) utilizar su reputación,
      calificaciones o comentarios recibidos en la APP o en el sitio de THE
      CRANE CLUB en cualquier ámbito fuera de THE CRANE CLUB, (i) publicar
      artículos idénticos en más de una publicación.
    </p>
    <p>
      Este tipo de actividades será investigado por THE CRANE CLUB y el
      infractor podrá ser sancionado con la suspensión o cancelación de la
      oferta e incluso de su registración como Usuario de THE CRANE CLUB y/o de
      cualquier otra forma que estime pertinente, sin perjuicio de las acciones
      legales a que pueda dar lugar por la configuración de delitos o
      contravenciones o los perjuicios civiles que pueda causar a los Usuarios
      oferentes.
    </p>
    <h3>OCTAVA: Violaciones del Sistema o Bases de Datos</h3>
    <p>
      No está permitida ninguna acción o uso de dispositivo, software, u otro
      medio tendiente a interferir tanto en las actividades y operatoria de THE
      CRANE CLUB como en las ofertas, descripciones, cuentas o bases de datos de
      THE CRANE CLUB. Cualquier intromisión, tentativa o actividad violatoria o
      contraria a las leyes sobre derecho de propiedad intelectual y/o a las
      prohibiciones estipuladas en este contrato harán pasible a su responsable
      de las acciones legales pertinentes, y a las sanciones previstas por este
      acuerdo, así como lo hará responsable de indemnizar los daños ocasionados.
    </p>
    <h3>NOVENA: Sanciones. Suspensión de operaciones</h3>
    <p>
      Sin perjuicio de otras medidas, THE CRANE CLUB podrá advertir, suspender
      en forma temporal o inhabilitar definitivamente la Cuenta de un Usuario o
      una publicación, aplicar una sanción que impacte negativamente en la
      reputación de un Usuario, iniciar las acciones que estime pertinentes y/o
      suspender la prestación de sus Servicios si (a) se quebrantara alguna ley,
      o cualquiera de las estipulaciones de los Términos y Condiciones Generales
      y demás políticas de THE CRANE CLUB; (b) si incumpliera sus compromisos
      como Usuario; (c) si se incurriera a criterio de THE CRANE CLUB en
      conductas o actos dolosos o fraudulentos; (d) no pudiera verificarse la
      identidad del Usuario o cualquier información proporcionada por el mismo
      fuere errónea; (e) THE CRANE CLUB entendiera que las publicaciones u otras
      acciones pueden ser causa de responsabilidad para el Usuario que las
      publicó, para THE CRANE CLUB o para los Usuarios. En el caso de la
      suspensión o inhabilitación de un Usuario, todos los artículos que tuviera
      publicados serán removidos del sistema. También se removerán del sistema
      las ofertas de compra de bienes ofrecidos en subasta.
    </p>
    <h3>DECIMA: Responsabilidad</h3>
    <p>
      THE CRANE CLUB sólo pone a disposición de los Usuarios un espacio virtual
      que les permite ponerse en comunicación mediante Internet para encontrar
      una forma de vender o comprar servicios o bienes.
    </p>
    <p>
      THE CRANE CLUB no es el propietario de los artículos ofrecidos, no tiene
      posesión de ellos ni los ofrece en venta.
    </p>
    <p>
      THE CRANE CLUB no interviene en el perfeccionamiento de las operaciones
      realizadas entre los Usuarios ni en las condiciones por ellos estipuladas
      para las mismas, por ello no será responsable respecto de la existencia,
      calidad, cantidad, estado, integridad o legitimidad de los bienes
      ofrecidos, adquiridos o enajenados por los Usuarios, así como de la
      capacidad para contratar de los Usuarios o de la veracidad de los Datos
      Personales por ellos ingresados. Cada Usuario conoce y acepta ser el
      exclusivo responsable por los artículos que publica para su venta y por
      las ofertas y/o compras que realiza.
    </p>
    <p>
      Debido a que THE CRANE CLUB no tiene ninguna participación durante todo el
      tiempo en que el artículo se publica para la venta, ni en la posterior
      negociación y perfeccionamiento del contrato definitivo entre las partes,
      no será responsable por el efectivo cumplimiento de las obligaciones
      asumidas por los Usuarios en el perfeccionamiento de la operación. El
      Usuario conoce y acepta que al realizar operaciones con otros Usuarios o
      terceros lo hace bajo su propio riesgo. En ningún caso THE CRANE CLUB será
      responsable por lucro cesante, o por cualquier otro daño y/o perjuicio que
      haya podido sufrir el Usuario, debido a las operaciones realizadas o no
      realizadas por artículos publicados a través de THE CRANE CLUB.
    </p>
    <p>
      THE CRANE CLUB recomienda actuar con prudencia y sentido común al momento
      de realizar operaciones con otros Usuarios. El Usuario debe tener
      presentes, además, los riesgos de contratar con menores o con personas que
      se valgan de una identidad falsa. THE CRANE CLUB NO será responsable por
      la realización de ofertas y/o operaciones con otros Usuarios basadas en la
      confianza depositada en el sistema o los Servicios brindados por THE CRANE
      CLUB.
    </p>
    <p>
      En virtud que el usuario vendedor tiene la facultad para eliminar
      preguntas o impedir a un usuario hacer preguntas u ofertas en sus
      publicaciones, se deja aclarado que en ese caso, el usuario será el
      exclusivo responsable por esa decisión y las consecuencias que pudieran
      acarrear.{' '}
    </p>
    <h3>DECIMA PRIMERA: Alcance de los servicios de THE CRANE CLUB</h3>
    <p>
      Este acuerdo no crea ningún contrato, asociación o relación laboral entre
      THE CRANE CLUB y el Usuario. El Usuario reconoce y acepta que THE CRANE
      CLUB no es parte en ninguna operación, ni tiene control alguno sobre la
      calidad, seguridad o legalidad de los artículos anunciados, la veracidad o
      exactitud de los anuncios, la capacidad de los Usuarios para vender o
      comprar artículos. THE CRANE CLUB no puede asegurar que un Usuario
      completará una operación ni podrá verificar la identidad o Datos
      Personales ingresados por los Usuarios no garantiza la veracidad de la
      publicidad de terceros que aparezca en la APP o en el sitio y no será
      responsable por la correspondencia o contratos que el Usuario celebre con
      dichos terceros o con otros Usuarios.
    </p>
    <h3>DECIMA SEGUNDA: Fallas en el sistema</h3>
    <p>
      THE CRANE CLUB no se responsabiliza por cualquier daño, perjuicio o
      pérdida al Usuario causados por fallas en el sistema, en el servidor o en
      Internet. THE CRANE CLUB tampoco será responsable por cualquier virus que
      pudiera infectar el equipo del Usuario como consecuencia del acceso, uso o
      examen de su sitio web o a raíz de cualquier transferencia de datos,
      archivos, imágenes, textos, o audio contenidos en el mismo. Los Usuarios
      NO podrán imputarle responsabilidad alguna ni exigir pago por lucro
      cesante, en virtud de perjuicios resultantes de dificultades técnicas o
      fallas en los sistemas o en Internet. THE CRANE CLUB no garantiza el
      acceso y uso continuado o ininterrumpido de su sitio. El sistema puede
      eventualmente no estar disponible debido a dificultades técnicas o fallas
      de Internet, o por cualquier otra circunstancia ajena a THE CRANE CLUB; en
      tales casos se procurará restablecerlo con la mayor celeridad posible sin
      que por ello pueda imputársele algún tipo de responsabilidad. THE CRANE
      CLUB no será responsable por ningún error u omisión contenidos en su APP o
      su sitio web.
    </p>
    <h3>DECIMA TERCERA: Tarifas.</h3>
    <p>
      THE CRANE CLUB cobrará las tarifas por la inscripción y publicación, venta
      o compra o alquiler de equipos a través de THE CRANE CLUB conforme al
      Anexo TARIFAS que se publique en la APP o en el sitio Web, el cual podrá
      ser modificado previa comunicación a través de la APP o sitio web a los
      Usuarios con 10 días de antelación.
    </p>
    <p>
      En todos los casos, los cargos varían conforme la exposición del anuncio
      en la APP o en el Sitio Web.
    </p>
    <p>
      El Usuario acepta pagar a THE CRANE CLUB los cargos por publicar, vender o
      por cualquier otro servicio prestado por THE CRANE CLUB que tenga una
      tarifa vigente.{' '}
    </p>
    <p>
      THE CRANE CLUB se reserva el derecho de modificar, cambiar, agregar, o
      eliminar las tarifas vigentes, en cualquier momento, lo cual será
      notificado a los Usuarios. Sin embargo, THE CRANE CLUB podrá modificar
      temporalmente la Política de Tarifas y las tarifas por sus servicios por
      razón de promociones, siendo efectivas estas modificaciones cuando se haga
      pública la promoción o se realice el anuncio.
    </p>
    <h3>DECIMA CUARTA: Sistema de reputación</h3>
    <p>
      Debido a que la verificación de la identidad de los Usuarios en Internet
      es difícil, THE CRANE CLUB no puede confirmar ni confirma la identidad
      pretendida de cada Usuario. Por ello el Usuario cuenta con un sistema de
      reputación de Usuarios que es actualizado periódicamente en base a datos
      vinculados con su actividad en el sitio o APP y a los comentarios
      ingresados por los Usuarios según las operaciones que hayan realizado.
      Tanto aquellos Usuarios que hayan ofertado la adquisición de un producto
      y/o servicio como el vendedor deberán ingresar una calificación informando
      acerca de la concreción o no de la operación; también podrán ingresar un
      comentario si así lo desean. Este sistema de reputación, además constará
      de un espacio donde los Usuarios podrán hacer comentarios sobre las
      calificaciones recibidas y acceder a los mismos. Dichos comentarios serán
      incluidos bajo exclusiva responsabilidad de los Usuarios que los emitan.
    </p>
    <p>
      En virtud que las calificaciones y comentarios son realizados por los
      Usuarios, éstos serán incluidos bajo exclusiva responsabilidad de los
      Usuarios que los emitan. THE CRANE CLUB no tiene obligación de verificar
      la veracidad o exactitud de los mismos y no se responsabiliza por los
      dichos allí vertidos por cualquier Usuario, por las ofertas de compras o
      ventas que los Usuarios realicen teniéndolos en cuenta o por la confianza
      depositada en las calificaciones de la contraparte o por cualquier otro
      comentario expresado dentro de la APP o sitio o a través de cualquier otro
      medio, incluido el correo electrónico. THE CRANE CLUB se reserva el
      derecho de editar y/o eliminar aquellos comentarios que sean considerados
      inadecuados u ofensivos. THE CRANE CLUB mantiene el derecho de excluir a
      aquellos Usuarios que sean objeto de comentarios negativos provenientes de
      fuentes distintas. Para obtener mayor información sobre el sistema de
      calificaciones, se pueden consultar nuestro Sistema de reputación de THE
      CRANE CLUB.
    </p>
    <h3>DECIMA QUINTA - Propiedad intelectual </h3>
    <p>
      THE CRANE CLUB se reserva todos los derechos, incluyendo los derechos de
      propiedad intelectual e industrial, asociados con los servicios de THE
      CRANE CLUB, sus aplicaciones, sitios web, los contenidos de sus pantallas,
      programas, bases de datos, redes, códigos, desarrollo, software,
      arquitectura, hardware, contenidos, información, tecnología, fases de
      integración, funcionalidades, dominios, archivos que permiten al Usuario
      acceder y crear su Cuenta, herramientas de venta, marcas, patentes,
      derechos de autor, diseños y modelos industriales, nombres comerciales,
      entre otros, y declara que están protegidos por leyes nacionales e
      internacionales vigentes.
    </p>
    <p>
      En ningún caso se entenderá que el Usuario tendrá algún tipo de derecho
      sobre los mismos excepto para utilizar el servicio de THE CRANE CLUB
      conforme a lo previsto en estos Términos y Condiciones Generales. El uso
      indebido o contrario a la normativa vigente de los derechos de propiedad
      intelectual e industrial de THE CRANE CLUB, así como su reproducción total
      o parcial, queda prohibido, salvo autorización expresa y por escrito de
      THE CRANE CLUB.
    </p>
    <p>
      Los Usuarios tampoco podrán comunicar que los productos o servicios que
      ofrecen son patrocinados, promovidos, producidos, ofrecidos y/o vendidos
      por THE CRANE CLUB y deberán abstenerse de realizar cualquier acto que
      pudiera causar un daño, pérdida de reputación, o disminución del valor de
      los derechos de propiedad intelectual e industrial de THE CRANE CLUB.
    </p>
    <p>
      La APP o el sitio puede contener enlaces a sitios web de terceros. En
      virtud que THE CRANE CLUB no tiene control sobre tales sitios, no será
      responsable por los contenidos, materiales, acciones y/o servicios
      prestados por los mismos, ni por daños o pérdidas ocasionadas por la
      utilización de éstos, causados directa o indirectamente. La presencia de
      enlaces a otros sitios web no implica una sociedad, relación, aprobación,
      respaldo de THE CRANE CLUB con dichos sitios y sus contenidos.
    </p>
    <h3>DECIMA SEXTA: Indemnidad</h3>
    <p>
      El Usuario mantendrá indemne a THE CRANE CLUB, así como a sus directivos y
      empleados, por cualquier reclamo iniciado por otros Usuarios, terceros o
      por cualquier Organismo, relacionado con sus actividades en la APP o en el
      Sitio, el cumplimiento y/o el incumplimiento de los Términos y Condiciones
      Generales o demás Políticas, así como respecto de cualquier violación de
      leyes o derechos de terceros.
    </p>
    <h3>DECIMA OCTAVA: Jurisdicción y Ley Aplicable</h3>
    <p>
      Estos TCG estarán regidos en todos sus puntos por las leyes vigentes del
      estado de LA FLORIDA, Estados Unidos de Norteamérica.
    </p>
    <p>
      Cualquier controversia derivada del presente acuerdo, su existencia,
      validez, interpretación, alcance o cumplimiento, será sometida a las leyes
      aplicables y a los Tribunales competentes de La Florida, USA.
    </p>
    <p>
      Toda la información suministrada por los Usuarios, se tomará como verídica
      para efectos tributarios.
    </p>
    <h3>DECIMA NOVENA – Domicilio.</h3>
    <p>
      Se fija como domicilio de THE CRANE CLUB (7901 4th St N Ste 300, St.
      Petersburg, FL 33702, USA), y como domicilio electrónico los siguientes
      email: hello@thecraneclub.com
    </p>
  </div>
);
