import { Switch, Route } from 'react-router-dom';
import { AppUrlListener } from 'hooks';
import i18n from '../i18n';
import { RouteChangeListener, Filters, MobileFiltersWrapper } from 'components';
import { JobFilters } from 'components/jobs';

import {
  AuthRoutes,
  DashboardRoutes,
  EditRoutes,
  OverviewRoutes,
  UploadRoutes,
  ProfileRoutes
} from './AppRoutes';

export const baseUrl = '/' + i18n.language;

const Routes = () => {
  return (
    <>
      <RouteChangeListener />
      <AppUrlListener />
      <Switch>
        <Route path="/:locale?/auth/:path?">
          <AuthRoutes />
        </Route>
        <Route path="/:locale?/dashboard/:path?">
          <DashboardRoutes />
        </Route>
        <Route path="/:locale?/upload/:path?" exact>
          <UploadRoutes />
        </Route>
        <Route path="/:locale?/edit/:path?" exact>
          <EditRoutes />
        </Route>
        <Route path="/:locale?/u/:id/:name/:path?">
          <ProfileRoutes />
        </Route>
        <Route path="/:path?">
          <OverviewRoutes />
        </Route>
      </Switch>
      <Route path="/:locale?/jobs/filters">
        <MobileFiltersWrapper clickOutsideRoute="/jobs" show={true}>
          <JobFilters />
        </MobileFiltersWrapper>
      </Route>
      <Route path="/:locale?/machines/filters">
        <MobileFiltersWrapper clickOutsideRoute="/machines" show={true}>
          <Filters />
        </MobileFiltersWrapper>
      </Route>
    </>
  );
};

export default Routes;
