import React, { useContext } from 'react';
import { Logo, Icon, Stepper, Divider } from 'components';
import { Button } from '@estudio-nk/the-crane-club-client-lib';
import { UserContext } from 'contexts/UserContext';
import { useLocation, useHistory } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { logout } from 'api/auth';
import chatClient from 'services/chat';
import SidebarCompany from './SidebarCompany';
import SidebarUser from './SidebarUser';
import cn from 'classnames';
import { useMediaQuery } from 'react-responsive';

import './index.scss';

export const Sidebar = ({ upload, className, showClose, setToggleSidebar }) => {
  const { user, setUser } = useContext(UserContext);
  const isCompany = user && user.isCompany;
  const { t } = useTranslation();
  const location = useLocation();
  const history = useHistory();
  const path = location?.pathname?.replace('/dashboard/', '');
  const isDesktop = useMediaQuery({
    query: '(min-width: 1280px)'
  });

  const closeSideBarOnClick = () => {
    setToggleSidebar && setToggleSidebar(false);
  };

  const handleOnLogout = async () => {
    setUser(null);
    await chatClient.disconnectUser();
    await logout();
    window.location.reload();
  };

  return (
    <aside className={cn('dashboard-sidebar', className)}>
      <div>
        <div className="flex items-center mb-8">
          {showClose && (
            <Icon
              className="ml-3 mr-6"
              height={20}
              name="SolidClose"
              onClick={() => setToggleSidebar && setToggleSidebar(false)}
            />
          )}
          <Logo
            size="collapsed"
            className="w-20 h-7 cursor-pointer"
            onClick={() => history.push('/')}
          />
        </div>
        <Divider className="hidden md:block md:mb-10" />
        {upload ? (
          <Stepper />
        ) : (
          <>
            {isCompany && (
              <Button linkTo="/upload" size="sm" colorVariant="yellow">
                {t('publishAMachineForSellOrRent')}
              </Button>
              /* <PublishNowButton size="sm" /> */
            )}
            <Button
              variant={isCompany ? 'outline' : 'primary'}
              colorVariant="yellow"
              size="sm"
              className={cn('mt-2', {
                'mb-8': isDesktop,
                'dark border-none shadow-m': isCompany
              })}
              linkTo="/social-club"
            >
              {t('socialClub')}
            </Button>

            {!isDesktop && (
              <>
                <div className="flex">
                  <Button
                    variant="outline"
                    size="sm"
                    className="mt-2 mr-2 border-none shadow-m"
                    linkTo="/suppliers"
                    colorVariant="yellow"
                  >
                    {t('suppliers')}
                  </Button>
                  <Button
                    variant="outline"
                    size="sm"
                    className="mt-2 border-none shadow-m"
                    linkTo="/jobs"
                    colorVariant="yellow"
                  >
                    {t('jobs')}
                  </Button>
                </div>
                <div className="flex mb-8">
                  <Button
                    variant="outline"
                    size="sm"
                    colorVariant="yellow"
                    className="mt-2 mr-2 bg-gray text-gray-dark border-none shadow-m"
                    linkTo="/influencers"
                  >
                    🔥{' ' + t('influencers')}
                  </Button>
                  <Button
                    variant="outline"
                    size="sm"
                    className="mt-2 border-none shadow-m"
                    linkTo="/vip-members"
                    colorVariant="yellow"
                  >
                    👑&nbsp;VIP Members
                  </Button>
                </div>
              </>
            )}

            <ul>
              <li
                className={cn('dashboard-sidebar-item', {
                  'dashboard-sidebar-item-selected': path === '/dashboard'
                })}
              >
                <Link to="/dashboard" onClick={closeSideBarOnClick}>
                  <Icon
                    name="Metrics"
                    width={20}
                    height={16}
                    canvasWidth={22}
                    center
                  />
                  <span>{t('metrics')}</span>
                </Link>
              </li>
              {isCompany ? (
                <SidebarCompany closeSideBarOnClick={closeSideBarOnClick} />
              ) : (
                <SidebarUser closeSideBarOnClick={closeSideBarOnClick} />
              )}
              <li
                className={cn('dashboard-sidebar-item', {
                  'dashboard-sidebar-item-selected': path.includes('settings')
                })}
              >
                <Link to="/dashboard/settings" onClick={closeSideBarOnClick}>
                  <Icon
                    name="Settings"
                    width={17}
                    height={17}
                    canvasWidth={22}
                    center
                  />
                  <span>{t('settings')}</span>
                </Link>
              </li>
              {!upload && (
                <li className={cn('dashboard-sidebar-item')}>
                  <Link onClick={handleOnLogout} to="/auth/login">
                    <Icon
                      name="Logout"
                      width={21}
                      height={16}
                      canvasWidth={22}
                      center
                    />
                    <span>{t('logout')}</span>
                  </Link>
                </li>
              )}
            </ul>
          </>
        )}
      </div>
    </aside>
  );
};
