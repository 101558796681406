import axios from 'utils/axios';

export const getCommentsByPost = async (postId) => {
  try {
    const res = await axios.get(`api/social/posts-comments/${postId}`);
    return res.data;
  } catch (error) {
    console.log(error.message);
  }
};

export const createComment = async (data) => {
  try {
    const res = await axios.post(`api/social/posts-comments`, data);
    return res.data;
  } catch (error) {
    console.log(error.message);
  }
};

export const getCommentLikes = async (postCommentId) => {
  try {
    const res = await axios.get(
      `api/social/posts-comments-likes/${postCommentId}`
    );
    return res.data;
  } catch (error) {
    console.log(error.message);
  }
};

export const saveCommentLike = async (postCommentId) => {
  try {
    const res = await axios.post(`api/social/posts-comments-likes`, {
      postCommentId
    });
    return res;
  } catch (error) {
    console.log(error.message);
  }
};
