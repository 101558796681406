import React, { useEffect, useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { ReloadContext } from 'contexts/ReloadContext';
import { getAll, getPostsByUser } from 'api/social/posts';
import { PostCard } from 'components/post';
import { Spinner, InfiniteScroll } from 'components';
import cn from 'classnames';

const POSTS_PER_PAGE = 10;

export const List = ({ userId, searchText, className }) => {
  const [loading, setLoading] = useState(false);
  const [posts, setPosts] = useState([]);
  const [hasMore, setHasMore] = useState(true);
  const [selectedPage, setSelectedPage] = useState([]);
  const [resultsTotal, setResultsTotal] = useState({});
  const { reload } = useContext(ReloadContext);
  const { t } = useTranslation();

  useEffect(() => {
    setLoading(true);
    fetch();
  }, []);

  useEffect(() => {
    if (!loading) {
      fetch(0);
    }
  }, [reload]);

  const fetch = async (page) => {
    try {
      const res = userId
        ? await getPostsByUser(userId, 0, POSTS_PER_PAGE)
        : await getAll(searchText, 0, POSTS_PER_PAGE);
      const _posts =
        res.rows?.filter(
          (post) =>
            !post.publicationId || (post.publicationId && !!post.publication)
        ) || [];
      setPosts(_posts);
      setResultsTotal({ count: res.count, totalPages: res.totalPages });
      setSelectedPage(0);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const loadNextPages = async (e) => {
    if (!loading) {
      try {
        const nextPage = selectedPage + 1;
        setSelectedPage(nextPage);
        const res = userId
          ? await getPostsByUser(userId, nextPage, POSTS_PER_PAGE)
          : await getAll(searchText, nextPage, POSTS_PER_PAGE);

        const _posts = [...posts, ...res.rows];
        setPosts(_posts);
        const restPosts = resultsTotal.count - res.rows.length;
        setResultsTotal({ ...resultsTotal, count: restPosts });
        if (restPosts - POSTS_PER_PAGE < POSTS_PER_PAGE) {
          setHasMore(false);
          const lastsPost = userId
            ? await getPostsByUser(userId, nextPage, restPosts)
            : await getAll(searchText, nextPage, restPosts);
          setPosts([..._posts, ...lastsPost.rows]);
        }
      } catch (error) {
        console.log(error);
      } finally {
      }
    }
  };

  return (
    <div className={cn('w-full', className)}>
      {loading ? (
        <Spinner />
      ) : !loading && !posts?.length ? (
        <div
          className={cn(
            'flex items-center justify-center text-2xl text-yellow'
          )}
        >
          <p>{t('noActivityToShow')}</p>
        </div>
      ) : (
        <div className="relative w-full flex flex-col gap-5">
          <InfiniteScroll
            dataLength={posts.length} //This is important field to render the next data
            next={loadNextPages}
            hasMore={hasMore}
          >
            {posts?.map((post) => (
              <PostCard className="mb-6" key={post.id} post={post} />
            ))}
          </InfiniteScroll>
        </div>
      )}
    </div>
  );
};
