import React from 'react';
import { number, func } from 'prop-types';
import { getPaginationArray } from 'utils/pagination';
import cn from 'classnames';
import { useMediaQuery } from 'react-responsive';
import { Icon } from 'components';

export const Pagination = ({
  totalPages,
  currentPage,
  setPages,
  className
}) => {
  const isDesktop = useMediaQuery({
    query: '(min-width: 768px)'
  });

  const pages = getPaginationArray(currentPage, totalPages, isDesktop ? 3 : 1);

  return (
    <div
      className={cn(
        'mt-4 px-4 py-3 flex items-center justify-center',
        className
      )}
    >
      <div>
        <div
          className="relative z-0 inline-flex rounded-md -space-x-px"
          aria-label="Pagination"
        >
          {currentPage !== 1 && (
            <div
              onClick={() => setPages(currentPage - 1)}
              className="relative inline-flex items-center px1 md:px-2 py-2 rounded-l-md text-sm font-medium hover:bg-black cursor-pointer"
            >
              <Icon name="ChevronLeft" />
            </div>
          )}
          {pages.map((page, index) => (
            <div
              key={index}
              onClick={() => setPages(page)}
              className={cn(
                'md:inline-flex relative items-center px-3.5 md:px-4 py-2 text-sm font-medium',
                page !== '...'
                  ? 'hover:bg-black cursor-pointer'
                  : 'pointer-events-none',
                {
                  'text-yellow': currentPage === page
                }
              )}
            >
              {page}
            </div>
          ))}
          {currentPage !== totalPages && totalPages > 1 && (
            <div
              onClick={() => setPages(currentPage + 1)}
              className="relative inline-flex items-center px-1 md:px-2 py-2 rounded-r-md text-sm font-medium hover:bg-black cursor-pointer"
            >
              <Icon name="ChevronRight" />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

Pagination.propTypes = {
  totalPages: number.isRequired,
  currentPage: number.isRequired,
  setPages: func.isRequired
};
