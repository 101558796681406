import React, { useState, useEffect, useContext } from 'react';
import { CheckboxGroup, Icon, Radio, Divider, Dropdown } from 'components';
import { Button } from '@estudio-nk/the-crane-club-client-lib';
import { PublicationFiltersContext } from 'contexts/PublicationFiltersContext';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';
import cn from 'classnames';
import { useFilterQueryParams, usePageQueryParams } from 'hooks';
import { getPublicationsFilters } from 'api/publications';
import { getTypes } from 'api/types';
import { useHistory } from 'react-router-dom';
import './index.scss';

export const Filters = () => {
  const history = useHistory();
  const { t } = useTranslation();

  const { setLoadingFilters } = useContext(PublicationFiltersContext);

  const {
    params,
    setParams,
    getParam,
    setTypeAndOperationTypeParams,
    cleanAllParams
  } = useFilterQueryParams();
  const { removePage } = usePageQueryParams();

  const isDesktop = useMediaQuery({
    query: '(min-width: 768px)'
  });

  const [types, setTypes] = useState([]);
  const [conditions, setConditions] = useState([]);
  const [filtersOptions, setFiltersOptions] = useState({});
  const [loading, setLoading] = useState(true);
  const [loadingTypes, setLoadingTypes] = useState(true);

  //SelectedOptions
  const [selectedType, setSelectedType] = useState(0);
  const [selectedOperationType, setSelectedOperationType] = useState('both');
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [selectedCondition, setSelectedCondition] = useState(null);
  const [selectedBrands, setSelectedBrands] = useState([]);
  const [selectedYears, setSelectedYears] = useState([]);
  const [selectedCountries, setSelectedCountries] = useState([]);

  const operationTypes = [
    {
      id: 'rent',
      label: t('rent'),
      value: 'rent'
    },
    {
      id: 'sell',
      label: t('sell'),
      value: 'sell'
    },
    {
      id: 'both',
      label: t('both'),
      value: 'both'
    }
  ];

  // Collapsed Filters controls
  const [optionsOpen, setOptionsOpen] = useState({
    operationType: true,
    categories: false,
    condition: false,
    brands: false,
    years: false,
    countries: false
  });

  useEffect(() => {
    loadTypes();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!loadingTypes) {
      loadFilters();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    loadingTypes,
    params.type,
    params.operationType,
    params.brands,
    params.years,
    params.categories,
    params.countries,
    params.conditions
  ]);

  const loadTypes = async () => {
    try {
      setLoadingFilters(true);
      setLoadingTypes(true);
      const _types = await getTypes();
      const formattedTypes = [
        ...[{ id: 0, value: 0, text: 'All' }],
        ..._types?.map(({ id, name }) => ({
          value: id,
          text: name,
          id: id
        }))
      ];
      setTypes(formattedTypes);
      !params?.type
        ? setSelectedType(formattedTypes[0].id)
        : setSelectedType(params?.type || 0);
    } catch (err) {
      console.log(err);
    } finally {
      setLoadingTypes(false);
    }
  };

  const loadFilters = async () => {
    try {
      setLoadingFilters(true);
      const filtersOptions = await getPublicationsFilters(
        selectedType,
        selectedOperationType || 'both'
      );
      const _conditions =
        filtersOptions?.filteredCondition?.map((i) => ({
          id: i,
          label: t(i),
          value: i
        })) || [];
      setConditions(_conditions);
      setFiltersOptions(filtersOptions);
      params.operationType &&
        setSelectedOperationType(params.operationType.toLowerCase());
      params.categories && setSelectedCategories(params.categories);
      params.brands && setSelectedBrands(params.brands);
      params.years && setSelectedYears(params.years);
      params.countries && setSelectedCountries(params.countries);
      params.condition && setSelectedCondition(params.condition.toLowerCase());
    } catch (err) {
      console.log(err);
    } finally {
      setLoadingFilters(false);
      setLoading(false);
    }
  };

  const handleOperationType = (value) => {
    setSelectedOperationType(value);
    setTypeAndOperationTypeParams(selectedType, value);
    setParams(
      {
        type: selectedType,
        operationType: value
      },
      '/machines'
    );
    removePage();
  };

  const handleType = (value) => {
    setSelectedType(value);
    setSelectedOperationType('both');
    setTypeAndOperationTypeParams(value, 'both');
    setParams(
      {
        type: value,
        operationType: 'both'
      },
      '/machines'
    );
    removePage();
  };

  const handleOnApply = () => {
    const _params = {
      type: selectedType,
      operationType: selectedOperationType
    };
    !!selectedCategories?.length && (_params.categories = selectedCategories);
    !!selectedBrands?.length && (_params.brands = selectedBrands);
    !!selectedCondition && (_params.condition = selectedCondition);
    !!selectedCountries?.length && (_params.countries = selectedCountries);
    !!selectedYears?.length && (_params.years = selectedYears);
    setParams(_params, '/machines');
    removePage();
  };

  const handleOnClear = () => {
    setSelectedType(0);
    setSelectedOperationType('both');
    setSelectedCategories([]);
    setSelectedCondition(null);
    setSelectedBrands([]);
    setSelectedYears([]);
    setSelectedCountries([]);
    setParams({}, '/machines');
    removePage();
  };

  const line = <Divider className="my-3 md:my-6" />;

  if (!loadingTypes) {
    <div>
      <div className="text-base md:text-xl text-yellow w-full bg-yellow bg-opacity-20 h-6 rounded-xs"></div>
    </div>;
  }

  const handleOptionsOpen = (name) => {
    setOptionsOpen({ ...optionsOpen, [name]: !optionsOpen[name] });
  };

  return (
    <>
      <h2 className="text-xl md:text-3xl text-gray-light-primary mb-4 md:mb-12 pr-3 capitalize">
        {getParam('search') || ''}
      </h2>
      {loading ? (
        <>
          <div className="w-full h-14 md:h-32 mb-6 rounded-md opacity-30 bg-gray-dark animate-pulse" />
          <div className="w-full h-14 md:h-32 mb-6 rounded-md opacity-30 bg-gray-dark animate-pulse" />
          {Array.from({ length: 6 }, (_, i) => (
            <div
              key={i}
              className="w-full h-12 mb-6 opacity-30 rounded-md bg-gray-dark animate-pulse"
            />
          ))}
        </>
      ) : (
        <>
          <div className="w-full flex justify-end">
            <button
              className="text-yellow underline hover:no-underline text-sm"
              onClick={handleOnClear}
            >
              {t('clearFilters')}
            </button>
          </div>
          {!!types.length && (
            <>
              <div className="flex justify-between items-center cursor-pointer">
                <h2 className="text-gray-intermediate text-base md:text-xl">
                  {t('category')}
                </h2>
              </div>
              <Dropdown
                items={types}
                className="mt-2 md:mt-5"
                value={selectedType}
                onChange={handleType}
              />
              {line}
            </>
          )}
          {!!filtersOptions?.filteredOperationType?.length && (
            <>
              <div
                onClick={() => handleOptionsOpen('operationType')}
                className="flex justify-between items-center cursor-pointer pr-4 md:pr-0"
              >
                <h2 className="text-gray-intermediate text-base md:text-xl">
                  {t('operation')}
                </h2>
                <Icon
                  name="ChevronDown"
                  className={cn(
                    'transform transition-all',
                    optionsOpen.operationType && 'rotate-180'
                  )}
                />
              </div>
              {optionsOpen.operationType && (
                <Radio
                  items={operationTypes}
                  className="mt-2 md:mt-5"
                  value={selectedOperationType}
                  setValue={handleOperationType}
                />
              )}

              {line}
            </>
          )}

          {!!filtersOptions?.filteredCategories?.length && (
            <>
              <div
                onClick={() => handleOptionsOpen('categories')}
                className="flex justify-between items-center cursor-pointer pr-4 md:pr-0"
              >
                <h2 className="text-gray-intermediate text-base md:text-xl">
                  {t('types')}
                </h2>
                <Icon
                  name="ChevronDown"
                  className={cn(
                    'transform transition-all',
                    optionsOpen.categories && 'rotate-180'
                  )}
                />
              </div>
              {optionsOpen.categories && (
                <CheckboxGroup
                  options={filtersOptions?.filteredCategories}
                  translateOptions
                  selected={selectedCategories}
                  setSelected={(value, checked) =>
                    setSelectedCategories(
                      checked
                        ? [...selectedCategories, value]
                        : selectedCategories.filter((cat) => cat !== value)
                    )
                  }
                />
              )}
              {line}
            </>
          )}
          {!!filtersOptions?.filteredCondition?.length && (
            <>
              <div
                onClick={() => handleOptionsOpen('condition')}
                className="flex justify-between items-center cursor-pointer pr-4 md:pr-0"
              >
                <h2 className="text-gray-intermediate text-base md:text-xl">
                  {t('condition')}
                </h2>
                <Icon
                  name="ChevronDown"
                  className={cn(
                    'transform transition-all',
                    optionsOpen.condition && 'rotate-180'
                  )}
                />
              </div>
              {optionsOpen.condition && (
                <Radio
                  className="mt-2 md:mt-5"
                  items={conditions}
                  value={selectedCondition || null}
                  setValue={setSelectedCondition}
                />
              )}

              {line}
            </>
          )}
          {!!filtersOptions?.filteredBrands?.length && (
            <>
              <div
                onClick={() => handleOptionsOpen('brands')}
                className="flex justify-between items-center cursor-pointer pr-4 md:pr-0"
              >
                <h2 className="text-gray-intermediate text-base md:text-xl">
                  {t('brands')}
                </h2>
                <Icon
                  name="ChevronDown"
                  className={cn(
                    'transform transition-all',
                    optionsOpen.brands && 'rotate-180'
                  )}
                />
              </div>
              {optionsOpen.brands && (
                <CheckboxGroup
                  options={filtersOptions?.filteredBrands}
                  selected={selectedBrands}
                  setSelected={(value, checked) =>
                    setSelectedBrands(
                      checked
                        ? [...selectedBrands, value]
                        : selectedBrands.filter((cat) => cat !== value)
                    )
                  }
                />
              )}
              {line}
            </>
          )}
          {!!filtersOptions?.filteredYears?.length && (
            <>
              <div
                onClick={() => handleOptionsOpen('years')}
                className="flex justify-between items-center cursor-pointer pr-4 md:pr-0"
              >
                <h2 className="text-gray-intermediate text-base md:text-xl">
                  {t('years')}
                </h2>
                <Icon
                  name="ChevronDown"
                  className={cn(
                    'transform transition-all',
                    optionsOpen.years && 'rotate-180'
                  )}
                />
              </div>
              {optionsOpen.years && (
                <CheckboxGroup
                  options={filtersOptions?.filteredYears}
                  selected={selectedYears}
                  setSelected={(value, checked) =>
                    setSelectedYears(
                      checked
                        ? [...selectedYears, value]
                        : selectedYears.filter((cat) => cat !== value)
                    )
                  }
                />
              )}

              {line}
            </>
          )}

          {!!filtersOptions?.filteredCountries?.length && (
            <>
              <div
                onClick={() => handleOptionsOpen('countries')}
                className="flex justify-between items-center cursor-pointer pr-4 md:pr-0"
              >
                <h2 className="text-gray-intermediate text-base md:text-xl">
                  {t('countries')}
                </h2>
                <Icon
                  name="ChevronDown"
                  className={cn(
                    'transform transition-all',
                    optionsOpen.countries && 'rotate-180'
                  )}
                />
              </div>
              {optionsOpen.countries && (
                <CheckboxGroup
                  options={filtersOptions?.filteredCountries}
                  selected={selectedCountries}
                  setSelected={(value, checked) =>
                    setSelectedCountries(
                      checked
                        ? [...selectedCountries, value]
                        : selectedCountries.filter((cat) => cat !== value)
                    )
                  }
                />
              )}
              {line}
            </>
          )}
          {!loading && (
            <div
              className={cn(
                'flex justify-center md:sticky md:pb-5 md:bg-background md:bottom-0',
                {
                  'left-0 right-0 wrapper-lg mx-auto fixed bottom-16 pb-safe':
                    !isDesktop
                }
              )}
            >
              {!isDesktop && (
                <Button
                  onClick={history.goBack}
                  variant="outline"
                  className="mr-2 w-2/6"
                  colorVariant="yellow"
                >
                  {t('close')}
                </Button>
              )}
              <Button size="md" onClick={handleOnApply} colorVariant="yellow">
                {t('applyFilters')}
              </Button>
            </div>
          )}
        </>
      )}
    </>
  );
};
