import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import {
  Divider,
  Icon,
  Location,
  ProfileFollowers,
  ProfileActionsMobile
} from 'components';
import { ChatButton, ProfileBadge } from 'components';
import { UserContext } from 'contexts/UserContext';
import cn from 'classnames';
import { useMediaQuery } from 'react-responsive';

export const UserProfileSideBar = ({ profile, className }) => {
  const { t } = useTranslation();
  const { user } = useContext(UserContext);
  const isDesktop = useMediaQuery({
    query: '(min-width: 1025px)'
  });

  return (
    <div
      className={cn(className, {
        'animate-pulse': !profile
      })}
    >
      <div className="my-5 md:my-7">
        {profile.name ? (
          <h2 className="font-semibold text-2xl uppercase flex items-center">
            {profile.name}
            <ProfileBadge profile={profile} className="ml-2" size={30} />
          </h2>
        ) : (
          <div className="h-8 bg-gray-100 rounded-sm"></div>
        )}
        {profile.role && (
          <p className="text-xs md:text-sm text-gray-400">
            <span className="leading-5 md:leading-7 line-clamp-5">
              {profile?.companyName || !!profile?.companyName?.trim()?.length
                ? `${profile.role} ${t('at')} ${profile.companyName}`
                : profile.role}
            </span>
          </p>
        )}
      </div>
      {profile?.location && (
        <Location location={profile.location} className="my-7 text-gray-400" />
      )}

      {!isNaN(parseInt(profile.countFollowers)) ? (
        <ProfileFollowers
          id={profile.uid}
          followers={parseInt(profile.countFollowers)}
          following={parseInt(profile.countFollowing)}
        />
      ) : (
        <div className="flex">
          <div className="h-6 w-28 rounded-sm bg-gray-200 mr-3"></div>
          <div className="h-6 w-28 rounded-sm bg-gray-200"></div>
        </div>
      )}

      <div className="mt-5 mb-10 md:mb-0 md:mt-3">
        <Divider color="silver" className="my-5 lg:my-9" />

        <div className="company-social justify-center lg:justify-start mb-8">
          {profile.phoneNumber && (
            <a
              href={`tel:${profile?.phoneNumber}`}
              className="flex items-center my-4 group"
              target="_blank"
              rel="noreferrer"
            >
              <Icon
                name="Smartphone"
                width={14}
                height={22}
                canvasWidth={22}
                center={true}
                className="mr-3"
              />
              <span className="text-sm text-yellow underline group-hover:no-underline">
                {profile?.phoneNumber}
              </span>
            </a>
          )}
          {profile.email && (
            <a
              href={`mailto:${profile?.email}`}
              className="flex items-center group"
              target="_blank"
              rel="noreferrer"
            >
              <Icon
                name="Email"
                width={20}
                height={20}
                canvasWidth={22}
                center={true}
                className="mr-3"
              />
              <span className="text-sm text-yellow underline group-hover:no-underline">
                {profile?.email}
              </span>
            </a>
          )}
        </div>

        <div className="flex items-center company-social justify-center lg:justify-start pl-7 lg:pl-0">
          {profile.facebookURL && (
            <a
              href={`${profile.facebookURL}?utm_source=TheCraneClub`}
              target="_blank"
              rel="noopener noreferrer"
              className="mr-7"
              // onClick={() => handleOnWebSiteClick('facebook')}
            >
              <Icon name="Facebookyellow" width={25} height={25} />
            </a>
          )}

          {profile.instagramURL && (
            <a
              href={`${profile.instagramURL}?utm_source=TheCraneClub`}
              target="_blank"
              rel="noopener noreferrer"
              className="mr-7"
              // onClick={() => handleOnWebSiteClick('instagram')}
            >
              <Icon name="Instagram" width={26} height={21} />
            </a>
          )}

          {profile.linkedinURL && (
            <a
              href={`${profile.linkedinURL}?utm_source=TheCraneClub`}
              target="_blank"
              rel="noopener noreferrer"
              className="mr-7"
              // onClick={() => handleOnWebSiteClick('linkedIn')}
            >
              <Icon name="Linkedin" width={23} height={22} />
            </a>
          )}

          {profile.twitterURL && (
            <a
              href={`${profile.twitterURL}?utm_source=TheCraneClub`}
              target="_blank"
              rel="noopener noreferrer"
              className="mr-7"
              // onClick={() => handleOnWebSiteClick('twitter')}
            >
              <Icon name="Twitter" width={26} height={21} />
            </a>
          )}
        </div>

        {!user?.uid === profile?.uid && <ChatButton userId={profile.uid} />}
      </div>

      {!isDesktop && (
        <ProfileActionsMobile profile={profile} className="mt-6" />
      )}
    </div>
  );
};
