import { useRef } from 'react';
import { useHistory } from 'react-router-dom';

export const ChannelListPreviewItem = ({
  active,
  Avatar,
  channel,
  displayImage,
  displayTitle,
  latestMessage,
  setActiveChannel,
  unread,
  watchers
}) => {
  const history = useHistory();
  const channelPreviewButton = useRef(null);
  const activeClass = active
    ? 'str-chat__channel-preview-messenger--active'
    : '';
  const unreadClass =
    unread && unread >= 1 ? 'str-chat__channel-preview-messenger--unread' : '';

  const avatarName =
    displayTitle ||
    channel.state.messages[channel.state.messages.length - 1]?.user?.id;

  const onSelectChannel = () => {
    history.push(`/dashboard/chat/${channel.id}`);
  };

  return (
    <button
      aria-label={`Select Channel: ${displayTitle || ''}`}
      aria-selected={active}
      className={`str-chat__channel-preview-messenger ${unreadClass} ${activeClass}`}
      data-testid="channel-preview-button"
      onClick={onSelectChannel}
      ref={channelPreviewButton}
      role="option"
    >
      <div className="str-chat__channel-preview-messenger--left">
        <Avatar image={displayImage} name={avatarName} size={40} borderColor="#f7a709"/>
      </div>
      <div className="str-chat__channel-preview-messenger--right">
        <div className="str-chat__channel-preview-messenger--name">
          <span>{displayTitle}</span>
        </div>
        <div className="str-chat__channel-preview-messenger--last-message">
          {latestMessage}
        </div>
      </div>
    </button>
  );
};
