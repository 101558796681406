import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { Link } from 'react-router-dom';
import { Icon, CurrencyFormat } from 'components';

export const FeaturedData = ({
  icon,
  iconWidth,
  iconHeight,
  number,
  text,
  to,
  loading,
  className
}) => {
  const classes = cn(
    'bg-black-light  border-none rounded-lg py-4 md:py-6 px-4 md:px-9 flex items-start flex-col lg:flex-row lg:items-center',
    className,
    {
      'opacity-30': loading,
      'hover:border-yellow cursor-pointer': to
    }
  );

  const content = () => (
    <>
      <Icon
        name={icon}
        rounded
        center
        width={iconWidth}
        height={iconHeight}
        canvasWidth={45}
        canvasHeight={45}
        className={cn('bg-white shadow-none', {
          'opacity-0': loading
        })}
      />

      <div
        className={cn('text-white mt-4 lg:pl-4 lg:mt-0', {
          'opacity-0': loading
        })}
      >
        <strong className="font-semibold text-xxl block tracking-tighter leading-7">
          <CurrencyFormat value={number} />
        </strong>
        <span className="text-2xs whitespace-nowrap block">{text}</span>
      </div>
    </>
  );

  return to ? (
    <Link className={classes} to={to}>
      {content()}
    </Link>
  ) : (
    <div className={classes}>{content()}</div>
  );
};

FeaturedData.propTypes = {
  icon: PropTypes.string,
  number: PropTypes.number,
  text: PropTypes.string,
  iconWidth: PropTypes.number,
  loading: PropTypes.bool,
  iconHeight: PropTypes.number
};
