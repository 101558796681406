import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { getSearch } from 'api/publications';
import { getUsers } from 'api/profiles';
import { AvatarUser, Icon, Spinner } from 'components/';
import { useDebounce } from 'hooks/useDebounce';
import { useTranslation } from 'react-i18next';
import { profileToURL } from 'utils/formatter';
import cn from 'classnames';
import './index.scss';

export const SearchBarDropdown = ({
  type = 'machines',
  search = '',
  onClick,
  onClickSearch
}) => {
  const [results, setResults] = useState([]);
  const [loading, setLoading] = useState(false);
  const debouncedSearchTerm = useDebounce(search, 400);
  const history = useHistory();
  const { t } = useTranslation();

  useEffect(() => {
    if (debouncedSearchTerm) {
      setLoading(true);
      load();
    }
  }, [debouncedSearchTerm, type]);

  const load = async () => {
    let res =
      type === 'machines' ? await getSearch(search) : await getUsers(search);
    if (type === 'social') {
      res?.length > 5 && (res.length = 5);
    }
    setResults(res?.rows || res || []);
    setLoading(false);
  };

  const onUserClick = (user) => {
    history.push(profileToURL(user));
    onClick && onClick();
  };

  const makeBold = (item) => {
    const re = new RegExp(search.toLowerCase(), 'g');
    return item
      ?.toLowerCase()
      .replace(re, '<highlight>' + search + '<highlight>');
  };

  return (
    <div className="cb-search-bar-dropdown bg-black-light">
      {loading ? (
        <Spinner width={30} height={30} />
      ) : (
        <>
          <div className="px-5 mb-2.5">
            <hr />
          </div>

          {type === 'machines'
            ? results.map(({ result }, i) => (
                <div
                  key={i}
                  className="sb-item relative cursor-pointer py-2.5 px-12 hover:bg-yellow-light group transition duration-300"
                  onClick={() => onClick(result)}
                >
                  <Icon name="Search" />
                  <span className="transition duration-300 group-hover:text-yellow capitalize">
                    {makeBold(result)
                      ?.split('<highlight>')
                      .map((t, i) =>
                        i % 2 === 1 ? <span className="font-bold">{t}</span> : t
                      )}
                  </span>
                </div>
              ))
            : results.map((user, i) => (
                <div
                  key={i}
                  className="sb-item relative cursor-pointer py-1.5 px-5 hover:bg-yellow-light hover:bg-opacity-20 group transition duration-300"
                  onClick={() => onUserClick(user)}
                >
                  <AvatarUser user={user} size={40} className="w-full" />
                </div>
              ))}
          <button
            className={cn(
              'sb-item relative cursor-pointer px-12 hover:bg-yellow-light group transition duration-300 block w-full justify-start text-left',
              {
                'py-2.5': type === 'machines',
                'py-5': type === 'social'
              }
            )}
            onClick={() => onClickSearch()}
          >
            <Icon name="Search" />
            <span className="transition duration-300 group-hover:text-yellow">
              {t('searchFor')} <strong>{search}</strong>...
            </span>
          </button>
        </>
      )}
    </div>
  );
};
