import React, { Fragment } from 'react';
import { Input, FileInput, SecondaryEditor } from 'components';
import { isValidFile } from 'utils/validators';
import { useTranslation } from 'react-i18next';

export const SharedInputs = ({ errors, Controller, watch, control }) => {
  const { t } = useTranslation();
  return (
    <Fragment>
      <div className="mb-10 mt-2">
        <label>{t('relatedFiles')}</label>
        <div className="flex items-end mt-5 mb-10 md:mb-0">
          <Controller
            control={control}
            name="relatedFileName1"
            render={({ field }) => (
              <Input
                className="mt-1 w-40 md:w-96 mr-5 md:mr-10"
                disabled={!watch('relatedFile1')}
                placeholder={t('fileName')}
                {...field}
              />
            )}
          />
          <Controller
            control={control}
            name="relatedFile1"
            render={({ field }) => (
              <FileInput
                title="loadFile"
                validate={isValidFile}
                accept="application/pdf,.csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                {...field}
              />
            )}
          />
        </div>
        {watch('relatedFile1') && (
          <div className="flex items-end mt-2 mb-10 md:mb-0">
            <Controller
              control={control}
              name="relatedFileName2"
              defaultValue=""
              render={({ field }) => (
                <Input
                  className="mt-1 w-40 md:w-96 mr-5 md:mr-10"
                  disabled={!watch('relatedFile2')}
                  validate={isValidFile}
                  placeholder={t('fileName')}
                  {...field}
                />
              )}
            />
            <Controller
              control={control}
              name="relatedFile2"
              render={({ field }) => (
                <FileInput
                  accept="application/pdf,.csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                  title="loadFile"
                  validate={isValidFile}
                  {...field}
                />
              )}
            />
          </div>
        )}
        {watch('relatedFile2') && (
          <div className="flex items-end mt-2 mb-10 md:mb-0">
            <Controller
              control={control}
              name="relatedFileName3"
              defaultValue=""
              render={({ field }) => (
                <Input
                  className="mt-1 w-40 md:w-96 mr-5 md:mr-10"
                  placeholder={t('fileName')}
                  disabled={!watch('relatedFile3')}
                  {...field}
                />
              )}
            />
            <Controller
              control={control}
              name="relatedFile3"
              render={({ field }) => (
                <FileInput
                  accept="application/pdf,.csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                  title="loadFile"
                  validate={isValidFile}
                  {...field}
                />
              )}
            />
          </div>
        )}
        {watch('relatedFile3') && (
          <div className="flex items-end mt-2 mb-10 md:mb-0">
            <Controller
              control={control}
              name="relatedFileName4"
              defaultValue=""
              render={({ field }) => (
                <Input
                  className="mt-1 w-40 md:w-96 mr-5 md:mr-10"
                  placeholder={t('fileName')}
                  disabled={!watch('relatedFile4')}
                  {...field}
                />
              )}
            />
            )
            <Controller
              control={control}
              name="relatedFile4"
              render={({ field }) => (
                <FileInput
                  accept="application/pdf,.csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                  title="loadFile"
                  validate={isValidFile}
                  {...field}
                />
              )}
            />
          </div>
        )}
        {watch('relatedFile4') && (
          <div className="flex items-end mt-2 mb-10 md:mb-0">
            <Controller
              control={control}
              name="relatedFileName5"
              defaultValue=""
              render={({ field }) => (
                <Input
                  className="mt-1 w-40 md:w-96 mr-5 md:mr-10"
                  placeholder={t('fileName')}
                  disabled={!watch('relatedFile5')}
                  {...field}
                />
              )}
            />
            <Controller
              control={control}
              name="relatedFile5"
              render={({ field }) => (
                <FileInput
                  accept="application/pdf,.csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                  title="loadFile"
                  validate={isValidFile}
                  {...field}
                />
              )}
            />
          </div>
        )}
      </div>
      <label className="textarea tracking-lighter text-xl font-semibold mb-4">
        {t('description')}
      </label>
      <Controller
        control={control}
        name="description"
        render={({ field }) => <SecondaryEditor className="mt-6" {...field} />}
      />
    </Fragment>
  );
};
