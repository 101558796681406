import React from 'react';
import { Icon, AvatarUser } from 'components';
import { saveWebVisit } from 'api/userWebVisits';
import { useTranslation } from 'react-i18next';

export const PublicationCompany = ({ publication }) => {
  const { t } = useTranslation();

  const handleClickWebsite = async (type, web = '') => {
    if (web) {
      window.open(web, '_blank');
    }
    await saveWebVisit(publication?.userId, 'publication', type);
  };

  return (
    <>
      <div className="flex items-center mb-4 md:mb-2">
        <AvatarUser user={publication?.user} />
      </div>

      {publication?.user?.description && (
        <div className="md:pl-12 pb-5">
          <p className="text-sm md:text-sm text-silver line-clamp-5 leading-normal">
            {publication?.user?.description}
          </p>
        </div>
      )}
      <div className="md:pl-12 flex items-center">
        <Icon
          name="User"
          width={17}
          height={17}
          canvasWidth={19}
          center={true}
          className="mr-3"
        />
        <span className="text-yellow">{publication?.contactResponsable}</span>
      </div>
      <div className="md:pl-12">
        <a
          href={`tel:${publication?.contactPhone}`}
          onClick={() => handleClickWebsite('phone')}
          className="flex items-center my-4 group"
          target="_blank"
          rel="noreferrer"
        >
          <Icon
            name="Smartphone"
            width={11}
            height={19}
            canvasWidth={19}
            center={true}
            className="mr-3"
          />
          <span className="text-sm text-yellow underline group-hover:no-underline">
            +{publication?.contactPhone}
          </span>
        </a>
        <a
          href={`mailto:${publication?.user?.email}`}
          onClick={() => handleClickWebsite('email')}
          className="flex items-center group my-4"
          target="_blank"
          rel="noreferrer"
        >
          <Icon
            name="Email"
            width={17}
            height={17}
            canvasWidth={19}
            center={true}
            className="mr-3"
          />
          <span className="text-sm text-yellow underline group-hover:no-underline">
            {publication?.user?.email}
          </span>
        </a>
        <a
          onClick={() =>
            handleClickWebsite('web', publication?.user?.websiteURL)
          }
          href={`${publication?.user?.websiteURL}?utm_source=TheCraneClub`}
          variant="link"
          className="flex items-center group my-4"
          target="_blank"
          rel="noreferrer"
        >
          <Icon
            name="World"
            width={17}
            height={17}
            canvasWidth={19}
            center={true}
            className="mr-3"
          />
          <span className="text-sm text-yellow underline group-hover:no-underline">
            {publication?.user?.websiteURL}
          </span>
        </a>
        <div className="flex items-center company-social justify-center lg:justify-start pl-7 pt-2 lg:pl-0">
          {publication?.user?.facebookURL && (
            <a
              onClick={() =>
                handleClickWebsite('facebook', publication?.user?.facebookURL)
              }
              href={`${publication?.user?.facebookURL}?utm_source=TheCraneClub`}
              variant="link"
              className="flex items-center group my-4"
              target="_blank"
              rel="noreferrer"
            >
              <Icon
                name="Facebookyellow"
                width={17}
                height={17}
                canvasWidth={19}
                center={true}
                className="mr-3"
              />
            </a>
          )}
          {publication?.user?.instagramURL && (
            <a
              onClick={() =>
                handleClickWebsite('instagram', publication?.user?.instagramURL)
              }
              href={`${publication?.user?.instagramURL}?utm_source=TheCraneClub`}
              variant="link"
              className="flex items-center group my-4"
              target="_blank"
              rel="noreferrer"
            >
              <Icon
                name="Instagram"
                width={17}
                height={17}
                canvasWidth={19}
                center={true}
                className="mr-3"
              />
            </a>
          )}
          {publication?.user?.linkedinURL && (
            <a
              onClick={() =>
                handleClickWebsite('linkedin', publication?.user?.linkedinURL)
              }
              href={`${publication?.user?.linkedinURL}?utm_source=TheCraneClub`}
              variant="link"
              className="flex items-center group my-4"
              target="_blank"
              rel="noreferrer"
            >
              <Icon
                name="Linkedin"
                width={17}
                height={17}
                canvasWidth={19}
                center={true}
                className="mr-3"
              />
            </a>
          )}
          {publication?.user?.twitterURL && (
            <a
              onClick={() =>
                handleClickWebsite('twitter', publication?.user?.twitterURL)
              }
              href={`${publication?.user?.twitterURL}?utm_source=TheCraneClub`}
              variant="link"
              className="flex items-center group my-4"
              target="_blank"
              rel="noreferrer"
            >
              <Icon
                name="Twitter"
                width={17}
                height={17}
                canvasWidth={19}
                center={true}
                className="mr-3"
              />
            </a>
          )}
        </div>
      </div>
    </>
  );
};
