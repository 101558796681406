import { forwardRef } from 'react';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { Icon } from 'components';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';

import './index.scss';

export const Dropdown = forwardRef(
  (
    {
      value,
      items = [],
      onChange = () => {},
      label,
      placeholder,
      className,
      error,
      variant = 'outlined',
      inputClasses
    },
    ref
  ) => {
    const { t } = useTranslation();
    return (
      <div className={cn('cb-select', className, { error })}>
        <FormControl variant={variant} className="w-full">
          {label && !value && <span className="label">{label}</span>}
          <Select
            ref={ref}
            value={value}
            onChange={(e) => onChange(e.target.value)}
            className={cn(inputClasses)}
            IconComponent={(props) => <Icon name="ChevronDown" {...props} />}
          >
            {placeholder && (
              <MenuItem disabled value={undefined}>
                <em>{placeholder}</em>
              </MenuItem>
            )}
            {items.map((item, index) => (
              <MenuItem
                key={index}
                value={typeof item === 'object' ? item.value : item}
              >
                {item.text || item}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        {error && (
          <span className="text-2xs text-yellow-dark flex justify-end mt-1">
            {t(`form.${error.type}`)}
          </span>
        )}
      </div>
    );
  }
);
