import { useState } from 'react';
import cn from 'classnames';
import { ForwardButton } from 'components';
import { useTranslation } from 'react-i18next';

export const CategoryCard = ({
  category: { logoURL, name },
  buttonText = 'select',
  disabled = false,
  ...rest
}) => {
  const [loaded, setLoaded] = useState(false);
  const { t } = useTranslation();

  return (
    <div {...rest}>
      <img
        src={logoURL}
        alt={name}
        onLoad={() => setLoaded(true)}
        className={cn(
          'mb-1 md:mb-2 transition transform duration-300 block w-full group-hover:scale-105',
          {
            hidden: !loaded
          }
        )}
      />
      {!loaded && (
        <div className="h-52 p-5 mb-4 opacity-20 w-full">
          <div className="bg-gray-dark h-full w-full rounded-xl" />
        </div>
      )}
      <div className="px-4 md:px-6 flex flex-col h-full">
        <span className="text-base md:text-xl leading-7 tracking-tight capitalize text-white-secondary self-start">
          {name}
        </span>
        <div>
          {!disabled && (
            <ForwardButton className="mt-2.5">{t(buttonText)}</ForwardButton>
          )}
        </div>
      </div>
    </div>
  );
};
