import React, {
  createContext,
  useEffect,
  useState,
  useCallback,
  useRef
} from 'react';
import { useTranslation } from 'react-i18next';
import chatClient from 'services/chat';
import { getMe } from 'api/user';
import { useChatEvents } from 'hooks';
import { NewNotification, Icon } from 'components';
import { Link, useHistory } from 'react-router-dom';
import { getNotifications, seenAll } from 'api/notifications';
import { profileToURL } from 'utils/formatter';
import { Capacitor } from '@capacitor/core';
import { toast } from 'react-toastify';
import { NOTIFICATIONS_PER_PAGE } from 'utils/constants';
import { addUserNotificationToken } from 'api/userNotificationTokens';
import { PushNotifications } from '@capacitor/push-notifications';
import { FCM } from '@capacitor-community/fcm';
import { io } from 'socket.io-client';

export const UserContext = createContext();

export default function Provider({ children }) {
  const [user, setUser] = useState(undefined);
  const notificationsRef = useRef([]);
  const quotationNotificationsRef = useRef([]);
  const history = useHistory();
  const [socket, setSocket] = useState(null);
  const [notifications, setNotifications] = useState([]);
  const [unseenNotifications, setUnseenNotifications] = useState(0);
  const [quotationsNotifications, setQuotationsNotifications] = useState([]);
  const [unseenQuotations, setUnseenQuotations] = useState(0);
  const [chatConnected, setChatConnected] = useState(false);
  const { t, i18n } = useTranslation();
  const { listenForEvents } = useChatEvents();

  const disconnectSocket = () => {
    if (socket?.connected) {
      socket.disconnect();
      setSocket(null);
    }
  };

  useEffect(() => {
    if (user && !socket) {
      const newSocket = io(process.env.REACT_APP_SOCKET_URL, {
        transports: ['websocket'],
        query: `userId=${user.uid}`
      });
      setSocket(newSocket);
      newSocket.on(`notification`, (data) => {
        showNewNotification(data);
      });
    }
    return disconnectSocket;
  }, [user, socket]);

  useEffect(() => {
    loadUser();
  }, []);

  useEffect(() => {
    notificationsRef.current = { notifications, unseenNotifications };
  }, [notifications, unseenNotifications]);

  useEffect(() => {
    quotationNotificationsRef.current = {
      quotationsNotifications,
      unseenQuotations
    };
  }, [quotationsNotifications, unseenQuotations]);

  const initChatClient = useCallback(
    async (user) => {
      try {
        if (process.env.REACT_APP_CHAT_DISCONNECTED === 'true') {
          return;
        }
        setChatConnected(true);
        await chatClient.connectUser(
          {
            id: user.uid.toString(),
            name: user.name,
            image: user.photoURL,
            photoURL: user.photoURL,
            profileURL: profileToURL(user)
          },
          user.chatToken
        );
        await listenForEvents(user.uid);
      } catch (error) {}
    },
    [listenForEvents]
  );

  const initNotifications = useCallback(async () => {
    Capacitor.isNativePlatform() &&
      PushNotifications.checkPermissions().then((res) => {
        if (res.receive !== 'granted') {
          PushNotifications.requestPermissions().then((res) => {
            if (res.receive !== 'denied') {
              registerPushNotifications();
            }
          });
        } else {
          registerPushNotifications();
        }
      });
  });

  const registerPushNotifications = () => {
    // Register with Apple / Google to receive push via APNS/FCM
    PushNotifications.register();

    // On success, we should be able to receive notifications
    PushNotifications.addListener('registration', async ({ value }) => {
      let token = value;
      if (Capacitor.getPlatform() === 'ios') {
        const { token: FCMToken } = await FCM.getToken();
        token = FCMToken;
      }

      await addUserNotificationToken(token);
      await chatClient.addDevice(token, 'firebase');
    });

    // Some issue with our setup and push will not work
    PushNotifications.addListener('registrationError', (error) => {});

    // Method called when tapping on a notification
    PushNotifications.addListener(
      'pushNotificationActionPerformed',
      (notification) => {
        notification?.notification?.data?.url &&
          history.push(notification.notification.data.url);
      }
    );
  };

  const disconnectChatClient = useCallback(async () => {
    user && (await chatClient.disconnectUser());
  }, []);

  useEffect(() => {
    if (user && user?.chatToken && !chatConnected) {
      initChatClient(user);
      initNotifications();
    }
    return () => {
      disconnectChatClient();
      // Capacitor.isNativePlatform() && PushNotifications.removeAllListeners();
    };
  }, [user, initChatClient, chatConnected, disconnectChatClient]);

  const loadUser = async (user, newUser = false) => {
    try {
      const me = user || (await getMe());
      setUser(newUser ? { ...me, isNew: true } : me);
      if (me) {
        me.profileURL = profileToURL(me);
        const {
          notifications,
          quotationNotifications,
          unseen,
          unseenQuotation
        } = await getNotifications(0, NOTIFICATIONS_PER_PAGE);
        setNotifications(notifications?.rows || []);
        setUnseenNotifications(unseen);
        if (me?.isCompany) {
          setQuotationsNotifications(quotationNotifications?.rows || []);
          setUnseenQuotations(unseenQuotation);
          if (me && me.language) {
            i18n.changeLanguage(me.language);
          }
        }
        if (me.language) {
          i18n.changeLanguage(me.language);
        }
      }
    } catch (error) {
      setUser(null);
    }
  };

  const showNewNotification = (notification) => {
    const isQuotation = notification.type === 'new-publication-quotation';
    toast(
      <NewNotification
        notification={notification}
        title={isQuotation ? t(notification.type) : t('newNotification')}
        user={notification.user}
      >
        <Link
          className="hover:underline decoration-1 text-sm"
          to={notification.url || 'dashboard/notifications'}
        >
          {isQuotation ? (
            <>
              {t('requestAQuoteFor')}{' '}
              <span className="semibold hover:underline">
                {t(notification.description)}
              </span>
            </>
          ) : (
            t(notification.type)
          )}
        </Link>
      </NewNotification>,
      {
        closeButton: (
          <Icon
            name="Close"
            style={{ top: '1.5rem', right: '1.5rem', position: 'absolute' }}
          />
        )
      }
    );
    if (isQuotation) {
      setQuotationsNotifications([
        notification,
        ...quotationNotificationsRef.current.quotationsNotifications
      ]);
      setUnseenQuotations(
        quotationNotificationsRef.current.unseenQuotations + 1
      );
    } else {
      setNotifications([
        notification,
        ...notificationsRef.current.notifications
      ]);
      setUnseenNotifications(notificationsRef.current.unseenNotifications + 1);
    }
  };

  const readAllNotification = async () => {
    try {
      await seenAll();
      const _notifications = notificationsRef.current.notifications || [];
      setNotifications(
        _notifications.map((notification) => ({
          ...notification,
          seen: true
        }))
      );
      setUnseenNotifications(0);
    } catch (error) {
      throw error;
    }
  };

  const readAllQuotationNotification = async () => {
    try {
      await seenAll();
      const _notifications =
        quotationNotificationsRef.current.notifications || [];
      setQuotationsNotifications(
        _notifications.map((notification) => ({
          ...notification,
          seen: true
        }))
      );
      setUnseenQuotations(0);
    } catch (error) {
      throw error;
    }
  };

  return (
    <UserContext.Provider
      value={{
        user,
        notifications,
        unseenNotifications,
        unseenQuotations,
        quotationsNotifications,
        setUser,
        loadUser,
        setNotifications,
        setUnseenNotifications,
        readAllNotification,
        setUnseenQuotations,
        readAllQuotationNotification
      }}
    >
      {children}
    </UserContext.Provider>
  );
}
