import React, { useState, useRef } from 'react';
import { Icon, AvatarEditorModal } from 'components';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import { useModal } from 'hooks/useModal';

export const AvatarUpload = ({
  className,
  label,
  onChange = () => {},
  icon = 'Company',
  error,
  errorMessage
}) => {
  const ref = useRef();
  const { t } = useTranslation();
  const [image, setImage] = useState(null);
  const [newAvatarImage, setNewAvatarImage] = useState(null);
  const { visible: avatarEditorVisible, toggleModal: avatarEditorToggle } =
    useModal();

  const handleChangeFile = (e) => {
    const file = e.target.files[0];
    setNewAvatarImage(file);
    avatarEditorToggle(true);
  };

  const onConfirmAvatarEditor = (file) => {
    const object = {
      file,
      url: URL.createObjectURL(file)
    };
    setImage(object);
    onChange(object);
  };

  return (
    <>
      <div
        onClick={() => ref.current.click()}
        className="mb-4 flex items-center mt-4 cursor-pointer"
      >
        <input
          onChange={handleChangeFile}
          type="file"
          accept="image/*"
          hidden
          ref={ref}
        />
        <div className="relative flex-shrink-0 h-16 w-16 mr-4">
          <div
            className={cn(
              'rounded-full mx-auto h-full w-full overflow-hidden relative p-1 border-1/2 border-yellow border-solid object-cover flex justify-center items-center hover:bg-black-light ',
              className
            )}
          >
            {image ? (
              <img
                className="object-contain absolute top-0 left-0 h-full w-full"
                src={image?.url}
                alt={t('companyLogo')}
              />
            ) : (
              <Icon name={icon} className="text-silver" />
            )}
          </div>
          {!image && (
            <Icon
              name="Plus"
              width={10}
              height={10}
              className="absolute bottom-0 -right-2 z-10 bg-black-light "
              rounded
            />
          )}
        </div>
        <span className="font-normal text-xs text-gray-400 text-yellow">
          {label}
        </span>
        {error && (
          <span className="text-2xs text-yellow-dark flex justify-start mt-1">
            {errorMessage ? errorMessage : t(`form.${error.type}`)}
          </span>
        )}
      </div>
      <AvatarEditorModal
        visible={avatarEditorVisible}
        toggleModal={avatarEditorToggle}
        image={newAvatarImage}
        onConfirm={onConfirmAvatarEditor}
      />
    </>
  );
};
