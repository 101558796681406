import React from 'react';
import CTooltip from '@mui/material/Tooltip';

export const Tooltip = ({ placement = 'right', children, ...rest }) => {
  return (
    <CTooltip placement={placement} {...rest}>
      {children}
    </CTooltip>
  );
};
